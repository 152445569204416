import React, { useEffect, useState, useContext, useReducer } from "react";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import CandidatePipelineService from "../../api/CandidatePipelineService";
import { AuthContext } from "../../Context/AuthContext";
import "datatables.net";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { click } from "@testing-library/user-event/dist/click";
import ReactTooltip from "react-tooltip";
import DemandService from "../../api/DemandService";
import DataTablee from "datatables.net-dt";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import "datatables.net";
import "datatables.net-fixedcolumns-dt";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
const _CandidatePipeLineService = new CandidatePipelineService();
const _DemandService = new DemandService();
const ConfigurationServiceobj = new ApplicarionConfigService();

const CandidatePipeline = () => {
  const { cookies } = useContext(AuthContext);
  const [DemandID, setDemandID] = useState(null);
  const [DemandCode, setDemandCode] = useState(null);
  const [Client, setClient] = useState(null);
  const [Experience, setExperience] = useState(null);
  const [Skill, setSkill] = useState(null);
  const [CandidateList, setCandidateList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [SkillDate, setSkillDate] = useState(null);
  const [DemandStep, setDemandStep] = useState(null);
  const [NextStepName, setNextStepName] = useState(null);
  const [Filetype, setFiletype] = useState(null);
  const [Isview, setIsview] = useState(null);
  const [PopName, setPopName] = useState("Add");
  const [ButtonName, setButtonName] = useState("Add");
  const [AcceptID, setAcceptID] = useState("");
  const [LocationStatus, setLocationStatus] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [MandatoryList, setMandatoryList] = useState({});
  const [activeRoleName, setactiveRoleName] = useState();
  const [DemandActiveTab, setDemandActiveTab] = useState();
  const [roleName, setRoleName] = useState(cookies?.roleName);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [companyID, setCompanyID] = useState(cookies?.companyID);

  const [selectedCompanyID, setSelectedCompanyID] = useState(companyID);
  const [CompanyLocation, setCompanyLocation] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);





  useEffect(() => {
    $(".loader-container").show();
    $(".submenu").removeClass("active");
    $("#Demandmenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Demand").addClass("show");

    $(".submenu li").removeClass("active");
    $("#CandidatePipe").addClass("active");

    // $("#DemandTable").dataTable();

    // $('#candidateProfiletable').dataTable();
    $("#candidateProfiletable").DataTable().destroy();
    _CandidatePipeLineService
      .getSkillData("SKILLSET")
      .then((res) => {
        setSkillDate(res.data);
        console.log(res.data);
      })
      .catch((res) => { });

    _CandidatePipeLineService
      .getSkillData("LOCSTATUS")
      .then((res) => {
        setLocationStatus(res.data);
        console.log(res.data);
      })
      .catch((res) => { });

    _CandidatePipeLineService
      .getSkillData("COMPANYNAME")
      .then((res) => {
        setCompanyLocation(res.data);
        console.log(res.data);
      })
      .catch((res) => { });

    GetLocationDetails();

    if (location.state !== null) {

      setDemandID(location.state.DemandReqID);
      setDemandCode(location.state.Dcode);
      setClient(location.state.client);
      setExperience(location.state.exp);
      setSkill(location.state.skill);
      setDemandStep(location.state.Step);
      setNextStepName(location.state.NextStepName);
      setIsview(location.state.isview);
      setactiveRoleName(location?.state?.activeRoleName)
      setDemandActiveTab(location.state.activeTab)
    }
    location.state = null;
    GetCandidateProfileByDemandID()
    ConfigurationServiceobj.GetFormMandatoryField()
      .then((res) => {
        console.log(res);

        pivot(res.data);
        // setMandatoryList([...res.data]);
      })
      .catch((error) => { });
    let table = new DataTablee("#candidateProfiletable");
  }, [Render]);
  useEffect(() => {
    GetLocationDetails();
  }, [selectedLocation]);

  const GetLocationDetails = () => {
    ConfigurationServiceobj.GetLocationDetails()
      .then((res) => {
        if (res.data.length > 0) {
          setLocationList(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DId = location.state !== null ? location?.state?.DemandReqID : DemandID
  const activeRole = activeRoleName == undefined || activeRoleName == null ? location?.state?.activeRoleName : activeRoleName

  function pivot(res) {

    const pivotedList = res.reduce((result, item) => {
      const key = item.FormFieldName;


      if (!result[key]) {
        result[key] = item.IsMandatory === 1 ? "*" : "";
      } else {
        result[key] += "";
      }

      return result;
    }, {});
    setMandatoryList(pivotedList);

  }

  const GetCandidateProfileByDemandID = () => {

    _CandidatePipeLineService
      .GetCandidateProfileByDemandID(
        DId,
        Number(cookies.userID),
        activeRole)
      .then((res) => {

        setCandidateList(res.data);
        console.log(res.data);
        $("#candidateProfiletable").DataTable().destroy();
        $(".loader-container").hide();
      })
      .catch((res) => {
        $(".loader-container").hide();
      });
  }

  useEffect(() => {
    $("#candidateProfiletable").DataTable().destroy();
    $("#candidateProfiletable").DataTable({
      scrollX: true,
      responsive: true,
      ordering: false,
      scrollCollapse: true,
      autoWidth: false,
      fixedColumns: true,
      columnDefs: [
        {
          targets: 0,
          width: "10px",
          orderable: false,
          responsivePriority: 1,
        },
        {
          targets: 1,
          width: "10px",
          orderable: false,
          responsivePriority: 1,
        },
      ],
      fixedColumns: {
        left: 2,
        // right: 1,
      },
    });
  }, [CandidateList]);

  const [CandidateErr, setCandidateErr] = useState({
    CandidateID: "",
    CandidateFirstName: "",
    CandidateLastName: "",
    ContactNumber: "",
    ContactEmail: "",
    Location: "",
    SkillSetID: "",
    Experience: "",
    Education: "",
    DateUpload: "",
    // FileUploadpath: 'fef',
    FileUploadpath: "",
    Filetype: "",
    DemandID: "",
    CompanyLocation: "",
  });

  const AddCandidate = (e) => {

    var flag = true;
    var ValidationErrrr = {};

    $(".loader-container").show();
    e.preventDefault();
    const formdata = new FormData(e.currentTarget);
    const CandidateDetails = {
      CandidateID: Number($("#CandidateID").val()),
      CandidateFirstName: formdata.get("FirstName"),
      CandidateLastName: formdata.get("lastName"),
      ContactNumber: formdata.get("ConNumber"),
      ContactEmail: formdata.get("email"),
      Location: selectedLocation,
      SkillSetID: Number($("#SkillSet").val()),
      Experience: Number(formdata.get("Experience")),
      Education: formdata.get("Education"),
      DateUpload: startDate,
      // FileUploadpath: 'fef',
      FileUploadpath: Filetring,
      Filetype: Filetype,
      EmployeeID: Number(cookies.userID),
      DemandID: DemandID,
      CompanyLocation: selectedCompanyID,
    };

    // if(CandidateDetails.CandidateID != null || CandidateDetails.CandidateID != '' ||){
    //     setCandidateErr({ CandidateID: "Please Enter Username" });
    //     return false;
    // }
    if (MandatoryList["CandidateFirstName"] == "*") {
      if (
        CandidateDetails.CandidateFirstName == null ||
        CandidateDetails.CandidateFirstName == ""
      ) {

        ValidationErrrr.CandidateFirstName = "Please Enter Candidate FirstName"

        $(".loader-container").hide();
        flag = false;
      }
    }
    if (MandatoryList["CandidateLastName"] == "*") {
      if (
        CandidateDetails.CandidateLastName == null ||
        CandidateDetails.CandidateLastName == ""
      ) {

        ValidationErrrr.CandidateLastName = "Please Enter Candidate LastName"

        $(".loader-container").hide();
        flag = false;
      }
    }
    if (MandatoryList["ContactNumber"] == "*") {
      if (
        CandidateDetails.ContactNumber == null ||
        CandidateDetails.ContactNumber == ""
      ) {
        ValidationErrrr.ContactNumber = "Please Enter Contact Number"
        $(".loader-container").hide();
        flag = false;
      }
    }
    if (MandatoryList["ContactEmail"] == "*") {
      if (
        CandidateDetails.ContactEmail == null ||
        CandidateDetails.ContactEmail == ""
      ) {
        ValidationErrrr.ContactEmail = "Please Enter Contact Email"
        $(".loader-container").hide();
        flag = false;
      } else {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            CandidateDetails.ContactEmail
          )
        ) {
          ValidationErrrr.ContactEmail = "Email ID is Not Vaild"
          $(".loader-container").hide();
          flag = false;
        }
      }
    }
    if (MandatoryList["Location"] == "*") {
      if (
        CandidateDetails.Location == null ||
        CandidateDetails.Location == ""
      ) {
        ValidationErrrr.Location = "Please Enter Location"
        $(".loader-container").hide();
        flag = false;
      }
    }
    if (MandatoryList["SkillSet"] == "*") {
      if (
        CandidateDetails.SkillSetID == null ||
        CandidateDetails.SkillSetID == ""
      ) {
        ValidationErrrr.SkillSetID = "Please Select Skill Set"
        $(".loader-container").hide();
        flag = false;
      }
    }
    if (MandatoryList["Experience"] == "*") {
      if (
        CandidateDetails.Experience == null ||
        CandidateDetails.Experience == ""
      ) {
        ValidationErrrr.Experience = "Please Enter Experience"
        $(".loader-container").hide();
        flag = false;
      }
    }
    if (MandatoryList["Education"] == "*") {
      if (
        CandidateDetails.Education == null ||
        CandidateDetails.Education == ""
      ) {
        ValidationErrrr.Education = "Please Enter Education"
        $(".loader-container").hide();
        flag = false;
      }
    }
    if (MandatoryList["DateApplied"] == "*") {
      if (
        CandidateDetails.DateUpload == null ||
        CandidateDetails.DateUpload == ""
      ) {
        ValidationErrrr.DateUpload = "Please Enter Date"
        $(".loader-container").hide();
        flag = false;
      }
    }
    if (MandatoryList["FileUploadpath"] == "*") {
      if (CandidateDetails.CandidateID == 0) {
        if (
          CandidateDetails.FileUploadpath == null ||
          CandidateDetails.FileUploadpath == ""
        ) {
          ValidationErrrr.FileUploadpath = "Please Select File"
          $(".loader-container").hide();
          flag = false;
        }
      }
    }
    if (MandatoryList["CompanyLocation"] == "*") {
      if (
        CandidateDetails.CompanyLocation == null ||
        CandidateDetails.CompanyLocation == 0
      ) {
        ValidationErrrr.CompanyLocation = "Company Location is required";

        flag = false;
      }
    }

    if (Object.keys(ValidationErrrr).length > 0) {
      // Display all validation errors
      setCandidateErr(ValidationErrrr);
    } else {
      if (flag) {
        SaveCandidateData(CandidateDetails);
      }
    }
  };

  const SaveCandidateData = (CandidateDetails) => {
    _CandidatePipeLineService
      .CreateCandidate(CandidateDetails)
      .then((res) => {
        $(".loader-container").hide();
        swal({
          text: "Candidate profile has been saved successfully",
          icon: "success",
          button: "Ok",
        });
        // $('.close').trigger("click");
        ClosePop();
        ClearData();
        GetCandidateProfileByDemandID()
        setPopName("Add");
      })
      .catch((res) => {
        $(".loader-container").hide();
      });
  };

  function ClearData() {
    $("#CandidateID").val(0);
    $("#FirstName").val("");
    $("#LastName").val("");
    $("#ConNumber").val("");
    $("#ConEmail").val("");
    $("#Location").val("");
    $("#SkillSet").val("");
    $("#Experience").val("");
    $("#Education").val("");
    setSelectedLocation()
    setStartDate(new Date());
    // FileUploadpath: 'fef',
    setFileString(null);
    setFiletype(null);
    $("#Resume").val("");
    setCandidateErr({});
   
  }

  // const ResumeFile = (e) => {
  //     ;
  //     var f = document.querySelector('#Resume').files[0];
  // }
  const [Filetring, setFileString] = useState(0);
  const ResumeFile = (e) => {
    var files = e.target.files;
    var FileName = files[0].name.split(".");
    var extension = FileName[1];
    setFiletype(extension);
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      var filestr = e.target.result.split(",");
      setFileString(filestr[1]);
    };
    setCandidateErr({ FileUploadpath: "" });
  };

  const EditCandidate = (
    candidateID,
    candidateName,
    contactNumber,
    contactEmail,
    location,
    skillSetID,
    experience,
    education,
    dateApplied,
    FileStr,
    filetype,
    LocSta
  ) => {
    
    setPopName("Edit");
    setButtonName("Update");
    var Name = candidateName.replace(" ", "-");
    Name = Name.split("-");
    var DateApp = dateApplied.split("-");
    DateApp = DateApp[2] + "-" + DateApp[1] + "-" + DateApp[0];
 
    $("#CandidateID").val(candidateID);
    $("#FirstName").val(Name[0]);
    $("#LastName").val(Name[1]);
    $("#ConNumber").val(contactNumber);
    $("#ConEmail").val(contactEmail);
    $("#Location").val(location);
    $("#Experience").val(experience);
    $("#SkillSet").val(skillSetID);
    $("#Education").val(education);
    $("#LocationStatus").val(LocSta);
    setSelectedLocation(location)
    setStartDate(new Date(DateApp));

    setFileString(FileStr);
    setFiletype(filetype);
  };
  const DeleteCandidate = (candidateID) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this candidate profile?",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        $(".loader-container").show();
        _CandidatePipeLineService
          .DeleteCandidate(Number(candidateID))
          .then((res) => {
            $(".loader-container").hide();
            swal({
              text: "Candidate profile has been deleted successfully",
              icon: "success",
              button: "Ok",
            });
            GetCandidateProfileByDemandID()
          })
          .catch((res) => { });
      }
    });
  };

  const MoveForward = (
    DemandId,
    StepName,
    DemandStep,
    ProfileLevel,
    DemandRID,
    Sla
  ) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to move forward this candidate profile?",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {

        _CandidatePipeLineService
          .MoveForward(
            Number(DemandId),
            StepName,
            DemandStep,
            ProfileLevel,
            Number(cookies.userID),
            DemandRID,
            Sla
          )
          .then((res) => {
            if (res.data != -1) {
              
              GetCandidateProfileByDemandID()
              swal({
                text: "Candidate profile has been move forwarded successfully",
                icon: "success",
                button: "Ok",
              });
            }
            else {
              swal({
                text: "This profile is under Assessment!",
                icon: "warning",
                button: "Ok",
              });
            }
            // navigate('/DemandIndex')

          })
          .catch((res) => { });
      }
    });
  };

  const Reject = (CandidateID, DemandStep) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this candidate profile?",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        $("#CandidateIDReject").val(CandidateID);
        $("#DemandStep").val(DemandStep);
        $(".AddcommmentBtn").click();
      }
    });
  };

  const MapCandidate = (CandidateID, DemandID, flag) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to " + flag + " this Candidate?",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        var r = CandidateID;
        var t = DemandID;

        _CandidatePipeLineService
          .CandidateMapping(Number(CandidateID), Number(DemandID))
          .then((response) => {
            swal({
              text: "Candidate " + flag + " Successfully",
              icon: "success",
              button: "Ok",
            });
            GetCandidateProfileByDemandID()
          })
          .catch((error) => { });
      }
    });
  };
  const filedownload = (id, firstname, filetypesrc) => {
    // e.preventDefault();
    const obj = {
      CandidateID: Number(id),
    };
    _CandidatePipeLineService
      .filedownload(obj)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", firstname + "." + filetypesrc);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((res) => { });
  };

  const [CommentErr, setDemandErr] = useState("");

  const RejectCandidate = () => {
    var CandidateID = $("#CandidateIDReject").val();
    var DemandStep = $("#DemandStep").val();
    var Comment = $("#CommentTxtArea").val();

    if (Comment == null || Comment == "") {
      setDemandErr("Please fill the comment");
      return false;
    }

    _CandidatePipeLineService
      .Reject(Number(CandidateID), DemandStep, Comment)
      .then((res) => {
        swal({
          text: "Candidate profile has been rejected",
          icon: "success",
          button: "Ok",
        });
        // navigate('/DemandIndex')
        ClosePop();
        GetCandidateProfileByDemandID()
      })
      .catch((res) => { });
  };

  const ClosePop = () => {
    $(".close").click();
    if (PopName == "Add") {
      setPopName("Edit");
    }
    if (PopName == "Edit") {
      setPopName("Add");
    }

    if (ButtonName == "Add") {
      setButtonName("Update");
    }
    if (ButtonName == "Update") {
      setButtonName("Add");
    }
    ClearData();
  };

  function ChangePopName() {
    // ClosePop();
    setPopName("Add");
    setButtonName("Add");
  }

  function BackTodemand() {
    navigate("/DemandIndex", { state: { activeTab: DemandActiveTab } });
  }
  function numberOnly(id) {
    var element = document.getElementById(id);
    element.value = element.value.replace(/[^0-9]/gi, "");

    if (id == "ConNumber") {
      setCandidateErr({ ContactEmail: "" });
    }
    if (id == "Experience") {
      setCandidateErr({ Experience: "" });
    }
  }
  const ChangeDate = (date) => {
    setStartDate(date);
    setCandidateErr({ DateUpload: "" });
  };

  var Arr = [];
  const Accept = (e) => {
    Arr.push(e);
    console.log(Arr);
    // setAcceptID(Arr.join(','))
  };

  const AcceptScreen = () => {
    var ArrString = Arr.join(",");
    var Step = DemandStep;

    if (ArrString == null || ArrString == "") {
      return false;
    }
    if (Step == null || Step == "") {
      return false;
    }

    _DemandService.AcceptAssessment(Number(0), Step).then((res) => {
      swal({
        title: "SLA",
        text:
          "On your acceptance, SLA will be initiated. SLA target is " +
          res.data,
        icon: "info",
        buttons: true,
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          _CandidatePipeLineService
            .SLA_AcceptScreen(ArrString, Step)
            .then((res) => {
              location.state = null;
              GetCandidateProfileByDemandID()
            })
            .catch((res) => { });
        }
      });
    });
  };
  const PMOAccept = (DemandCandidateID, Step) => {
    _DemandService.AcceptAssessment(Number(0), Step).then((res) => {
      swal({
        title: "SLA",
        text:
          "On your acceptance, SLA will be initiated. SLA target is " +
          res.data,
        icon: "info",
        buttons: true,
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          _CandidatePipeLineService
            .SLA_AcceptScreen(DemandCandidateID, Step)
            .then((res) => {
              GetCandidateProfileByDemandID()
            })
            .catch((res) => { });
        }
      });
    });
  };

  function IsMandatory(Fieldname, IsMandatory, Param) {
    if (Fieldname == Param && IsMandatory == 1) {
      return <>*</>;
    } else {
      return <></>;
    }
  }
  const TableHeader = (e, ev) => {
    var html = "";
    var body = "";

    html += `<th>Skill Set</th>
    <th>Experience</th>
    <th>Education</th><th>Created Date</th>`;
    body += `
    <td>${e.skillSetName}</td>
    <td>${e.experience}</td>
    <td>${e.education}</td><td>${e.dateApplied}</td>`;

    let table = new DataTablee("#candidateProfiletable");
    let tr = ev.target.closest("td");
    let row = table.row(tr);

    if (row.child.isShown()) {
      // This row is already open - close it
      row.child.hide();
    } else {
      // Open this row
      row
        .child(
          `<table class='subTable'><thead><tr>${html}</tr></thead><tbody><tr>${body}</tr></tbody></table>`
        )
        .show();
    }

    // return `<table id='SubTable'><thead><th>${html}</tr></thead><tbody><tr>${body}</tr></tbody></table>`;
  };

  const CompanyIDOnChange = (e) => {
    const val = e.target.value
    setSelectedCompanyID(val)
  }

  function alphabetOnly(e) {
    const inputChar = String.fromCharCode(e.charCode);
    if (!/^[a-zA-Z]+$/.test(inputChar)) {
      e.preventDefault();
    }
  }

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4
                        className="card-title mr-auto"
                        style={{ width: "85%" }}
                      >
                        Candidate Pipeline
                      </h4>
                      {priviledge.includes("CANDPIPCREATE") && (
                        <button
                          className="btn btn-primary btn-round btn-sm ml-auto"
                          data-bs-toggle="modal"
                          onClick={(e) => ChangePopName()}
                          data-bs-target="#addRowModal"
                          style={{ marginRight: "10px" }}
                        >
                          <i className="fa fa-plus"></i>&nbsp;
                          Add Candidate
                        </button>
                      )}
                      <button
                        className="btn btn-primary btn-round btn-sm ml-auto"
                        onClick={(e) => BackTodemand()}
                      >
                        <i className="fas fa-arrow-alt-circle-left mr-2"></i>
                        Back to Demand
                      </button>
                    </div>
                  </div>
                  <div
                    className="card-header"
                    style={{ background: "#f1f1f1" }}
                  >
                    <div className="d-flex align-items-center">
                      {/* <h4 className="card-title mr-auto" style={{ width: '85%' }}>Candidate Pipeline</h4>
                                            <button className="btn btn-primary btn-round ml-auto" data-bs-toggle="modal"
                                                data-bs-target="#addRowModal">
                                                <i className="fa fa-plus"></i>
                                                Add
                                            </button> */}
                      <h3 className="card-title mr-auto">
                        <span>Demand Code - </span> {DemandCode}
                      </h3>
                      <h3 className="card-title mr-auto">
                        <span>Client - </span> {Client}{" "}
                      </h3>
                      <h3 className="card-title mr-auto">
                        <span>Skill Set - </span> {Skill}{" "}
                      </h3>
                      <h3 className="card-title mr-auto">
                        <span>Experience - </span> {Experience}{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="modal fade"
                      id="addRowModal"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                      data-bs-backdrop='static'
                    >
                      <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                          <div className="modal-header no-bd">
                            <h5 className="modal-title">
                              <span className="fw-mediumbold">{PopName} </span>
                              <span className="fw-light">Candidate</span>
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form onSubmit={AddCandidate}>
                              <div className="row">
                                <input type="hidden" id="CandidateID" />
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>First Name</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["CandidateFirstName"]}
                                    </span>
                                    <input
                                      id="FirstName"
                                      type="text"
                                      className="form-control"
                                      name="FirstName"
                                      placeholder="Candidate First Name"
                                      onChange={(e) =>
                                        setCandidateErr({
                                          CandidateFirstName: "",
                                        })
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.CandidateFirstName}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Last Name</label>{" "}
                                    <span className="text-danger">
                                      {" "}
                                      {MandatoryList["CandidateLastName"]}
                                    </span>
                                    <input
                                      id="LastName"
                                      type="text"
                                      className="form-control"
                                      name="lastName"
                                      onChange={(e) =>
                                        setCandidateErr({
                                          CandidateLastName: "",
                                        })
                                      }
                                      placeholder="Candidate Last Name"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.CandidateLastName}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Contact Number</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["ContactNumber"]}
                                    </span>
                                    <input
                                      id="ConNumber"
                                      type="text"
                                      maxLength="10"
                                      onChange={(e) => numberOnly("ConNumber")}
                                      className="form-control"
                                      name="ConNumber"
                                      placeholder="Contact Number"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.ContactNumber}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Email</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["ContactEmail"]}
                                    </span>
                                    <input
                                      id="ConEmail"
                                      type="text"
                                      className="form-control"
                                      name="email"
                                      onChange={(e) =>
                                        setCandidateErr({ ContactEmail: "" })
                                      }
                                      placeholder="Email"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.ContactEmail}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Location</label>{" "}
                                    <span className="text-danger">*</span>
                                    <Autocomplete
                                      //options={locationList?.map((location) => location.LocationName)}
                                      options={locationList?.length > 0 ? locationList.map((location) => location.LocationName) : []}
                                      value={selectedLocation}
                                      id="Location"
                                      name="Location"
                                      onKeyUp={(e) => {
                                        setSelectedLocation(e.target.defaultValue);
                                      }}
                                      onChange={(e) => {
                                        setSelectedLocation(e.target.innerText);
                                        setCandidateErr({
                                          ...CandidateErr,
                                          Location: "",
                                        })
                                      }}

                                      renderInput={(params) => <TextField {...params} placeholder="Location" />}
                                    />

                                    <span style={{ color: "red" }}>
                                      {CandidateErr.Location}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    {/* <label>Location Status</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["LocationStatus"]}
                                    </span>
                                    <select
                                      className="form-control "
                                      onChange={(e) =>
                                        setCandidateErr({ LocationStatus: "" })
                                      }
                                      id="LocationStatus"
                                      name="LocationStatus"
                                    >
                                      <option value="">
                                        Select Location Status
                                      </option>
                                      {LocationStatus != null &&
                                        LocationStatus.length > 0 &&
                                        LocationStatus.map((e) => {
                                          return (
                                            <option value={e.id}>
                                              {e.value}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.LocationStatus}
                                    </span> */}

                                    <label>Company Loaction</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["CompanyLocation"]}
                                    </span>
                                    <select
                                      className="form-control "
                                      value={selectedCompanyID}
                                      onChange={CompanyIDOnChange}
                                      id="CompanyLocation"
                                      name="CompanyLocation"
                                      disabled={roleName.includes('Super Admin') ? false : true}
                                    >
                                      <option value="">
                                        Select Company Location
                                      </option>
                                      {CompanyLocation != null &&
                                        CompanyLocation.length > 0 &&
                                        CompanyLocation.map((e) => {
                                          return (
                                            <option value={e.id}>
                                              {e.value}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.CompanyLocation}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Skill Set</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["SkillSet"]}
                                    </span>
                                    <select
                                      className="form-control "
                                      onChange={(e) =>
                                        setCandidateErr({ SkillSetID: "" })
                                      }
                                      id="SkillSet"
                                    >
                                      <option value="">Select Skill Set</option>
                                      {SkillDate != null &&
                                        SkillDate.length > 0 &&
                                        SkillDate.map((e) => {
                                          return (
                                            <option value={e.id}>
                                              {e.value}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.SkillSetID}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Experience</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["Experience"]}
                                    </span>
                                    <input
                                      id="Experience"
                                      type="text"
                                      maxLength="3"
                                      name="Experience"
                                      onChange={(e) => numberOnly("Experience")}
                                      className="form-control"
                                      placeholder="Experience"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.Experience}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Education</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["Education"]}
                                    </span>
                                    <input
                                      id="Education"
                                      type="text"
                                      name="Education"
                                      onChange={(e) =>
                                        setCandidateErr({ Education: "" })
                                      }
                                      className="form-control"
                                      placeholder="Education"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.Education}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Resume Upload</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["FileUploadpath"]}
                                    </span>
                                    <input
                                      id="Resume"
                                      type="file"
                                      name="Resume"
                                      onChange={(e) => ResumeFile(e)}
                                      className="form-control"
                                      placeholder="Resume Upload"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.FileUploadpath}
                                    </span>
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Date Applied</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["DateApplied"]}
                                    </span>
                                    <DatePicker
                                      className="form-control"
                                      selected={startDate}
                                      onChange={(date) => ChangeDate(date)}
                                      showTimeSelect
                                      dateFormat="dd-MM-yyyy"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.Date}
                                    </span>
                                  </div>
                                </div> */}
                                {/* <div className="col-md-6">
                                                                    <div className="form-group ">
                                                                        <label>Comment</label>
                                                                        <input id="addPosition" type="text"
                                                                            className="form-control" placeholder="Comment" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="form-group ">
                                                                        <label>Assigned To</label> <span className='text-danger'>*</span>
                                                                        <select className="form-control "
                                                                            id="smallSelect">
                                                                            <option>Select Employee</option>
                                                                            <option>Rajesh</option>
                                                                            <option>Praveen</option>
                                                                        </select>
                                                                    </div>
                                                                </div> */}
                              </div>
                              <div className="modal-footer no-bd">
                              <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  id="addRowButton"
                                  className="btn btn-primary btn-sm"
                                >
                                  {ButtonName}
                                </button>
                               
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(!cookies.roleName.includes("PMO") || DemandActiveTab != "PMO") &&
                      (!cookies.roleName.includes("HR") || DemandActiveTab != "HR") &&
                      (!cookies.roleName.includes("Recruiter") || DemandActiveTab != "Recruiter") &&
                      Isview != null &&
                      Isview == 1 ? (
                      <div className="row mb-4">
                        <div className="col-md-12">
                          <button
                            type="button"
                            id="addRowButton"
                            style={{ margin: "35px 0 0 0" }}
                            onClick={AcceptScreen}
                            hidden
                            className="btn btn-primary pull-right btn-sm"
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {Isview != null && Isview == 1 && (
                      <div className="row mt-2" style={{ display: "none" }}>
                        <div className="col-md-12">
                          {DemandStep != null && DemandStep != "Assessment" && (
                            <button
                              className="btn btn-danger btn-round btn-sm ml-auto pull-right"
                              onClick={(e) => Reject(DemandID, DemandStep)}
                            >
                              Reject
                            </button>
                          )}

                          {NextStepName != "" ? (
                            <button
                              className="btn btn-success btn-round btn-sm ml-auto pull-right mr-3"
                              onClick={(e) =>
                                MoveForward(DemandID, NextStepName, DemandStep)
                              }
                            >
                              {`Move Forward to ${NextStepName}`}
                            </button>
                          ) : (
                            <button
                              className="btn btn-success btn-round btn-sm ml-auto pull-right mr-3"
                              onClick={(e) =>
                                MoveForward(DemandID, NextStepName, DemandStep)
                              }
                            >
                              Move Forward to Next Stage
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="table-responsive mt-4">
                      <table
                        id="candidateProfiletable"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            {/* // (Isview != null && Isview == 1) && */}
                            {/* <th>Select Profile</th> */}
                            <th
                              className="dt-control"
                            // style={{ width: "20px" }}
                            ></th>
                            <th>Task ID</th>
                            <th>Candidate Name</th>
                            <th>Contact Number</th>
                            <th>Email</th>
                            <th>Location</th>
                            {/* <th>Skill Set</th>
                            <th>Experience</th>
                            <th>Education</th> */}
                            <th>Resume</th>
                            {/* <th>Created Date</th> */}
                            <th>Status</th>
                            {/* <th>Comments</th> */}
                            {/* <th>Assigned To</th> */}
                            <th style={{ width: "10%" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {CandidateList != null &&
                            CandidateList.length > 0 &&
                            CandidateList.map((e) => {
                              debugger
                              var candidateID = e.candidateID;
                              var DemandCandidateID = e.demandCandidateID;
                              var DemandId = e.demandID;

                              var candidateName = e.candidateName;
                              var contactNumber = e.contactNumber;
                              var contactEmail = e.contactEmail;
                              var location = e.locationName;
                              var skillSetID = e.skillSetID;
                              var experience = e.experience;
                              var education = e.education;
                              var dateApplied = e.dateApplied;
                              var FileStr = e.fileString;
                              var filetype = e.filetype;


                              var currentStatus = e.currentStatus.trim();
                              var candidateNextStep = e.candidateNextStep;

                              var CurrentStepp = e.currentStatus;
                              var CandidateStatus = e.candidateStatus;

                              var ProfileLevel = e.profileLevel;


                              var AccTime = e.acceptTime;
                              var AccStep = e.acceptStep.trim();
                              var Sla = e.sla;

                              var DemandStepp = DemandStep;
                              var r = cookies.roleName;
                              var currentApproval = e.currentApproval

                              var tt = !DemandStep.includes(currentStatus);
                              var LocSta = e.locationStatus;
                              var IsProcess = e.isProcess;

                              return (
                                <tr className={Sla > 1 ? "TextRed" : ""}>
                                  <td
                                    className="dt-control"
                                    onClick={(ev) => TableHeader(e, ev)}
                                  // style={{ width: "20px !important" }}
                                  ></td>
                                  <td>{e.taskID}</td>
                                  <td>{e.candidateName}</td>
                                  <td>{e.contactNumber}</td>
                                  <td>
                                    <p>{e.contactEmail}</p>
                                  </td>
                                  <td>{e.locationName}</td>
                                  {/* <td>{e.skillSetName}</td>
                                  <td>{e.experience}</td>
                                  <td>{e.education}</td> */}

                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) =>
                                        filedownload(
                                          candidateID,
                                          candidateName,
                                          filetype
                                        )
                                      }
                                      title={e.candidateName + " - Resume"}
                                    >
                                      <i className="fas fa-file-alt"></i>{" "}
                                      {e.candidateName + " - Resume"}
                                    </a>
                                  </td>
                                  {/* <td>{e.dateApplied}</td> */}
                                  <td>
                                    <span style={{ display: "flex" }}>
                                      <p style={{ width: "90px" }}>
                                        {e.candidateStatus}

                                        {e.candidateStatus == "Rejected" && (
                                          <>
                                            <span
                                              className="ml-4 mt-1"
                                              data-tip={e.comments}
                                            >
                                              <i className="fas fa-comment-alt"></i>
                                            </span>
                                            <ReactTooltip
                                              multiline="true"
                                              className="ReactToolTip"
                                              effect="solid"
                                              type="info"
                                            />
                                          </>
                                        )}
                                      </p>
                                    </span>
                                  </td>
                                  <td>
                                    {Isview != null && Isview == 1 ? (
                                      <div className="form-button-action">
                                        {CandidateStatus != "Rejected" ? (
                                          <>
                                            {cookies.roleName != null ? (
                                              DemandStep.includes(
                                                currentStatus
                                              ) ||
                                                (candidateNextStep != "" &&
                                                  candidateNextStep !=
                                                  "INTERVIEWPROCESSDONE" &&
                                                  candidateNextStep !=
                                                  "PROCESSDONE") ? (
                                                <>
                                                  {AccStep != currentStatus ? (
                                                    <>
                                                      <button
                                                        className="bbtn btn-link btn-success btn-lg None"
                                                        title={
                                                          ProfileLevel != "SPOC"
                                                            ? `${candidateNextStep} Completed`
                                                            : `Move Forward to ${candidateNextStep}`
                                                        }
                                                        onClick={(e) =>
                                                          MoveForward(
                                                            DemandCandidateID,
                                                            candidateNextStep,
                                                            DemandStep,
                                                            ProfileLevel,
                                                            DemandId,
                                                            Sla
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-arrow-circle-right"></i>
                                                      </button>
                                                      <button
                                                        className="bbtn btn-link btn-danger btn-lg None"
                                                        title="Reject Profile"
                                                        onClick={(e) =>
                                                          Reject(
                                                            DemandCandidateID,
                                                            DemandStep
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-window-close"></i>
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        className="bbtn btn-link btn-success btn-lg"
                                                        title={
                                                          ProfileLevel != "SPOC"
                                                            ? `${candidateNextStep} Completed`
                                                            : `Move Forward to ${candidateNextStep}`
                                                        }
                                                        onClick={(e) =>
                                                          MoveForward(
                                                            DemandCandidateID,
                                                            candidateNextStep,
                                                            DemandStep,
                                                            ProfileLevel,
                                                            DemandId,
                                                            Sla
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-arrow-circle-right"></i>
                                                      </button>
                                                      <button
                                                        className="bbtn btn-link btn-danger btn-lg"
                                                        title="Reject Profile"
                                                        onClick={(e) =>
                                                          Reject(
                                                            DemandCandidateID,
                                                            DemandStep
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-window-close"></i>
                                                      </button>
                                                    </>
                                                  )}

                                                  {IsProcess == 1 &&
                                                    AccStep != currentStatus &&
                                                    ProfileLevel == "SPOC" ? (
                                                    <button
                                                      className="bbtn btn-link btn-primary btn-lg"
                                                      onClick={(e) =>
                                                        PMOAccept(
                                                          DemandCandidateID,
                                                          currentStatus
                                                        )
                                                      }
                                                      title={`Acceptance for ${CurrentStepp}`}
                                                    >
                                                      <i className="fas fa-angle-double-right"></i>
                                                    </button>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {IsProcess == 1 &&
                                                    AccStep != currentStatus &&
                                                    (ProfileLevel == "PMO" ||
                                                      ProfileLevel == "HR") ? (
                                                    <button
                                                      className="bbtn btn-link btn-primary btn-lg"
                                                      onClick={(e) =>
                                                        PMOAccept(
                                                          DemandCandidateID,
                                                          currentStatus
                                                        )
                                                      }
                                                      title={`Acceptance for ${candidateNextStep}`}
                                                    >
                                                      <i className="fas fa-angle-double-right"></i>
                                                    </button>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )
                                            ) : (
                                              <></>
                                            )}

                                            {DemandStep.includes(
                                              currentStatus
                                            ) &&
                                              candidateNextStep == "" && (
                                                <>
                                                  {AccStep != currentStatus ? (
                                                    <>
                                                      <button
                                                        className="bbtn btn-link btn-success btn-lg None"
                                                        title="Move Forward to Next Stage"
                                                        onClick={(e) =>
                                                          MoveForward(
                                                            DemandCandidateID,
                                                            candidateNextStep,
                                                            DemandStep,
                                                            ProfileLevel,
                                                            DemandId,
                                                            Sla
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-arrow-circle-right"></i>
                                                      </button>
                                                      <button
                                                        className="bbtn btn-link btn-danger btn-lg None"
                                                        title="Reject Profile"
                                                        onClick={(e) =>
                                                          Reject(
                                                            DemandCandidateID,
                                                            DemandStep
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-window-close"></i>
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        className="bbtn btn-link btn-success btn-lg"
                                                        title="Move Forward to Next Stage"
                                                        onClick={(e) =>
                                                          MoveForward(
                                                            DemandCandidateID,
                                                            candidateNextStep,
                                                            DemandStep,
                                                            ProfileLevel,
                                                            DemandId,
                                                            Sla
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-arrow-circle-right"></i>
                                                      </button>
                                                      <button
                                                        className="bbtn btn-link btn-danger btn-lg"
                                                        title="Reject Profile"
                                                        onClick={(e) =>
                                                          Reject(
                                                            DemandCandidateID,
                                                            DemandStep
                                                          )
                                                        }
                                                      >
                                                        <i className="fas fa-window-close"></i>
                                                      </button>
                                                    </>
                                                  )}
                                                  {IsProcess == 1 &&
                                                    AccStep != currentStatus &&
                                                    ProfileLevel == "SPOC" ? (
                                                    // <input class="form-check-input" name='Candidatecheck' type="checkbox" value={DemandCandidateID} onChange={(e) => Accept(DemandCandidateID, DemandStep)} style={{ margin: '0' }} />
                                                    <button
                                                      className="bbtn btn-link btn-primary btn-lg"
                                                      onClick={(e) =>
                                                        PMOAccept(
                                                          DemandCandidateID,
                                                          currentStatus
                                                        )
                                                      }
                                                      title={`Acceptance for ${currentStatus}`}
                                                    >
                                                      <i className="fas fa-angle-double-right"></i>
                                                    </button>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )}
                                            {candidateNextStep ==
                                              "INTERVIEWPROCESSDONE" && (
                                                <>
                                                  <button
                                                    className="btn btn-link btn-success btn-lg"
                                                    title="Move Forward to HR Process"
                                                    onClick={(e) =>
                                                      MoveForward(
                                                        DemandCandidateID,
                                                        candidateNextStep,
                                                        DemandStep,
                                                        ProfileLevel,
                                                        DemandId,
                                                        Sla
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-arrow-circle-right"></i>
                                                  </button>
                                                  <button
                                                    className="bbtn btn-link btn-danger btn-lg"
                                                    title="Reject Profile"
                                                    onClick={(e) =>
                                                      Reject(
                                                        DemandCandidateID,
                                                        DemandStep
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-window-close"></i>
                                                  </button>
                                                </>
                                              )}
                                            {candidateNextStep ==
                                              "PROCESSDONE" && (
                                                <div
                                                  className="btn btn-link btn-lg"
                                                  title="Onboarded"
                                                >
                                                  <button
                                                    type="button"
                                                    class="btn btn-icon btn-round btn-sm btn-success ml-auto"
                                                  >
                                                    <i class="fas fa-thumbs-up"></i>
                                                  </button>
                                                </div>
                                              )}
                                          </>
                                        ) : (
                                          <div
                                            className="btn btn-link btn-lg"
                                            title="Rejected"
                                          >
                                            <button
                                              type="button"
                                              class="btn btn-icon btn-round btn-sm btn-danger ml-auto"
                                            >
                                              <i class="fas fa-thumbs-down"></i>
                                            </button>
                                          </div>
                                        )}

                                        {priviledge.includes("CANDPIPEDIT") &&
                                          CandidateStatus != "Rejected" && (
                                            <button
                                              type="button"
                                              data-bs-toggle="modal"
                                              title="Edit"
                                              className="btn btn-link btn-primary btn-lg"
                                              data-bs-target="#addRowModal"
                                              onClick={(e) =>
                                                EditCandidate(
                                                  candidateID,
                                                  candidateName,
                                                  contactNumber,
                                                  contactEmail,
                                                  location,
                                                  skillSetID,
                                                  experience,
                                                  education,
                                                  dateApplied,
                                                  FileStr,
                                                  filetype,
                                                  LocSta
                                                )
                                              }
                                            >
                                              <i className="fa fa-edit"></i>
                                            </button>
                                          )}
                                        {priviledge.includes("CANDPIPDELETE") &&
                                          e.candidateStatus == "Open" && (
                                            <button
                                              type="button"
                                              data-bs-toggle="tooltip"
                                              title="Remove"
                                              className="btn btn-link btn-danger"
                                              onClick={(e) =>
                                                DeleteCandidate(candidateID)
                                              }
                                            >
                                              <i className="fas fa-trash-alt"></i>
                                            </button>
                                          )}
                                      </div>
                                    ) : (
                                      <>
                                        {candidateNextStep == "PROCESSDONE" ? (
                                          <div
                                            className="btn btn-link btn-lg"
                                            title="Onboarded"
                                          >
                                            <button
                                              type="button"
                                              class="btn btn-icon btn-round btn-sm btn-success ml-auto"
                                            >
                                              <i class="fas fa-thumbs-up"></i>
                                            </button>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {CandidateStatus == "Rejected" ? (
                                          <div
                                            className="btn btn-link btn-lg"
                                            title="Rejected"
                                          >
                                            <button
                                              type="button"
                                              class="btn btn-icon btn-round btn-sm ml-auto"
                                            >
                                              <i class="fas fa-thumbs-down"></i>
                                            </button>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="AddcommmentBtn"
        data-bs-toggle="modal"
        data-bs-target="#AddComment"
      ></button>
      <div
        className="modal fade"
        id="AddComment"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title">
                <span className="fw-mediumbold"></span>
                <span className="fw-light">Comment</span>
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input type="hidden" id="CandidateIDReject" />
              <input type="hidden" id="DemandStep" />
              <textarea
                className="form-control"
                rows="7"
                cols="50"
                id="CommentTxtArea"
              ></textarea>
              <span style={{ color: "red" }}>{CommentErr}</span>
            </div>
            <div className="modal-footer no-bd">
              <button
                type="button"
                onClick={RejectCandidate}
                id="addRowButton"
                className="btn btn-primary btn-sm"
              >
                Reject
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidatePipeline;
