import React from "react";
import Sidebar from "./SideBar";

function Navbar(){
    return(
      
            <Sidebar />
          
      )
}
export default Navbar;