import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import swal from "sweetalert";
import { AuthContext } from "../../Context/AuthContext";

export default function CompanyConfig() {
  const { cookies } = useContext(AuthContext);
  const [companyID, setCompanyID] = useState(0);
  const [companyName, setCompanyName] = useState();
  const [companyCode,setCompanyCode]=useState();
  const [companyLocation, setcompanyLocation] = useState();
  const [companyList, setCompanyList] = useState();
  const [Share_Point_Library_Name, set_Share_Point_Library_Name] = useState();
  const [Share_Point_Folder_Name, set_Share_Point_Folder_Name] = useState();
  const [user, setUser] = useState(cookies.userID);
  const [Library_Check,setLibrary_Check]=useState();
  const [Folder_Check,setFolder_Check]=useState();
  const [Save_Flag,setSave_Flag]=useState(false);
  const [errors, setErrors] = useState({
    companyName: "",
    Location: "",
    companyCode:"",
    share_Point_Library_Name:"",
    share_Point_Folder_Name:""
  });

  const AppService = new ApplicarionConfigService();

  useEffect(() => {
    GetCompanyDetails();
  }, []);

  const GetCompanyDetails = () => {
    AppService.GetCompnayDetails()
      .then((res) => {
        if (res.data.length > 0) {
          
          setCompanyList(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ClearForm = () => {
    setCompanyID(0);
    setCompanyName("");
    setcompanyLocation("");
    setErrors("");
    setCompanyCode("");
    set_Share_Point_Library_Name("");
    set_Share_Point_Folder_Name("");
    $(".close").click();
  };
  const SaveCompany = async() => {
    var flag = true;
    if (companyName == "" || companyName == undefined || companyName == null) {
      setErrors((errors)=>({ ...errors, companyName: "Company Name is required." }));
      flag = false;
    }
    if (
      companyLocation == "" ||
      companyLocation == undefined ||
      companyLocation == null
    ) {
      setErrors((errors)=>({ ...errors, Location: "Company Location is required." }));
      flag = false;
    }
    if (
      companyCode == "" ||
      companyCode == undefined ||
      companyCode == null
    ) {
      setErrors((errors)=>({ ...errors, companyCode: "Company Code is required." }));
      flag = false;
    }
    if (
      Share_Point_Library_Name == "" ||
      Share_Point_Library_Name == undefined ||
      Share_Point_Library_Name == null
    ) {
      setErrors((errors)=>({ ...errors, share_Point_Library_Name: "Share Point Library Name is required." }));
      flag = false;
    }
    if (
      Share_Point_Folder_Name == "" ||
      Share_Point_Folder_Name == undefined ||
      Share_Point_Folder_Name == null
    ) {
      setErrors((errors)=>({ ...errors, share_Point_Folder_Name: "Share Point Folder Name is required." }));
      flag = false;
    }
    debugger
    if (Library_Check!=null&&Library_Check!=undefined &&Library_Check!="" && Library_Check!="LIBRARY CHECK SUCCESS") {
      var error_name=Library_Check?.charAt(0)?.toUpperCase() + Library_Check?.slice(1)?.toLowerCase();
      setErrors((errors)=>({ ...errors, share_Point_Library_Name: error_name }));
      flag = false;
    }
    debugger
    if (Folder_Check!=null&&Folder_Check!=undefined&& Folder_Check!="" && Folder_Check!="FOLDER CHECK SUCCESS") {
      var error_name=Folder_Check?.charAt(0)?.toUpperCase() + Folder_Check?.slice(1)?.toLowerCase();
      setErrors((errors)=>({ ...errors, share_Point_Folder_Name: error_name }));
      flag = false;
    }
    else{
     var res=await CheckCompanyCodeExists(companyCode,companyID)
     if(res)
     {
      setErrors((errors)=>({ ...errors, companyCode: "Company Code already exists" }));
      flag = false;
     }
    }
    if (flag) {
      const Obj = {
        CompanyID: companyID,
        CompanyName: companyName,
        Location: companyLocation,
        companyCode:companyCode,
        Share_Point_Library_Name:Share_Point_Library_Name,
        Share_Point_Folder_Name:Share_Point_Folder_Name,
        CreatedBy: user,
        ModifiedBy: companyID > 0 ? user : "",
      };
      AppService.SaveUpdateCompany(Obj)
        .then((res) => {
          var result = res.data;
          if (result > 0) {
            ClearForm()
            // $('#CompanyConfig').hide();
            GetCompanyDetails();
            swal("Details", "Saved Successfully!", "success")
            // window.location.reload(false)
          } else if ((result = -1)) {
            swal("Details", "Company Name/Location Already Exist!", "error")
            // window.location.reload(false)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const EditCompany = (CompanyID, CompanyName, Location,CompanyCode,Share_Point_Library_Name,Share_Point_Folder_Name) => {
    setCompanyName(CompanyName);
    setCompanyID(CompanyID);
    setcompanyLocation(Location);
    setCompanyCode(CompanyCode);
    set_Share_Point_Library_Name(Share_Point_Library_Name);
    set_Share_Point_Folder_Name(Share_Point_Folder_Name);
    setLibrary_Check("LIBRARY CHECK SUCCESS");
    setFolder_Check("FOLDER CHECK SUCCESS");
  };

  const HandleChange=async(e,name)=>{

    var value=e?.target?.value

    if(name=="Company Name"){
      if(value!=null&&value!=undefined&&value!=""){

        setCompanyName(value)

        setErrors((errors)=>({...errors,companyName:""}))
      }
      else{
        setCompanyName(value)
        setErrors((errors)=>({...errors,companyName:`${name} is required`}))
      }
    }
    else if(name=='Location'){
      if(value!=null&&value!=undefined&&value!=""){

        setcompanyLocation(value)

        setErrors((errors)=>({...errors,Location:""}))
      }
      else{
        setcompanyLocation(value)
        setErrors((errors)=>({...errors,Location:`${name} is required`}))
      }
    }
    else if(name=="Share Point Library Name"){
      debugger
      setSave_Flag(true)
      if(value!=null&&value!=undefined&&value!=""){

        set_Share_Point_Library_Name(value)
        var res=await CheckSharePointExistsPath(value,Share_Point_Folder_Name,"LIBRARY");
        setLibrary_Check(res);
        var error_name=res?.charAt(0)?.toUpperCase() + res?.slice(1)?.toLowerCase();
        if(res!="LIBRARY CHECK SUCCESS" && res!="FOLDER CHECK SUCCESS")
        {
          setErrors((errors)=>({...errors,share_Point_Library_Name:`${error_name}`}))
        }
        else
        {
          setSave_Flag(false);
          setErrors((errors)=>({...errors,share_Point_Library_Name:""}))
        }
      }
      else{
        set_Share_Point_Library_Name(value)
        setErrors((errors)=>({...errors,share_Point_Library_Name:`${name} is required`}))
      }
    }
    else if(name=="Share Point Folder Name"){
      debugger
      setSave_Flag(true)
      if(value!=null&&value!=undefined&&value!=""){

        set_Share_Point_Folder_Name(value)
        var res=await CheckSharePointExistsPath(Share_Point_Library_Name,value,"FOLDER");
        setFolder_Check(res);
        var error_name=res?.charAt(0)?.toUpperCase() + res?.slice(1)?.toLowerCase();
        if(res!="FOLDER CHECK SUCCESS" && res!="LIBRARY CHECK SUCCESS")
        {
          setErrors((errors)=>({...errors,share_Point_Folder_Name:`${error_name}`}))
        }
        else
        {
          setSave_Flag(false);  
          setErrors((errors)=>({...errors,share_Point_Folder_Name:""}))
        }
      }
      else{
        set_Share_Point_Folder_Name(value)
        setErrors((errors)=>({...errors,share_Point_Folder_Name:`${name} is required`}))
      }
    }
    else if(name=='Company Code'){
      if(value!=null&&value!=undefined&&value!=""){
        var containsSpecialChars =!/^[a-zA-Z0-9]*$/.test(value);
        if(!containsSpecialChars && value?.length<=4)
        {
          setCompanyCode(value)

          setErrors((errors)=>({...errors,companyCode:""}))
          
          var res=await CheckCompanyCodeExists(value,companyID)
          
          if(res){
            setErrors((errors)=>({...errors,companyCode:`${name} is already exists`}))
          }
          else{
            setErrors((errors)=>({...errors,companyCode:""}))
          }
        }
      }
      else{
        setCompanyCode(value)
        setErrors((errors)=>({...errors,companyCode:`${name} is required`}))
      }
    }
  }

  const CheckCompanyCodeExists=async(CompanyCode,CompanyID)=>{
    try{
      
      var res=await AppService.CheckCompanyCodeExists(CompanyCode,CompanyID)
      
      return res.data
    }
    catch(ex){
      console.log(ex)
    }
  }

  const CheckSharePointExistsPath=async(Share_Point_Library_Name,Share_Point_Folder_Name,OnChengPath)=>{
    try{
      
      var res=await AppService.CheckSharePointExistsPath(Share_Point_Library_Name,Share_Point_Folder_Name,OnChengPath)
      
      return res.data
    }
    catch(ex){
      console.log(ex)
    }
  }

  const DeleteCompany=(compID)=>{
    if(compID>0){
      $(window).scrollTop(0);
    swal({
      title: "Are You Sure?",

      text: "Do you want to delete this data from the list?",

      buttons: true,

      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        AppService.DeleteCompany(compID)
          .then((response) => {
            if(response.data>0){
              GetCompanyDetails()
              swal("Details", "Deleted Successfully!", "success");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    }
  }
  function alphabetOnly(e) {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z0-9 ()-]+$/;
    if (!regex.test(inputValue)) {
        e.target.value = inputValue.replace(/[^a-zA-Z0-9 ()-]/g, '');
    }
}
function localphabetOnly(e) {
  const inputValue = e.target.value;
  const regex = /^[a-zA-Z ]+$/;

  if (!regex.test(inputValue)) {
      e.target.value = inputValue.replace(/[^a-zA-Z ]/g, '');
  }
}


  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <button
          className="btn btn-primary btn-round ml-auto  pull-right btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#CompanyConfig"
          style={{ zIndex: 999, position: "relative" }}
          title="Add Company"
        >
          <i className="fa fa-plus"></i> <span className="ml-1">Add</span>
        </button>
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="table-responsive">
          <table
            id="add-row"
            className="display table table-striped table-hover"
          >
            <thead>
              <tr>
                <th style={{ display: "none" }}></th>
                <th>Company Code</th>
                <th>Company Name</th>
                <th>Location</th>
                <th>Share Point Library</th>
                <th>Share Point Folder</th>
                <th style={{ width: "10%", textAlign: "center" }}>Options</th>
              </tr>
            </thead>
            <tbody>
              {companyList &&
                companyList.map((e) => {
                  return (
                    <tr>
                      <td style={{ display: "none" }}>{e.CompanyID}</td>
                      <td>{e.CompanyCode}</td>
                      <td>{e.CompanyName}</td>
                      <td>{e.Location}</td>
                      <td>{e.Share_Point_Library_Name}</td>
                      <td>{e.Share_Point_Folder_Name}</td>
                      <td>
                        <div class="form-button-action">
                          <button
                            type="button"
                            title=""
                            class="btn btn-link btn-primary btn-lg"
                            data-original-title="Edit"
                            data-bs-toggle="modal"
                            data-bs-target="#CompanyConfig"
                            onClick={() =>
                              EditCompany(
                                e.CompanyID,
                                e.CompanyName,
                                e.Location,
                                e.CompanyCode,
                                e.Share_Point_Library_Name,
                                e.Share_Point_Folder_Name
                              )
                            }
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            data-bs-toggle="tooltip"
                            title=""
                            class="btn btn-link btn-danger"
                            data-original-title="Remove"
                            onClick={()=>{
                              DeleteCompany(e.CompanyID)
                            }}
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="modal fade"
        id="CompanyConfig"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title">
                <span className="fw-light">{companyID>0?"Update Company Details":"Add Company Details"}</span>
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={ClearForm}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="DDlForm" style={{ marginTop: -32 }}>
                <div className="row card-body">
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group">
                      <label>Company Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={companyCode}
                        onChange={(e)=>HandleChange(e,'Company Code')}
                        // onInput={alphabetOnly}
                      />
                      <span className="text-danger">{errors?.companyCode}</span>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={companyName}
                        onChange={(e)=>HandleChange(e,'Company Name')}
                        // onInput={alphabetOnly}
                      />
                      <span className="text-danger">{errors?.companyName}</span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-3 col-sm-12">
                    <div className="form-group">
                      <label>Company Location</label>
                      <input
                        type="text"
                        className="form-control"
                        value={companyLocation}
                        onChange={(e)=>HandleChange(e,'Location')}

                        // onInput={alphabetOnly}

                      />
                      <span className="text-danger">{errors?.Location}</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Share Point Library Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={Share_Point_Library_Name}
                        onChange={(e)=>HandleChange(e,'Share Point Library Name')}

                        // onInput={alphabetOnly}

                      />
                      <span className="text-danger">{errors?.share_Point_Library_Name}</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Share Point Folder Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={Share_Point_Folder_Name}
                        onChange={(e)=>HandleChange(e,'Share Point Folder Name')}
                        disabled={Library_Check=="LIBRARY CHECK SUCCESS"?false:true}

                        // onInput={alphabetOnly}

                      />
                      <span className="text-danger">{errors?.share_Point_Folder_Name}</span>
                    </div>
                  </div>
                  <div
                    className="col-lg-12 mt-6 col-md-9 col-sm-12"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <button
                      type="button"
                      id="addRowButton"
                      class="btn btn-primary btn-sm"
                      style={Save_Flag==true?{ marginTop: "1%",cursor:"not-allowed"}:{ marginTop: "1%",cursor:"pointer"}}
                      onClick={() => SaveCompany()}
                      disabled={Save_Flag==true?true:false}
                    >
                      {companyID>0?"Update":"Add"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
