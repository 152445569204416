import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import ApplicationConfigService from "../../api/ApplicationConfigService";
import swal from "sweetalert";
import { AuthContext } from "../../Context/AuthContext";

export default function LocationConfig() {
    const { cookies } = useContext(AuthContext);
    const [locationId, setLocationId] = useState(0);
    const [locationName, setLocationName] = useState();
    const [locationList, setLocationList] = useState([]);
    const [user, setUser] = useState(cookies.userID);
    const [errors, setErrors] = useState({
        locationName: "",
    });

    const AppService = new ApplicationConfigService();

    useEffect(() => {
        GetLocationDetails();
    }, []);

    const GetLocationDetails = () => {
        AppService.GetLocationDetails()
            .then((res) => {
                if (res.data.length > 0) {
                    setLocationList(res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const ClearForm = () => {
        setLocationId(0);
        setLocationName("");
        setErrors("");
        $(".close").click();
    };
    const SaveLocation = () => {
        var flag = true;
        if (locationName == "" || locationName == undefined || locationName == null) {
            setErrors((errors) => ({ ...errors, locationName: "Location is required." }));
            flag = false;
        }

        if (flag) {
            const Obj = {
                LocationId: locationId,
                LocationName: locationName,
                CreatedBy: user,
                ModifiedBy: locationId > 0 ? user : "",
            };
            AppService.SaveUpdateLocation(Obj)
                .then((res) => {
                    var result = res.data;
                    if (result > 0) {
                        ClearForm()
                        // $('#LocationConfig').hide();
                        GetLocationDetails();

                        swal("Details", "Saved Successfully!", "success")
                        // window.location.reload(false)



                    } else if ((result = -1)) {
                        swal("Details", "Location Already Exist!", "warning")
                        // window.location.reload(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const EditLocation = (LocationId, LocationName) => {
        setLocationName(LocationName);
        setLocationId(LocationId);

    };

    const HandleChange = (e, name) => {
        var value = e.target.value
        if (name == "LocationName") {
            if (value != null && value != undefined && value != "") {
                setLocationName(value)
                setErrors((errors) => ({ ...errors, locationName: "" }))
            }
            else {
                setLocationName(value)
                setErrors((errors) => ({ ...errors, locationName: "Location is required" }))
            }
        }

    }

    const DeleteLocation = (locationId) => {
        if (locationId > 0) {
            $(window).scrollTop(0);
            swal({
                title: "Are You Sure?",

                text: "Do you want to delete this data from the list?",

                buttons: true,

                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    AppService.DeleteLocation(locationId)
                        .then((response) => {
                            if (response.data > 0) {
                                GetLocationDetails()
                                swal("Details", "Deleted Successfully!", "success");
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });
        }
    }
    function alphabetOnly(e) {
        const inputValue = e.target.value;
        const regex = /^[a-zA-Z ]+$/;

        if (!regex.test(inputValue)) {
            e.target.value = inputValue.replace(/[^a-zA-Z ]/g, '');
        }
    }

    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <button
                    className="btn btn-primary btn-round ml-auto  pull-right btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#LocationConfig"
                    style={{ zIndex: 999, position: "relative" }}
                    title="Add Location"
                >
                    <i className="fa fa-plus"></i> <span className="ml-1">Add</span>
                </button>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
                <div class="table-responsive">
                    <table
                        id="add-row"
                        className="display table table-striped table-hover"
                    >
                        <thead>
                            <tr>
                                <th style={{ display: "none" }}></th>
                                <th>Location</th>
                                <th style={{ width: "10%", textAlign: "center" }}>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {locationList &&
                                locationList.map((e) => {
                                    return (
                                        <tr>
                                            <td style={{ display: "none" }}>{e.LocationId}</td>
                                            <td>{e.LocationName}</td>
                                            <td>
                                                <div class="form-button-action">
                                                    <button
                                                        type="button"
                                                        title=""
                                                        class="btn btn-link btn-primary btn-lg"
                                                        data-original-title="Edit"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#LocationConfig"
                                                        onClick={() =>
                                                            EditLocation(
                                                                e.LocationId,
                                                                e.LocationName
                                                            )
                                                        }
                                                    >
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        data-bs-toggle="tooltip"
                                                        title=""
                                                        class="btn btn-link btn-danger"
                                                        data-original-title="Remove"
                                                        onClick={() => {
                                                            DeleteLocation(e.LocationId)
                                                        }}
                                                    >
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                className="modal fade"
                id="LocationConfig"
                tabindex="-1"
                role="dialog"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-keyboard="false"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header no-bd">
                            <h5 className="modal-title">
                                <span className="fw-light">{locationId>0?"Update Location Details":"Add Location Details"}</span>
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={ClearForm}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="DDlLocForm" style={{ marginTop: -32 }}>
                                <div className="row card-body">
                                    <div className="col-lg-7 col-md-8 col-sm-12">
                                        <div className="form-group">
                                            <label>Location</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={locationName}
                                                onChange={(e) => HandleChange(e, 'LocationName')}
                                                onInput={alphabetOnly}
                                            />

                                            <span className="text-danger">{errors?.locationName}</span>
                                        </div>
                                    </div>
                                    <div
                                        className="col-lg-12 mt-6 col-md-9 col-sm-12"
                                        style={{ display: "flex", justifyContent: "end" }}
                                    >
                                        <button
                                            type="button"
                                            id="addRowButton"
                                            class="btn btn-primary btn-sm"
                                            style={{ marginTop: "1%" }}
                                            onClick={() => SaveLocation()}
                                        >
                                            {locationId>0?"Update":"Add"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
