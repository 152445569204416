import React from "react";
import AxiosServices from "./AxiosService";

const Auth = new AxiosServices();

class DashboardService {
  GetDashboard_DemandList = async (EmployeeID, RoleName, Year, Month,companyID) => {
    return Auth.get(
      `/Dashboard/Get_DashboardList?EmployeeID=${EmployeeID}&RoleName=${RoleName}&Year=${Year}&Month=${Month}&companyID=${companyID}`
    );
  };
  MonthWise_Demand = async (Month, RoleName, EmployeeID, Year,companyID) => {
    return Auth.get(
      `/Dashboard/MonthWise_Demand?Month=${Month}&RoleName=${RoleName}&EmployeeID=${EmployeeID}&Year=${Year}&companyID=${companyID}`
    );
  };
  MonthWise_Candidate = async (Month, RoleName, Year,companyID) => {
    return Auth.get(
      `/Dashboard/MonthWise_Candidate?Month=${Month}&RoleName=${RoleName}&Year=${Year}&companyID=${companyID}`
    );
  };
  Dashboard_DemandList_MonthWise = async (
    Month,
    RoleName,
    EmployeeID,
    Year,
    companyID
  ) => {
    return Auth.get(
      `/Dashboard/Dashboard_DemandList_MonthWise?Month=${Month}&RoleName=${RoleName}&EmployeeID=${EmployeeID}&Year=${Year}&companyID=${companyID}`
    );
  };
  Dashboard_CandidateList_MonthWise = async (Month, Year,RoleName,companyID) => {
    return Auth.get(
      `/Dashboard/Dashboard_CandidateList_MonthWise?Month=${Month}&Year=${Year}&RoleName=${RoleName}&companyID=${companyID}`
    );
  };
}

export default DashboardService;
