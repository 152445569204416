import React, { useEffect, useState, useContext, useReducer } from "react";
import $ from "jquery";
import "datatables.net";
import * as XLSX from "xlsx";
import swal from "sweetalert";
import ProfileSearchService from "../../api/ProfileSearchService";
import CandidatePipelineService from "../../api/CandidatePipelineService";
import { saveAs } from "file-saver";
import Axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../../Context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { Switch } from "@mui/material";
import "datatables.net";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import ProfileTemplate from "../../assets/ProfileUpload.xlsx";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
const AppService = new ProfileSearchService();
const _CandidatePipeLineService = new CandidatePipelineService();

const ConfigurationServiceobj = new ApplicarionConfigService();

const ProfileSearchList = () => {
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [stepDate, setstepDate] = useState(null);
  const [Profiledatalist, setProfiledatalist] = useState([]);
  const [FilterProfiledatalist, setFilterProfiledatalist] = useState([]);
  const [DemandList, SetDemandList] = useState([]);
  const [Demanddata, SetDemand] = useState(0);
  const [LocationList, SetLocationList] = useState([]);
  const [Location, SetLocation] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [SkillList, SetSkillList] = useState([]);
  const [Skill, SetSkill] = useState("");
  const [Experience, SetExperience] = useState("");
  const [Education, SetEducation] = useState("");
  const [BaseStr, SetBaseStr] = useState([]);
  const [SkillDate, setSkillDate] = useState(null);
  const [PopName, setPopName] = useState("Add");
  const [ButtonName, setButtonName] = useState("Add");
  const [startDate, setStartDate] = useState(new Date());
  const [Filetring, setFileString] = useState(0);
  const [Filetype, setFiletype] = useState(null);
  const [CompanyLocation, setCompanyLocation] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [MandatoryList, setMandatoryList] = useState({});
  const [ResumeUploaded, setResumeUploaded] = useState("");
  const [candidatefirstName, setcanditefirstName] = useState("");
  const [ext, setext] = useState("");
  const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const [companyID, setCompanyID] = useState(cookies?.companyID);
  const [roleName, setRoleName] = useState(cookies?.roleName);
  const roleNameDumm = roleName?.includes('Super Admin') ? 'Super Admin' : 'Others'

  const [selectedCompanyID, setSelectedCompanyID] = useState(companyID);
  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    GetLocationDetails();
  }, [selectedLocation]);

  const GetLocationDetails = () => {
    ConfigurationServiceobj.GetLocationDetails()
      .then((res) => {
        if (res.data.length > 0) {
          setLocationList(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    $("#ProfileSearchTable").DataTable().destroy();
    $("#ProfileSearchTable").DataTable({
      scrollX: true,
      responsive: true,
      ordering: false,
      scrollCollapse: true,
      autoWidth: false,
      fixedColumns: true,
      columnDefs: [
        {
          targets: 0,
          width: "10px",
          orderable: false,

          responsivePriority: 1,
        },
      ],
      fixedColumns: {
        left: 2,
        right: 1,
      },
    });
  }, [Profiledatalist, Render]);
  useEffect(() => {
    Profilelist();
    Demanddatalist();
    _CandidatePipeLineService
      .getSkillData("SKILLSET")
      .then((res) => {
        setSkillDate(res.data);
        console.log(res.data);
      })
      .catch((res) => { });
    _CandidatePipeLineService
      .getSkillData("COMPANYNAME")
      .then((res) => {
        setCompanyLocation(res.data);
        console.log(res.data);
      })
      .catch((res) => { });
    $(".submenu").removeClass("active");
    $("#Demandmenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Demand").addClass("show");

    $(".submenu li").removeClass("active");
    $("#ProfileSearch").addClass("active");
    $("#ProfileSearchTable").DataTable().destroy();
    const interval = setInterval(() => {
      GetFileSaveNotification();
    }, 100);
    ConfigurationServiceobj.GetFormMandatoryField()
      .then((res) => {
        console.log(res);

        pivot(res.data);
        // setMandatoryList([...res.data]);
      })
      .catch((error) => { });
  }, [Render]);

  const Profilelist = () => {
    AppService.Profiledatalist(companyID, roleName).then((res) => {
      
      if (res != null && res.data != null && res.data.length > 0) {

        setProfiledatalist(res.data);
        setFilterProfiledatalist(res.data);
        console.log(res.data);
        const data = res.data;
        var DistinctList = Array.from(new Set(data.map((item) => item.Skills)));
        SetSkillList(DistinctList);
        $("#ProfileSearchTable").DataTable().destroy();
      }
    });
  };

  const Demanddatalist = () => {
    AppService.Demanddatalist(companyID, roleName).then((res) => {
      if (res != null && res.data != null && res.data.length > 0) {
        SetDemandList(res.data);
      }
    });
  };

  function pivot(res) {

    const pivotedList = res.reduce((result, item) => {
      const key = item.FormFieldName;

      if (!result[key]) {
        result[key] = item.IsMandatory === 1 ? "*" : "";
      } else {
        result[key] += "";
      }

      return result;
    }, {});
    setMandatoryList(pivotedList);
    // return pivotedList;
  }

  useEffect(() => {
    const filterlist = FilterProfiledatalist.filter((el) => {
      
      return (
        el?.LocationName?.toLowerCase()?.includes(Location?.toLowerCase()) &&
        (parseInt(Experience) > 0
          ? el?.Experience == parseInt(Experience)
          : true) &&
        (el?.Skills != null
          ? el?.Skills?.toLowerCase()?.includes(Skill?.toLowerCase())
          : true) &&
        el?.Education?.toLowerCase()?.includes(Education?.toLowerCase())
      );
    });

    console.log(filterlist);
    setProfiledatalist([...filterlist]);
    $("#ProfileSearchTable").DataTable().destroy();
  }, [Education, Location, Skill, Experience]);

  useEffect(() => {
    if (Number(Demanddata) > 0) {
      AppService.GetProfilelistByDemandID(Number(Demanddata), companyID, roleNameDumm).then((res) => {
        if (res != null && res.data != null && res.data.length > 0) {

          setProfiledatalist(res.data);
          setFilterProfiledatalist(res.data);
          console.log(res.data);
          const data = res.data;
          var DistinctList = Array.from(
            new Set(data.map((item) => item.Skills))
          );
          SetSkillList(DistinctList);
          $("#ProfileSearchTable").DataTable().destroy();
        }
      });
    } else {
      
      AppService.Profiledatalist(companyID,roleName).then((res) => {
        if (res != null && res.data != null && res.data.length > 0) {

          setProfiledatalist(res.data);
          setFilterProfiledatalist(res.data);
          console.log(res.data);
          const data = res.data;
          var DistinctList = Array.from(
            new Set(data.map((item) => item.Skills))
          );
          SetSkillList(DistinctList);

          $("#ProfileSearchTable").DataTable().destroy();
        }
      });
    }
  }, [Demanddata, Render]);

  const uploadbtn = () => {
    $("#ProfileUpload").click();
  };

  const uploadbtnFTP = () => {
    $("#ProfileUploadFTP").click();
  };

  var item;
  var Basestring = [];
  const BulkFileUpload = (e) => {
    var len = e.target.files.length;

    for (let i = 0; i < len; i++) {
      var files = e.target.files;
      let filename = e.target.files[i].name;
      let reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = (e) => {
        var file_name_array = filename.split(".");
        var file_extension = file_name_array[file_name_array.length - 1];
        var FileStr = e.target.result.split(",");
        item = {
          AttachmentFile: FileStr[1],
          AttachmentfileName: filename,
          FileExtension: file_extension,
        };
        Basestring.push(item);
        SetBaseStr(Basestring);
      };
    }
    $(".AddProfileBulkUpload").click();
  };

  const BulkFileUploadFTP = (e) => {
    
    var files = e.target.files;
    var MIME_Type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if(files[0]?.type!=MIME_Type)
    {
      swal({
        text: "Invalid file type. Please select an Excel file (.xlsx)",
        icon: "warning",
        button: "Ok",
      });
    }
    else
    {
      let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {

      var filestr = e.target.result.split(",");

      SavaFileFTP(filestr[1]);
    };
    }
  };

  const SavaFileFTP = (str) => {
    const obj = {
      str: str,
      EmployeeID: Number(cookies.userID),
      CompanyID: companyID
    };
    $(".loader-container").show();
    AppService.BulkUploadFromFTP(obj)
      .then((res) => {
        if (res.data === 1) {
          swal(
            "File upload!",
            "Profile Upload process will take time, once completed system will send the notification",
            "info"
          );
          PageRender();
          // $(".close").click();
          $(".loader-container").hide();
        } else {
          $(".loader-container").hide();
          swal("File upload!", "Failed to save", "error");
        }
      })
      .catch((res) => {
        $(".loader-container").hide();
      });
  };

  const [CandidateErr, setCandidateErr] = useState({
    CandidateID: "",
    CandidateFirstName: "",
    CandidateLastName: "",
    ContactNumber: "",
    ContactEmail: "",
    Location: "",
    SkillSetID: "",
    Experience: "",
    Education: "",
    DateUpload: "",
    // FileUploadpath: 'fef',
    FileUploadpath: "",
    Filetype: "",
    DemandID: "",
    CompanyLocation: "",
  });
  function numberOnly(id) {
    var element = document.getElementById(id);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }

  function change(id) {
    var idName = "#" + id;
    $(idName).css("border-color", "#ebedf2");
  }

  const UploadFiles = () => {
    var Dynamiccool = [];
    var flag = 0;

    $("#FileDataTable tbody tr").each(function () {
      var item1 = {};

      if (
        $(this).closest("tr").find(".CandidateName").val() != null &&
        $(this).closest("tr").find(".CandidateName").val() != ""
      ) {
        item1["CandidateFirstName"] = $(this)
          .closest("tr")
          .find(".CandidateName")
          .val();
      } else {
        if (MandatoryList["CandidateFirstName"] == "*") {
          $(this)
            .closest("tr")
            .find(".CandidateName")
            .css("border-color", "red");
          flag = 1;
        }
      }

      if (
        $(this).closest("tr").find(".ContactNumebr").val() != null &&
        $(this).closest("tr").find(".ContactNumebr").val() != ""
      ) {
        item1["ContactNumber"] = $(this)
          .closest("tr")
          .find(".ContactNumebr")
          .val();
      } else {
        if (MandatoryList["ContactNumber"] == "*") {
          $(this)
            .closest("tr")
            .find(".ContactNumebr")
            .css("border-color", "red");
          flag = 1;
        }
      }
      if (
        $(this).closest("tr").find(".ContactEmail").val() != null &&
        $(this).closest("tr").find(".ContactEmail").val() != ""
      ) {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            $(this).closest("tr").find(".ContactEmail").val()
          )
        ) {
          $(this)
            .closest("tr")
            .find(".ContactEmail")
            .css("border-color", "red");
          flag = 1;
        } else {
          item1["ContactEmail"] = $(this)
            .closest("tr")
            .find(".ContactEmail")
            .val();
        }
      } else {
        if (MandatoryList["ContactEmail"] == "*") {
          $(this)
            .closest("tr")
            .find(".ContactEmail")
            .css("border-color", "red");
          flag = 1;
        }
      }
      if (
        $(this).closest("tr").find(".Location").val() != null &&
        $(this).closest("tr").find(".Location").val() != ""
      ) {
        item1["Location"] = $(this).closest("tr").find(".Location").val();
      } else {
        if (MandatoryList["Location"] == "*") {
          $(this).closest("tr").find(".Location").css("border-color", "red");
          flag = 1;
        }
      }
      if (
        $(this).closest("tr").find(".SkillSet").val() != null &&
        $(this).closest("tr").find(".SkillSet").val() != ""
      ) {
        item1["Skills"] = $(this).closest("tr").find(".SkillSet").val();
      } else {
        if (MandatoryList["SkillSet"] == "*") {
          $(this).closest("tr").find(".SkillSet").css("border-color", "red");
          flag = 1;
        }
      }
      if (
        $(this).closest("tr").find(".Experience").val() != null &&
        $(this).closest("tr").find(".Experience").val() != ""
      ) {
        item1["Experience"] = Number(
          $(this).closest("tr").find(".Experience").val()
        );
      } else {
        if (MandatoryList["Experience"] == "*") {
          $(this).closest("tr").find(".Experience").css("border-color", "red");
          flag = 1;
        }
      }
      if (
        $(this).closest("tr").find(".Education").val() != null &&
        $(this).closest("tr").find(".Education").val() != ""
      ) {
        item1["Education"] = $(this).closest("tr").find(".Education").val();
      } else {
        if (MandatoryList["Education"] == "*") {
          $(this).closest("tr").find(".Education").css("border-color", "red");
          flag = 1;
        }
      }

      if (
        $(this).closest("tr").find(".LocationStatus").val() != null &&
        $(this).closest("tr").find(".LocationStatus").val() != ""
      ) {
        item1["LocationStatus"] = Number(
          $(this).closest("tr").find(".LocationStatus").val()
        );
      } else {
        if (MandatoryList["LocationStatus"] == "*") {
          $(this)
            .closest("tr")
            .find(".LocationStatus")
            .css("border-color", "red");
          flag = 1;
        }
      }
      item1["Filestring"] = $(this).closest("tr").find(".Base64File").val();
      item1["Filetype"] = $(this).closest("tr").find(".FileExtension").val();
      item1["EmployeeID"] = Number(cookies.userID);
      item1["Status"] = "AC";
      item1["CompanyID"] = companyID;
      Dynamiccool.push(item1);
    });
    if (flag != 1) {

      SavaFile(JSON.stringify(Dynamiccool));
    }
  };

  const filterdata = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "location":
        SetLocation(value);
        break;
      case "experience":
        SetExperience(value);
        break;
      case "skill":
        SetSkill(value);
        break;
      case "education":
        SetEducation(value);
        break;
      default:
        break;
    }
  };

  const SavaFile = (str) => {
    const obj = {
      str: str,
    };
    AppService.BulkUpload(obj)
      .then((res) => {
        if (res.data === 1) {
          swal("File upload!", "Saved Successfully", "success");
          PageRender();
          $(".close").click();
        } else {
          swal("File upload!", "Failed to save", "error");
        }
      })
      .catch((res) => { });
  };

  const filesave = (id, firstname, filetypesrc) => {

    const obj = {
      CandidateID: id,
    };
    AppService.filesave(obj)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", firstname + "." + filetypesrc);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((res) => { });
  };

  const SaveDemandProfile = () => {

    var Profileid = [];

    if (Number(Demanddata) > 0) {
      $("input:checkbox[name='Candidatecheck']:checked").each(function () {
        Profileid.push($(this).val());
      });
      const obj = {
        Profileid: Profileid.join(", "),
        DemandId: Number(Demanddata),
        EmployeeID: cookies.userID,
        CompanyID: companyID
      };
      AppService.SaveDemandProfile(obj)
        .then((res) => {
          if (res.data >0) {
            $(".Candidatecheck").prop("checked", false);
            swal("Profile!", "Profile linked Successfully", "success");
            window.location.reload(false);
            SetDemand(Demanddata);
          } else {
          }
        })
        .catch((res) => { });
    } else {
      swal("Profile", "Please select the demand", "error");
    }
  };

  $(function (e) {
    $(".CheckAllLocation").click(function () {
      if ($(this).is(":checked")) {
        $(".open_CheckAllLocation").prop("checked", true);
      } else {
        $(".open_CheckAllLocation").prop("checked", false);
      }
    });

    $(".ChangeLocation").click(function () {
      var Value = $(this).closest(".tdLocation").find(".Location").val();
      if (
        $(this)
          .closest(".tdLocation")
          .find(".open_CheckAllLocation")
          .is(":checked")
      ) {
        $("#AddBulkUpload tbody tr").each(function () {
          if ($(this).find(".open_CheckAllLocation").is(":checked")) {
            $(this).find(".Location").val(Value);
          }
        });
      }
    });

    $(".ChangeSkill").click(function () {
      var Value = $(this).closest(".TdSkillSet").find(".SkillSet").val();
      if (
        $(this)
          .closest(".TdSkillSet")
          .find(".open_CheckAllSkillSet")
          .is(":checked")
      ) {
        $("#AddBulkUpload tbody tr").each(function () {
          if ($(this).find(".open_CheckAllSkillSet").is(":checked")) {
            $(this).find(".SkillSet").val(Value);
          }
        });
      }
    });

    $(".CheckAllSkillSet").click(function () {
      if ($(this).is(":checked")) {
        $(".open_CheckAllSkillSet").prop("checked", true);
      } else {
        $(".open_CheckAllSkillSet").prop("checked", false);
      }
    });

    $(".changeExperience").click(function () {
      var Value = $(this).closest(".TdExperience").find(".Experience").val();
      if (
        $(this)
          .closest(".TdExperience")
          .find(".open_CheckAllExperience")
          .is(":checked")
      ) {
        $("#AddBulkUpload tbody tr").each(function () {
          if ($(this).find(".open_CheckAllExperience").is(":checked")) {
            $(this).find(".Experience").val(Value);
          }
        });
      }
    });

    $(".CheckAllExperience").click(function () {
      if ($(this).is(":checked")) {
        $(".open_CheckAllExperience").prop("checked", true);
      } else {
        $(".open_CheckAllExperience").prop("checked", false);
      }
    });

    $(".changeEducation").click(function () {
      var Value = $(this).closest(".TdEducation").find(".Education").val();
      if (
        $(this)
          .closest(".TdEducation")
          .find(".open_CheckAllEducation")
          .is(":checked")
      ) {
        $("#AddBulkUpload tbody tr").each(function () {
          if ($(this).find(".open_CheckAllEducation").is(":checked")) {
            $(this).find(".Education").val(Value);
          }
        });
      }
    });

    $(".changeLocStatus").click(function () {
      var Value = $(this)
        .closest(".TdLocationStatus")
        .find(".LocationStatus")
        .val();
      if (
        $(this)
          .closest(".TdLocationStatus")
          .find(".open_CheckAllLocationStatus")
          .is(":checked")
      ) {
        $("#AddBulkUpload tbody tr").each(function () {
          if ($(this).find(".open_CheckAllLocationStatus").is(":checked")) {
            $(this).find(".LocationStatus").val(Value);
          }
        });
      }
    });

    $(".CheckAllEducation").click(function () {
      if ($(this).is(":checked")) {
        $(".open_CheckAllEducation").prop("checked", true);
      } else {
        $(".open_CheckAllEducation").prop("checked", false);
      }
    });

    $(".CheckAllLocationStatus").click(function () {
      if ($(this).is(":checked")) {
        $(".open_CheckAllLocationStatus").prop("checked", true);
      } else {
        $(".open_CheckAllLocationStatus").prop("checked", false);
      }
    });

    $(".close").click(function () {
      SetBaseStr(null);
      $("#ProfileUpload").val("");
    });
  });

  const ClosePop = () => {
    $(".close").click();
    if (PopName == "Add") {
      setPopName("Edit");
    }
    if (PopName == "Edit") {
      setPopName("Add");
    }

    if (ButtonName == "Add") {
      setButtonName("Update");
    }
    if (ButtonName == "Update") {
      setButtonName("Add");
    }
    ClearData();
  };

  function ChangePopName() {
    // ClosePop();
    setPopName("Add");
    setButtonName("Add");
  }

  function numberOnly(id) {
    var element = document.getElementById(id);
    element.value = element.value.replace(/[^0-9]/gi, "");

    if (id == "ConNumber") {
      setCandidateErr({ ...CandidateErr, ContactNumber: "" });
    }
    if (id == "Experience") {
      setCandidateErr({ ...CandidateErr, Experience: "" });
    }
  }

  const CompanyIDOnChange = (e) => {
    const val = e.target.value
    setSelectedCompanyID(val)
  }
  function ClearData() {
    $("#CandidateID").val(0);
    $("#FirstName").val("");
    $("#LastName").val("");
    $("#ConNumber").val("");
    $("#ConEmail").val("");
    $("#Location").val("");
    $("#SkillSet").val("");
    $("#Experience").val("");
    $("#Education").val("");
    setSelectedLocation()
    setSelectedCompanyID(companyID)
    setStartDate(new Date());
    // FileUploadpath: 'fef',
    setFileString(null);
    setFiletype(null);
    setcanditefirstName('');
    setResumeUploaded('');

    $("#Resume").val("");
    setCandidateErr({});
  }

  const ResumeFile = (e) => {
    var files = e.target.files;
    var FileName = files[0].name.split(".");
    var extension = FileName[1];
    setFiletype(extension);
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      var filestr = e.target.result.split(",");
      setFileString(filestr[1]);
    };
    setCandidateErr({ ...CandidateErr, FileUploadpath: "" });
  };

  const AddCandidate = (e) => {
    var flag = true;

    var ValidationErrrr = {};

    e.preventDefault();
    const formdata = new FormData(e.currentTarget);
    const CandidateDetails = {
      CandidateID: Number($("#CandidateID").val()),
      CandidateFirstName: formdata.get("FirstName"),
      CandidateLastName: formdata.get("lastName"),
      ContactNumber: formdata.get("ConNumber"),
      ContactEmail: formdata.get("email"),
      Location: selectedLocation,
      SkillSetID: Number($("#SkillSet").val()),
      Experience: Number(formdata.get("Experience")),
      Education: formdata.get("Education"),
      DateUpload: startDate,
      FileUploadpath: Filetring,
      Filetype: Filetype,
      EmployeeID: Number(cookies.userID),
      CompanyLocation: selectedCompanyID,
    };

    if (MandatoryList["CandidateFirstName"] == "*") {
      if (
        CandidateDetails.CandidateFirstName == null ||
        CandidateDetails.CandidateFirstName == ""
      ) {
        ValidationErrrr.CandidateFirstName = "First Name is required";
        flag = false;
      }
    }
    if (MandatoryList["CandidateLastName"] == "*") {
      if (
        CandidateDetails.CandidateLastName == null ||
        CandidateDetails.CandidateLastName == ""
      ) {
        ValidationErrrr.CandidateLastName = "Last Name is required";
        flag = false;
      }
    }
    if (MandatoryList["ContactNumber"] == "*") {
      if (
        CandidateDetails.ContactNumber == null ||
        CandidateDetails.ContactNumber == ""
      ) {
        ValidationErrrr.ContactNumber = "Contact Number is required";

        flag = false;
      }
    }
    if (MandatoryList["ContactEmail"] == "*") {
      if (
        CandidateDetails.ContactEmail == null ||
        CandidateDetails.ContactEmail == ""
      ) {
        ValidationErrrr.ContactEmail = "Contact Email is required";

        flag = false;
      } else {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            CandidateDetails.ContactEmail
          )
        ) {
          ValidationErrrr.ContactEmail = "Email ID is Not Vaild";

          flag = false;
        }
      }
    }
    if (MandatoryList["Location"] == "*") {
      if (
        CandidateDetails.Location == null ||
        CandidateDetails.Location == ""
      ) {
        ValidationErrrr.Location = "Location is required";

        flag = false;
      }
    }
    if (MandatoryList["SkillSet"] == "*") {
      if (
        CandidateDetails.SkillSetID == null ||
        CandidateDetails.SkillSetID == ""
      ) {
        ValidationErrrr.SkillSetID = "Skill Set is required";

        flag = false;
      }
    }
    if (MandatoryList["Experience"] == "*") {
      if (
        CandidateDetails.Experience == null ||
        CandidateDetails.Experience == ""
      ) {
        ValidationErrrr.Experience = "Experience is required";

        flag = false;
      }
    }
    if (MandatoryList["Education"] == "*") {
      if (
        CandidateDetails.Education == null ||
        CandidateDetails.Education == ""
      ) {
        ValidationErrrr.Education = "Education is required";

        flag = false;
      }
    }
    if (MandatoryList["DateApplied"] == "*") {
      if (
        CandidateDetails.DateUpload == null ||
        CandidateDetails.DateUpload == ""
      ) {
        ValidationErrrr.DateUpload = "Date is required";

        flag = false;
      }
    }
    if (MandatoryList["FileUploadpath"] == "*") {
      if (
        CandidateDetails.CandidateID == null ||
        CandidateDetails.CandidateID == ""
      ) {
        if (
          CandidateDetails.FileUploadpath == null ||
          CandidateDetails.FileUploadpath == ""
        ) {
          ValidationErrrr.FileUploadpath = "File is required";

          flag = false;
        }
      }
    }
    if (MandatoryList["CompanyLocation"] == "*") {
      if (
        CandidateDetails.CompanyLocation == null ||
        CandidateDetails.CompanyLocation == 0
      ) {
        ValidationErrrr.CompanyLocation = "Company Location is required";

        flag = false;
      }
    }

    if (Object.keys(ValidationErrrr).length > 0) {
      // Display all validation errors
      setCandidateErr(ValidationErrrr);
    } else {
      SaveCandidateData(CandidateDetails);
    }
  };

  const SaveCandidateData = (CandidateDetails) => {
    _CandidatePipeLineService
      .CreateCandidate(CandidateDetails)
      .then((res) => {

        $(".loader-container").hide();
        swal({
          text: "Candidate profile has been saved successfully",
          icon: "success",
          button: "Ok",
        });
        // $('.close').trigger("click");
        Profilelist();
        ClosePop();
        ClearData();
        PageRender();
        setPopName("Add");
      })
      .catch((res) => {
        $(".loader-container").hide();
      });
  };
  const ChangeDate = (date) => {
    setStartDate(date);
    setCandidateErr({ ...CandidateErr, DateUpload: "" });
  };
  const EditCandidate = (
    candidateID,
    candidateName,
    candidateLastName,
    contactNumber,
    contactEmail,
    location,
    skillSetID,
    experience,
    education,
    FileStr,
    filetype,
    companyID
  ) => {
    const currentPageBeforeEdit = currentPage;
    setCurrentPage(currentPageBeforeEdit);
    setPopName("Edit");
    setButtonName("Update");
    var Name = candidateName.replace(" ", "-");
    Name = Name.split("-");
    $("#CandidateID").val(candidateID);
    $("#FirstName").val(Name[0]);
    $("#LastName").val(candidateLastName);
    $("#ConNumber").val(contactNumber);
    $("#ConEmail").val(contactEmail);
    $("#Location").val(location);
    $("#Experience").val(experience);
    $("#SkillSet").val(skillSetID);
    $("#Education").val(education);
    $("#CompanyLocation").val(companyID);
    setSelectedLocation(location)
    setResumeUploaded(candidateID);
    setext(filetype);
    setcanditefirstName(candidateName);
    setFileString(FileStr);
    setFiletype(filetype);
  };
  const GetFileSaveNotification = () => {
    _CandidatePipeLineService
      .GetFileSaveNotification(Number(cookies.userID),companyID)
      .then((res) => {
        if (res.data != null && res.data != "") {
          PageRender();
          showError(res.data.isCompleted, res.data.responseText);
        }
      })
      .catch((res) => {
        $(".loader-container").hide();
      });
  };

  const showError = (iscomplete, response) => {
    
    var invaild_columns=response?.includes("mismatched column names:")?response?.split("mismatched column names:")[1]:response;
    var column_Name=response?.includes("mismatched column names:")?"Invalid Column Names":"Error Message"
    var type = "";
    var msg = "";
    if (iscomplete == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Demand Upload Details',
        customClass: 'swal-wide',
        html: `
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
            margin-bottom: 10px;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
          }
          .error-container {
            max-height: 100px;
            overflow-y: auto;
            margin-top: 10px;
            word-wrap: break-word;
          }
          .error-list {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
          .error-list-item {
            margin-bottom: 5px;
            font-size: 13px;
            text-align:left;
          }
          .error-head{
            font-size: 13px;
          }
          
        </style>
        <table>
          <thead>
            <tr>
              <th>${column_Name}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${invaild_columns}</td>
            </tr>
          </tbody>
        </table>
      `,
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      // Toast(response, "warning");
    }

    if (iscomplete == 1) {
      type = "success";
      msg = "Profile uploaded successfully";
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Toast(msg, type);
    }
  };

  const ClearFilter = () => {
    $("#EducationFilter").val("");
    $("#LocationFilter").val("");
    $("#ExperienceFilter").val("");
    $("#SkillFilter").val("");

    SetLocation("");
    SetExperience("");
    SetSkill("");
    SetEducation("");
    PageRender();
  };
  function alphabetOnly(e) {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z ]+$/;

    if (!regex.test(inputValue)) {
        e.target.value = inputValue.replace(/[^a-zA-Z ]/g, '');
    }
}

useEffect(() => {
    // Destroy the existing DataTable instance if it exists
    const existingTable = $("#ProfileSearchTable").DataTable();
    let searchValue = existingTable ? existingTable.search() : '';

    if ($.fn.DataTable.isDataTable("#ProfileSearchTable")) {
      existingTable.destroy();
    }

    // Initialize the DataTable
    const table = $("#ProfileSearchTable").DataTable({
      pageLength: 10,
      scrollX: true,
      responsive: true,
      ordering: false,
      scrollCollapse: true,
      autoWidth: false,
      fixedColumns: true,
      columnDefs: [
        {
          targets: 0,
          width: "10px",
          orderable: false,

          responsivePriority: 1,
        },
      ],
      fixedColumns: {
        left: 2,
        right: 1,
      },
    });

    // Restore the search value
    table.search(searchValue).draw();

    
    // Attach an event listener for page change
    table.on("page.dt", function () {
      const newPage = table.page.info().page + 1;
      handlePageChange(newPage);
    });

    // Initialize the DataTable with the current page
    const currentPageToDisplay =
      cookies.ProfileTableCookies != null && cookies.ProfileTableCookies!="" && cookies.ProfileTableCookies!=undefined
        ? cookies.ProfileTableCookies
        : currentPage;
    table.page(currentPageToDisplay - 1).draw(false);

    return () => {
      // Make sure to remove the event listener when the component unmounts
      table.off("page.dt");
    };
  }, [Profiledatalist, currentPage, setCookie, cookies.ProfileTableCookies]);

  function handlePageChange(newPage) {
    setCurrentPage(newPage);

    // Update the DataTable page immediately
    const table = $("#ProfileSearchTable").DataTable();

    table.page(newPage - 1).draw(false);

    // Update your custom cookie here
    setCookie("ProfileTableCookies", newPage, { path: "/" });
  }

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4
                        className="card-title mr-auto"
                        style={{ width: "75%" }}
                      >
                        Profile Search
                      </h4>
                      {priviledge.includes('PROSERCHCREATE') &&
                        <>
                          <button
                            className="btn btn-primary btn-round btn-sm ml-auto"
                            data-bs-toggle="modal"
                            onClick={(e) => ChangePopName()}
                            data-bs-target="#addRowModal"
                            style={{ marginRight: "10px" }}
                          >
                            <i className="fa fa-plus"></i>
                            <span className="ml-1">Add Profile</span>
                          </button>

                          <button
                            className="btn btn-primary btn-sm btn-round ml-auto"
                            onClick={uploadbtn}
                            id="uploadBtn"
                            style={{ marginRight: "10px" }}
                          >
                            <i className="fa fa-upload" style={{ color: "white" }}></i>{" "}
                            <span className="ml-2" style={{ color: "white" }}>
                              Bulk upload
                            </span>
                            <input
                              type="file"
                              multiple
                              id="ProfileUpload"
                              onChange={BulkFileUpload}
                              style={{ display: "none" }}
                            />
                          </button>

                          <button
                            className="btn btn-primary btn-sm btn-round ml-auto"
                            onClick={uploadbtnFTP}
                            id="uploadBtnFTP"
                          >
                            <i className="fa fa-upload"></i>{" "}
                            <span className="ml-2">FTP Bulk upload</span>
                            <input
                              type="file"
                              multiple
                              id="ProfileUploadFTP"
                              onChange={BulkFileUploadFTP}
                              style={{ display: "none" }}
                            />
                          </button>
                          &nbsp;
                          <button className="btn btn-primary btn-sm btn-round ml-auto">
                            <a
                              className="fa fa-download"
                              href={ProfileTemplate}
                              download
                              style={{ marginLeft: "1px", color: "white" }}
                            >
                              <span className="ml-2" style={{ color: "white" }}>
                                FTP Bulk Upload Template
                              </span>
                            </a>
                          </button>
                        </>
                      }

                    </div>
                  </div>

                  <div className="card-body">
                    <div
                      className="modal fade"
                      id="addRowModal"
                      tabIndex="-1"
                      role="dialog"
                      aria-hidden="true"
                      data-bs-backdrop="static"
                    >
                      <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                          <div className="modal-header no-bd">
                            <h5 className="modal-title">
                              <span className="fw-mediumbold">{PopName} </span>
                              <span className="fw-light">Candidate</span>
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form onSubmit={AddCandidate}>
                              <div className="row">
                                <input type="hidden" id="CandidateID" />
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>First Name</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["CandidateFirstName"]}
                                    </span>
                                    <input
                                      id="FirstName"
                                      type="text"
                                      className="form-control"
                                      name="FirstName"
                                      placeholder="Candidate First Name"
                                      onChange={(e) =>
                                        setCandidateErr({
                                          ...CandidateErr,
                                          CandidateFirstName: "",
                                        })
                                      }
                                      onInput={alphabetOnly}
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.CandidateFirstName}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Last Name</label>{" "}
                                    <span className="text-danger">
                                      {" "}
                                      {MandatoryList["CandidateLastName"]}
                                    </span>
                                    <input
                                      id="LastName"
                                      type="text"
                                      className="form-control"
                                      name="lastName"
                                      onChange={(e) =>
                                        setCandidateErr({
                                          ...CandidateErr,
                                          CandidateLastName: "",
                                        })
                                      }
                                      onInput={alphabetOnly}
                                      placeholder="Candidate Last Name"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.CandidateLastName}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Contact Number</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["ContactNumber"]}
                                    </span>
                                    <input
                                      id="ConNumber"
                                      type="text"
                                      maxLength="20"
                                      onChange={(e) => numberOnly("ConNumber")}
                                      className="form-control"
                                      name="ConNumber"
                                      placeholder="Contact Number"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.ContactNumber}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Email</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["ContactEmail"]}
                                    </span>
                                    <input
                                      id="ConEmail"
                                      type="text"
                                      className="form-control"
                                      name="email"
                                      onChange={(e) =>
                                        setCandidateErr({
                                          ...CandidateErr,
                                          ContactEmail: "",
                                        })
                                      }
                                      placeholder="Email"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.ContactEmail}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Location</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["Location"]}
                                    </span>
                                   <Autocomplete
                                      //options={locationList?.map((location) => location.LocationName)}
                                      options={locationList?.length > 0 ? locationList.map((location) => location.LocationName) : []}
                                      value={selectedLocation}
                                      id="Location"
                                      name="location"
                                      onKeyUp={(e) => {
                                        setSelectedLocation(e.target.defaultValue);
                                      }}
                                      onChange={(e) => {
                                        setSelectedLocation(e.target.innerText);
                                        setCandidateErr({
                                          ...CandidateErr,
                                          Location: "",
                                        })
                                      }}
                                      
                                      renderInput={(params) => <TextField {...params} placeholder="Location" />}
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.Location}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Company Loaction</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["CompanyLocation"]}
                                    </span>
                                    <select
                                      className="form-control "
                                      value={selectedCompanyID}
                                      onChange={CompanyIDOnChange}
                                      id="CompanyLocation"
                                      name="CompanyLocation"
                                      disabled={roleName.includes('Super Admin') ? false : true}
                                    >
                                      <option value="">
                                        Select Company Location
                                      </option>
                                      {CompanyLocation != null &&
                                        CompanyLocation.length > 0 &&
                                        CompanyLocation.map((e) => {
                                          return (
                                            <option value={e.id}>
                                              {e.value}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.CompanyLocation}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Skill Set</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["SkillSet"]}
                                    </span>
                                    <select
                                      className="form-control "
                                      onChange={(e) =>
                                        setCandidateErr({
                                          ...CandidateErr,
                                          SkillSetID: "",
                                        })
                                      }
                                      id="SkillSet"
                                    >
                                      <option value="">Select Skill Set</option>
                                      {SkillDate != null &&
                                        SkillDate.length > 0 &&
                                        SkillDate.map((e) => {
                                          return (
                                            <option value={e.id}>
                                              {e.value}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.SkillSetID}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Experience</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["Experience"]}
                                    </span>
                                    <input
                                      id="Experience"
                                      type="text"
                                      maxLength="3"
                                      name="Experience"
                                      onChange={(e) => numberOnly("Experience")}
                                      className="form-control"
                                      placeholder="Experience"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.Experience}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Education</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["Education"]}
                                    </span>
                                    <input
                                      id="Education"
                                      type="text"
                                      name="Education"
                                      onChange={(e) =>
                                        setCandidateErr({
                                          ...CandidateErr,
                                          Education: "",
                                        })
                                      }
                                      className="form-control"
                                      placeholder="Education"
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.Education}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Resume Upload</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["FileUploadpath"]}
                                    </span>
                                    <input
                                      id="Resume"
                                      type="file"
                                      name="Resume"
                                      onChange={(e) => ResumeFile(e)}
                                      className="form-control"
                                      placeholder="Resume Upload"
                                    />
                                    <span> </span>
                                    {ext != null &&
                                      ext != "" ? (
                                      <>
                                        <div style={{ marginTop: "5px" }}>
                                          <a
                                            onClick={(e) =>
                                              filesave(
                                                ResumeUploaded,
                                                candidatefirstName,
                                                ext
                                              )
                                            }
                                            title={
                                              candidatefirstName + " Resume"
                                            }
                                          >
                                            <i
                                              className="fas fa-file-alt"
                                              style={{ color: "#1572e8" }}
                                            ></i>{" "}
                                          </a>
                                          <span>
                                            {candidatefirstName}.{ext}{" "}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.FileUploadpath}
                                    </span>
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Date Applied</label>{" "}
                                    <span className="text-danger">
                                      {MandatoryList["DateApplied"]}
                                    </span>
                                    <DatePicker
                                      className="form-control"
                                      selected={startDate}
                                      onChange={(date) => ChangeDate(date)}
                                      showTimeSelect
                                      dateFormat="dd-MM-yyyy"
                                      maxDate={new Date()}
                                    />
                                    <span style={{ color: "red" }}>
                                      {CandidateErr.Date}
                                    </span>
                                  </div>
                                </div> */}
                              </div>
                              <div className="modal-footer no-bd">

                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    ClosePop();
                                  }}
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  id="addRowButton"
                                  className="btn btn-primary btn-sm"
                                >
                                  {ButtonName}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-11">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group ">
                              <label>Location</label>
                              <input
                                id="LocationFilter"
                                type="text"
                                className="form-control"
                                placeholder="Location"
                                name="location"
                                onChange={filterdata}
                                autoComplete="off"
                              />

                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group ">
                              <label>Skill Set</label>
                              <select
                                name="skill"
                                className="form-control"
                                id="SkillFilter"
                                onChange={(e) => SetSkill(e.target.value)}
                                value={Skill}
                              >
                                <option value="">Select Skill Set</option>
                                {SkillList &&
                                  SkillList.map((x, i) => {
                                    return (
                                      <option key={i} value={x}>
                                        {x}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group ">
                              <label>Experience</label>
                              <input
                                id="ExperienceFilter"
                                type="text"
                                className="form-control"
                                placeholder="Experience"
                                name="experience"
                                onChange={filterdata}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group ">
                              <label>Education</label>
                              <input
                                id="EducationFilter"
                                type="text"
                                className="form-control"
                                placeholder="Education"
                                name="education"
                                onChange={filterdata}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-1"
                        style={{ marginTop: "19px", marginLeft: "-16px" }}
                      >
                        <button
                          type="button"
                          id="SaveDemand"
                          className="btn btn-primary mt-4 btn-sm"
                          onClick={ClearFilter}
                        >
                          Clear
                        </button>
                      </div>
                      <div className="col-md-11">
                        <div className="row">
                          <div className="col-md-3"></div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3">
                            <div className="form-group ">
                              <label>Demand</label>
                              <select
                                name="Demand"
                                className="form-control"
                                id=""
                                onChange={(e) => SetDemand(e.target.value)}
                                value={Demanddata}
                              >
                                <option value="0">Select Demand</option>
                                {DemandList &&
                                  DemandList.map((x, i) => {
                                    return (
                                      <option key={i} value={x.DemandRequestID}>
                                        {x.DemandRequestCode}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {Demanddata > 0 && roleName.includes('Recruiter') ? (
                        <div
                          className="col-md-1"
                          style={{ marginTop: "17px", marginLeft: "-16px" }}
                        >
                          <button
                            type="button"
                            id="addRowButton"
                            className="btn btn-success mt-4 btn-sm"
                            onClick={(e) => SaveDemandProfile()}
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="table-responsive">
                      <table
                        id="ProfileSearchTable"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Select Profile</th>
                            <th>Candidate Name</th>
                            <th>Task ID</th>
                            <th>Contact Number</th>
                            <th>Email</th>
                            <th>Location</th>
                            <th>Skill Set</th>
                            <th>Experience</th>
                            <th>Education</th>
                            <th>Company Location</th>
                            <th>Resume</th>
\
                            {priviledge.includes('PROSERCHEDIT')&&
                            <th>Options</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {Profiledatalist &&
                            Profiledatalist.map((x, i) => {
                              return (
                                <tr>
                                  <td>
                                    <div class="form-check">
                                      <label class="form-check-label">
                                        <input
                                          class="form-check-input Candidatecheck"
                                          name="Candidatecheck"
                                          type="checkbox"
                                          value={x.CandidateID}
                                        />
                                        <span class="form-check-sign"></span>
                                      </label>
                                    </div>
                                  </td>
                                  <td>{x.CandidateFirstName}</td>
                                  <td>
                                    <span
                                      style={{
                                        width: "150px",
                                        display: "block",
                                      }}
                                    >
                                      {x.TaskID}
                                    </span>
                                  </td>
                                  <td>{x.ContactNumber}</td>
                                  <td>
                                    <span
                                      style={{
                                        width: "150px",
                                        display: "block",
                                      }}
                                    >
                                      {x.ContactEmail}
                                    </span>
                                  </td>
                                  <td>{x.LocationName}</td>
                                  <td>{x.Skills}</td>
                                  <td>{x.Experience}</td>
                                  <td>{x.Education}</td>
                                  <td>{x.CompanyName}</td>
                                  <td>
                                    {x.Filetype &&
                                      <button
                                        className="btn btn-link btn-primary btn-md"
                                        onClick={(e) =>
                                          filesave(
                                            x.CandidateID,
                                            x.CandidateFirstName,
                                            x.Filetype
                                          )
                                        }
                                        title={x.CandidateFirstName + " Resume"}
                                      >
                                        {" "}
                                        <i className="fas fa-file-alt"></i>{" "}
                                      </button>
                                    }
                                  </td>
                                  {priviledge.includes('PROSERCHEDIT') &&
                                    <td>
                                      <button
                                        type="button"
                                        data-bs-toggle="modal"
                                        title="Edit"
                                        className="btn btn-link btn-primary btn-md"
                                        data-bs-target="#addRowModal"
                                        onClick={(e) =>
                                          EditCandidate(
                                            x.CandidateID,
                                            x.CandidateFirstName,
                                            x.CandidateLastName,
                                            x.ContactNumber,
                                            x.ContactEmail,
                                            x.LocationName,
                                            x.SkillSetID,
                                            x.Experience,
                                            x.Education,
                                            "",
                                            x.Filetype,
                                            x.CompanyID
                                          )
                                        }
                                      >
                                        <i className="fa fa-edit"></i>
                                      </button>
                                    </td>
                                  }
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        className="AddProfileBulkUpload"
        data-bs-toggle="modal"
        data-bs-target="#AddBulkUpload"
      ></button>
      <div
        className="modal fade"
        id="AddBulkUpload"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          style={{ maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title">
                <span className="fw-mediumbold"></span>
                <span className="fw-light">Profile Upload</span>
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table
                  className="table-card display table table-striped table-hoverd"
                  id="FileDataTable"
                >
                  <thead>
                    <tr className="TableTr">
                      <td>File</td>
                      <td>
                        Candidate Name{" "}
                        <span className="text-danger">
                          {MandatoryList["CandidateFirstName"]}
                        </span>
                      </td>
                      <td>
                        Contact Number{" "}
                        <span className="text-danger">
                          {MandatoryList["ContactNumber"]}
                        </span>
                      </td>
                      <td>
                        Contact Email{" "}
                        <span className="text-danger">
                          {MandatoryList["ContactEmail"]}
                        </span>
                      </td>
                      <td>
                        Location{" "}
                        <span className="text-danger">
                          {MandatoryList["Location"]}
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            name="CheckAllLocation"
                            class="CheckAllLocation"
                            style={{ float: "right", margin: "5px 10px" }}
                          />
                        </span>
                      </td>
                      <td>
                        SkillSet{" "}
                        <span className="text-danger">
                          {MandatoryList["SkillSet"]}
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            name="CheckAllSkillSet"
                            class="CheckAllSkillSet"
                            style={{ float: "right", margin: "5px 10px" }}
                          />
                        </span>
                      </td>
                      <td>
                        Experience{" "}
                        <span className="text-danger">
                          {MandatoryList["Experience"]}
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            name="CheckAllExperience"
                            class="CheckAllExperience"
                            style={{ float: "right", margin: "5px 10px" }}
                          />
                        </span>
                      </td>
                      <td>
                        Education{" "}
                        <span className="text-danger">
                          {MandatoryList["Education"]}
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            name="CheckAllEducation"
                            class="CheckAllEducation"
                            style={{ float: "right", margin: "5px 10px" }}
                          />
                        </span>
                      </td>
                      {/* <td>
                        Company Location{" "}
                        <span className="text-danger">
                          {MandatoryList["CompanyLocation"]}
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            name="CheckAllLocationStatus"
                            class="CheckAllLocationStatus"
                            style={{ float: "right", margin: "5px 10px" }}
                          />
                        </span>
                      </td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {BaseStr != null && BaseStr.length > 0 ? (
                      BaseStr.map((e) => {
                        var ID = "Experinece" + e.AttachmentfileName;
                        var CandidateaName = "CandidateName" + e.FileExtension;
                        var ContactNumebr = "ContactNumebr" + e.FileExtension;
                        var ContactEmail = "ContactEmail" + e.FileExtension;
                        var Location = "Location" + e.FileExtension;
                        var SkillSet = "SkillSet" + e.FileExtension;
                        var Education = "Education" + e.FileExtension;
                        return (
                          <tr>
                            <td hidden>
                              <input
                                type="text"
                                className="FileName"
                                value={e.AttachmentfileName}
                                hidden
                              />
                            </td>
                            <td hidden>
                              <input
                                type="text"
                                className="Base64File"
                                value={e.AttachmentFile}
                                hidden
                              />
                            </td>
                            <td hidden>
                              <input
                                type="text"
                                className="FileExtension"
                                value={e.FileExtension}
                                hidden
                              />
                            </td>
                            <td>{e.AttachmentfileName}</td>
                            <td className="InputFill">
                              <input
                                type="text"
                                className="CandidateName form-control"
                                id={CandidateaName}
                                onChange={(e) => change(CandidateaName)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="ContactNumebr form-control"
                                id={ContactNumebr}
                                onChange={(e) => change(ContactNumebr)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="ContactEmail form-control"
                                id={ContactEmail}
                                onChange={(e) => change(ContactEmail)}
                              />
                            </td>
                            <td
                              style={{ position: "relative" }}
                              className="tdLocation"
                            >
                              <input
                                type="text"
                                className="Location form-control"
                                id={Location}
                                onChange={(e) => change(Location)}
                              />

                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: "0",
                                }}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    name="open_CheckAllLocation"
                                    class="open_CheckAllLocation"
                                    style={{
                                      float: "right",
                                      margin: "15px 10px",
                                    }}
                                  />
                                </span>
                                <span
                                  class="fas fa-arrow-circle-right ChangeLocation"
                                  style={{
                                    margin: " 15px 0px 0px 0px",
                                    float: "right",
                                  }}
                                ></span>
                              </div>
                            </td>
                            <td
                              style={{ position: "relative" }}
                              className="TdSkillSet"
                            >
                              <input
                                type="text"
                                list="browsers"
                                className="SkillSet form-control"
                                id={SkillSet}
                                onChange={(e) => change(SkillSet)}
                              />
                              <datalist
                                id="browsers"
                                style={{ overflow: "scroll", height: "20px" }}
                              >
                                {SkillDate != null && SkillDate.length > 0 ? (
                                  SkillDate.map((e) => {
                                    return <option value={e.value} />;
                                  })
                                ) : (
                                  <></>
                                )}
                              </datalist>
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: "0",
                                }}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    name="open_CheckAllSkillSet"
                                    class="open_CheckAllSkillSet"
                                    style={{
                                      float: "right",
                                      margin: "15px 10px",
                                    }}
                                  />
                                </span>
                                <span
                                  class="fas fa-arrow-circle-right ChangeSkill"
                                  style={{
                                    margin: " 15px 0px 0px 0px",
                                    float: "right",
                                  }}
                                ></span>
                              </div>
                              {/* </div> */}
                            </td>
                            <td
                              style={{ position: "relative" }}
                              className="TdExperience"
                            >
                              <input
                                type="text"
                                className="Experience form-control"
                                id={ID}
                                onChange={(e) => numberOnly(ID)}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: "0",
                                }}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    name="open_CheckAllExperience"
                                    class="open_CheckAllExperience"
                                    style={{
                                      float: "right",
                                      margin: "15px 10px",
                                    }}
                                  />
                                </span>
                                <span
                                  class="fas fa-arrow-circle-right changeExperience"
                                  style={{
                                    margin: " 15px 0px 0px 0px",
                                    float: "right",
                                  }}
                                ></span>
                              </div>
                            </td>
                            <td
                              style={{ position: "relative" }}
                              className="TdEducation"
                            >
                              <input
                                type="text"
                                className="Education form-control"
                                id={Education}
                                onChange={(e) => change(Education)}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: "0",
                                }}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    name="open_CheckAllEducation"
                                    class="open_CheckAllEducation"
                                    style={{
                                      float: "right",
                                      margin: "15px 10px",
                                    }}
                                  />
                                </span>
                                <span
                                  class="fas fa-arrow-circle-right changeEducation"
                                  style={{
                                    margin: " 15px 0px 0px 0px",
                                    float: "right",
                                  }}
                                ></span>
                              </div>
                            </td>
                            {/* <td
                              style={{ position: "relative" }}
                              className="TdLocationStatus"
                            >
                              <select
                                className="form-control LocationStatus"
                                id="LocationStatus"
                                name="LocationStatus"
                              >
                                <option value="">Select Company Location</option>
                                {CompanyLocation != null &&
                                  CompanyLocation.length > 0 &&
                                  CompanyLocation.map((e) => {
                                    return (
                                      <option value={e.id}>{e.value}</option>
                                    );
                                  })}
                              </select>
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: "0",
                                }}
                              >
                                <span>
                                  <input
                                    type="checkbox"
                                    name="open_CheckAllLocationStatus"
                                    class="open_CheckAllLocationStatus"
                                    style={{
                                      float: "right",
                                      margin: "15px 10px",
                                    }}
                                  />
                                </span>
                                <span
                                  class="fas fa-arrow-circle-right changeLocStatus"
                                  style={{
                                    margin: " 15px 0px 0px 0px",
                                    float: "right",
                                  }}
                                ></span>
                              </div>
                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer no-bd">
              <button
                type="button"
                id="addRowButton"
                className="btn btn-primary btn-sm"
                onClick={UploadFiles}
              >
                upload
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default ProfileSearchList;
