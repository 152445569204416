
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { AuthContext } from "../../Context/AuthContext";
import $ from "jquery";
import EmailtemplateServie from "../../api/EmailtemplateServie";
import 'datatables.net'
import Toast from "../Toastify";
import React, { useState, useContext, useEffect, useReducer } from "react";

import { ToastContainer, toast } from "react-toastify";

function EmailTemplateList() {


  const { setCookie, removeCookie, cookies } = useContext(AuthContext);
  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);

  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const EmailtemplateServieObj = new EmailtemplateServie();

  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);

  var UserValidate = cookies.token;
  const DeleteSolution = (val) => {
    swal({
      title: "Are you sure?",
      text: "You Want to Delete this Solution?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {

        EmailtemplateServieObj.DeleteTemplate(val)
          .then((response) => {
            return response;
          })
          .then((data) => {
            {
              swal("Solution has been deleted!", {
                icon: "success",
              });
              // window.location.reload(false);
              setGridRender();
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  let redriect = useNavigate();

  const navigateToContacts = (val) => {

    redriect("/EmailTemplateCreate", { state: val });
  };

  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  useEffect(() => {

    EmailtemplateServieObj.getData()
      .then((res) => {
        if (res != null && res.data.emailTemplateList != null && res.data.emailTemplateList.length > 0) {
          setData(res.data.emailTemplateList);
          // $('#templateTables').DataTable().destroy();


        }
      })

      .catch((err) => {
        console.log(err);
      });

  }, [GridRender]);

  useEffect(() => {


    if (cookies.tempEmailTempCreate == 1) {
      removeCookie("tempEmailTempCreate");
      Toast("Email Template Created Succesfully", "success");
    }
    if (cookies.tempEmailTempCreate == 2) {
      removeCookie("tempEmailTempCreate");
      Toast("Email Template Updated Succesfully", "success");
    }
    if (data.length !== 0) {
      console.log(data);
      setIsLoading(false);
      $('#templateTable').DataTable();
    }


  }, [data]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />

      <div className="table-responsive">

        <table
          className="display table table-striped table-hover"
          id="templateTables"
        >
          <thead>
            <tr>
              <th>Module Name</th>
              <th>Functionality Name</th>
              <th>Subject</th>
              {priviledge.includes("EMAILTEMPLATEEDIT")||priviledge.includes("EMAILTEMPLATEDELETE")?(
              <th className="text-end no-sort" style={{ textAlign: "center" }} >Action</th>
              ):("")}

            </tr>
          </thead>

          {isLoading ? (
            <tbody>
              <tr>No Data</tr>
            </tbody>
          ) : (
            data.map((user) => (
              <tbody>
                <tr>
                  <td style={{ display: "none" }}>{user.emailTemplateID}</td>

                  <td>{user.moduleName}</td>
                  <td>{user.moduleFunctionalityName}</td>

                  <td>
                    <span
                      style={{
                        whiteSpace: "pre-wrap",
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "50ch",
                      }}
                    >
                      {user.subject}
                    </span>
                  </td>
                  {priviledge.includes("EMAILTEMPLATEEDIT")||priviledge.includes("EMAILTEMPLATEDELETE")?(
                     <td className="text-end" style={{ textAlign: "center" }}>
                     <div className="dropdown dropdown-action">
 
                       {priviledge.includes("EMAILTEMPLATEEDIT") &&
                         <button
                           type="button"
                           data-bs-toggle="tooltip"
                           title=""
                           class="btn btn-link btn-primary btn-lg"
                           data-original-title="Edit"
                           onClick={() =>
                             navigateToContacts(user.emailTemplateID)
                           }
                         >
                           <i class="fa fa-edit"></i>
                         </button>
                       }
 
                       {priviledge.includes("EMAILTEMPLATEDELETE") &&
                         <button
                           type="button"
                           data-bs-toggle="tooltip"
                           title=""
                           class="btn btn-link btn-danger"
                           data-original-title="Remove"
                           onClick={() => DeleteSolution(user.emailTemplateID)}
                         >
                           <i class="fas fa-trash-alt"></i>
                         </button>}
 
 
 
                     </div>
 
                   </td>
                  ):("")}
                 
                </tr>
              </tbody>
            ))
          )}
        </table>
      </div>
    </>
  );
}

export default EmailTemplateList;
