import React,{useEffect} from "react";
import $ from "jquery";
// import 'datatables.net';


// useEffect(() => {
//  $('#candidateProfiletable').dataTable();
//});

function EmployeeList() {
  useEffect(() => {
    $('.submenu').removeClass('active');
    $('#ConfigurationMenu').addClass('active');

    $('.collapse').removeClass('show')
    $('#Configuration').addClass('show')

    $('.submenu li').removeClass('active');
    $('#Employee').addClass('active');

  });
  return (
    <>
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <h4 class="card-title">Employee </h4>
                      <button
                        class="btn btn-primary btn-round ml-auto"
                        data-bs-toggle="modal"
                        data-bs-target="#addRowModal"
                      >
                        <i class="fa fa-plus"></i>
                        Add
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    {/* <!-- Modal --> */}
                    <div
                      class="modal fade"
                      id="addRowModal"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                          <div class="modal-header no-bd">
                            <h5 class="modal-title">
                              <span class="fw-mediumbold">Add Employee</span>
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <form>
                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group ">
                                    <label>First Name</label>
                                    <input
                                      id="addName"
                                      type="text"
                                      class="form-control"
                                      placeholder="First Name"
                                    />
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label>Middle Name</label>
                                    <input id="addName"
                                      type="text"
                                      class="form-control"
                                      placeholder="Middle name" />
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group ">
                                    <label>Last Name</label>
                                    <input id="addName"
                                      type="text"
                                      class="form-control"
                                      placeholder="Last name" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group ">
                                    <label>Title</label>
                                    <input id="addPosition"
                                      type="text"
                                      class="form-control"
                                      placeholder="Title" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group ">
                                    <label>Location</label>
                                    <input id="addPosition"
                                      type="text"
                                      class="form-control"
                                      placeholder="Location" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group ">
                                    <label>Mobile Number</label>
                                    <input id="addOffice"
                                      type="text"
                                      class="form-control"
                                      placeholder="Mobile Number" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group ">
                                    <label>Email</label>
                                    <input id="addOffice"
                                      type="text"
                                      class="form-control"
                                      placeholder="Email" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group ">
                                    <label>Role</label>
                                    <select class="form-control form-control-sm"
                                      id="smallSelect">
                                      <option>Select Role</option>
                                      <option>Recruiter</option>
                                      <option>Manager</option>
                                      <option>HR Admin</option>
                                      <option>SPOC</option>
                                      <option>PMO</option>
                                    </select>
                                  </div>
                                </div>
                                 <div class="col-md-6">
                                  <div class="form-group ">
                                    <label>Reporting Manager</label>
                                    <select class="form-control form-control-sm"
                                      id="smallSelect">
                                      <option>Select Reporting Manager</option>
                                      <option>Richard</option>
                                      <option>John</option>
                                      <option>Smart</option>
                                     
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group ">
                                    <label>Status</label>
                                    <div class="form-check">
                                      <div class="row">
                                        <div class="col-3">
                                          <label class="form-radio-label ">
                                            <input class="form-radio-input"
                                              type="radio"
                                              name="optionsRadios" value=""
                                              checked="" />
                                            <span
                                              class="form-radio-sign">Active</span>
                                          </label>
                                        </div>
                                        <div class="col-3">
                                          <label class="form-radio-label ml-3">
                                            <input class="form-radio-input"
                                              type="radio"
                                              name="optionsRadios" value="" />
                                            <span
                                              class="form-radio-sign">InActive</span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div class="modal-footer no-bd">
                            <button
                              type="button"
                              id="addRowButton"
                              class="btn btn-primary"
                            >
                              Add
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <table
                        id="add-row"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Employee Code</th>
                            <th>Employee Name</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Reporting Manager</th>
                           
                            <th>Role</th>
                            <th style={{ width: "10%", textAlign: "center" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>EMP001</td>
                            <td>Rajesh</td>
                            <td>9876543210</td>
                            <td>rajesh@testMail.com</td>
                            <td>Richard</td>
                            <td>Manager</td>
                            <td>
                              <div class="form-button-action">
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-primary btn-lg"
                                  data-original-title="Edit"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>EMP002</td>
                            <td>Praveen</td>
                            <td>9876543210</td>
                            <td>Praveen@testMail.com</td>
                            <td>Smart</td>
                            <td>SPOC</td>

                            <td>
                              <div class="form-button-action">
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-primary btn-lg"
                                  data-original-title="Edit"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EmployeeList;
