import React, { useState, useContext } from "react";
import $ from "jquery";
import logo from "../../assets/Final-logo.jpg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Toast from "../../Context/Toastify";
import { AuthContext } from "../../Context/AuthContext";
import { useLocation } from "react-router-dom";
import AuthenticationService from "../../api/AuthenticationService";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import AxiosServices, { imgURL } from "../../api/AxiosService";
import default_Back_Img from "../../assets/Default-Background Img.png"
import ApplicationConfigService from "../../api/ApplicationConfigService";


export const OTP = () => {

  const [disabled, setDisabled] = useState(false);

  const location = useLocation();
  const [username, setuserName] = useState(location.state && location.state.username)
  const [logoImage, setLogoImage] = useState('')
  const [bgImage, setBgImage] = useState('')
  const AppService = new ApplicationConfigService();
  const [otperror, setOtperror] = useState({
    isNavigate: false,
    errormsg: "",
  })
  // const currentYear = new Date().getFullYear();
  const AuthObject = new AuthenticationService();

  const navigate = useNavigate();
  const { data, setCookie, cookies } = useContext(AuthContext);

  const [userOtp, setUserOtp] = useState({
    Username: username,
    otp: "",
  });
  const [dummy, setDummy] = useState({
    prefixName: "",
    endValue: "",

    errotp: "",
  });

  //logoImage 
  useEffect(() => {
    GetLogoImage()
  }, [])


  const GetLogoImage = () => {

    AppService.GetImageDetails()
      .then((res) => {
        if (res.data.length > 0) {
          const logoImg = res.data.find(img => img.ImageId === 2)
          if (logoImg) {
            setLogoImage(logoImg.ImageUrl)
          }
          const backgroundImg = res.data.find(img => img.ImageId === 1)
          if (backgroundImg) {
            setBgImage(backgroundImg.ImageUrl)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const forgotsubmit = (e) => {

    e.preventDefault();
    console.log(e.target);
    var data = {
      Username: username,

      otp: document.getElementById("otp").value,
    };

    if (data.otp == null || data.otp == "") {
      setDummy({ errotp: "Enter otp" });

      return false;
    }

    if (data.otp != null || data.otp != "") {
      setDummy({ errotp: "" });
    }



    verifyOTP(data);

  };
  useEffect(() => {
    toast.success("OTP has been Sent to Your Email ID!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      //theme: "colored",
    });
  }, [])

  const verifyOTP = (data) => {


    $(".loader-container").show();

    AuthObject.verifyotp(data)
      .then((res) => {

        setOtperror({
          errormsg: "",
          isNavigate: true,
        });
        $(".loader-container").show();
        navigate("/forgotpassword", { state: { UserName: username } });
        $(".loader-container").hide();
        setDisabled(false);
      })
      .catch((error) => {
        toast.error("Invalid OTP!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // theme: "colored",
        });

        $(".loader-container").hide();
      });
  };

  const ResendOTP = () => {

    setUserOtp({
      ...userOtp,
      otp: "",
    });
    var user = {
      Username: username,
    };
    toast.success("Please wait...", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      //theme: "colored",
    });

    AuthObject.forgotpassword(user)
      .then((res) => {

        $(".loader-container").hide();
        toast.success("OTP has been Sent to Your Email ID.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
        setOtperror({
          errormsg: "",
          isNavigate: true,
        });
        setDisabled(false);
        navigate("/OTP", { state: { username: user.UserName } });


      })
      .catch((error) => {

        if (error.response && error.response.data == 0) {

          toast.error("Username doesn't exist!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            //theme: "colored",
          });
          $(".loader-container").hide();
        } else {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            //theme: "colored",
          });
          $(".loader-container").hide();
        }
      });
  }

  const [code, setCode] = useState("");


  const handleChange = (code) => setCode(code);
  return (


    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      //theme="colored"
      />
      <div
        className="loaders-containers"
        style={{
          background: "#f9fbfd",
          width: "100%",
          height: "100vh",
          position: "fixed",
          zIndex: "1",
          display: "none",
        }}
      >
        <span
          className="loadercube"
          style={{ position: "fixed", right: "50%", bottom: "50%" }}
        ></span>
      </div>
      <div
        className="wrapper sidebar_minimize login"
        style={{ overflow: 'hidden' }}
      >
        <div className="bgimg" style={{
          backgroundImage: `url('${bgImage != null && bgImage != undefined && bgImage != ""
            ? `${imgURL}${bgImage}`
            : `${default_Back_Img}`
            }')`,
          position: 'absolute'
        }}></div>
        <div className="container d-flex justify-content-center">
          <div className="d-flex flex-column justify-content-between">
            <div className="card mt-3 p-5 Top_Card">
            {logoImage != null && logoImage != undefined && logoImage != "" ?
                <img
                  src={imgURL + logoImage}
                  style={{
                    marginTop: "-25px",
                    backgroundColor: "white",
                    padding: "1px",
                    borderRadius: "10px",
                  }}
                /> :
                <img
                  src={logo}
                  style={{
                    marginTop: "-25px",
                    backgroundColor: "white",
                    padding: "34px",
                    borderRadius: "10px",
                  }}
                />
              }

              <div>
                <p
                  className="mb-3 pt-1"
                  style={{
                    marginBottom: "-0.75rem !important",
                    fontFamily: "inherit",
                    fontSize: "25px",
                    textAlign: "center",
                    color: 'white'
                  }}
                >
                  OTP
                </p>

                {/* <h4 className="mb-5 text-white"></h4> */}
              </div>
            </div>
            <form onSubmit={forgotsubmit}>
              <div className="card two bg-white px-5 py-4 mb-3">
                <div className="form-group">
                  <label
                    className="form-label"
                    id="Lblname"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    OTP
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    name="otp"
                    id="otp"
                  />
                  <span style={{ color: "red" }}>{dummy.errotp}</span>
                  <a
                    href="#"
                    className="btn btn-link box-shadow-0 px-1"
                    onClick={ResendOTP}
                  >
                    ResendOTP
                  </a>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button
                      disabled={disabled}
                      type="submit"
                      className={
                        disabled
                          ? "form-control btn btn-default rounded submit px-3"
                          : "form-control btn btn-primary rounded submit px-3"
                      }
                    >
                      Submit
                    </button>
                    {/* <a
                    href="/forgotpassword"
                    className="btn btn-primary btn-lg d-block w-100 fw-500 mb-3"
                  >
                    Submit
                  </a> */}
                  </div>
                  <p
                    className="text-center mt-lg"
                    style={{ marginLeft: "15px" }}
                  >
                    Remembered? <a href="/">Sign In</a>
                  </p>
                </div>
              </div>
            </form>
            <div>
              <p className="" style={{ textAlign: "center" }}>
                &copy; Copyright 2022. All rights reserved.
              </p>
            </div>
          </div>
          <a href="#" data-click="scroll-top" className="btn-scroll-top fade">
            <i className="fa fa-arrow-up"></i>
          </a>
        </div>
      </div>
    </>
  );
};
