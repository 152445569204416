import React, { useEffect, useState, useRef, useContext } from "react";
import $, { isPlainObject } from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import AssociateService from "../../api/AssociateService";
import moment from "moment";
import exportFromJSON from "export-from-json";
import DataTable from "datatables.net-dt";
import "datatables.net";
import "datatables.net-fixedcolumns-dt";
import MySelect from "../MultiSelect/Myselect";
import parse from "html-react-parser";
import swal from 'sweetalert';
import { AuthContext } from "../../Context/AuthContext";

const ConfigurationServiceobj = new ApplicarionConfigService();
const AssociateServiceobj = new AssociateService();

const DemandReport = () => {
  const { cookies } = useContext(AuthContext);
  const ReportTable = useRef(null);
  const [DemandReportList, setDemandReportList] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());
  const [MomentDate, setMomentDate] = useState(new Date());
  const [MomentEndDate, setMomentEndDate] = useState(new Date());
  const [SelectedValue, SetSelectedValue] = useState(null);
  const [EmpIDs, SetEmpIDs] = useState("");
  const [EmpID, setEmpID] = useState("");
  const [companyID, setCompanyID] = useState(cookies?.companyID);
  const [roleName, setRoleName] = useState(cookies?.roleName);

  useEffect(() => {
    ConfigurationServiceobj.GetDemandReport(
      moment(MomentDate).format("YYYY-MM-DD"),
      moment(MomentEndDate).format("YYYY-MM-DD"),
      companyID,
      roleName
    )
      .then((response) => {
        setDemandReportList(response.data);
        console.log(response.data);
      })
      .catch((error) => {});

    $(".submenu").removeClass("active");
    $("#ReportMenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Report").addClass("show");

    $(".submenu li").removeClass("active");
    $("#DemandReport").addClass("active");
  }, [EmpIDs, MomentDate, EndDate]);
  const FromDate = (date) => {
    var MmDate = moment(date).format("YYYY-MM-DD");
    setStartDate(date);
    setMomentDate(MmDate);
  };
  const ToDate = (date) => {
    var MmmDate = moment(date).format("YYYY-MM-DD");
    setEndDate(date);
    setMomentEndDate(MmmDate);
  };
  const ExportExcel = () => {
    // Check if there are records in DemandReportList
    if (DemandReportList.length === 0) {
      swal({
        title: 'No Records',
        text: 'There are no records to export.',
        icon: 'info',
        button: 'OK',
      });
      return;
    }
  
    const data = DemandReportList.map((item) => {
      // Replace null values with "-"
      const processedItem = Object.fromEntries(
        Object.entries(item).map(([key, value]) => [key, value === null ? '-' : value])
      );
      return processedItem;
    });
  
    const fileName = 'DemandReport';
    const exportType = exportFromJSON.types.csv;
  
    exportFromJSON({ data, fileName, exportType });
  };
  

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title mr-auto" style={{ width: "85%" }}>
                    Demand Report
                  </h4>
                  <button
                    className="btn btn-primary btn-round btn-sm ml-auto"
                    data-bs-toggle="modal"
                    onClick={(e) => ExportExcel()}
                    data-bs-target="#addRowModal"
                  >
                    <i class="fas fa-file-export"></i>
                    <span className="ml-1">Export</span>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                    <div className="form-group mb-3">
                      <label className="label" for="name">
                        Form Date
                      </label>
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={(date) => FromDate(date)}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                    <div className="form-group mb-3">
                      <label className="label" for="name">
                        To Date
                      </label>
                      <DatePicker
                        className="form-control"
                        selected={EndDate}
                        onChange={(date) => ToDate(date)}
                        dateFormat="dd-MM-yyyy"
                        minDate={startDate}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="table-responsive"
                  style={{ overflowX: "scroll", height: "70vh" }}
                >
                  <table
                    className="display table table-striped table-hover"
                    id="ReportTable"
                    ref={ReportTable}
                  >
                    <thead>
                      <tr>
                        {/* <th>Employee Code</th>
                        <th style={{ minWidth: "200px" }}>Employee Name</th>
                        <th>Employee Role</th> */}
                        {DemandReportList != null &&
                          DemandReportList.length > 0 &&
                          DemandReportList.map((e, i) => {
                            if (i == 0) {
                              var obj = Object.keys(e);
                              return (
                                obj != null &&
                                obj.length > 0 &&
                                obj.map((e) => {
                                  return (
                                    <th
                                      data-sort="string"
                                      style={{ minWidth: "250px" }}
                                    >
                                      {e}
                                    </th>
                                  );
                                })
                              );
                            }
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {DemandReportList != null &&
                        DemandReportList.length > 0 &&
                        DemandReportList.map((e, i) => {
                          var j = Object.values(e);

                          return (
                            <tr>
                              {j != null &&
                                j.length > 0 &&
                                j.map((k, i) => {
                                  if (k != null) {
                                    return <td>{parse(k)}</td>;
                                  } else {
                                    return <td>-</td>;
                                  }
                                })}
                            </tr>
                          );
                        })}
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemandReport;
