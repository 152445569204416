import React, { useEffect, useState, useReducer } from "react";
import $ from "jquery";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import swal from "sweetalert";

const ConfigurationServiceobj = new ApplicarionConfigService();
export default function FormValidationConfig() {
  const [MandatoryList, setMandatoryList] = useState([]);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    ConfigurationServiceobj.GetFormMandatoryField()
      .then((res) => {
        console.log(res);
        setMandatoryList([...res.data]);
      })
      .catch((error) => {});
  }, [Render]);
  const ChangeMandatory = (e, FormFieldValidationID) => {
    
    var Ischecked = e.target.checked;
    ConfigurationServiceobj.UpdateMandatoryField(
      Ischecked ? 1 : 0,
      FormFieldValidationID
    )
      .then((res) => {
        PageRender();
        swal(
          `Mandatory Field ${Ischecked ? `Applied` : `Removed`}`,
          "Saved Successfully!",
          "success"
        );
      })
      .catch((error) => {});
  };
  const downloadFile = () => {
    ConfigurationServiceobj.DownloadProfileExcel()
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Profile.xlsx");

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {});
  }
  return (
    <div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <button
          className="btn btn-primary btn-round ml-auto  pull-right btn-sm"
          onClick={downloadFile}
          style={{ zIndex: 999, position: "relative" }}
        >
          <i className="fa fa-download"></i> <span className="ml-1">DownLoad</span>
        </button>
      </div>    
      <div className="table-responsive">
        <table className="display table table-striped table-hover">
          <thead>
            <tr>
              <th>Field Name</th>
              <th>Validation</th>
            </tr>
          </thead>
          <tbody>
            {MandatoryList?.length > 0 ? (
              MandatoryList.map((i) => {
                return (
                  <tr>
                    <td>{i.DisplayName}</td>
                    <td>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            class="form-check-input Mandatory"
                            name="Mandatory"
                            type="checkbox"
                            disabled={i.IsDisable > 0 ? true : false}
                            defaultChecked={i.IsMandatory > 0 ? true : false}
                            onChange={(e) =>
                              ChangeMandatory(e, i.FormFieldValidationID)
                            }
                          />
                          <span class="form-check-sign"></span>
                        </label>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
