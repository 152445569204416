import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import logo from "../../assets/Final-logo.png";
import "../../assets/css/LoginIndex.css";
import "../../assets/js/atlantis.js";
import { hasFormSubmit } from "@testing-library/user-event/dist/utils";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../Context/AuthContext";
import AuthenticationService from "../../api/AuthenticationService";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import MySelect from "../MultiSelect/Myselect";
import "../SideBar/Loader.css";
import AxiosServices, { imgURL } from "../../api/AxiosService";
import ApplicationConfigService from "../../api/ApplicationConfigService";
import default_Back_Img from "../../assets/Default-Background Img.png"
const URLHR = new AxiosServices();

function Login() {
  const { data, setCookie, removeCookie, cookies } = useContext(AuthContext);
  const [logoImage, setLogoImage] = useState('')
  const [bgImage, setBgImage] = useState('')
  const AppService = new ApplicationConfigService();

  useEffect(() => {
    if (cookies.ISReloadfunction == 1) {
      removeCookie("ISReloadfunction");
      window.location.reload();
    }
    var data = {
      UserName: cookies.KAPPU,
      Password: cookies.KAPPP,
      // RoleID: Number($("#Roleselect").val()),
      rememberMeflag: true,
    };
    if (cookies.ISReload == 1) {
      setCookie("ISReload", 0, { path: "/" });
      window.location.reload();
    }

    if (data.UserName != null && data.Password != null) {
      $(".loaders-containers").show();
      AuthObject.getData(data)

        .then(

          (res) => (
            // localStorage.setItem("token", data.user.token),

            setTimeout(() => {

              RedirectFunctions(res.data.roleName.trim().toLowerCase());
            }),
            setDisabled(false)
            // setTimeout(() => { Toast('Logged in Succesfully', 'success')}, 500)
          )
        )
        .catch((res) => {
          console.log(res);

          removeCookie("KAPPU");
          removeCookie("KAPPP");

          window.location.reload();
        });
      function RedirectFunctions(RoleName) {
        switch (RoleName) {
          case "hod":
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
          case "engineer":
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
          case "admin":
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
          case "md":
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
          default:
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
        }
      }
    }
    // if (data.UserName == null && data.Password == null) {
    //   removeCookie("FirstroleCountLogin");
    // }
  });
  //logoImage 
  useEffect(() => {
    GetLogoImage()
  }, [])


  const GetLogoImage = () => {

    AppService.GetImageDetails()
      .then((res) => {
        if (res.data.length > 0) {
          const logoImg = res.data.find(img => img.ImageId === 2)
          if (logoImg) {
            setLogoImage(logoImg.ImageUrl)
          }
          const backgroundImg = res.data.find(img => img.ImageId === 1)
          if (backgroundImg) {
            setBgImage(backgroundImg.ImageUrl)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AuthObject = new AuthenticationService();
  const [RoleList, setRoleList] = useState([]);
  const getRole = () => {
    var EmployeeCode = $("#Username").val();
    AuthObject.getRole(EmployeeCode)
      .then((res) => {
        console.log(res.data.roleList);

        setRoleList([...res.data.roleList]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [EmployeeModel, setEmployeeModel] = useState([]);
  const myEyeFunction = () => {
    var x = document.getElementById("Password");
    if (x.type === "password") {
      x.type = "text";
      $("#pswicon").removeClass("fa-eye-slash");
      $("#pswicon").addClass("fa-eye");
    } else {
      $("#pswicon").removeClass("fa-eye");
      $("#pswicon").addClass("fa-eye-slash");
      x.type = "password";
    }
  };

  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data, e) => {
    setDisabled(true);
    e.preventDefault();

    Login(data);
  };

  const [dummy, setDummy] = useState({
    prefixName: "",
    endValue: "",

    errUserName: "",
    errPassword: "",
    errRoleID: "",
  });

  const navigate = useNavigate();

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    const obj = new FormData(e.currentTarget);
    console.log(e);
    var data = {
      UserName: document.getElementById("Username").value,
      Password: document.getElementById("Password").value,
      // RoleID: Number($("#Roleselect").val()),
      rememberMeflag: document.querySelector("#RememberFlagCkboxs").checked,
    };

    if (data.UserName == "" && data.Password == "") {
      $("#errUsername").text("Enter Username");
      $("#errPassword").text("Enter password");

      return false;
    }

    // if (data.RoleID == null || data.RoleID == "" || data.RoleID == "0") {
    //   setDummy({ errRoleID: "select Role" });

    //   return false;
    // }

    // if (data.RoleID != null || data.RoleID != "") {
    //   setDummy({ errRoleID: "" });
    // }

    if (data.UserName == "" && data.Password == "") {
      //     setDummy({ errUserName: "Enter username" });
      // setDummy({ errPassword: "Enter password" });
      $("#errUsername").text("Enter username");
      $("#errPassword").text("Enter password");
      return false;
    }

    if (data.UserName == null || data.UserName == "") {
      //  setDummy({ errUserName: "Enter username" });
      $("#errUsername").text("Enter Username");

      return false;
    }

    if (data.UserName != null || data.UserName != "") {
      // setDummy({ errUserName: "" });
      $("#errUsername").text("");
    }

    if (data.Password == null || data.Password == "") {
      //  setDummy({ errPassword: "Enter password" });
      $("#errPassword").text("Enter password");

      return false;
    }

    if (data.Password != null || data.Password != "") {
      $("#errPassword").text("");
      // setDummy({ errPassword: "" });
    }

    login(data);
  };
  const login = (authentication) => {


    if (authentication.rememberMeflag == true) {
      setCookie("KAPPU", authentication.UserName, { path: "/" });
      setCookie("KAPPP", authentication.Password, { path: "/" });
    }

    $("#btnLoginSubmit").prop("disabled", true);
    // $(".main-loader").show();
    $('.loaders-containers').show();
    AuthObject.getData(authentication)

      .then(
        (res) => (
          setCookie("emailID", res.data?.emailID, { path: "/" }),
          // setCookie("departmentid", data.user.departmentids, { path: "/" }),
          // setCookie(
          //   "departmentfirstid",
          //   data.user.departmentids.split(",")[0],
          //   { path: "/" }
          // ),
          // setCookie(
          //   "departmentfirstname",
          //   data.user.departmentNames.split(",")[0],
          //   { path: "/" }
          // ),
          // setCookie("departmentnames", data.user.departmentNames, {
          //   path: "/",
          // }),
          setCookie("locationStatus", res.data.locationStatus, { path: "/" }),
          setCookie("userID", res.data?.userID, { path: "/" }),
          setCookie("userName", res.data?.userName, { path: "/" }),
          setCookie("employeeName", res.data?.employeeName, { path: "/" }),
          setCookie("token", res.data?.token, { path: "/" }),
          setCookie("Rememberme", authentication.rememberMeflag, { path: "/" }),
          setCookie("roleName", res.data?.roleName, { path: "/" }),
          setCookie("roleID", res.data?.roleID, { path: "/" }),
          setCookie("companyID", res?.data?.companyID, { path: "/" }),
          setCookie("KpppDecript", res.data?.decriptPassword, { path: "/" }),
          setCookie("KuuuDecript", res.data?.decriptUsername, { path: "/" }),
          setCookie("CT_HRMS_APP", process.env.CT_HRMS_APP, { path: "/" }),
          setCookie("roleCount", res.data?.roleCount, { path: "/" }),
          setCookie("FirstroleCountLogin", res.data?.firstroleCountLogin, {
            path: "/",
          }),
          setCookie("KACHPPP", authentication.Password, { path: "/" }),
          setCookie("UserPrivileges", res.data?.userPrivileges.split(","), {
            path: "/",
          }),
          setCookie("SidebarReload", 1, { path: "/" }),
          setCookie("idleTime", res.data?.idletime, { path: "/" }),
          setCookie("AssociateTitle", res.data?.associateTitle, { path: "/" }),
          setCookie("ProfilePicture", res.data?.profilePicture, { path: "/" }),
          localStorage.setItem("token", data.user.token),
          setTimeout(() => {
            var checkfirstroleCountLogin = res.data?.firstroleCountLogin;

            if (checkfirstroleCountLogin == 1) {
              LoadCTHrms();
            } else {
              RedirectFunction(res.data.roleName.trim().toLowerCase());
            }
          }, 2000),
          setDisabled(false),
          $("#btnLoginSubmit").prop("disabled", false)

          // setTimeout(() => { Toast('Logged in Succesfully', 'success')}, 500)

        )

      )

      .catch((res) => {

        $("#btnLoginSubmit").prop("disabled", false);

        if (
          res.response.data.message ==
          "Username or Password should not be empty." ||
          res.response.data.message == "Invalid Employee Code" ||
          res.response.data.message == "No User With the Employee Code" ||
          res.response.data.message == "Multiple Users With Same Username" ||
          res.response.data.message == "Invalid username"
        ) {
          swal({
            text: "Invalid Username or Password",
            icon: "warning",
          });
        } else if (res.response.data.message == "Enter a valid username or password") {
          swal({
            text: "Invalid Password",
            icon: "warning",
          });
        } else {
          swal({
            text: res.response.data.message,
            icon: "warning",
          });
        }

        $("#btnLoginSubmit").prop("disabled", false);
        removeCookie("KAPPU");
        removeCookie("KAPPP");
        $('.loaders-containers').hide();
      });

    function LoadCTHrms(e) {


      setCookie("FirstroleCountLogin", 0, { path: "/" });

      var userNameObj = cookies.KuuuDecript;

      var PasswordObj = cookies.KpppDecript;

      var ishrmsObj = 1;

      var url = process.env.CT_HRMS_APP;

      var url = URLHR.geturl();

      var HRMSURL =
        url +
        "Login/HRRecumentIndex?UserName=" +
        userNameObj +
        "&Password=" +
        PasswordObj +
        "&ishrms=" +
        ishrmsObj;

      window.location.href = HRMSURL;

      // window.reload(HRMSURL);
    }
    function RedirectFunction(RoleName) {



      switch (RoleName) {
        case "hod":
          navigate("/Dashboard", { replace: true });
          ////$('.main-loader').hide();
          break;
        case "engineer":
          navigate("/Dashboard", { replace: true });
          ////$('.main-loader').hide();
          break;
        case "admin":
          navigate("/Dashboard", { replace: true });
          ////$('.main-loader').hide();
          break;
        case "md":
          navigate("/Dashboard", { replace: true });
          ////$('.main-loader').hide();
          break;
        default:
          navigate("/Dashboard", { replace: true });
          ////$('.main-loader').hide();
          break;
      }
    }
  };
  function ROleOnchange(e) {
    setEmployeeModel((prevState) => ({
      ...prevState,
      ["roleSeletedList"]: e,
    }));
  }

  return (
    <>
      {/* 
width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.73) ;
       */}

      {/* z-index: 1; */}
      <div
        className="loaders-containers"
        style={{
          background: "#f9fbfd",
          width: "100%",
          height: "100vh",
          position: "fixed",
          zIndex: "1",
          display: "none",
        }}
      >
        <span
          className="loadercube"
          style={{ position: "fixed", right: "50%", bottom: "50%" }}
        ></span>
      </div>
      <div
        className="wrapper sidebar_minimize login"
        style={{ overflow: 'hidden' }}
      >
        <div className="bgimg" style={{
          backgroundImage: `url('${bgImage != null && bgImage != undefined && bgImage != ""
              ? `${imgURL}${bgImage}`
              : `${default_Back_Img}`
            }')`,
          position: 'absolute'
        }}></div>
        <div className="container d-flex justify-content-center" style={{ position: 'relative' }} >
          <div className="d-flex flex-column justify-content-between">
            <div className="card mt-3 p-5 Top_Card">
              {logoImage != null && logoImage != undefined && logoImage != "" ?
                <img
                  src={imgURL + logoImage}
                  style={{
                    marginTop: "-25px",
                    backgroundColor: "white",
                    padding: "1px",
                    borderRadius: "10px",
                  }}
                /> :
                <img
                  src={logo}
                  style={{
                    marginTop: "-25px",
                    backgroundColor: "white",
                    padding: "34px",
                    borderRadius: "10px",
                  }}
                />
              }
              <div>
                <p
                  className="mb-3 pt-1"
                  style={{
                    marginBottom: "-0.75rem !important",
                    fontFamily: "inherit",
                    fontSize: "25px",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  HR Recruitment
                </p>
                {/* <h4 className="mb-5 text-white"></h4> */}
              </div>
            </div>
            <form onSubmit={loginSubmit}>
              <div className="card two bg-white px-5 py-4 mb-3">
                <div className="form-group">
                  <label
                    className="form-label"
                    id="Lblname"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    Username
                  </label>

                  <input
                    type="text"
                    name="UserName"
                    id="Username"
                    maxLength={12}
                    className="form-control"
                    {...register("UserName", {
                      required: "Employee Code is Required",
                    })}
                    value={register.UserName}
                    onChange={getRole}
                  />
                  <span style={{ color: "red" }} id="errUsername">
                    {dummy.errUserName}
                  </span>
                </div>
                <div className="form-group">
                  <label
                    name="Lblpassword"
                    className="form-label"
                    id="Lblpassword"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    Password
                  </label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="password"
                        className="form-control"
                        name="Password"
                        id="Password"
                        {...register("Password", {
                          required: "Password is Required",
                        })}
                        value={register.Password}
                        onKeyDown={(event) => onKeyDown(event)}
                      />
                      <i
                        className="fa fa-eye-slash"
                        id="pswicon"
                        onClick={myEyeFunction}
                        style={{
                          float: "right",
                          marginRight: "10px",
                          marginTop: "-28px",
                          position: "relative",

                        }}
                      ></i>
                    </div>

                    <div className="col-md-12">
                      <span style={{ color: "red" }} id="errPassword">
                        {dummy.errPassword}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="form-group">
                  <label
                    className="form-label"
                    id="Lblname"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    Role
                  </label>
                  <select
                    id="Roleselect"
                    className="form-control"
                    name="Roleselect"
                    value={EmployeeModel.roleSeletedList}
                  >
                    {RoleList != null ? (
                      RoleList.map((item) => {
                        
                        return <option value={item.value}>{item.label}</option>;
                      })
                    ) : (
                      <option value="0">ljkhg </option>
                    )}
                  </select>

                  <span style={{ color: "red" }}>{dummy.errRoleID}</span>
                </div> */}
                <div
                  className="form-group text-left  px-2 ml-3"
                  style={{ marginBottom: "0 !important" }}
                >
                  <input
                    className="form-check-input mr-2"
                    type="checkbox"
                    style={{ color: "black" }}
                    value="true"
                    name="RememberMeflag"
                    id="RememberFlagCkboxs"
                  />
                  <label
                    className="form-check-label fw-500"
                    for="RememberFlagCkbox"
                    id="remembermeflag"
                    style={{ color: "black" }}
                  >
                    Remember me
                  </label>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button
                      // disabled={disabled}
                      type="submit"
                      id="btnLoginSubmit"
                      className={
                        disabled
                          ? "form-control btn btn-default rounded submit px-3"
                          : "form-control btn btn-primary rounded submit px-3"
                      }
                      style={{ marginBottom: "0" }}
                    >
                      Sign In
                    </button>
                    <a
                      href="/Username"
                      className="btn btn-link box-shadow-0 px-3"
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <div className="col-12"></div>
                </div>
              </div>
            </form>
            <div>
              <p className="" style={{ textAlign: "center", filter: "brightness(0.1)" }}>
                &copy; Copyright 2024. All rights reserved.
              </p>
            </div>
          </div>

          <a href="#" data-click="scroll-top" className="btn-scroll-top fade">
            <i className="fa fa-arrow-up"></i>
          </a>
        </div>
      </div>
    </>
  );
}
export default Login;
