// import logo from './logo.svg';
import "./App.css";
import React, { useEffect } from "react";
import { useState, useHistory, useContext } from "react";
import $ from "jquery";
import "jquery";
import WithNav from "./Components/SideBar/WithNav";
import WithoutNav from "./Components/SideBar/WithoutNav";
import SideBar from "./Components/SideBar/SideBar";
import Login from "./Components/Login/Login";
import EmployeeList from "./Components/Employee/EmployeeList";
import RoleIndex from "./Components/Role/RoleIndex";
import EmailTemplateList from "./Components/EmailTemplate/Emailtemplate";
import EmailTemplateCreate from "./Components/EmailTemplate/EmailTemplateCreate";
import DashboardIndex from "./Components/Dashboard/DashboardIndex";
import DemandIndex from "./Components/Demand/DemandIndex";
import ProfileSearchIndex from "./Components/ProfileSearch/ProfileSearchIndex";
import "./assets/css/fonts.css";
import MainConinter from "./Components/SideBar/MainConinter";
import ApplicationIndex from "./Components/ApplicationConfig/ApplicationConfigIndex";
import CandidatePipeline from "./Components/Demand/CandidatePipeline";
import ProfileSearchList from "./Components/ProfileSearch/ProfileSearchList";
import { Username } from "./Components/Login/Username";
import { OTP } from "./Components/Login/OTP";
import { Forgotpassword } from "./Components/Login/Forgotpassword";
import { ChangePassword } from "./Components/Login/ChangePassword";
import { LogoutHR } from "./Components/SideBar/LogoutHR";
import "./assets/css/Common.css";
import Associate from "./Components/Associate/MainGrid";
import { RoleConfig } from "./Components/RoleConfig/RoleConfig";
import Emailtemplate from "./Components/EmailTemplate/Emailtemplate";
import { Cybertronic } from "./Components/Login/Cybertronic";
import LoginTracker from "./Components/ApplicationConfig/LoginTracker";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/css/atlantis.css";
import DemandReport from "./Components/ApplicationConfig/DemandReport";
import "./assets/js/atlantis.js";
import "bootstrap/dist/js/bootstrap.js"
function App() {
  return (
    <Router>
      <Routes>
        <Route element={<WithoutNav />}>
          <Route path="/" exact index element={<Login />} />
          <Route path="/Username" exact index element={<Username />} />
          <Route path="/OTP" exact index element={<OTP />} />
          <Route
            path="/Forgotpassword"
            exact
            index
            element={<Forgotpassword />}
          />
          <Route path="/Cybertronic" exact index element={<Cybertronic />} />
          <Route path="/LogoutHR" exact index element={<LogoutHR />} />

          {/* <Route path="/test" exact index element={<Test />} /> */}
        </Route>

        <Route element={<WithNav />}>
          {/* <Route path="/Login" exact index element={<Login />} /> */}
          <Route path="/DemandIndex" element={<DemandIndex />} />
          <Route path="/CandidatePipeline" element={<CandidatePipeline />} />
          <Route path="/ProfileSearch" element={<ProfileSearchList />} />
          <Route path="/RoleIndex" exact index element={<RoleIndex />} />
          <Route
            path="/ApplicationIndex"
            exact
            index
            element={<ApplicationIndex />}
          />
          <Route path="/Employee" exact index element={<EmployeeList />} />
          <Route
            path="/ChangePassword"
            exact
            index
            element={<ChangePassword />}
          />
          <Route path="/Associate" element={<Associate />} />
          <Route path="/Role" element={<RoleConfig />} />
          <Route path="/Dashboard" element={<DashboardIndex />} />
          <Route path="/Emailtemplate" element={<Emailtemplate />} />
          <Route
            path="/EmailTemplateCreate"
            element={<EmailTemplateCreate />}
          />
          <Route path="/LoginTracker" element={<LoginTracker />} />
          <Route path="/DemandReport" element={<DemandReport />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
