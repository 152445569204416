import React, { useRef, useReducer } from "react";
import { useEffect } from "react";
import { useState } from "react";
import swal from "sweetalert";

import ApplicarionConfigService from "../../api/ApplicationConfigService";
// import { IconButton } from '@material-ui/core';
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from '@mui/icons-material/Delete';
// import MUIDataTable from 'mui-datatables';
import { useForm } from "react-hook-form";
import $ from "jquery";

// import Toast from '../Toastify';
// import Moment from 'react-moment';

const ConfigurationServiceobj = new ApplicarionConfigService();
const SLAConfiguration = (props) => {
  const [SLAdataList, SetSLAdataList] = useState([]);
  const [SLAAlldataList, SetSLAAlldataList] = useState([]);
  const [SLAdataListValue, SetSLAdataListValue] = useState("");
  const [ListSLAConfigurationobj, setListSLAConfiguration] = useState([]);

  const valueRef = useRef(null);
  const [SLAConfigurationobj, setSLAConfigurationobj] = useState({
    status: "",
    slA_ID: 0,
    end_Date: "",
    start_Date: "",
    slA_Name: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm();

  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [Refresh, setRefresh] = useState(0);
  const onKeyDown = (event) => {
    // if (event.code === 'Space') event.preventDefault()
  };

  function EditSLA(Id, name, hour) {
    
    $(window).scrollTop(0);

    document.getElementById("slA_ID").value = Id;
    document.getElementById("SLA_Name").value = name;
    document.getElementById("SLAHour").value = hour;
    SetSLAdataListValue(name);
  }
  function DeleteSLA(Id) {
    swal({
      title: "Are You Sure?",

      text: "Do you want to delete this data from the list?",

      buttons: true,

      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const found = ListSLAConfigurationobj.find((obj) => {
          return obj.slA_ID === Id;
        });
        found.status = "DEL";
        $(".main-loader").show();
        ConfigurationServiceobj.SaveSLAConfiguration(found)
          .then((response) => {
            $(".main-loader").hide();
            swal("SLA", "Deleted Successfully!", "success");
            PageRender();
            // Toast("SLA Deleted Successfully", 'success');
          })
          .catch((error) => {
            $(".main-loader").hide();
          });
      }
    });
  }

  useEffect(() => {
    ConfigurationServiceobj.GetConfigurationData()
      .then((response) => {
        var l = response.data.slaConfiguration;
        setListSLAConfiguration(l);
      })
      .catch((error) => {});
    ConfigurationServiceobj.GetStepDropDownList().then((res) => {
      if (res != null && res.data != null && res.data.length > 0) {
        SetSLAdataList(res.data);
      }
    });
  }, [Render]);

  const columns = [
    { name: "slA_Name", label: "SLA Month " },
    { name: "start_Date", label: "From Date" },
    { name: "end_Date", label: "To Date" },
    {
      label: "Action",
      name: "slA_ID",
      options: {
        filter: false,
        sort: false,
        empty: true,

        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[3];
          return (
            <div className=" ico-sec">
              {/* <Tooltip title="Edit SLA">

                <IconButton aria-label="Edit"
                  onClick={() => EditSLA(value)}
                >

                  <EditIcon />

                </IconButton>
              </Tooltip> */}

              {/* <Tooltip title="Delete SLA">
              <IconButton aria-label="Delete"
                onClick={() => DeleteSLA(value)}
              >

                <DeleteIcon />

              </IconButton>
            </Tooltip> */}
            </div>
          );
        },
      },
    },
  ];

  // const HandleChange = (e) => {

  //     setEmailConfigObj({
  //         ...EmailConfigObj,
  //         [e.target.name]: e.target.value,
  //     })
  // }
  function onSubmitSLA(e) {
    e.preventDefault();
    const SLA = new FormData(e.currentTarget);
    const SLAHour = Number(SLA.get("SLAHour"));

    // Check if SLAHour is 0
    if (SLAHour === 0) {
        swal("Error", "SLAHour cannot be 0 or Empty", "error");
        return; 
    }

    const data = {
        SLA_ID: Number(SLA.get("slA_ID")),
        SLA_Name: $("#SLA_Name").val(),
        SLAHour: SLAHour,
    };

    ConfigurationServiceobj.SaveSLAConfiguration(data)
        .then((response) => {
            document.getElementById("SLAHour").value = "";
            ClosePop();
            swal("SLA", "Saved Successfully!", "success");
            PageRender();
            // Toast("SLA Saved Successfully", 'success');
        })
        .catch((error) => {
            $(".main-loader").hide();
        });
}

  const onSubmit = (data) => {
    // $('.main-loader').show();
    ConfigurationServiceobj.SaveSLAConfiguration(data)
      .then((response) => {
        $(".main-loader").hide();
        var count = Refresh;
        setRefresh(++count);
        // Toast("SLA Saved Successfully", 'success');

        reset();
      })
      .catch((error) => {
        $(".main-loader").hide();

        if (error.response.data === "SLA Name Already Exist.") {
          // Toast("SLA Name Already Exist.", 'error');
        } else {
          // Toast("Something went wrong", 'error');
        }
      });
  };

  const [ButtonName, setButtonName] = useState("Add");
 
  const ClosePop = () => {
    $(".close").click();
    
   if (ButtonName == "Add") {
      setButtonName("Update");
    }
    if (ButtonName == "Update") {
      setButtonName("Add");
    }
    
    document.getElementById("SLAConfiguration").reset();
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <button
          className="btn btn-primary btn-round ml-auto  pull-right btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#slaConfig"
          style={{ zIndex: 999, position: "relative" }}
        >
          <i className="fa fa-plus"></i> <span className="ml-1">Add</span>
        </button>
      </div>
      <div>
        <table id="add-row" className="display table table-striped table-hover">
          <thead>
            <tr>
              <th style={{ display: "none" }}></th>
              <th>Step</th>
              <th>Hours</th>

              <th style={{ width: "10%", textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {ListSLAConfigurationobj &&
              ListSLAConfigurationobj.map((x, i) => {
                return (
                  <tr>
                    <td style={{ display: "none" }}>{x.slA_ID}</td>
                    <td>{x.slA_Name}</td>
                    <td>{x.slaHour}</td>
                    <td>
                      <div class="form-button-action">
                        <button
                          type="button"
                          title=""
                          class="btn btn-link btn-primary btn-lg"
                          data-bs-toggle="modal"
                          data-bs-target="#slaConfig"
                          data-original-title="Edit"
                          onClick={(e) =>
                            EditSLA(x.slA_ID, x.slA_Name, x.slaHour)
                          }
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        <button
                          type="button"
                          data-bs-toggle="tooltip"
                          title=""
                          class="btn btn-link btn-danger"
                          data-original-title="Remove"
                          onClick={(e) => DeleteSLA(x.slA_ID)}
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {/* <MUIDataTable
        title="SLA List"
        data={ListSLAConfigurationobj}
        columns={columns}
        options={{
          download: false,
          print: false,
          selectableRows: false,
          filter:false,
          viewColumns : false,
        
          responsive : true,
        }}
      /> */}
      </div>

      <div
        className="modal fade"
        id="slaConfig"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title">
          
                <span className="fw-light">{Number($("#slA_ID").val())>0?"Update SLA value":"Add SLA value"}</span>
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
               onClick={ClosePop}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                id="SLAConfiguration"
                onKeyPress={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                  }
                }}
                onSubmit={(e) => onSubmitSLA(e)}
              >
                <div className="row">
                  <div className="col-lg-2 col-md-9 col-sm-12"></div>
                  <div className="col-lg-4 col-md-9 col-sm-12">
                    <input
                      type="text"
                      hidden
                      id="slA_ID"
                      value={0}
                      name="slA_ID"
                    />
                    <div class="form-group">
                      <label>Step </label>
                      <select
                        class="form-control "
                        id="SLA_Name"
                        onChange={(e) => SetSLAdataListValue(e.target.text)}
                        value={SLAdataListValue}
                      >
                        {SLAdataList &&
                          SLAdataList.map((x, i) => {
                            return (
                              <option key={i} value={x.stepDescription}>
                                {x.stepDescription}
                              </option>
                            );
                          })}
                      </select>
                      {/* <input
                  id="SLA_Name"
                  type="text"
                  class="form-control"
                  placeholder="Role"
                  name='SLA_Name'
                /> */}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-9 col-sm-12">
                    <div class="form-group ">
                      <label>Hours</label>
                      <input
                        id="SLAHour"
                        type="text"
                        class="form-control"
                        placeholder="Hours"
                        name="SLAHour"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-9 col-sm-12">
                    <button
                      type="submit"
                      id="addRowButton"
                      class="btn btn-primary btn-sm"
                      style={{ marginTop: "42px" }}
                    >
                      {Number($("#slA_ID").val())>0?"Update":"Add"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SLAConfiguration;
