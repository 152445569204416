import React, { useState, useReducer, useContext } from "react";
import $ from "jquery";
import { useEffect } from "react";
import AssociateService from "../../api/AssociateService";
import Toast from "../Toastify";
import MySelect from "../MultiSelect/Myselect";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import { imgURL } from "../../api/AxiosService"
import dummyimg from "../../assets/img/dummyImg_1.jpg"
import { AuthContext } from "../../Context/AuthContext";
// import {Switch} from "antd";
// import Select from 'react-dropdown-select';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const AssociateServiceobj = new AssociateService();

const Options = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

// const animatedComponents = makeAnimated();
const animatedComponents = makeAnimated();

function AddOrEditEmployee(props) {
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);

  const employeeID = props?.employeeID;
  const EmployeeCode = props?.Employeecode;
  const [companyID, setCompanyID] = useState(cookies?.companyID);
  const [roleName, setRoleName] = useState(cookies?.roleName);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [empcode, setEmpcode] = useState();
  const [empid,setEmpid]=useState();
  const [toogle, setToggle] = useState(false);
  const [reportinglistbyselect, setreportinglistbyselect] = useState([]);
  const [companyBasedMangerList, setcompanyBasedMangerList] = useState([]);
  const [rolelistbyselect, setrolelistbyselect] = useState([]);
  const [HonorTitle, setHonorTitle] = useState([]);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [EmployeeModel, setEmployeeModel] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [companyList, setCOmpanyList] = useState([]);
  const [CompanyName, setCompanyName] = useState();
  const [locationList, setLocationList] = useState([]);

  const [SelectedCompanyName, setSelectedCompanyName] = useState(companyID);
  const [selLocationName, setSelLocationName] = useState();
  const [ReportingList, setReportingList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [HODList, setHODList] = useState([]);
  const [employeePhoto, setEmployeePhoto] = useState(null);
  const [selectedimage, setSelectedImage] = useState(null);
  const [SelectedVAl, setSelectedVAl] = useState(null);
  const [pathName, setPathName] = useState(null);
  const [error, setformError] = useState({
    employee_Code: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Title: "",
    Location: "",
    Mobile_Number: "",
    Email: "",
    PersonalEmail: "",
    Role: "",
    status: "",
    CompanyName: "",
    AssociateTitle: ""

  });
  const [Empcode, setEmployeecode] = useState()



  useEffect(() => {
    if(props.Employeecode){
      setEmpcode(props?.Employeecode)
      setEmpid(props?.employeeID)
    }
  }, [props.Employeecode])

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleEmpCodeChange =(e)=>{
    setEmpcode(e.target.value)
  }

  const toogler = () => {
    toogle ? setToggle(false) : setToggle(true);
  };

  useEffect(() => {


    document.getElementById("AddOrEditEmployee").reset();

    setformError([]);
    AssociateServiceobj.Create(employeeID).then((res) => {
      debugger
      var data = res.data;


      setEmployeeModel(data);

      setEmployeecode(data.employee_Code)

      setRoleList(data.roleList);
      setCOmpanyList(data.companyList)

      setLocationList(data.locationList)
      setReportingList(data.reportingList);
      //  setreportinglistbyselect(data.reportingSeletedList)
      setHonorTitle(data.title);

      setreportinglistbyselect(data.reportManager);

      setSelectedCompanyName(data.companyID == 0 ? companyID : data.companyID);
      CompanyNameOnChange(data.companyID == 0 ? companyID : data.companyID)


      setSelLocationName(data.locationId);
      setSelectedLocation(data.location)
      setEmployeePhoto(data.profilePicture)

      var stationIDs =
        data.stringRoleID != null ? data.stringRoleID.split(",") : [];
      var stationArray = [];
      RoleList.map((x) => {
        for (var i = 0; i < stationIDs.length; i++) {
          if (Number(stationIDs[i]) == x.value) {
            const values = {
              label: x.label,
              value: x.value,
            };
            stationArray.push(values);
          }
        }
      });
      setrolelistbyselect([...stationArray]);
    });
  }, [employeeID, GridRender]);

  const onSubmit = async (data) => {
    debugger

    data.preventDefault();

    var myerror = error;
    var data = EmployeeModel;
    data.isActive = Number(EmployeeModel.isActive);
    data.mobile_Number = document.getElementById("mobile_Number").value;
    data.reportManager = document.getElementById("reportingmanager").value;
    data.title = document.getElementById("HonorTitle").value;
    data.AssociateTitle = document.getElementById("associateTitle").value;
    data.location = document.getElementById("location").value;
    data.employee_Code = document.getElementById("employee_Code").value;
    var flag = true;
    // 
    // if (data.employee_Code == null || data.employee_Code == "") {
    //   var emplcode = employeeID == 0 ? EmployeeCode : Empcode;
    //   if (emplcode == null || emplcode == undefined || emplcode == "") {
    //     myerror.employee_Code = "Employee Code is Required";
    //     flag = false;
    //   }
    //   else {
    //     data.employee_Code = emplcode;
    //   }
    // } else {
    //   if (/[^A-Za-z0-9]+/.test(data.employee_Code)) {
    //     myerror.employee_Code = "Sorry! Only Allow Alphabets and Numbers";
    //     flag = false;
    //   }
    //   else {
    //     myerror.employee_Code = ""
    //   };
    // }
    // 

    if (data.employee_Code == null || data.employee_Code == "") {
      myerror.employee_Code = "Associate Code is Required";
      flag = false;

      // else {
      //   if (/[^A-Za-z0-9]+/.test(data.employee_Code)) {
      //     myerror.employee_Code = "Sorry! Only Allow Alphabets and Numbers";
      //     flag = false;
      //   }

    } else {
      myerror.employee_Code = ""
    };

    if (data.email == null || data.email == "") {
      myerror.Email = "Email is Required";
      flag = false;
    } else {
      if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}$/i.test(data.email)) {
        myerror.Email = "Sorry! Email ID is Not Valid";
        flag = false;
      }
      else { myerror.Email = "" };
    }

    if (data.firstName == null || data.firstName == "") {
      myerror.FirstName = " First Name is Required";
      flag = false;
    } else {
      if (!/^[A-Za-z .]+$/.test(data.firstName)) {
        myerror.FirstName = "Sorry! Only Allow Alphabets";

        flag = false;
      } else { myerror.FirstName = "" };
    }
    if (data.isManager == 1) {
      myerror.reportManager = "";
    }
    else if (
      data.reportManager == null ||
      data.reportManager == "" ||
      data.reportManager == "-1" ||
      data.reportManager == 'No data'
    ) {
      myerror.reportManager = " Reporting Manager is Required";
      $('#isManager').prop('disabled', false);
      flag = false;
    }
    else {
      myerror.reportManager = "";
    }
    if (data.lastName == null || data.lastName == "") {
      myerror.LastName = " Last Name is Required";
      flag = false;
    } else {
      if (!/^[A-Za-z .]+$/.test(data.lastName)) {
        myerror.LastName = "Sorry! Only Allow Alphabets";

        flag = false;
      } else { myerror.LastName = "" };
    }
    if (data.location == null || data.location == "") {
      myerror.Location = " Associate Location is Required";
      flag = false;
    }

    if (data.mobile_Number == null || data.mobile_Number == "") {
      myerror.Mobile_Number = " Mobile Number is Required";
      flag = false;
    }
    // else if (data.mobile_Number.length < 10) {
    //   myerror.Mobile_Number = " Invalid Mobile Number";
    //   flag = false
    // }
    if (data.title == "Select Title") {
      myerror.Title = " Title is Required";
      flag = false;
    }
    else {
      myerror.Title = "";
    }

    // if (rolelistbyselect.values == null || rolelistbyselect == 0) {
    //   myerror.Role = " Role name  is Required";
    //   flag = false;
    // } else {
    //   myerror.Role = "";
    // }
    ;
    if (SelectedCompanyName != null && SelectedCompanyName != undefined && SelectedCompanyName != -1) {
      data.companyID = SelectedCompanyName
    } else {
      flag = false;
      myerror.CompanyName = "Company Location is Required";
    }
    if (data.AssociateTitle == null || data.AssociateTitle == undefined || data.AssociateTitle == "") {
      flag = false;
      myerror.AssociateTitle = "Position Title is Required";
    }
    else {
      myerror.AssociateTitle = ""
    }
    setformError({ ...myerror });

    if (flag) {

      $(".loader-container").show();

      data.status = "AC";

      data.stringRoleID = "";
      data.roleList = [];

      for (var i = 0; i < rolelistbyselect.length; i++) {
        data.stringRoleID += rolelistbyselect[i].value + ",";
      }

      data.rolelistbyselect = {};
      const pathName = await handleSavePicture()
      data.profilePicture = pathName;

      await AssociateServiceobj.Register(data)
        .then((response) => {

          $(".loader-container").hide();
          if (data.employeeID == 0) {
            Toast("Associate Created Successfully", "success");
          } else {
            Toast("Associate Updated Successfully", "success");
          }
          setTimeout(function () {
            $(".close").trigger("click");
          }, 2000);

          setGridRender();
          // PageRender();
        })
        .catch((error) => {
          $(".loader-container").hide();
          if (error.response.data === "employee_code") {

            Toast("Associate Code is Already Exists.", "Warning");

          }
          else if (error.response.data === "email") {

            Toast("Personal Email is Already Exists.", "Warning");

          }
          else if (error.response.data === "mobile_Number") {
            if (EmployeeModel.Mobile_Number === data.Mobile_Number) {
              Toast("Mobile Number is Already Exists.", "Warning");
            }

          } else {
            Toast("Mail is not working ", "error");
            console.log(error);
            $(".close").trigger("click");

            setGridRender();
          }
        });
    }
    // }
  };

  function EmplocodeChange() {
    var Empcode = $("#employee_Code").val();
    if (Empcode == "") {
      $("#erremployee_Code").text("Associate Code is Required");
    } else {
      $("#erremployee_Code").text("");
    }
  }
  function FirstNameChange() {
    var FirstName = $("#employee_Code").val();
    if (FirstName == "") {
      $("#erremployee_Code").text("Employee Code is Required");
    } else {
      $("#erremployee_Code").text("");
    }
  }

  function firstNameChange() {
    var FirstName = $("#firstName").val();
    if (FirstName == "") {
      $("#errfirstName").text("First Name is Required");
    } else {
      $("#errfirstName").text("");
    }
  }

  function lastNameChange() {
    var lastName = $("#lastName").val();
    if (lastName == "") {
      $("#errlastName").text("Last Name is Required");
    } else {
      $("#errlastName").text("");
    }
  }
  function PositionTitleChange() {
    var Title = $("#associateTitle").val();
    if (Title == "") {
      $("#errAssociateTitle").text("Title is Required");
    } else {
      $("#errAssociateTitle").text("");
    }
  }
  function locationChange() {
    var location = $("#location").val();
    if (location == "") {
      $("#errlocation").text("Associate Location is Required");
    } else {
      $("#errlocation").text("");
    }
  }



  function mobile_NumberChange() {
    var mobile_Number = $("#mobile_Number").val();
    if (mobile_Number == "") {
      $("#errmobile_Number").text("Mobile Number is Required");
    }
    //  else if (mobile_Number.length < 10) {
    //   $("#errmobile_Number").text("Invalid Mobile Number");
    // }
    else {
      $("#errmobile_Number").text("");
    }
  }

  function emailChange() {
    var email = $("#email").val().trim();

    var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}$/i;

    if (email === "") {
      $("#erremail").text("Email Id is Required");
    } else if (!emailRegex.test(email)) {
      $("#erremail").text("Invalid Email Id");
    } else {
      $("#erremail").text("");
    }
  }

  function PersonalEmailChange() {
    var email = $("#workEmail").val().trim();
    var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}$/i;

    if (email === "") {
      $("#errPersonalEmail").text("");
    } else if (!emailRegex.test(email)) {

      $("#errPersonalEmail").text("Invalid Email Id");
    } else {

      $("#errPersonalEmail").text("");
    }
  }


  function reportingmanagerChange(e) {


    if (e.target.value != "-1" || e.target.value != 'No data') {
      $('#isManager').prop('disabled', true);
      $("#errreportingmanager").text("");
    }
    if (e.target.value === "-1" || e.target.value == 'No data') {
      $('#isManager').prop('disabled', false);
    }
    setreportinglistbyselect(e.target.value);
    var reportingmanager = document.getElementById("reportingmanager").value;
    if (reportingmanager == "") {

      $("#errreportingmanager").text("Reporting Manager is Required");
    } else {

      $("#errreportingmanager").text("");
    }
  }

  function ROleOnchange(e) {
    $("#errroleSeletedList").text("");

    setrolelistbyselect([...e]);
    console.log(e);

    // setEmployeeModel((prevState) => ({
    //   ...prevState,
    //   ["roleSeletedList"]: e,

    //   // setMultiselectValue()
    // }));
  }

  const CompanyNameOnChange = (e) => {

    var value = e?.target?.value === undefined ? e : e?.target?.value;
    setSelectedCompanyName(value)
    if (value != undefined && value != null && value != -1) {
      AssociateServiceobj.Get_Company_Based_ManagerRole(value)
        .then((response) => {

          var reportingManagerLists = (response.data.reportingList)
          setcompanyBasedMangerList(response.data.reportingList)

        })

      error.CompanyName = ""
    }
    else {
      error.CompanyName = "Company Location is Required"
    }

  }

  function ReportOnchange(e) {
    this.setState({ selectValue: e.target.value });

  }
  function HonorTitleChange(e) {
    if (e.target.value != "-1") {
      $("#errTitle").text("");
    }
    setHonorTitle(e.target.value);

    var tit = e.target.value;
  }

  function numberOnly(e) {
    const inputValue = e.target.value;
    const regex = /^\d+$/;

    if (!regex.test(inputValue)) {
      e.target.value = inputValue.replace(/\D/g, '');
    }
  }

  function alphabetOnly(e) {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z ]+$/;

    if (!regex.test(inputValue)) {
      e.target.value = inputValue.replace(/[^a-zA-Z ]/g, '');
    }
  }

  function refreshPage() {
    window.location.href = "/Associate"
  }
  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    const value = checked ? 1 : 0; // Set value to 1 if checked, 'off' if unchecked
    setEmployeeModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setEmployeePhoto(URL.createObjectURL(file));

  };
  const handleSavePicture = async () => {

    if (selectedimage) {
      const formData = new FormData();
      formData.append('employeePhoto', selectedimage);

      try {
        const response = await AssociateServiceobj.UploadImage(formData);
        if (response != null) {
          return response;
        }
      } catch (error) {
        console.error(error);
      }
      return null;
    }
  };


  const handleEditPicture = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        setSelectedImage(selectedFile)
        setEmployeePhoto(URL.createObjectURL(selectedFile));

      }
    });
    fileInput.click();
  };

  const handleDeletePicture = () => {
    setEmployeePhoto(null);
  };
  const handleLocationChange = (event, newValue) => {
    setSelectedLocation(newValue);
  };


  return (
    <div>
      <form
        id="AddOrEditEmployee"
        onKeyPress={(e) => {
          if (e.key == "Enter") {
            e.preventDefault();
          }
        }}
        onSubmit={onSubmit}
      >
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group ">
              <label>
                Associate Code <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                className="form-control"
                maxLength={6}
                value={empid === 0 ? empcode : EmployeeModel.employee_Code}
                name="employee_Code"
                id="employee_Code"
                onChange={empid ==0 ?handleEmpCodeChange:handleChange}
                disabled={employeeID===1}
                onBlur={EmplocodeChange}
                onInput={numberOnly}
                onKeyDown={(event) => onKeyDown(event)}
                autoComplete="off"
              />
              {employeeID == 0 &&
                <span className="lastcode">Lastest Associate Code -<b> {EmployeeCode - 1}</b></span>
              }
              {/* <span className="text-danger" id="erremployee_Code">
                {error.employee_Code}
              </span> */}
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group  ">
              <label>
                Title<span className="text-danger">*</span>
              </label>
              <select
                class="form-control "
                id="HonorTitle"
                name="HonorTitle"
                value={HonorTitle}
                onChange={HonorTitleChange}
              >
                <option>Select Title</option>
                <option value="1">Mr</option>
                <option value="2">Ms</option>
              </select>

              <span className="text-danger" id="errTitle">
                {error.Title}
              </span>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group ">
              <label>Status </label>
              {/* <Switch  onClick={toogler} />
               {toogle ? <span>Active</span> : <span>InActive</span>} */}
              <div class="form-check">
                <div class="row">
                  <div class="col-4">
                    <label class="form-radio-label ">
                      {EmployeeModel.isActive == 1 ? (
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="isActive"
                          value={1}
                          onChange={handleChange}
                          checked="1"
                        />
                      ) : (
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="isActive"
                          value={1}
                          onChange={handleChange}
                        />
                      )}
                      <span
                        style={{ marginLeft: "5px" }}
                        class="form-radio-sign"
                      >
                        Active
                      </span>
                    </label>
                  </div>
                  <div class="col-4">
                    <label class="form-radio-label ml-3">
                      {EmployeeModel.isActive == 0 ? (
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="isActive"
                          value={0}
                          onChange={handleChange}
                          checked="0"
                        />
                      ) : (
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="isActive"
                          value={0}
                          onChange={handleChange}
                        />
                      )}
                      <span
                        style={{ marginLeft: "5px" }}
                        class="form-radio-sign"
                      >
                        InActive
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <span className="text-danger">{error.status}</span>
            </div>
          </div>

          {/* editmode picture */}
          <div className="col-md-3">
            <div className="form-group">
              <label >Profile Picture</label>
            </div>
            <div>
              {EmployeeModel.employeeID > 0 && (
                <div className="position-relative profile">
                  {employeePhoto ? (
                    <div className="clsimg">
                      <img
                        src={
                          employeePhoto.includes("Images/Associate/")
                            ? imgURL + employeePhoto
                            : employeePhoto
                        }
                        alt="Profile"

                        className="main-profile-img"
                      />
                      <a href="#" className="edit-icon mr-3" onClick={(e) => { e.preventDefault(); handleEditPicture(); }}>
                        <i className="fa fa-edit i-con" style={{ color: 'blue' }}></i>
                      </a>
                    </div>
                  ) : (
                    <>
                      <img
                        src={dummyimg}
                        width="65"
                        height="65"
                        onClick={() => document.getElementById('fileInput').click()}
                        style={{ cursor: 'pointer' }}
                      />
                      <label className="text-primary" htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                        Upload Image
                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          className="d-none"
                          onChange={handlePictureChange}
                        />
                      </label>
                    </>

                  )}
                </div>
              )}

              {EmployeeModel.employeeID <= 0 && (
                <div className="position-relative profile">
                  {employeePhoto ? (
                    <div className="clsimg">
                      <img
                        src={
                          employeePhoto.includes("Images/Associate/")
                            ? imgURL + employeePhoto
                            : employeePhoto
                        }
                        alt="Profile"

                        className="main-profile-img"
                      />
                      <a href="#" className="edit-icon mr-3" onClick={(e) => { e.preventDefault(); handleEditPicture(); }}>
                        <i className="fa fa-edit i-con" style={{ color: 'blue' }}></i>
                      </a>
                    </div>

                  ) : (
                    <>
                      <img
                        src={dummyimg}
                        width="55"
                        height="55"
                        onClick={() => document.getElementById('fileInput').click()}
                        style={{ cursor: 'pointer' }}
                      />
                      <label className="text-primary" htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                        Upload Image
                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          className="d-none"
                          onChange={handlePictureChange}
                        />
                      </label>
                    </>

                  )}
                </div>
              )}
            </div>
          </div>


          <div class="col-sm-4">
            <div class="form-group ">
              <label>
                First Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="First Name"
                value={EmployeeModel.firstName}
                name="firstName"
                id="firstName"
                onInput={alphabetOnly}
                onChange={handleChange}
                onBlur={firstNameChange}
                autoComplete="off"
              />
              <span className="text-danger" id="errfirstName">
                {error.FirstName}
              </span>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group ">
              <label>Middle Name</label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="Middle Name"
                value={EmployeeModel.middleName}
                name="middleName"
                id="middleName"
                onInput={alphabetOnly}
                onChange={handleChange}
                autoComplete="off"
              />
              <span className="text-danger" id="errmiddleName">
                {error.MiddleName}
              </span>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group ">
              <label>
                Last Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                onBlur={lastNameChange}
                placeholder="Last Name"
                value={EmployeeModel.lastName}
                name="lastName"
                id="lastName"
                onInput={alphabetOnly}
                onChange={handleChange}
                autoComplete="off"
              />
              <span id="errlastName" className="text-danger">
                {error.LastName}
              </span>
            </div>
          </div>


          <div class="col-md-4">
            <div class="form-group ">
              <label>
                Personal Email <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="Personal Email"
                name="email"
                id="email"
                value={EmployeeModel.email}
                onChange={handleChange}
                onBlur={emailChange}
                autoComplete="off"
              />
              <span className="text-danger" id="erremail">
                {error.Email}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>
                Office Email <span className="text-danger"></span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="Office Email"
                name="workEmail"
                id="workEmail"
                value={EmployeeModel.workEmail}
                onChange={handleChange}
                onBlur={PersonalEmailChange}
                autoComplete="off"
              />
              <span className="text-danger" id="errPersonalEmail">
                {error.PersonalEmail}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>
                Mobile Number <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength="20"
                placeholder="Mobile Number"
                value={EmployeeModel.mobile_Number}
                name="mobile_Number"
                id="mobile_Number"
                onInput={numberOnly}
                onChange={handleChange}
                onBlur={mobile_NumberChange}
                autoComplete="off"
              />
              <span className="text-danger" id="errmobile_Number">
                {error.Mobile_Number}
              </span>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group ">
              <label>
                Position Title <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                onBlur={PositionTitleChange}
                placeholder="Position Title"
                value={EmployeeModel.associateTitle}
                name="associateTitle"
                id="associateTitle"
                onChange={handleChange}
                onInput={alphabetOnly}
                autoComplete="off"
              />
              <span id="errAssociateTitle" className="text-danger">
                {error.AssociateTitle}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>Company Location</label><span className="text-danger">*</span>
              <select
                id="CompanyName"
                name="CompanyNameList"
                placeholder="Select..."
                onChange={CompanyNameOnChange}
                value={SelectedCompanyName}
                className="form-control"
                disabled={roleName.includes('Super Admin') ? false : true}
              >
                <option value="-1">Select...</option>
                {EmployeeModel.companyList != null &&
                  EmployeeModel.companyList.length > 0 ? (
                  EmployeeModel.companyList.map((item, indexs) => {
                    return <option value={item.value}>{item.label}</option>;
                  })
                ) : (
                  <option>No data</option>
                )}
              </select>

              <span className="text-danger">
                {error.CompanyName}
              </span>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="location">
                Associate Location <span className="text-danger">*</span>
              </label>
              <Autocomplete
                options={locationList?.length > 0 ? locationList.map((location) => location.locationName) : []}
                value={selectedLocation}
                id="location"

                onKeyUp={(e) => {
                  setSelectedLocation(e.target.defaultValue);
                }}
                onChange={(e) => {
                  setSelectedLocation(e.target.innerText);
                }}
                onBlur={locationChange}
                renderInput={(params) => <TextField  {...params} placeholder="Associate Location" />}
              />

              <span id="errlocation" className="text-danger">
                {error.Location}
              </span>
            </div>
          </div>


          <div class="col-md-4">
            <div class="form-group ">
              <label>Role</label>
              <MySelect
                options={RoleList}
                value={rolelistbyselect}
                name="roleSeletedList"
                id="roleSeletedList"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                placeholder="Select Role"
                components={{ Options, MultiValue, animatedComponents }}
                onChange={ROleOnchange}
                isMulti
                allowSelectAll={true}
              />

              <span className="text-danger" id="errroleSeletedList">
                {error.Role}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>
                is Manager? <span className="text-danger"></span>
              </label>

              <input
                type="checkbox"
                name="isManager"
                id="isManager"
                className="form-control isManagerChk"
                value={EmployeeModel?.isManager}
                disabled={EmployeeModel?.reportManager == -1 || EmployeeModel?.reportManager == 'No data' || EmployeeModel?.reportManager == null ? false : reportinglistbyselect == -1 || reportinglistbyselect == null ? false : true}
                onChange={handleCheckChange}
                checked={EmployeeModel?.isManager == 1 ? true : false}
              />

            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>
                Reporting Manager <span className="text-danger">*</span>
              </label>

              <select
                id="reportingmanager"
                name="roleSeletedList"
                placeholder="Select..."
                onChange={reportingmanagerChange}
                value={reportinglistbyselect}
                className="form-control"
                disabled={EmployeeModel?.isManager === 1 ? true : false}
              >
                <option value="-1">Select...</option>
                {companyBasedMangerList != null &&
                  companyBasedMangerList.length > 0 ? (
                  companyBasedMangerList.map((item, indexs) => {
                    return <option value={item.value}>{item.label}</option>;
                  })
                ) : (
                  <option>No data</option>
                )}
              </select>

              <span className="text-danger" id="errreportingmanager">
                {error.reportManager}
              </span>
            </div>
          </div>

        </div>

        <div class="modal-footer no-bd">

          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={refreshPage}>
            Close
          </button>
          {EmployeeModel.employeeID == 0 ? (
            <button type="submit" id="addRowButton" class="btn btn-primary">
              Add
            </button>
          ) : (
            <button type="submit" id="addRowButton" class="btn btn-primary">
              Update
            </button>
          )}


        </div>
      </form>
    </div>
  );
}

export default AddOrEditEmployee;
