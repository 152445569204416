import { useNavigate } from "react-router";
import React, { useEffect, useState, useContext } from "react";
import { UserRoleConfig } from "./UserRoleConfig";
import $ from "jquery";
import RoleConfigurationService from "../../api/RoleconfigurationService";
import swal from "sweetalert";
import { useReducer } from "react";
import Toast from "../Toastify";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../../Context/AuthContext";
import "react-toastify/dist/ReactToastify.css";

export const RoleConfig = () => {
  const ConfigurationServiceobj = new RoleConfigurationService();

  const { cookies, setCookie, removeCookie } = useContext(AuthContext);

  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);

  //  const [sessionRole, setPriviledge] = useState(cookies.roleName);
  //  console.log(sessionRole);
  const [employeeID, setEmployeeID] = useState(0);
  const [RoleData, SetRoleData] = useState([]);
  const [FilteredRoleData, SetFilteredRoleData] = useState([]);
  const [Refresh, SetRefresh] = useState(0);
  const [RoleID, setRoleID] = useState(0);
  const navigate = useNavigate();
  const [modelRefresh, setmodelRefresh] = useState(0);
  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    getRoleData();
  }, [GridRender]);

  const getRoleData = () => {
    $(".main-loader").show();

    $('.submenu').removeClass('active');
    $('#ConfigurationMenu').addClass('active');

    $('.collapse').removeClass('show')
    $('#Configuration').addClass('show')

	 $(".submenu li").removeClass("active");

   $("#roleConf").addClass("active");

    ConfigurationServiceobj.RoleList()
      .then((response) => {
        SetRoleData(response.data);

        SetFilteredRoleData(response.data);
        $(".main-loader").hide();
      })
      .catch((error) => {
        $(".main-loader").hide();
      });
  };

  const EditRole = (val) => {
    setRoleID(val);
    setmodelRefresh(modelRefresh + 1);
  };

  function DeleteRole(val) {
    swal({
      title: "Are You Sure?",
      text: "Do You Want to Delete this Role?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        
        ConfigurationServiceobj.DeleteRole(val, employeeID)
          .then((res) => {
            Toast("Role Deleted Succesfully", "success");

            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const columns = [
    { name: "roleName", label: "Role Name" },
    { name: "description", label: "Description" },
    {
      label: "Action",
      name: "roleID",
      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[2];
          return (
            <div className=" ico-sec">
              {/* <Tooltip title="Edit Role">

                                <IconButton aria-label="Edit" data-bs-toggle="modal" data-bs-target="#Role" onClick={() => EditRole(value)}>

                                    <EditIcon />

                                </IconButton>

                            </Tooltip> */}

              {/* <IconButton aria-label="Delete"
                              onClick={() => DeleteRole(value)}
                            >

                                <DeleteIcon />

                            </IconButton> */}
              {/* <TrashFill   /> */}
            </div>
          );
        },
      },
    },
  ];

  function tablerefresh(id) {
    SetRefresh(Refresh + 1);
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div>
        <div class="main-panel">
          <div class="content">
            <div className="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex align-items-center">
                        <h4 class="card-title">Role </h4>

                        {priviledge.includes("ROLECREATE") ? (
                          <a
                            href={() => false}
                            className="btn btn-primary btn-round ml-auto btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#Add_Role"
                            style={{ color: "#fff" }}
                          >
                            <i className="fa fa-plus"></i> <span className="ml-1">Add Role</span>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table
                          id="RoleList-Display"
                          className="display table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Role Name</th>
                              <th>Status</th>
                              {priviledge.includes("ROLEEDIT")||priviledge.includes("ROLEDELETE")?(
                                 <th style={{ width: "10%", textAlign: "center" }}>
                                 Action
                               </th>
                              ):("")}
                             
                            </tr>
                          </thead>
                          <tbody>
                            {RoleData != null && RoleData.length > 0
                              ? RoleData.map((v, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{v.roleName}</td>
                                      <td>Active</td>
                                      {priviledge.includes("ROLEEDIT")||priviledge.includes("ROLEDELETE")?(
                                        <td>
                                        <div class="form-button-action">
                                          {priviledge.includes("ROLEEDIT") ? (
                                            <>
                                              {v.roleName == "Superr Admin" ? (
                                                <button
                                                  type="button"
                                                  title=""
                                                  class="btn btn-link btn-primary  dis"
                                                  data-original-title="Edit"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#Add_Role"
                                                  onClick={() =>
                                                    EditRole(v.roleID)
                                                  }
                                                  hidden
                                                  style={{
                                                    background: "#171f2a00",
                                                  }}
                                                >
                                                  <i className="fa fa-edit"></i>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  title=""
                                                  class="btn btn-link btn-primary btn-lg"
                                                  data-original-title="Edit"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#Add_Role"
                                                  onClick={() =>
                                                    EditRole(v.roleID)
                                                  }
                                                >
                                                  <i class="fa fa-edit"></i>
                                                </button>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {priviledge.includes(
                                            "ROLEDELETE"
                                          ) ? (
                                            <>
                                              {v.roleName == "Employeer" ||
                                              v.roleName == "Superr Admin" ? (
                                                <button
                                                  type="button"
                                                  data-bs-toggle="tooltip"
                                                  title=""
                                                  class="btn btn-link btn-danger"
                                                  data-original-title="Remove"
                                                  onClick={() =>
                                                    DeleteRole(v.roleID)
                                                  }
                                                  hidden
                                                >
                                                  <i
                                                    class="fas fa-trash-alt"
                                                    hidden
                                                  ></i>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  data-bs-toggle="tooltip"
                                                  title=""
                                                  class="btn btn-link btn-danger"
                                                  data-original-title="Remove"
                                                  onClick={() =>
                                                    DeleteRole(v.roleID)
                                                  }
                                                >
                                                  <i class="fas fa-trash-alt"></i>
                                                </button>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </td>
                                      ):("")}
                                      
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          {/* <!-- Modal --> */}
          <div
            class="modal fade"
            id="Add_Role"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            data-backdrop="static"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header no-bd">
                  <h5 class="modal-title">
                    <span class="fw-mediumbold">Role Configuration</span>
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={refreshPage}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <UserRoleConfig roleID={RoleID} tablerefresh={tablerefresh} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
