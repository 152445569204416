import React, { useState, useEffect } from 'react'
import ApplicarionConfigService from '../../api/ApplicationConfigService';
import { useForm } from 'react-hook-form';
import swal from "sweetalert";
// import Toast from '../Toastify';
const ConfigurationServiceobj = new ApplicarionConfigService();

function SharedFolder(){






    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
        clearErrors
    } = useForm();

    const onKeyDown = (event) => {
        if (event.code === 'Space') event.preventDefault()
      }

    const onSubmit = (data) => {


        ConfigurationServiceobj.SaveSharedFolder(data)
            .then((response) => {
                swal("Shared Folder","File Path Saved Successfully!","success");
                // Toast('File Path Saved Successfully', 'success');
            })
            .catch((res) => {
                swal("Shared Folder","Failed to save the File Path!","error");
                // Toast(res.response.data.message, 'error');
            });
    }
    const [passwordtoggle, setPasswordToggle] = useState(true)
    const passwordToggle = () => {
        
        
        setPasswordToggle(!passwordtoggle)
    }
    useEffect(() => {

        ConfigurationServiceobj.GetConfigurationData()
            .then((response) => {
                var sharedfolderdata = response.data.sharedFolderDetail;

                {

                    Object.keys(sharedfolderdata).map((key, i) => (

                        setValue(key, sharedfolderdata[key])

                    ))
                }

            })
            .catch((error) => {
                // Toast('Something Went Wrong', 'error');

            });
    }, []);


    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                        <div class="form-group mb-3">
                            <label class="label" for="name">FTP Location <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                maxLength={500}
                                class="form-control"
                                name='shareD_FOLDER_LOCATION'
                                maxlength="500"
                                placeholder="Shared Location"
                                {...register("shareD_FOLDER_LOCATION", {
                                    required: "Shared Location is Required",


                                })}
                                onChange={() => setError("shareD_FOLDER_LOCATION", "")}
                                onKeyDown={(event)=>onKeyDown(event)}
                            />
                            {errors.shareD_FOLDER_LOCATION && <span className='text-danger'>{errors.shareD_FOLDER_LOCATION.message}</span>}
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                        <div class="form-group mb-3">
                            <label class="label" for="name">Username <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                maxLength={100}
                                class="form-control"
                                name='shareD_FOLDER_USERNAME'
                                placeholder="Username"
                                {...register("shareD_FOLDER_USERNAME", {
                                    required: "Username is Required",


                                })}
                                onChange={() => setError("shareD_FOLDER_USERNAME", "")}
                                onKeyDown={(event)=>onKeyDown(event)}
                            />
                            {errors.shareD_FOLDER_USERNAME && <span className='text-danger'>{errors.shareD_FOLDER_USERNAME.message}</span>}
                        </div>
                    </div>

                    <div class="col-md-4 col-sm-12 col-lg-4 col-xl-4" style={{postion:"relative"}}>
                        <div class="form-group mb-3">
                            <label class="label" for="name">Password <span className="text-danger">*</span></label>
                            <input
                                type={passwordtoggle ? "password":"text"}
                                class="form-control"
                                name='shareD_FOLDER_PASSWORD'
                                placeholder="Password"
                                {...register("shareD_FOLDER_PASSWORD", {
                                    required: "Password is Required",


                                })}
                                onChange={() => setError("shareD_FOLDER_PASSWORD", "")}
                            />
                            {errors.shareD_FOLDER_PASSWORD && <span className='text-danger'>{errors.shareD_FOLDER_PASSWORD.message}</span>}

                            <span className='shared-password'>

                                <span onClick={() => passwordToggle()}>{passwordtoggle}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    <button class="btn btn-primary btn-sm"><i className="la la-save"></i> Save</button>

                </div>
            </form>
        </div>
    )

}
export default SharedFolder;
