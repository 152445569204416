import React, { useContext, useEffect, useState } from "react";
import $, { isPlainObject } from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import AssociateService from "../../api/AssociateService";
import moment from "moment";
import exportFromJSON from "export-from-json";
import MySelect from "../MultiSelect/Myselect";
import { AuthContext } from "../../Context/AuthContext";

const ConfigurationServiceobj = new ApplicarionConfigService();
const AssociateServiceobj = new AssociateService();

const LoginTracker = () => {
  const { cookies } = useContext(AuthContext);
  const [LoginHrs, setLoginHrs] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());
  const [MomentDate, setMomentDate] = useState(new Date());
  const [MomentEndDate, setMomentEndDate] = useState(new Date());
  const [SelectedValue, SetSelectedValue] = useState(null);
  const [EmpIDs, SetEmpIDs] = useState("");
  const [EmpID, setEmpID] = useState("");
  const [companyID, setCompanyID] = useState(cookies?.companyID);
  const [roleName, setRoleName] = useState(cookies?.roleName);



  useEffect(() => {
    ConfigurationServiceobj.GetLoginHours(
      EmpIDs,
      moment(MomentDate).format("YYYY-MM-DD"),
      moment(MomentEndDate).format("YYYY-MM-DD"),
      companyID,
      roleName
    )
      .then((response) => {
        setLoginHrs(response.data);
      })
      .catch((error) => {});

    AssociateServiceobj.getData(companyID,roleName)
      .then((res) => {
        const Emp = res.data.map((response) => ({
          value: response.employeeID,
          label: response.employee_Name + "- (" + response.employee_Code + ")",
        }));
        setEmployee(Emp);
      })
      .catch((error) => {});

    // $('.submenu').removeClass('active');
    // $('#ReportMenu').addClass('active');

    // $('.collapse').removeClass('show')
    // $('#Report').addClass('show')

    // $('.submenu li').removeClass('active');
    // $('#LoginTrackerHistpry').addClass('active');

    $(".submenu").removeClass("active");
    $("#ReportMenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Report").addClass("show");

    $(".submenu li").removeClass("active");
    $("#LoginTrackerHistory").addClass("active");
  }, [EmpIDs, MomentDate, EndDate]);
  const FromDate = (date) => {
    var MmDate = moment(date).format("YYYY-MM-DD");
    setStartDate(date);
    setMomentDate(MmDate);
  };
  const ToDate = (date) => {
    var MmmDate = moment(date).format("YYYY-MM-DD");
    setEndDate(date);
    setMomentEndDate(MmmDate);
  };
  const ExportExcel = () => {
    debugger;
    const data = LoginHrs.map((entry, index) => {
      const entryKeys = Object.keys(entry);
      if(entry[entryKeys[3]]==null){
        entry[entryKeys[3]]="0 hours 0 minutes 0 seconds";
      }
      return entry
    });
    
    
    
    
    const fileName = "LoginActivityHistory";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };
  const EmployeeChange = (e) => {
    SetSelectedValue(e);
    
    var val = [];
    e.map((k, i) => val.push(k.value));
    var j = val.join(",");
    SetEmpIDs(val.join(","));
  };
  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title mr-auto" style={{ width: "85%" }}>
                    Login Activity History
                  </h4>
                  <button
                    className="btn btn-primary btn-round btn-sm ml-auto"
                    data-bs-toggle="modal"
                    onClick={(e) => ExportExcel()}
                    data-bs-target="#addRowModal"
                  >
                    <i class="fas fa-file-export"></i>
                    <span className="ml-1">Export</span>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                    <div className="form-group mb-3">
                      <label className="label" for="name">
                        Employee
                      </label>
                      {/* <select className="form-control" onChange={(e) => setEmpID(e.target.value)}>
                                                <option value=''>Select Employee</option>
                                                {
                                                    Employee != null && Employee.length > 0 && Employee.map((e) => {
                                                        return <option value={e.employeeID}>{e.employee_Name + ' (' + e.employee_Code + ')'}</option>
                                                    })
                                                }
                                            </select> */}
                      <MySelect
                        isMulti
                        options={Employee}
                        value={SelectedValue}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        name="EmployeeList"
                        placeholder="Select Employee"
                        onChange={EmployeeChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                    <div className="form-group mb-3">
                      <label className="label" for="name">
                        Form Date
                      </label>
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={(date) => FromDate(date)}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                    <div className="form-group mb-3">
                      <label className="label" for="name">
                        To Date
                      </label>
                      <DatePicker
                        className="form-control"
                        selected={EndDate}
                        onChange={(date) => ToDate(date)}
                        dateFormat="dd-MM-yyyy"
                        minDate={startDate}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="table-responsive"
                  style={{ overflowX: "scroll", height: "70vh" }}
                >
                  <table className="display table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Employee Code</th>
                        <th style={{ minWidth: "200px" }}>Employee Name</th>
                        <th>Employee Role</th>
                        {LoginHrs != null &&
                          LoginHrs.length > 0 &&
                          LoginHrs.map((e, i) => {
                            if (i == 0) {
                              var obj = Object.keys(e);
                              return (
                                obj != null &&
                                obj.length > 0 &&
                                obj.map((e) => {
                                  if (
                                    e != "EmployeeName" &&
                                    e != "EmployeeCode" &&
                                    e != "EmployeeRole" &&
                                    e != "CompanyName"
                                  )
                                    return (
                                      <th style={{ minWidth: "250px" }}>{e}</th>
                                    );
                                })
                              );
                            }
                          })}
                          <th>Company Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {LoginHrs != null &&
                        LoginHrs.length > 0 &&
                        LoginHrs.map((e, i) => {
                          var j = Object.values(e);

                          return (
                            <tr>
                              <td>{e.EmployeeCode}</td>
                              <td>{e.EmployeeName}</td>
                              <td>{e.EmployeeRole}</td>
                              {j != null &&
                                j.length > 0 &&
                                j.map((k, i) => {
                                  if (i != 0 && i != 1 && i != 2 && i!=4) {
                                    if (k != null) {
                                      return (
                                        <td>
                                          <span style={{ color: "green" }}>
                                            {k}
                                          </span>
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td>0 hours 0 minutes 0 seconds</td>
                                      );
                                    }
                                  }
                                })}
                                <td>{e.CompanyName}</td>
                            </tr>
                          );
                        })}
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginTracker;
