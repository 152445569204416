import React from "react";
import AxiosServices from "./AxiosService";

const Auth = new AxiosServices();


class AssociateService {

     getData = async (CompanyID,roleName) => {
          return Auth.get(`/Employee/List?CompanyID=${CompanyID}&&roleName=${roleName}`)        
    };
    Create = async (EmpID) => {
     return Auth.get(`/Employee/Create?id=${EmpID}`)        
     };
     Register = async (data) => {
         debugger
          return Auth.post(`/Employee/Register`,data)        
     };
     DeleteEmployee = async (EmpID) => {
          return Auth.get(`/Employee/DeleteEmployee?Id=${EmpID}`,)        
     };
     GetAutogenrateEmployeeCode=async()=>{
          
          return Auth.get(`/Employee/GetAutogenrateEmployeeCode`)   
      }
      UploadImage=async (formdata)=>{
        
          try {
               const response = await Auth
                    .post(`Employee/UploadEmployeeImage`, formdata);
               return response.data;
          } catch (err) {
               console.log(err);
          }
      }
      BulkUpload = async (data) => {
          
          return Auth.post(`Employee/BulkUpload`, data);
        };

      Get_Company_Based_ManagerRole=async(value)=>{
          try{
               
               return Auth.get(`/Employee/Get_Company_Based_ManagerRole?CompanyId=${value}`)
              

          }
          catch(err) {
               console.log(err);
          }

      }

}

export default AssociateService

