import AxiosServices from "./AxiosService";

const axios = new AxiosServices();

class NotificationService {
  Notification_GetNotificationById(EmpId) {
    return axios.get(`DemandRequest/GetNotification?EmpId=${EmpId}`);
  }
  Notification_ClearCnt(EmpId) {
    return axios.get(`DemandRequest/ClearCnt?EmpId=${EmpId}`);
  }

  Notification_GetCnt(EmpId) {
    return axios.get(`DemandRequest/GetCnt?EmpId=${EmpId}`);
  }
  Notification_ClearAll(EmpId) {
    return axios.get(`DemandRequest/ClearAll?EmpId=${EmpId}`);
  }
  Notification_SingleClear(Id) {
    return axios.get(`DemandRequest/SingleClear?Id=${Id}`);
  }
}

export default NotificationService;
