import AxiosServices from "./AxiosService";

const Axios = new AxiosServices();

class CandidatePipelineService {
  // CreateSave=async(Settings)=>{
  //     return Axios.post('/Settings/DropDownListSave',Settings);
  // }
  getSkillData = async (Code) => {
    return Axios.get(`/Settings/DropDownList?Code=${Code}`);
  };
  CreateCandidate = async (data) => {

    return Axios.post("/DemandRequest/CreateCandidate", data);
  };
  GetCandidateProfileByDemandID = async (DemandID, EmpId, RoleName) => {
    return Axios.get(
      `/DemandRequest/GetCandidateProfileByDemandID?DemandID=${DemandID}&EmployeeId=${EmpId}&&RoleName=${RoleName}`
    );
  };
  MoveForward = async (
    DemandId,
    StepName,
    DemandStep,
    ProfileLevel,
    EmployeeID,
    DemandRID,
    Sla
  ) => {
    return Axios.get(
      `/DemandRequest/MoveForward?DemandId=${DemandId}&StepName=${StepName}&DemandStep=${DemandStep}&ProfileLevel=${ProfileLevel}&EmployeeID=${EmployeeID}&DemandRID=${DemandRID}&Sla=${Sla}`
    );
  };

  Reject = async (DemandId, DemandStep, Comment) => {
    return Axios.get(
      `/DemandRequest/Reject?DemandId=${DemandId}&DemandStep=${DemandStep}&Comment=${Comment}`
    );
  };

  CandidateMapping = async (CandidateID, DemandID) => {
    return Axios.get(
      `/DemandRequest/CandidateMapping?CandidateID=${CandidateID}&DemandID=${DemandID}`
    );
  };

  DeleteCandidate = async (candidateID) => {
    
    return Axios.get(
      `/DemandRequest/DeleteCandidate?candidateID=${candidateID}`
    );
  };
  filedownload = async (data) => {
    
    return Axios.post("/DemandRequest/fileSave", data, {
      responseType: "blob",
    });
  };

  SLA_AcceptScreen = async (ArrString, Step) => {
    return Axios.get(
      `/DemandRequest/SLA_AcceptScreen?ArrString=${ArrString}&Step=${Step}`
    );
  };
  GetFileSaveNotification = async (EmpID,CompanyID) => {
    return Axios.get(`/CandidatePipeLine/GetNotification?EmpID=${EmpID}&CompanyID=${CompanyID}`);
  };
}

export default CandidatePipelineService;
