import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
} from "react";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import $ from "jquery";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { AuthContext } from "../../Context/AuthContext";
import { Switch } from "@mui/material";

const AppService = new ApplicarionConfigService();
const Holidaycalendar = () => {
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [HolidaydataList, SetHolidaydataList] = useState([]);
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const Holidayname = useRef(null);
  const [Holidaydate, setHolidaydate] = useState(null);
  const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  useEffect(() => {
    AppService.GetHolidayList().then((res) => {
      console.log(res.data);
      if (res != null && res.data != null && res.data.length > 0) {
        SetHolidaydataList([...res.data]);
      } else {
        SetHolidaydataList(null);
      }
    });
  }, [Render]);

  const ExcelHolidayUpload = (e) => {
    var stepValue;
    var dateList;
    var FileDate = [];
    var dynaStep = [];
    var str = "";
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      // console.log(selectedFile.type);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);

          const workbook = XLSX.read(e.target.result, {
            type: "buffer",
            cellText: false,
            cellDates: true,
          });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const data = XLSX.utils.sheet_to_json(worksheet, {
            header: 0,
            raw: true,
            dateNF: "yyyy-mm-dd",
          });

          data != null &&
            data.map((i, obj) => {   
              dynaStep = [];
              dateList = {
                HolidayName: i.HolidayName,
                Holidaydate: i.Holidaydate,
                IsInternal: i.IsInternal,
                ModifiedBy: cookies.userID,
              };

              FileDate.push(dateList);
              str = JSON.stringify(FileDate);
            });
          SaveHoliday(str);
        };
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
        swal("Error", "Please select only Excel file types", "error");
      }
    } else {
      console.log("Please select your file");
    }
  };
  const SaveHoliday = (str) => {

    AppService.SaveHoliday(str)
      .then((res) => {
        swal("Holiday Excel upload!", "Saved Successfully", "success");
        PageRender();
      })
      .catch((res) => {
        swal("Holiday Excel!", "Failed to save", "error");
      });
  };
  const SaveEditHoliday = () => {
    var FileDate = [];
    var editdata = {
      Holidayid: Number($("#HolidayID").val()),
      HolidayName: $("#HolidayName").val(),
      Holidaydate: Holidaydate,
      ModifiedBy: Number(cookies.userID),
    };
    FileDate.push(editdata);
    var str = JSON.stringify(FileDate);
    AppService.SaveHoliday(str)
      .then((res) => {
        swal("Holiday Excel upload!", "Saved Successfully", "success");
        $("#Editform").hide();
        PageRender();
        ClosePop()
      })
      .catch((res) => {
        swal("Holiday Excel!", "Failed to save", "error");
      });
  };
  const uploadholidaybtn = () => {
    $("#HolidayUpload").click();
  };
  const EditHoliday = (id, name, Holidaydate) => {
    
    setHolidaydate(new Date(Holidaydate));
    $("#HolidayName").val(name);
    $("#HolidayID").val(id);
    $("#Editform").show();
  };
  function deleteHoliday(ele, id) {
    
    if (id == 0) {
      $(ele).closest("tr").remove();
    } else {
      var id = id;
      swal({
        title: "Are you sure?",
        text: "You won't be able Holidaydate revert this!",
        type: "warning",
        buttons: {
          confirm: {
            text: "Yes, delete it!",
            className: "btn btn-success",
          },
          cancel: {
            visible: true,
            className: "btn btn-danger",
          },
        },
      }).then((Delete) => {
        if (Delete) {
          AppService.DeleteHoliday(id).then((res) => {
            if (res.data === 1) {
              swal("Holiday!", "Deleted Successfully", "success");
              PageRender();
            } else {
              swal("Holiday!", "Failed to delete", "error");
            }
          });
        } else {
          swal.close();
        }
      });
    }
  }
  const [ButtonName, setButtonName] = useState("Add");
  const [PopName, setPopName] = useState("Add");
  const ClosePop = () => {
    $(".close").click();
    if (PopName == "Add") {
      setPopName("Edit");
    }
    if (PopName == "Edit") {
      setPopName("Add");
    }

    if (ButtonName == "Add") {
      setButtonName("Update");
    }
    if (ButtonName == "Update") {
      setButtonName("Add");
    }

    document.getElementById("holiConfiguration").reset();
  };
  return (
    <>
      <div class="row card-body">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <button
            className="btn btn-primary btn-round ml-auto  pull-right btn-sm"
            onClick={uploadholidaybtn}
            id="uploadholidaybtn"
          >
            <i className="fa fa-upload"></i>{" "}
            <span className="ml-1">Holiday upload</span>
            <input
              type="file"
              id="HolidayUpload"
              onChange={ExcelHolidayUpload}
              style={{ display: "none" }}
            />
          </button>
        </div>

        <div className="col-md-12" id="Holiday">
          {/* <div className="col-md-12" style={{ textAlign: "right" }}>
                    <button type="button" className="btn btn-sm btn-gray" onClick={Addholiday}>Add</button>
                </div> */}
          <table id="Holidaytbl" className="table">
            <thead>
              <tr>
                <th>Holiday</th>
                <th>Date</th>
                <th>Option</th>
              </tr>
            </thead>
            <tbody id="SaveHoliday">
              {HolidaydataList &&
                HolidaydataList.map((x, i) => {
                  return (
                    <tr
                      className="gradeX"
                      id="Holidayid"
                      data-pid={x.Holidayid}
                    >
                      <td>{x.HolidayName}</td>

                      <td>{x.Holidaydate}</td>
                      <td>
                        <button
                          type="button"
                          title=""
                          class="btn btn-link btn-primary btn-lg"
                          data-original-title="Edit"
                          data-bs-toggle="modal"
                          data-bs-target="#holiConfig"
                          onClick={(e) =>
                            EditHoliday(
                              x.Holidayid,
                              x.HolidayName,
                              x.HolidayEditdate
                            )
                          }
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        <a href="#" className="on-default remove-row">
                          <i
                            className="fa fa-trash "
                            title="Delete"
                            onClick={(e) => deleteHoliday(this, x.Holidayid)}
                          ></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="modal fade"
        id="holiConfig"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title">
                <span className="fw-mediumbold">{PopName} </span>
                <span className="fw-light">Dropdown value</span>
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                onClick={ClosePop}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="holiConfiguration">
                <div
                  id="Editform"
                  class="row card-body"
                  style={{ display: "none" }}
                >
                  <div className="col-lg-4 col-md-9 col-sm-12">
                    <input type="hidden" name="HolidayID" id="HolidayID" />
                    <div class="form-group">
                      <label>Holiday</label>
                      <input
                        type="text"
                        class="form-control "
                        id="HolidayName"
                        ref={Holidayname}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-9 col-sm-12">
                    <div class="form-group">
                      <label>Holiday Date</label>
                      <DatePicker
                        className="form-control"
                        selected={Holidaydate}
                        onChange={(date) => setHolidaydate(date)}
                        // showTimeSelect
                        dateFormat="dd-MM-yyyy"
                      />
                      <span style={{ color: "red" }} id="errValue"></span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-9 col-sm-12">
                    <div class="form-group">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        style={{ marginTop: "34px" }}
                        onClick={SaveEditHoliday}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Holidaycalendar;
