import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../Context/AuthContext";
import $ from "jquery";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/atlantis.min.css";
import "../../assets/css/demo.css";
// import "../../assets/js/plugin/sweetalert/sweetalert.min"
// import '../../assets/js/plugin/webfont/webfont.min.js'
import logofinal from "../../assets/Final-logo.png";
import Prof from "../../assets/img/user.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import "../SideBar/Loader.css";
import NotificationService from "../../api/NotificationService";
import AxiosServices from "../../api/AxiosService";
import { imgURL } from "../../api/AxiosService"
import dummyimg from "../../assets/img/dummyImg_1.jpg"
import AuthenticationService from "../../api/AuthenticationService";
import ProfileEditModal from "../Associate/ProfileEditModal";
import ApplicationConfigService from "../../api/ApplicationConfigService";
import CandidatePipelineService from "../../api/CandidatePipelineService";
const URLHR = new AxiosServices();

function Sidebar() {
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);

  const navigate = useNavigate();
  const AuthObject = new AuthenticationService();
  const AppService = new ApplicationConfigService();

  const ConfigurationServiceobj = new NotificationService();
  const _CandidatePipeLineService = new CandidatePipelineService();

  const [EmployeeModel, setEmployeeModel] = useState([]);
  const [selectDropdownvalue, setselectDropdownvalue] = useState("");
  const [Dropdownvalue, setDropdownvalue] = useState("");
  const [logoImage,setLogoImage]=useState('');
  const [NotifiList, setNotifiList] = useState([]);
  const [NotifiCnt, setNotifiCnt] = useState([]);
  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);
  const [ShowProfileEdit, setShowProfileEdit] = useState(false)
  const [employeeID, setEmployeeID] = useState(cookies.userID)
  const [employeeCode, setEmployeeCode] = useState(cookies.userName);
  const [flag, setFlag] = useState(true)
  const [show, setShow] = useState(false)
  const [companyID,setCompanyID]=useState(cookies.companyID);
  const [companyName,setCompanyName]=useState();

  useEffect(() => {
    _CandidatePipeLineService
    .getSkillData("COMPANYNAME")
    .then((res) => {
      var companyName = res?.data?.filter(item => companyID.includes(item.id));
      setCompanyName(companyName[0]?.value);
      console.log(res.data);
    })
    .catch((res) => { });

    var seconds = 0;
    var el = document.getElementById("licnt");

    function incrementSeconds() {
      seconds += 1;
      el.innerText = "You have been here for " + seconds + " seconds.";

      if (seconds == 5) {
        console.log("5");
      }
    }

    var cancel = setInterval(incrementSeconds, 1000);

    if (cookies.SidebarReload == 1) {
      setCookie("SidebarReload", 0, { path: "/" });
      if (cookies.Rememberme == false) {
        setCookie("ISReload", 1, { path: "/" });
      }
      window.location.reload();
    }
    var EmployeeId = cookies.userID;
    AuthObject.getSidebarDropdownList(EmployeeId)
      .then((res) => {
        // console.log(res);

        setEmployeeModel(res.data);
        setDropdownvalue(res.data.sidebarList);
        // setselectDropdownvalue(res.data.seletedList);
        setselectDropdownvalue(cookies.roleID);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getNoti();
    GetCount();
    if (cookies.FirstroleCountLogin == 1) {
      const interval = setInterval(() => {
        GetCount();
      }, 10000);
    }
    console.log('cookpri', priviledge);
  }, []);

  const ClearCnt = () => {
    ConfigurationServiceobj.Notification_ClearCnt(Number(cookies.userID))
      .then((response) => {
        GetCount();
        getNoti();
      })
      .catch((error) => { });
  };

  const SingleClearCnt = (Id) => {

    ConfigurationServiceobj.Notification_SingleClear(Number(Id))
      .then((response) => {
        GetCount();
        getNoti();
      })
      .catch((error) => { });
  };

  const getNoti = () => {
    ConfigurationServiceobj.Notification_GetNotificationById(
      Number(cookies.userID)
    )
      .then((response) => {
        var notifi = response.data.nlist;
        setNotifiList(notifi);

        // PageRender();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetCount = () => {
    ConfigurationServiceobj.Notification_GetCnt(Number(cookies.userID))
      .then((response) => {
        setNotifiCnt(response.data);
      })
      .catch((error) => { });
  };

  const ClearAll = () => {
    ConfigurationServiceobj.Notification_ClearAll(Number(cookies.userID))
      .then((response) => {
        GetCount();
        getNoti();
      })
      .catch((error) => { });
  };
    //logoimage
  useEffect(()=>{
    GetLogoImage()
  },[])


  const GetLogoImage = () => {

    AppService.GetImageDetails()
        .then((res) => {
          
            if (res.data.length > 0) {
               const logoImg = res.data.find(img=>img.ImageId ===2)
               if(logoImg){
                console.log(logoImg.ImageUrl)
                setLogoImage(logoImg.ImageUrl)
               }
            }
        })
        .catch((error) => {
            console.log(error);
        });
};


  function ReportOnchange(e) {
    this.setState({ selectValue: e.target.selectValue });
  }
  function LoadCTHrms(e) {
    setCookie("FirstroleCountLogin", 0, { path: "/" });

    var userNameObj = cookies.KuuuDecript;
    var PasswordObj = cookies.KpppDecript;
    var Role = cookies.roleID;
    var ishrmsObj = 1;
    var url = URLHR.geturl();

    var HRMSURL =
      url +
      "Login/HRRecumentIndex?UserName=" +
      userNameObj +
      "&Password=" +
      PasswordObj +
      "&Role=" +
      Role +
      "&ishrms=" +
      ishrmsObj;

    window.location.href = HRMSURL;
    // window.reload(HRMSURL);
  }

  // console.log(priviledge);
  const btnlogout = () => {
    setCookie("ISReload", 1, { path: "/" });
    logout();
    navigate("/");
    removeAllCookies()
  };
  function removeAllCookies() {
    
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + `=;expires=${new Date}`;
    }
  }

  function refreshPage() {

    window.location.reload(false);
  }

  function RolechangeID(e) {
    setselectDropdownvalue(e.target.value);
    var RoleID = e.target.value;
    // var ROleName = e.target.text;
    var ROleName = $("#SwitchRoledropdown option:selected").text();

    AuthObject.getSidebarDropdownSelected(RoleID)

      .then(
        (res) => (
          // console.log(res),
          removeCookie("UserPrivileges"),
          removeCookie("roleID"),
          removeCookie("roleName"),
          setselectDropdownvalue(RoleID),
          setCookie("roleID", RoleID, { path: "/" }),
          setCookie("roleName", ROleName, { path: "/" }),
          setCookie("UserPrivileges", res.data?.userPrivileges.split(","), {
            path: "/",
          }),
          navigate("/Dashboard", { replace: true }),
          window.location.reload()
        )
      )
      .catch((err) => {
        console.log(err);
      });
  }
  const showPopup = (e) => {
    e.preventDefault();
    setShow(true);
  }

  return (
    <>
      <div className="loader-container" style={{ display: "none" }}>
        <span
          className="loadercube"
          style={{ position: "fixed", right: "46%", bottom: "50%" }}
        ></span>
      </div>

      <div className="main-header">
        {/* <!-- Logo Header --> */}
        <div className="logo-header" data-background-color="blue">
          {/* <a href="#" className="logo">
            <img
              id="loginimgWrap"
              src={logofinal}
              alt="navbar brand"
              className="navbar-brand"
              style={{ height: "92%", width: "170px" }}
            />
          </a> */}
          <button
            className="navbar-toggler sidenav-toggler ml-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="collapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="icon-menu"></i>
            </span>
          </button>
          <button className="topbar-toggler more">
            <i className="icon-options-vertical"></i>
          </button>
          <div className="nav-toggle">
            <button className="btn btn-toggle toggle-sidebar">
              <i className="icon-menu"></i>
            </button>
          </div>
        </div>
        {/* <!-- End Logo Header --> */}

        {/* <!-- Navbar Header --> */}
        <nav
          className="navbar navbar-header navbar-expand-lg"
          data-background-color="blue2"
        >
          <div className="container-fluid">
            {/* <div className="collapse" id="search-nav">
              
              
            </div> */}
            <ul id="LogoImage" className="navbar-nav topbar-nav  align-items-center">
              <li className="nav-item">
                <a href="#" className="logo">
                  {logoImage!=null && logoImage!=undefined && logoImage!=""?
                  <img
                  id="loginimgWrap"
                  src={imgURL+logoImage}
                  alt="navbar brand"
                  className="navbar-brand"
                  style={{ maxWidth: "100%", height: "60px", maxHeight: "92%", width: "113px" }}
                />:
                <img
                  id="loginimgWrap"
                  src={logofinal}
                  alt="navbar brand"
                  className="navbar-brand"
                  style={{ maxWidth: "100%", height: "60px", maxHeight: "92%", width: "113px" }}
                />                                
                } 
                </a>
              </li>
            </ul>
            {(companyName!=null && companyName!=undefined && companyName!="")&&
            <div><b style={{color:"#fff"}}>{companyName}</b></div>
            }
            <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
              {/* <li className="nav-item toggle-nav-search hidden-caret">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#search-nav"
                  role="button"
                  aria-expanded="false"
                  aria-controls="search-nav"
                >
                  <i className="fa fa-search"></i>
                </a>
              </li> */}
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="messageDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  hidden
                >
                  <i className="fa fa-envelope"></i>
                </a>
                <ul
                  className="dropdown-menu messages-notif-box animated fadeIn"
                  aria-labelledby="messageDropdown"
                >
                  <li>
                    <div className="dropdown-title d-flex justify-content-between align-items-center">
                      Messages
                      <a href="#" className="small">
                        Mark all as read
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="message-notif-scroll scrollbar-outer">
                      <div className="notif-center">
                        <a href="#">
                          <div className="notif-img">
                            <img
                              id="loginimgWrap"
                              src={imgURL + cookies.ProfilePicture}
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Jimmy Denis</span>
                            <span className="block">How are you ?</span>
                            <span className="time">5 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              id="loginimgWrap"
                              src={imgURL + cookies.ProfilePicture}
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Chad</span>
                            <span className="block">Ok, Thanks !</span>
                            <span className="time">12 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              id="loginimgWrap"
                              src={Prof}
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Jhon Doe</span>
                            <span className="block">
                              Ready for the meeting today...
                            </span>
                            <span className="time">12 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              id="loginimgWrap"
                              src={Prof}
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Talha</span>
                            <span className="block">Hi, Apa Kabar ?</span>
                            <span className="time">17 minutes ago</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a className="see-all" href="javascript:void(0);">
                      See all messages<i className="fa fa-angle-right"></i>{" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li id="licnt" hidden></li>
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="notifDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={ClearCnt}
                >
                  <i className="fa fa-bell"></i>
                  <span className="notification">{NotifiCnt}</span>
                </a>
                <ul
                  className="dropdown-menu notif-box animated fadeIn"
                  aria-labelledby="notifDropdown"
                >
                  <li>
                    <div
                      className="dropdown-title"
                      style={{ textAlign: "right" }}
                    >
                      <span onClick={ClearAll}>Clear All</span>
                    </div>
                  </li>
                  <li className="NotificationList">
                    {NotifiList != null && NotifiList.length > 0 ? (
                      NotifiList.map((e) => {
                        return (
                          <div className="notif-center">
                            <a
                              href="/DemandIndex"
                              onClick={() => SingleClearCnt(e.notificationID)}
                            >
                              <div className="notif-icon notif-success">
                                {" "}
                                <i className="fa fa-comment"></i>{" "}
                              </div>
                              <div className="notif-content">
                                <span className="block">{e.notification}</span>
                                <span className="time">
                                  {e.notificationTime}
                                </span>
                              </div>
                            </a>
                          </div>
                        );
                      })
                    ) : (
                      <div className="notif-icon notif-success">
                        <div className="notif-center">
                          <div
                            className="notif-content m-3"
                            style={{ textAlign: "center" }}
                          >
                            <span className="block">No Notification</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  {/* <li>
                    <a className="see-all" href="javascript:void(0);">
                      See all notifications
                      <i className="fa fa-angle-right"></i>{" "}
                    </a>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="nav-link"
                  data-bs-toggle="dropdown"
                  href="#"
                  aria-expanded="false"
                  hidden
                >
                  <i className="fas fa-layer-group"></i>
                </a>
                <div className="dropdown-menu quick-actions quick-actions-info animated fadeIn">
                  <div className="quick-actions-header">
                    <span className="title mb-1">Quick Actions</span>
                    <span className="subtitle op-8">Shortcuts</span>
                  </div>
                  <div className="quick-actions-scroll scrollbar-outer">
                    <div className="quick-actions-items">
                      <div className="row m-0">
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-file-1"></i>
                            <span className="text">Generated Report</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-database"></i>
                            <span className="text">Create New Database</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-pen"></i>
                            <span className="text">Create New Post</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-interface-1"></i>
                            <span className="text">Create New Task</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-list"></i>
                            <span className="text">Completed Tasks</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-file"></i>
                            <span className="text">Create New Invoice</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="dropdown-toggle profile-pic"
                  data-bs-toggle="dropdown"
                  href="#"
                  aria-expanded="false"
                >
                  <div className="avatar-sm">
                    {cookies.ProfilePicture != 'null' ?
                      <img
                        id="loginimgWrap"
                        src={imgURL + cookies.ProfilePicture}
                        alt="Img Profile"
                        className="avatar-img rounded-circle"
                      /> : <img
                        id="loginimgWrap"
                        src={dummyimg}
                        alt="Img Profile"
                        className="avatar-img rounded-circle"
                      />

                    }
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-user animated fadeIn">
                  <div className="dropdown-user-scroll scrollbar-outer">
                    <li>
                      <div className="user-box">
                        <div className="avatar-lg">
                          {cookies.ProfilePicture != 'null' ? <img
                            id="loginimgWrap"
                            src={imgURL + cookies.ProfilePicture}
                            alt="Img Profile"
                            className="avatar-img rounded-circle"
                          /> : <img
                            id="loginimgWrap"
                            src={dummyimg}
                            alt="Img Profile"
                            className="avatar-img rounded-circle"
                          />}
                        </div>
                        <div className="u-text">
                          <h4>{cookies.employeeName}</h4>
                          <p className="text-muted">{cookies.AssociateTitle}</p>

                          {/* <a
                              href="profile.html"
                              className="btn btn-xs btn-secondary btn-sm"
                            >
                              View Profile
                            </a> */}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>

                      <Link
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#addRowModal_1"
                        onClick={(e) => showPopup(e)}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fas fa-user-edit"></i>
                        &nbsp;
                        <span>Profile Edit</span>
                      </Link>

                      <Link className="dropdown-item" to={"/ChangePassword"}>
                        <i className="fas fa-user"></i>{" "}
                        &nbsp;
                        <span>Change Password</span>
                      </Link>

                
                      <a className="dropdown-item" href="#" onClick={btnlogout}>
                        <i className="fas fa-sign-out-alt"></i>
                        &nbsp; Logout
                      </a>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        {/* <!-- End Navbar --> */}
      </div>
      {/* <!-- Sidebar --> */}
      <div className="sidebar sidebar-style-2">
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <div className="sidebar-content">
            {/* <div className="user">
              <div className="avatar-sm float-left mr-2">
                <img
                  id="loginimgWrap"
                  src={Prof}
                  alt="Img Profile"
                  className="avatar-img rounded-circle"
                />
              </div>
              <div className="info">
                <a data-bs-toggle="collapse" href="#" aria-expanded="true">
                  <span>
                    {cookies.employeeName}
                    {/* <span className="user-level">{cookies.roleName}</span> */}
            {/* <span className="">
                      <select
                        id="SwitchRoledropdown"
                        name="SwitchRoledropdown"
                        placeholder="Select Reporting Manager"
                        value={selectDropdownvalue}
                        
                        onChange={(e) => RolechangeID(e)}
                        class="form-control  filled"
                        style={{ marginTop: "5px", padding: "5px" }}
                      > */}
            {/* <option  value="-1" >
                   Select Reporting Manager
                    </option> */}
            {/* {Dropdownvalue != null && Dropdownvalue.length > 0 ? (
                          Dropdownvalue.map((item, indexs) => {
                            return (
                              <option value={item.roleID}>
                                {item.roleName}
                              </option>
                            );
                          })
                        ) : (
                          <option>No data</option>
                        )}
                      </select>
                    </span>
                  </span>
                </a>
                <div className="clearfix"></div>
              </div>
            </div> */}
            <ul className="nav nav-primary">
              {priviledge.includes("DASHBOARD") ? (
                <li className="nav-item submenu" id="Dashboard">
                  {/* <a
                   data-bs-toggle="collapse"
                   href="#dashboard"
                   className="collapsed"
                   aria-expanded="false"
                 > */}
                  <Link to={"/Dashboard"}>
                    <i className="fas fa-home"></i> <span>Dashboard</span>
                  </Link>

                  {/* </a> */}
                </li>
              ) : ""}

              {/* {priviledge.includes("TIMESHEETVIEW") ? (
                <li className="nav-item submenu" id="TimeSheet">
                  <a href="#" id="TimeSheet" onClick={(e) => LoadCTHrms(e)}>
                    <i className="fas fa-calendar-alt"></i>{" "}
                    <span>Time Sheet</span>
                  </a>
                </li>
              ) : ""} */}



              {priviledge.includes("DEMANDREQVIEW") || priviledge.includes("PROSERCHVIEW") || priviledge.includes("CANDPIPVIEW") ? (
                <li className="nav-item  submenu" id="Demandmenu">
                  <a data-bs-toggle="collapse" href="#Demand">
                    <i className="fas fa-splotch"></i>
                    <p>Demand</p>
                    <span className="caret"></span>
                  </a>
                  <div className="collapse" id="Demand">
                    <ul className="nav nav-collapse">
                      {priviledge.includes("DEMANDREQVIEW") &&
                        <li className="active" id="DemandReq">
                          <Link to={"/DemandIndex"}>
                            <i className="fas fa-layer-group"></i>{" "}
                            <span>Demand Request</span>
                          </Link>
                        </li>}
                      {priviledge.includes("CANDPIPVIEW") &&
                        <li className="active" id="CandidatePipeline" hidden>
                          <Link to={"/CandidatePipeline"}>
                            <i className="fas fa-users"></i>{" "}
                            <span>Candidate Pipeline</span>
                          </Link>
                        </li>}
                      {priviledge.includes("PROSERCHVIEW") &&
                        <li className="active" id="ProfileSearch">
                          <Link to={"/ProfileSearch"}>
                            <i className="fas fa-search"></i>{" "}
                            <span>Profile Search</span>
                          </Link>
                        </li>}
                    </ul>
                  </div>
                </li>
              ) : ("")

              }







              {priviledge.includes("REPORTVIEW")||priviledge.includes("LOGVIEW") || priviledge.includes("DEMREPVIEW") ? (
                <li className="nav-item  submenu" id="ReportMenu">
                  <a data-bs-toggle="collapse" href="#Report">
                    <i class="fa fa-flag" aria-hidden="true"></i>
                    <p>Report</p>
                    <span className="caret"></span>
                  </a>
                  <div className="collapse" id="Report">
                    <ul className="nav nav-collapse">
                      {priviledge.includes("LOGVIEW") ? (
                        <li id="LoginTrackerHistory">
                          <Link to={"/LoginTracker"}>
                            <i class="fa fa-history" aria-hidden="true"></i>{" "}
                            <span>Login Activity History</span>
                          </Link>
                        </li>) : ""}
                      {priviledge.includes("DEMREPVIEW") ? (
                        <li id="DemandReport">
                          <Link to={"/DemandReport"}>
                            <i class="fa fa-history" aria-hidden="true"></i>{" "}
                            <span>Demand Report</span>
                          </Link>
                        </li>) : ""}

                    </ul>
                  </div>
                </li>
              ) : ""}


              {priviledge.includes("EMPVIEW")||priviledge.includes("EMAILTEMPLATEVIEW") ||priviledge.includes("ROLEVIEW")||priviledge.includes("APPCONFIGVIEW") ? (
                <li className="nav-item  submenu" id="ConfigurationMenu">
                  <a data-bs-toggle="collapse" href="#Configuration">
                    <i className="fas fa-cog"></i>
                    <p>Configuration</p>
                    <span className="caret"></span>
                  </a>
                  <div className="collapse" id="Configuration">
                    <ul className="nav nav-collapse">
                      {priviledge.includes("EMPVIEW") ? (
                        <li id="Employee">
                          <Link to={"/Associate"}>
                            <i className="fas fa-user"></i>{" "}
                            <span>Associate</span>
                          </Link>
                        </li>
                      ) : ""}

                      {priviledge.includes("EMAILTEMPLATEVIEW") ? (
                        <li id="EmailTemplate">
                          <Link to={"/Emailtemplate"}>
                            <i className="fab fa-fly"></i>{" "}
                            <span>Email Template</span>
                          </Link>
                        </li>) : ""}


                      {priviledge.includes("ROLEVIEW") ? (
                        <li id="roleConf">
                          <Link to={"/Role"}>
                            <i className="fas fa-compact-disc"></i>{" "}
                            <span>Role Configuration</span>
                          </Link>
                        </li>) : ""}



                      {priviledge.includes("APPCONFIGVIEW") ? (
                        <li id="appconf">
                          <Link to={"/ApplicationIndex"}>
                            <i className="fas fa-desktop"></i>{" "}
                            <span>Application Configuration</span>
                          </Link>
                        </li>) : ""}


                    </ul>
                  </div>
                </li>) : ""}


            </ul>
          </div>
        </div>
      </div>
      
       <div
        className="modal fade "
        id="addRowModal_1"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        data-bs-backdrop="static"
      //style={{ display: "block", overflowY: "scroll" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title">
                <span className="fw-mediumbold">Edit Associate</span>
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={refreshPage}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            {show &&
              <ProfileEditModal employeeID={employeeID} Employeecode={employeeCode} flag={flag} setShow={setShow} />
            }
            </div>
          </div>
        </div>
      </div>
       
    
      
    </>
  );
}
export default Sidebar;
