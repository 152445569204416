import React, { useEffect, useState } from "react";
import $ from "jquery";
import swal from "sweetalert";
import ApplicarionConfigService from '../../api/ApplicationConfigService';
import AssociateService from "../../api/AssociateService";
const ConfigurationServiceobj = new ApplicarionConfigService();

const EmployeeCodeConfig = () => {
    const [empPreffixCode, setEmpPreffixCode] = useState("");
    const [AutoGenrateEmployeeCode, setAutoGenrateEmployeeCode] = useState(0);
    const AssociateServiceObj = new AssociateService();
    const EmployeeCode= AutoGenrateEmployeeCode -1 
    useEffect(() => {
        ConfigurationServiceobj.GetEmpPreffixCode()
            .then((response) => {
                setEmpPreffixCode(response.data);
            })
            .catch((error) => {
                console.error("Error fetching employee prefix code:", error);
            });
            GenrateEmpCode()
    }, []);

    const saveEmpPreffix = (e) => {
        e.preventDefault();
        const empPreCode = Number($('#EmpPreCode').val());
        ConfigurationServiceobj.SaveEmpPreffixCode(empPreCode)
            .then((response) => {
                swal('Details', "Updated Successfully!", "success");
            })
            .catch((res) => {
                swal("Idle And Session Expire Time", "Failed to save!", "error");
            });
    };

    const numberOnly = (e) => {
        const inputChar = String.fromCharCode(e.charCode);
        if (!/^\d+$/.test(inputChar) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
            e.preventDefault();
        }
    };
    function GenrateEmpCode() {
        AssociateServiceObj.GetAutogenrateEmployeeCode().then((res) => {
    
          setAutoGenrateEmployeeCode(res.data)
        })
          .catch((err) => {
            console.log(err);
          });
      }

    return (
        <>
            <form onSubmit={saveEmpPreffix}>
                <div className="row card-body">
                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="row">
                                {/* <div className="col-md-5">
                                    <label>Set Employee Prefix Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="EmpPreCode"
                                        placeholder=""
                                        value={empPreffixCode}
                                        onKeyPress={numberOnly}
                                        maxLength={2}
                                        onChange={(e) => {
                                            const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
                                            setEmpPreffixCode(onlyNumbers);
                                        }}
                                    />
                                </div> */}
                                <div className="col-md-5">
                                    <label>Lastest Employee Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={EmployeeCode}
                                        disabled
                                    />
                                </div>
                                {/* <div className="col-md-2">
                                <button type="submit" className="btn btn-primary btn-sm" style={{ marginTop: '30px' }}>Save</button>
 
                                </div> */}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </form>
        </>
    );
};

export default EmployeeCodeConfig;
