import React, { useEffect, useState } from "react";
import $ from "jquery";
import ApplicarionConfigService from '../../api/ApplicationConfigService';
import swal from "sweetalert";

const ConfigurationServiceobj = new ApplicarionConfigService();
const IdleTimeConfig = () => {
    const [IdleTime, setIdleTime] = useState({})
    useEffect(() => {
        ConfigurationServiceobj.GetConfigurationData()
            .then((response) => {
                setIdleTime(response.data.idleTime);
            })
            .catch((error) => {
            });
    }, []);

    const saveIdle = (e) => {
        
        e.preventDefault();
        const hours = Number($('#Hours').val());
        const minutes = Number($('#miunte').val());
        if (minutes === 0) {
            // Display an error message
            swal("Error", "Minutes should be greater than 0", "error");
            return; // Stop further execution
        }
        var data = {
            IDLE_HOURS: hours,
            IDLE_MINUTES: minutes
        }
        ConfigurationServiceobj.SaveIdleTime(data)
            .then((response) => {
                swal("Idle And Session Expire Time", "Saved Successfully!", "success");
            })
            .catch((res) => {
                swal("Idle And Session Expire Time", "Failed to save!", "error");
            });
    }
    return (
        <>
            <form onSubmit={saveIdle}>
                <div class="row card-body">
                    <div className="col-lg-4 col-md-9 col-sm-12">
                        <div class="form-group">
                            <label>Session Expire Time</label>
                            <div className="row">
                                <div className="col-6" hidden>
                                    <input
                                        type="text"
                                        class="form-control "
                                        id="Hours"
                                        placeholder="Hours"
                                        defaultValue={IdleTime.idlE_HOURS}
                                    />
                                </div>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        class="form-control "
                                        id="miunte"
                                        placeholder="Minutes"
                                        defaultValue={IdleTime.idlE_MINUTES}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-9 col-sm-12">
                        <label></label>
                        <div class="form-group">
                            <button type="submit" className="btn btn-primary btn-sm" style={{ marginTop: '10px' }}>Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )

}

export default IdleTimeConfig;