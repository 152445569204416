
// /*
// ***********************************************************************
//  * Author		:	Tinu G
//  * Create date	:	24/10/2022
//  * Module       :   Email Template
// ***********************************************************************
//  * */
import EmailTemplateList from "./EmailTemplateList";
import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect, useReducer } from "react";
import { AuthContext } from "../../Context/AuthContext";
import $ from 'jquery'


function EmailTemplate() {
 // var UserValidate = JSON.parse(localStorage.getItem("UserDetails"));
  let redriect = useNavigate();
  const { cookies, logout, data, removeCookie, setCookie } =
  useContext(AuthContext);
  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);

  const navigateToContacts = (val) => {
    // 
    //   redriect.push({
    //     pathname: "/EmailTemplateCreate",
    //   state: val
    //   });
    // redriect('/EmailTemplateCreate');
    redriect("/EmailTemplateCreate", { state: val });
  };


  useEffect(() => {
    $('.submenu').removeClass('active');
    $('#ConfigurationMenu').addClass('active');

    $('.collapse').removeClass('show')
    $('#Configuration').addClass('show')

    $(".submenu li").removeClass("active");

    $("#EmailTemplate").addClass("active");
  }, []);

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <h3 className="card-title">Email Template List</h3>
                      {priviledge.includes("EMAILTEMPLATECREATE")&&
                       <button
                       className="btn btn-primary btn-round ml-auto btn-sm"
                       data-bs-toggle="modal"
                       data-bs-target="#addRowModal"
                       onClick={() => navigateToContacts(0)}
                     >
                       <i class="fa fa-plus"></i>
                       <span className='ml-1'>Add Email Template</span>
                     </button>
                      }
                     

                    </div>

                    {/*      
          <div className="col-auto float-end ms-auto"> */}
                    {/* 
          {UserValidate.userPrivileges.includes(
                        "EMAILTEMPLATECREATE"
                      ) ? (
                        <a className="btn add-btn" onClick={() => navigateToContacts(0)}>
              <i className="fa fa-plus"></i> Add Email Template
            </a>
                      ) : (
                        <></>
                      )}
 */}

                    {/* <a className="btn add-btn" onClick={() => navigateToContacts(0)}>
              <i className="fas fa-cog"></i> Add Email Template
            </a> */}
                    {/* <div className="view-icons">
                    <a href="employees.html" className="grid-view btn btn-link active"><i className="fa fa-th"></i></a>
                    <a href="employees-list.html" className="list-view btn btn-link"><i className="fa fa-bars"></i></a>
                </div> */}
                    {/* 
<a
                        href="#"
                        className="btn btn-primary btn-round ml-auto"
                        data-bs-toggle="modal"
                        data-bs-target="#addRowModal"
                        onClick={() => navigateToContacts(0)}
                      >
                        <i className="fa fa-plus"></i>
                        Add Email Template
                      </a>
          </div> */}
                  </div>

                  <EmailTemplateList></EmailTemplateList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailTemplate;
