import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import ApplicarionConfigService from '../../api/ApplicationConfigService';
import { useForm } from 'react-hook-form';
import swal from "sweetalert";

const ConfigurationServiceobj = new ApplicarionConfigService();


const BusinessHrs = (props) => {
    var temp1 = false

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
        clearErrors
    } = useForm();

   
    const onKeyDown = (event) => {
        if (event.code === 'Space') event.preventDefault()
    }
    const savebHrs = (data) => {
        console.log(data);
        
      
        ConfigurationServiceobj.SaveBusinessHrs(data)
            .then((response) => {
                swal("Business Hours","Saved Successfully!","success");
                // Toast('Mail Config Saved Successfully', 'success');
            })
            .catch((res) => {
                swal("Business Hours","Failed to save!","error");
                // Toast(res.response.data.message, 'error');
            });
    }

    useEffect(() => {
        ConfigurationServiceobj.GetConfigurationData()
            .then((response) => {

                var emaildata = response.data.businessHours;

                {
                  
                    Object.keys(emaildata).map((key, i) => (

                        setValue(key, emaildata[key])

                    ))
                }


              
            })
            .catch((error) => {
                // Toast('Something Went Wrong', 'error');
            });
    }, []);

    return (
        <>

            <div>
                <form onSubmit={handleSubmit(savebHrs)}>
                    <div className="row">
                       



                       

                        <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                            <div className="form-group mb-3">
                                <label className="label" for="name">Start Hours<span className="text-danger">*</span></label>
                                <input
                                     type="text"
                                  
                                    name='businesS_START_HRS'
                                    className="form-control"
                                    placeholder="Start Hours"
                                     {...register("businesS_START_HRS", {
                                    required: "Business Start Hours is Required",


                                })}
                                    onKeyDown={(event)=>onKeyDown(event)}
                                    onChange={() => setError("businesS_START_HRS", "")}
                                
                                />
                                {errors.businesS_START_HRS && <span className='text-danger'>{errors.businesS_START_HRS.message}</span>}
                            </div>
                        </div>
                         <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                            <div className="form-group mb-3">
                                <label className="label" for="name">End Hours<span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    
                                  
                                    name='businesS_END_HRS'
                                    className="form-control"
                                    placeholder="End Hours"
                                    {...register("businesS_END_HRS", {
                                    required: "Business End Hours is Required",


                                })}
                                    onKeyDown={(event)=>onKeyDown(event)}
                                    onChange={() => setError("businesS_END_HRS", "")}
                                
                                />
                                {errors.businesS_END_HRS && <span className='text-danger'>{errors.businesS_END_HRS.message}</span>}
                            </div>
                        </div>

                       


                    </div>

                      <div className="text-right">
                        <button className="btn btn-primary btn-sm" onClick={() => handleSubmit()}><i className="la la-save"></i> Save</button>

                    </div>
                </form>
            </div>

        </>
    )
}
export default BusinessHrs 