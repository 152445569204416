import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import ApplicarionConfigService from '../../api/ApplicationConfigService';
import { useForm } from 'react-hook-form';
import swal from "sweetalert";

const ConfigurationServiceobj = new ApplicarionConfigService();


const EmailConfig = (props) => {
    var temp1 = false

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
        clearErrors
    } = useForm();

    const [passwordtoggle, setPasswordToggle] = useState(true)
    const passwordToggle = () => {
        
        
        setPasswordToggle(!passwordtoggle)
    }
    const onKeyDown = (event) => {
        if (event.code === 'Space') event.preventDefault()
      }
    const onSubmit = (data) => {

        var temp = "";
        data.emaiL_SSL ? data.emaiL_SSL = "true" : data.emaiL_SSL = "false";
        ConfigurationServiceobj.SaveEmailConfiguration(data)
            .then((response) => {
                swal("Email Configuration","Saved Successfully!","success");
                // Toast('Mail Config Saved Successfully', 'success');
            })
            .catch((res) => {
                swal("Email Configuration","Failed to save!","error");
                // Toast(res.response.data.message, 'error');
            });
    }

    useEffect(() => {
        ConfigurationServiceobj.GetConfigurationData()
            .then((response) => {

                var emaildata = response.data.emailConfig;

                {
                    { emaildata.emaiL_SSL == 'false' ? emaildata.emaiL_SSL = false : emaildata.emaiL_SSL = true }
                    Object.keys(emaildata).map((key, i) => (

                        setValue(key, emaildata[key])

                    ))
                }


              
            })
            .catch((error) => {
                // Toast('Something Went Wrong', 'error');
            });
    }, []);

    return (
        <>

            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                            <div className="form-group mb-3">
                                <label className="label" for="name">Server Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    className="form-control"
                                    name='emaiL_SERVER'
                                    placeholder="Server Name"
                                    {...register("emaiL_SERVER", {
                                        required: "Server Name is Required",

                                    })}
                                    onKeyDown={(event)=>onKeyDown(event)}
                                    onChange={() => setError("emaiL_SERVER", "")}
                              
                                />
                                {errors.emaiL_SERVER && <span className='text-danger'>{errors.emaiL_SERVER.message}</span>}
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                            <div className="form-group mb-3">
                                <label className="label" for="name">Email ID <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    className="form-control"
                                    name='emaiL_ID'
                                    placeholder="Email ID"
                                    {...register("emaiL_ID", {
                                        required: "Email ID is Required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Please Enter a Valid Email ID"
                                        }
                                    })}
                                    onKeyDown={(event)=>onKeyDown(event)}
                                    onChange={() => setError("emaiL_ID", "")}
                                
                                />
                                {errors.emaiL_ID && <span className='text-danger'>{errors.emaiL_ID.message}</span>}
                            </div>
                        </div>



                        <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4" style={{postion:"relative"}}>
                            <div className="form-group mb-3">
                                <label className="label" for="name">Password <span className="text-danger">*</span></label>
                                <input
                                    type={passwordtoggle ? "password":"text"}
                                    maxLength={100}
                                    name='emaiL_PASSWORD'
                                    className="form-control"
                                    placeholder="Password"
                                    {...register("emaiL_PASSWORD", {
                                        required: "Password is Required",

                                    })}
                                    onKeyDown={(event)=>onKeyDown(event)}
                                    onChange={() => setError("emaiL_PASSWORD", "")}
                               
                                />
                                 <div className='email-password'>
                                <span onClick={() => passwordToggle()}>{passwordtoggle}</span>
                                </div>
                                {errors.emaiL_PASSWORD && <span className='text-danger'>{errors.emaiL_PASSWORD.message}</span>}
                               
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                            <div className="form-group mb-3">
                                <label className="label" for="name">Port <span className="text-danger">*</span></label>
                                <input
                                    type="number"
                                    valueAsNumber={true}
                                    max={1000}
                                    // value={EmailConfigObj.emaiL_PORT}
                                    name='emaiL_PORT'
                                    className="form-control"
                                    placeholder="Port"
                                    {...register("emaiL_PORT", {
                                        required: "Port is Required",
                                        pattern: {
                                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                            message: "Port Should be Numbers"
                                        }

                                    })}
                                    onKeyDown={(event)=>onKeyDown(event)}
                                    onChange={() => setError("emaiL_PORT", "")}
                                
                                />
                                {errors.emaiL_PORT && <span className='text-danger'>{errors.emaiL_PORT.message}</span>}
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
                            <div className="form-group mb-3">
                                <label className="label" for="name"></label><br></br>


                                {register.emaiL_SSL == "false" ? temp1 = false : temp1 = true}
                                <input
                                    type="checkbox"

                                    name='emaiL_SSL'
                                    {...register("emaiL_SSL")}
                                    // value={register.emaiL_SSL}
                                    value={temp1}
                                    // checked={register.emaiL_SSL == "true" ? true : false}
                                    onChange={() => setError("emaiL_SSL", "")}
                                
                                />{' '}
                                <label className="label" for="name">Enable SSL</label>
                            </div>
                        </div>


                    </div>

                    <div className="text-right">
                        <button className="btn btn-primary btn-sm" onClick={() => handleSubmit()}><i className="la la-save"></i> Save</button>

                    </div>
                </form>
            </div>

        </>
    )
}
export default EmailConfig 