import AxiosServices from "./AxiosService";

const Axios = new AxiosServices();

class ApplicarionConfigService {
  CreateSave = async (Settings) => {
    return Axios.post("/Settings/DropDownListSave", Settings);
  };
  getData = async (Code) => {
    return Axios.get(`/Settings/DropDownList?Code=${Code}`);
  };
  Deletedata = async (Settings) => {
    return Axios.post("/Settings/DropDownListDelete", Settings);
  };
  Create = async (ID) => {
    return Axios.get("/Settings/DropDownListDelete", ID);
  };
  DropDownTypeList = async () => {
    return Axios.get(`/Settings/DropDownTypeList`);
  };
  GetStepDropDownList = async () => {
    return Axios.get(`/Settings/GetStepDropDownList`);
  };

  GetConfigurationData() {
    return Axios.get("Settings/GetConfigurationData");
  }

  SaveEmailConfiguration(data) {
    return Axios.post("Settings/SaveEmailConfiguration", data);
  }

  Getcheckexitsornot(obj) {
    return Axios.post("Settings/Getcheckexitsornot", obj);
  }
  SaveSharedFolder(data) {
    return Axios.post("Settings/SaveSharedFolder", data);
  }
  SaveSLAConfiguration(data) {
    return Axios.post("Settings/SaveSLAConfiguration", data);
  }
  SaveBusinessHrs(data) {
    
    return Axios.post("Settings/SaveBusinessHrs", data);
  }

  GetHolidayList = async () => {
    return Axios.get(`/Settings/Holidaylist`);
  };

  SaveHoliday(data) {
    return Axios.post(`Settings/SaveHoliday?Dynamiccol=${data}`);
  }
  DeleteHoliday(id) {
    return Axios.post(`Settings/DeleteHoliday?id=${id}`);
  }

  GetIdleTime() {
    return Axios.get("Settings/GetIdleTime");
  }
  SaveIdleTime(data) {
    
    return Axios.post("Settings/SaveIdleTime", data);
  }
  GetLoginHours(Employee, startDate, todate,companyID,roleName) {
    return Axios.get(
      `Settings/LoginHours?Employee=${Employee}&startDate=${startDate}&todate=${todate}&CompanyID=${companyID}&roleName=${roleName}`
    );
  }
  GetFormMandatoryField() {
    return Axios.get("Settings/GetFormMandatoryField");
  }
  UpdateMandatoryField(Ischecked, FormFieldValidationID) {
    return Axios.get(
      `Settings/UpdateMandatoryField?FormFieldValidationID=${FormFieldValidationID}&IsMandatory=${Ischecked}`
    );
  }
  DownloadProfileExcel() {
    return Axios.get("Settings/DownloadProfileExcel", { responseType: "blob" });
  }
  GetDemandReport(startDate, todate,companyID,roleName) {
    return Axios.get(
      `Settings/GetDemandReport?startDate=${startDate}&todate=${todate}&CompanyID=${companyID}&roleName=${roleName}`
    );
  }
  CheckLevelSortOrder(obj) {
    return Axios.post("Settings/GetSortOrdercheckexitsornot", obj);
  }

  GetEmpPreffixCode() {
    return Axios.get("Settings/GetEmpPreffixCode");
  }
  SaveEmpPreffixCode(empPreCode){
    
    return Axios.post(`Settings/SaveEmpPreffixCode?empPreCode=${empPreCode}`);
  }
  SaveUpdateCompany(obj) {
    return Axios.post("Settings/SaveUpdateCompany", obj);
  }

  GetCompnayDetails(obj) {
    return Axios.get(`Settings/GetCompnayDetails`);
  }

  DeleteCompany(companyID) {
    return Axios.post(`Settings/DeleteCompany?companyID=${companyID}`);
  }
   //LocationConfig
   SaveUpdateLocation(obj) {
    return Axios.post("Settings/SaveUpdateLocation", obj);
  }

  GetLocationDetails() {
    return Axios.get(`Settings/GetLocationDetails`);
  }

  DeleteLocation(locationId) {
    return Axios.post(`Settings/DeleteLocation?locationId=${locationId}`);
  }

  CheckCompanyCodeExists(CompanyCode,CompanyID){
    return Axios.get(`Settings/CheckCompanyCodeExists?CompanyCode=${CompanyCode}&CompanyID=${CompanyID}`)
  }
  GetImageDetails() {
    return Axios.get(`Settings/GetImageDetails`);
  }
  SaveImage=async (formdata,imageId)=>{
        
    try {
         const response = await Axios
              .post(`Settings/SaveImage?imageId=${imageId}`,formdata);
         return response.data;
    } catch (err) {
         console.log(err);
    }
}
CheckSharePointExistsPath(SharePointLibrary,SharePointFolderPath,OnChengPath){
  return Axios.get(`CandidatePipeLine/Check_SharePointExistsPath?SharePointLibrary=${SharePointLibrary}&SharePointFolderPath=${SharePointFolderPath}&OnChengPath=${OnChengPath}`)
}

}

export default ApplicarionConfigService;
