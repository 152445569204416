import React ,{useEffect}from "react";
import $ from "jquery";

function RoleIndex() {
  
    useEffect(() => {
      $('.submenu').removeClass('active');
      $('#ConfigurationMenu').addClass('active');
  
      $('.collapse').removeClass('show')
      $('#Configuration').addClass('show')
  
      $('.submenu li').removeClass('active');
      $('#roleConf').addClass('active');
  
    });
  return (
    <>
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <h4 class="card-title">Role </h4>
                      <button
                        class="btn btn-primary btn-round ml-auto"
                        data-bs-toggle="modal"
                        data-bs-target="#addRowModal"
                      >
                        <i class="fa fa-plus"></i>
                        Add
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    {/* <!-- Modal --> */}
                    <div
                      class="modal fade"
                      id="addRowModal"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                          <div class="modal-header no-bd">
                            <h5 class="modal-title">
                              <span class="fw-mediumbold">Add Role</span>
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <form>
                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group ">
                                    <label>Role Name <span className="text-danger" >*</span></label>
                                    <input
                              id="addName"
                              type="text"
                              class="form-control"
                              placeholder="Role Name"
                            />
                                
                                  </div>
                                </div>

                                <div class="col-sm-12">
                                  <table class="table table-striped custom-table">
                                    <thead>
                                      <tr>
                                        <th>Module Permission</th>
                                        <th class="text-center">View</th>
                                        <th class="text-center">Edit</th>
                                        <th class="text-center">Create</th>
                                        <th class="text-center">Delete</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Employee</td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input type="checkbox" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Role</td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input type="checkbox" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Bulk Upload</td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input type="checkbox" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Configuration</td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input type="checkbox" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Candidate Pipeline</td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                        <td class="text-center">
                                          <input checked="" type="checkbox" />
                                        </td>
                                      </tr>
                                    
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div class="modal-footer no-bd">
                            <button
                              type="button"
                              id="addRowButton"
                              class="btn btn-primary"
                            >
                              Add
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <table
                        id="add-row"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Role Name</th>
                            <th>Status</th>
                            <th style={{ width: "10%", textAlign:"center" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>SPOCs</td>
                            <td>Active</td>

                            <td>
                              <div class="form-button-action">
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-primary btn-lg"
                                  data-original-title="Edit"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Super Admin</td>
                            <td>Active</td>

                            <td>
                              <div class="form-button-action">
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-primary btn-lg"
                                  data-original-title="Edit"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>HR Admin</td>
                            <td>Active</td>

                            <td>
                              <div class="form-button-action">
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-primary btn-lg"
                                  data-original-title="Edit"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>PMO</td>
                            <td>Active</td>

                            <td>
                              <div class="form-button-action">
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-primary btn-lg"
                                  data-original-title="Edit"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Recruiters</td>
                            <td>Active</td>

                            <td>
                              <div class="form-button-action">
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-primary btn-lg"
                                  data-original-title="Edit"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>TLs</td>
                            <td>Active</td>

                            <td>
                              <div class="form-button-action">
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-primary btn-lg"
                                  data-original-title="Edit"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RoleIndex;
