import React, { useContext, useEffect } from "react";
import $ from "jquery";
import DropdownList from "./DropDownList";
import SLA from "./SLA";
import EmailConfig from "./EmailConfig";
import SharedFolder from "./SharedFolder";
import BusinessHrs from "./BusinessHrs";
import HolidayCalendar from "./HolidayCalendar";
import IdleTimeConfig from "./IdleTimeConfig";
import LoginTracker from "./LoginTracker";
import FormValidationConfig from "./FormValidationConfig";

import EmployeeCodeConfig from "./EmployeeCodeConfig"
import LocationConfig from "./LocationConfig"
import ImageConfig from "./ImageConfig";

import CompanyConfig from "./CompanyConfig";
import { AuthContext } from "../../Context/AuthContext";


function ApplicationIndex() {
  const { cookies,setCookie} = useContext(AuthContext);
  useEffect(() => {
    $(".submenu").removeClass("active");
    $("#ConfigurationMenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Configuration").addClass("show");

    $(".submenu li").removeClass("active");
    $("#appconf").addClass("active");
    debugger
    if(cookies?.ApplicationConfigActiveTab!=null && cookies?.ApplicationConfigActiveTab!=undefined && cookies?.ApplicationConfigActiveTab!="")
    {
      var ActiveTab=cookies?.ApplicationConfigActiveTab
      const element = document.getElementById(ActiveTab);
      element.click();
    }
  },[]);
  const handleClick=(e)=>{
    var id=e?.target?.id
    setCookie("ApplicationConfigActiveTab",id,{path:"/"});
  }
  return (
    <>
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Application Configuration</h4>
              </div>
              <div class="card-body">
                <ul
                  class="nav nav-pills nav-secondary"
                  id="pills-tab"
                  role="tablist"
                >
                <li class="nav-item submenu">
                    <a
                      class="nav-link active show"
                      id="pills-companyConfig-tab"
                      data-bs-toggle="pill"
                      href="#pills-companyConfig"
                      role="tab"
                      aria-controls="pills-companyConfig"
                      aria-selected="true"
                      onClick={handleClick}
                    >
                      Company Config
                    </a>
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-Dropdown-tab"
                      data-bs-toggle="pill"
                      href="#pills-Dropdown"
                      role="tab"
                      aria-controls="pills-Dropdown"
                      aria-selected="true"
                      onClick={handleClick}
                    >
                      Dropdown Config
                    </a>
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-SLA-tab"
                      data-bs-toggle="pill"
                      href="#pills-SLA"
                      role="tab"
                      aria-controls="pills-SLA"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      SLA
                    </a>
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-Email-tab"
                      data-bs-toggle="pill"
                      href="#pills-Email"
                      role="tab"
                      aria-controls="pills-Email"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      Email Config
                    </a>
                  </li>

                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-Shared-tab"
                      data-bs-toggle="pill"
                      href="#pills-Shared"
                      role="tab"
                      aria-controls="pills-Shared"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      FTP Server
                    </a>
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-Business-tab"
                      data-bs-toggle="pill"
                      href="#pills-Business"
                      role="tab"
                      aria-controls="pills-Business"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      Business Hours
                    </a>
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-Holiday-tab"
                      data-bs-toggle="pill"
                      href="#pills-Holiday"
                      role="tab"
                      aria-controls="pills-Holiday"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      Holiday Calendar
                    </a>
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-IdleTime-tab"
                      data-bs-toggle="pill"
                      href="#pills-IdleTime"
                      role="tab"
                      aria-controls="pills-IdleTime"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      Idle Time
                    </a>
                  </li>
                  <li class="nav-item submenu" hidden>
                    <a
                      class="nav-link"
                      id="pills-LoginTracker-tab"
                      data-bs-toggle="pill"
                      href="#pills-LoginTracker"
                      role="tab"
                      aria-controls="pills-LoginTracker"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      Login Tracker
                    </a>
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-MandatoryField-tab"
                      data-bs-toggle="pill"
                      href="#pills-MandatoryField"
                      role="tab"
                      aria-controls="pills-MandatoryField"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      Form Validate
                    </a>
                    
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-EmployeeCodeConfig-tab"
                      data-bs-toggle="pill"
                      href="#pills-EmployeeCodeConfig"
                      role="tab"
                      aria-controls="pills-EmployeeCodeConfig"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      EmpCode Config
                    </a>
                    
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-LocationConfig-tab"
                      data-bs-toggle="pill"
                      href="#pills-LocationConfig"
                      role="tab"
                      aria-controls="pills-LocationConfig"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      Location Config
                    </a>
                    
                  </li>
                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-ImageConfig-tab"
                      data-bs-toggle="pill"
                      href="#pills-ImageConfig"
                      role="tab"
                      aria-controls="pills-ImageConfig"
                      aria-selected="false"
                      onClick={handleClick}
                    >
                      Image Config
                    </a>
                    
                  </li>
                </ul>
                <div class="tab-content mt-2 mb-3" id="pills-tabContent">
                <div
                    class="tab-pane fade active show"
                    id="pills-companyConfig"
                    role="tabpanel"
                    aria-labelledby="pills-companyConfig-tab"
                  >
                    <CompanyConfig></CompanyConfig>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-Dropdown"
                    role="tabpanel"
                    aria-labelledby="pills-Dropdown-tab"
                  >
                    <DropdownList></DropdownList>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-SLA"
                    role="tabpanel"
                    aria-labelledby="pills-SLA-tab"
                  >
                    <SLA></SLA>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-Email"
                    role="tabpanel"
                    aria-labelledby="pills-Email-tab"
                  >
                    <EmailConfig></EmailConfig>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-Shared"
                    role="tabpanel"
                    aria-labelledby="pills-Shared-tab"
                  >
                    <SharedFolder></SharedFolder>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-Business"
                    role="tabpanel"
                    aria-labelledby="pills-Email-tab"
                  >
                    <BusinessHrs></BusinessHrs>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-Holiday"
                    role="tabpanel"
                    aria-labelledby="pills-Holiday-tab"
                  >
                    <HolidayCalendar></HolidayCalendar>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-IdleTime"
                    role="tabpanel"
                    aria-labelledby="pills-IdleTime-tab"
                  >
                    <IdleTimeConfig></IdleTimeConfig>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-LoginTracker"
                    role="tabpanel"
                    aria-labelledby="pills-LoginTracker-tab"
                  >
                    <LoginTracker></LoginTracker>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-MandatoryField"
                    role="tabpanel"
                    aria-labelledby="pills-MandatoryField-tab"
                  >
                    <FormValidationConfig></FormValidationConfig>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-EmployeeCodeConfig"
                    role="tabpanel"
                    aria-labelledby="pills-EmployeeCodeConfig-tab"
                  >
                    <EmployeeCodeConfig></EmployeeCodeConfig>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-LocationConfig"
                    role="tabpanel"
                    aria-labelledby="pills-LocationConfig-tab"
                  >
                  <LocationConfig></LocationConfig>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-ImageConfig"
                    role="tabpanel"
                    aria-labelledby="pills-ImageConfig-tab"
                  >
                  <ImageConfig></ImageConfig>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ApplicationIndex;
