import React,{useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { AuthContext } from "../../Context/AuthContext";
import { AuthContext } from './Context/AuthContext';
import { useNavigate } from "react-router";

export const IdleTimeOutModal = ({ showModal, handleContinue, handleLogout, remainingTime }) => {
    const { logout, setCookie } =
        useContext(AuthContext);
        const navigate = useNavigate();
    if (showModal) {
        setCookie("ISReload", 1, { path: "/" });
        logout();
        navigate("/");
    }
    return (
        <></>
        // <Modal show={showModal} onHide={handleContinue}>
        //     <Modal.Header closeButton>
        //         <Modal.Title>You Have Been Idle!</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>Your session is Timed Out. You want to stay?</Modal.Body>
        //     <Modal.Footer>
        //         <Button variant="danger" onClick={handleLogout}>
        //             Logout
        //         </Button>
        //         <Button variant="primary" onClick={handleContinue}>
        //             Continue Session
        //         </Button>
        //     </Modal.Footer>
        // </Modal>
    )
}