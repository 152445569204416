// /*
// ***********************************************************************
//  * Author		:	Tinu G
//  * Create date	:	24/10/2022
//  * Module       :   Email Template
// ***********************************************************************
//  * */

import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import JoditEditor from "jodit-react";
import FormControl from "@mui/material/FormControl";
import $ from "jquery";
import { AuthContext } from "../../Context/AuthContext";
import EmailtemplateServie from "../../api/EmailtemplateServie";
import { useNavigate } from "react-router-dom";
// import { useQuill } from "react-quilljs";
// import "quill/dist/quill.snow.css";
import AssociateService from "../../api/AssociateService";
import { display } from "@mui/system";

const EmailtemplateServieObj = new EmailtemplateServie();
const ApplicationConfiguration = (props) => {
  const location = useLocation();
  const emid = location.state;
  const [mId, Setmid] = useState(0);
  const [mfId, Setmfid] = useState(0);
  const [age, setAge] = useState();

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // const { quill, quillRef } = useQuill();

  const [funLists, setfunDownList] = useState([
    {
      text: "No Data",
      value: "",
      moduleFunctionalityID: 0,
      moduleFunctionalityName: "",
    },
  ]);

  const { data, setCookie, removeCookie, cookies } = useContext(AuthContext);

  const [DropdownConfigvalues, setDropDownList] = useState([]);
  const navigate = useNavigate();

  const SubmitsaveemaiilCreate = (e) => {
    e.preventDefault();

    

    const data = new FormData(e.currentTarget);
    const text = $(".jodit-wysiwyg").html();

    const obj = {
      CategoryID: Number(ModuleIDCreate.moduleID),
      ModuleFunctionalityID: Number(funLists?.length==1?funLists[0]?.value:funLists.length>0?funLists[1]?.value:funLists?.value),
      Subject: data.get("Subject"),
      Body: text,
      CCEmailList: data.get("CCEmailList"),
      EmailTemplateID: JSON.parse(data.get("EmailTemplateID")),

      rememberMeflag: true,
      userTypeFlag: true,
    };

    console.log(obj);

    if (obj.CategoryID == null || obj.CategoryID == 0) {
      setEmailtemplateCreate({ errCategoryID: "Select Module " });
      return false;
    }
    if (
      obj.ModuleFunctionalityID == null ||
      obj.ModuleFunctionalityID == 0 ||
      obj.ModuleFunctionalityID == -1
    ) {
      setEmailtemplateCreate({
        errModuleFunctionalityID: "Select Functionality ",
      });
      return false;
    }

    if (obj.Subject == null || obj.Subject == 0) {
      setEmailtemplateCreate({ errSubject: "Enter Subject " });
      return false;
    }

    if (obj.Body == null || obj.Body == 0) {
      setEmailtemplateCreate({ errBody: "Select Functionality " });
      return false;
    }
    if (obj.CategoryID != null || obj.CategoryID != 0) {
      setEmailtemplateCreate({ errCategoryID: " " });
      // return false;
    }

    if (obj.Body != null || obj.Body != 0) {
      setEmailtemplateCreate({ errBody: " " });
      // return false;
    }

    if (obj.ModuleFunctionalityID != null || obj.ModuleFunctionalityID != 0) {
      setEmailtemplateCreate({ errModuleFunctionalityID: "" });
    }

    if (obj.Subject == null || obj.Subject == 0) {
      setEmailtemplateCreate({ errSubject: " " });
    }
    var readed = 1;
    if (obj.EmailTemplateID != 0) {
      readed = 2;
    }

    EmailtemplateServieObj.CreateUpdateEmailtemplate(obj)
      .then((response) => {
        setCookie("tempEmailTempCreate", readed, { path: "/" });

        navigate("/Emailtemplate");
      })
      .catch((err) => {
        // ;
        return console.log(err);
      });
  };

  const [ModuleFunctionalityConfig, setModuleFunctionalityConfigvalues] =
    useState({
      value: "",
      text: "",
      dropdownDetailID: 0,
    });
  const [DropdownCode, setDrropdownCode] = useState("No Data");

  const [ApplicationData, SetApplicationData] = useState([]);

  const [ModuleFunctionalityList, SetDDLModuleFunctionalityList] = useState([
    {
      text: "No Data",
      value: "",
    },
  ]);
  const [EmailtemplateCreate, setEmailtemplateCreate] = useState({
    moduleID: 0,
    categoryID: 0,
    moduleFunctionalityID: "",
    subject: "",
    body: "",
    ccEmailList: "",
    emailTemplateID: 0,
    errCategoryID: "",
    errModuleFunctionalityID: "",
    errSubject: "",
    errBody: "",
    errCCEmailList: "",
    errEmailTemplateID: "",
  });

  const [ModuleIDCreate, setModuleIDCreate] = useState({
    moduleID: 0,
    moduleName: "",
  });

  const [SubjectbodyList, SetSubjectbodyList] = useState([
    {
      text: "No Data",
      value: "",
    },
  ]);

  useEffect(() => {
    module();
  }, []);

  const module = () => {
    var EmpID = emid;
    if (emid == null || emid == 0) {
      EmpID = 0;
    }
    EmailtemplateServieObj.Createtemplate(EmpID)

      .then((res) => {
        console.log(res.data.ddlModuleList);
        console.log(res.data);

        const { ddlModuleList } = res.data;

        setDropDownList([...ddlModuleList]);
        setEmailtemplateCreate(res.data);
        SetSubjectbodyList(res.data.ddlTempColList);
        SetDDLModuleFunctionalityList(res.data.ddlModuleFunctionalityList);
        setfunDownList(res.data.ddlModuleFunctionalityList);
        console.log(res.data.ddlModuleFunctionalityList);
        setModuleIDCreate(res.data);
        setContent(res.data.body);
        document.getElementById("CCEmailList").value = res.data.ccEmailList;
        subjectRef.current.querySelector("#Subject").value = res.data.subject;

        var div = document.getElementById("w1-Body");

        subjectRef.current.querySelector("#DropdownmoduleIDCode").value =
          res.data.moduleID;
        Setmid(res.data.moduleID);
        Setmfid(res.data.moduleFunctionalityID);
      })

      .catch((e) => console.error(e.message));
  };

  const funtionality = (DropdownCode) => {
    var id = DropdownCode > 0 ? DropdownCode : 0;

    EmailtemplateServieObj.GetModuleNameChanged(id)
      .then((res) => {
        if (res.data.ddlModuleFunctionalityList != null)
          SetDDLModuleFunctionalityList(res.data.ddlModuleFunctionalityList);
        console.log(res.data);
      })

      .catch((e) => console.error(e.message));
  };
  const ReplaceBodyTextFunction = (e) => {
    var SampleText = document.getElementById("Subject").value;
    var modulename = document.getElementById("DropdownmoduleIDCode");
    var MName = modulename.options[modulename.selectedIndex].text;

    const text = $(".jodit-wysiwyg").html();
    var NewText = text + "{" + MName + "." + e + "}";
    setContent(NewText);
    this?.setState({ SampleText: NewText });
  };

  const ReplaceTextFunction = (e) => {
    var SampleText = document.getElementById("Subject").value;
    var modulename = document.getElementById("DropdownmoduleIDCode");
    var MName = modulename.options[modulename.selectedIndex].text;
    var NewText = SampleText + "{" + MName + "." + e + "}";

    document.getElementById("Subject").value = NewText;
    this?.setState({ SampleText: NewText });
  };

  const sub = (funLists) => {
    var Ids;
    if (funLists != 0) Ids = funLists;

    EmailtemplateServieObj.GetModuleFuncNameChanged(Ids)

      .then((res) => {
        console.log(res.data.ddlTempColList);
        SetSubjectbodyList(res.data.ddlTempColList);
      })
      .catch((e) => console.error(e.message));
  };
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    height: 400,
  };
  const handleUpdate = (event) => {
    const editorContent = event.target?.innerHTML;
    setContent(editorContent);
  };

  const subjectRef = useRef();

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            <div className="row mb-4">
              <div className="col-6">
                <h4 class="card-title">Email Template</h4>
              </div>
              <div className="col-3 text-right">
                <div className="float-end ms-auto">
                  <a
                    href="/Emailtemplate"
                    className="btn btn-primary btn-round ml-auto btn-sm"
                  >
                    <i className="fa fa-backward"></i>{" "}
                    <span className="ml-1">Back to List</span>
                  </a>
                </div>
              </div>
              <div className="col-3"></div>
            </div>

            <form onSubmit={SubmitsaveemaiilCreate} ref={subjectRef}>
              <div className="row ">
                <div className="col-sm-9 ">
                  <div
                    className="card"
                    style={{
                      borderRadius: "25px",
                      border: "2px solid #5E92D5",
                    }}
                  >
                    <div className="card-body">
                      <div className="form-group">
                        <FormControl fullWidth>
                          <label id="demo-simple-select-label">
                            Module <span className="text-danger">*</span>
                          </label>
                          {mId === 0 ? (
                            // Render the select element when yourCondition is true
                            <select
                              id="DropdownmoduleIDCode"
                              name="DropdownmoduleIDCode"
                              label="Module"
                              value={ModuleIDCreate.moduleID}
                              onChange={(e) => {
                                setModuleIDCreate({
                                  moduleID: e.target.value,
                                  moduleName: e.target.name,
                                });
                                setEmailtemplateCreate({
                                  ...EmailtemplateCreate,
                                  errCategoryID: "",
                                });

                                funtionality(e.target.value);
                                return true;
                              }}
                              className="form-control"
                            >
                              {DropdownConfigvalues != null &&
                              DropdownConfigvalues.length > 0 ? (
                                DropdownConfigvalues.map((item, indexs) => {
                                  return (
                                    <option key={indexs} value={item.value}>
                                      {item.text}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value=""></option>
                              )}
                            </select>
                          ) : (
                            // Render something else when yourCondition is false
                            <select
                              id="DropdownmoduleIDCode"
                              name="DropdownmoduleIDCode"
                              label="Module"
                              value={ModuleIDCreate.moduleID}
                              className="form-control"
                              disabled
                            >
                              {DropdownConfigvalues != null &&
                              DropdownConfigvalues.length > 0 ? (
                                DropdownConfigvalues.map((item, indexs) => {
                                  return (
                                    <option key={indexs} value={item.value}>
                                      {item.text}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value=""></option>
                              )}
                            </select>
                          )}
                          <span style={{ color: "red" }}>
                            {EmailtemplateCreate.errCategoryID}
                          </span>
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <FormControl fullWidth>
                          <label id="demo-simple-select-label">
                            Functionality <span className="text-danger">*</span>
                          </label>
                          {mfId === 0 ? (
                            // Editable select for ModuleFunctionalityID equal to 0
                            <select
                              id="DropdownFunctionalityCode"
                              name="DropdownFunctionalityCode"
                              label="Functionality"
                              value={ModuleIDCreate.moduleFunctionalityID}
                              onChange={(e) => {
                                setfunDownList({
                                  value: e.target.value,
                                  text: e.target.name,
                                });
                                setEmailtemplateCreate({
                                  ...EmailtemplateCreate,
                                  errModuleFunctionalityID: "",
                                });

                                return sub(e.target.value);
                              }}
                              className="form-control"
                            >
                              {ModuleFunctionalityList.map((item, indexs) => {
                                return (
                                  <option key={indexs} value={item.value}>
                                    {item.text}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            // Non-editable select for ModuleFunctionalityID not equal to 0
                            <select
                              id="DropdownFunctionalityCode"
                              name="DropdownFunctionalityCode"
                              label="Functionality"
                              value={ModuleIDCreate.moduleFunctionalityID}
                              className="form-control"
                              disabled
                            >
                              {ModuleFunctionalityList.map((item, indexs) => {
                                return (
                                  <option key={indexs} value={item.value}>
                                    {item.text}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          <span style={{ color: "red" }}>
                            {EmailtemplateCreate.errModuleFunctionalityID}
                          </span>
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <label id="demo-simple-select-label">
                          Subject <span className="text-danger">*</span>
                        </label>
                        <input
                          fullWidth
                          id="Subject"
                          label="Subject"
                          name="Subject"
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {EmailtemplateCreate.errSubject}
                        </span>
                      </div>
                      <div className="form-group">
                        <label id="demo-simple-select-label">CC</label>
                        <input
                          fullWidth
                          id="CCEmailList"
                          label="Cc"
                          name="CCEmailList"
                          className="form-control"
                        />

                        <span style={{ color: "red" }}>
                          {EmailtemplateCreate.errCCEmailList}
                        </span>

                        <input
                          fullWidth
                          className="form-control"
                          id="EmailTemplateID"
                          label="EmailTemplateID"
                          name="EmailTemplateID"
                          value={EmailtemplateCreate.emailTemplateID}
                          style={{ display: "none" }}
                          onChange={(e) =>
                            this.setState({
                              Configs: {
                                ...this.state.EmailtemplateCreate,
                                ccEmailList: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <div
                          style={{
                            width: "100%",
                            height: "320px",
                            marginBottom: "75px",
                          }}
                        >
                          <JoditEditor
                            id="w1-Body"
                            ref={editor}
                            value={content}
                            config={config}
                            onBlur={handleUpdate}
                            onChange={(newContent) => {}}
                          />
                          <div
                            dangerouslySetInnerHTML={{ __html: content }}
                            style={{ display: "none" }}
                          />

                          <span style={{ color: "red" }}>
                            {EmailtemplateCreate.errBody}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-auto float-end ms-auto"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginBottom: "14px",
                      }}
                    >
                      <button
                        className="btn add-btn btn-primary btn-sm"
                        type="Submit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                {/*---------------------------------------- For Placehoder side bar ---------------------------  */}
                <div className="col-sm-3">
                  <div
                    className="card"
                    style={{ marginBottom: "0px", background: "#f9fbfd" }}
                  >
                    <div className="card-body">
                      <h3
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        Placeholder
                      </h3>
                    </div>
                  </div>
                  <div className="card">
                    <div class="card-body">
                      <ul
                        class="nav nav-pills nav-secondary"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item submenu" style={{ width: "50%" }}>
                          <a
                            class="nav-link active show"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="false"
                          >
                            Subject
                          </a>
                        </li>
                        <li class="nav-item submenu" style={{ width: "50%" }}>
                          <a
                            class="nav-link "
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="true"
                          >
                            Body
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content mt-2 mb-3" id="pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <ul className="list-group list-group-flush col-12">
                            {SubjectbodyList != null &&
                            SubjectbodyList.length > 0 ? (
                              SubjectbodyList.map((item, indexs) => {
                                return (
                                  <li
                                    className="list-group-item"
                                    onClick={(e) =>
                                      ReplaceTextFunction(item.value)
                                    }
                                  >
                                    {item.text}
                                  </li>
                                );
                              })
                            ) : (
                              <li className="list-group-item">
                                NO Data Subject
                              </li>
                            )}
                          </ul>{" "}
                        </div>
                        <div
                          class="tab-pane fade "
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <ul className="list-group list-group-flush col-12">
                            {SubjectbodyList != null &&
                            SubjectbodyList.length > 0 ? (
                              SubjectbodyList.map((item, indexs) => {
                                return (
                                  <li
                                    className="list-group-item"
                                    onClick={(e) =>
                                      ReplaceBodyTextFunction(item.value)
                                    }
                                  >
                                    {item.text}
                                  </li>
                                );
                              })
                            ) : (
                              <li className="list-group-item">NO Data Body </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ApplicationConfiguration;
