
    import React, { useContext, useState, useEffect } from "react";
    import { useLocation } from "react-router-dom";
    import { AuthContext } from "../../Context/AuthContext";
    import AuthenticationService from "../../api/AuthenticationService";
    import $ from "jquery";
    import { useNavigate } from "react-router";
    import "../SideBar/Loader.css";
    
    

export const LogoutHR = () => {
    const { data, setCookie, removeCookie, cookies ,logout} = useContext(AuthContext);
    const navigate = useNavigate();
 
    
    useEffect(() => {
        setCookie("ISReload", 1, { path: "/" });
        logout();
        navigate("/");
      });
    
      
  return (
    <>
   <div className="loader-container" style={{backgroundColor:"rgba(192, 193, 194, 0.73)"}}>

<span className="loadercube" style={{position:"fixed",right:"46%",bottom:"50%"}}></span>
  </div>
    </>
  );
};
