import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import ApplicationConfigService from "../../api/ApplicationConfigService";
import swal from "sweetalert";
import { AuthContext } from "../../Context/AuthContext";
import { imgURL } from "../../api/AxiosService"

export default function ImageConfig() {
    const { cookies } = useContext(AuthContext);
    const [locationId, setLocationId] = useState(0);
    const [locationName, setLocationName] = useState();
    const [imageList, setImageList] = useState([]);
    const [user, setUser] = useState(cookies.userID);
    const AppService = new ApplicationConfigService();

    useEffect(() => {
        GetImageDetails();
    }, []);

    const GetImageDetails = () => {

        AppService.GetImageDetails()
            .then((res) => {

                if (res.data.length > 0) {
                    setImageList(res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleImageChange = (event, imageId) => {
        const file = event?.target?.files[0];
        const filetype=file?.name?.split(".")?.pop()?.toLowerCase()
        if(["jpg", "jpeg", "png", "svg", "ico"].includes(filetype)){
            const formData = new FormData();
        formData.append('file', file);


        AppService.SaveImage(formData, imageId)
            .then((res) => {
                if (res == 1 || res==2) {
                  
                    GetImageDetails();
                    swal("Details", "Saved Successfully!", "success")

                    setInterval(()=>{
                    window.location.reload();
                    },1500)
                } 
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
        else{
            swal("warning", "Invalid file type!", "warning")
        }
    };






    return (
        <>


            <div className="col-lg-12 col-md-12 col-sm-12">
                <div class="table-responsive">
                    <table
                        id="add-row"
                        className="display table table-striped table-hover"
                    >
                        <thead>
                            <tr>
                                <th style={{ display: "none" }}></th>
                                <th>Image Name </th>
                                <th >Image</th>
                                <th style={{ width: "10%", textAlign: "center" }}>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {imageList &&
                                imageList.map((e) => {
                                    return (
                                        <tr key={e.ImageId}>
                                            <td style={{ display: "none" }}>{e.ImageId}</td>
                                            <td>{e.ImageName}</td>
                                            <td>
                                                {e.ImageUrl!=null && e.ImageUrl!=undefined && e.ImageUrl!=""?
                                                <div className="clsimage">
                                                <img
                                                    src={  imgURL + e.ImageUrl
                                                    }
                                                    alt="Profile"
                                                    className="main-profile-image"
                                                />
                                                </div>:""
                                            }
                                                
                                            </td>
                                            <td>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(event) => handleImageChange(event, e.ImageId)}
                                                    ref={(input) => input && (input.value = null)}
                                                    id={`imageInput-${e.ImageId}`}
                                                />
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => document.getElementById(`imageInput-${e.ImageId}`).click()}
                                                >
                                                    Change
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>

                    </table>
                </div>
            </div>

        </>
    );
}
