import React, { useEffect, useState, useContext } from "react";
import $, { data } from "jquery";
import "../../assets/css/atlantis.css";
import Demand from "../../assets/img/Dashboard/request.png";
import Selected from "../../assets/img/Dashboard/selection.png";
import Rejected from "../../assets/img/Dashboard/resume.png";
import New from "../../assets/img/Dashboard/add-group.png";
// import 'datatables.net';
import MonthPicker from "simple-react-month-picker";
import moment from "moment";
import DashboardService from "../../api/DashboardService";
import { AuthContext } from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import openpic from "../../assets/img/Dashboard/open-source.png";
import profilenum from "../../assets/img/Dashboard/man.png";
import DemandService from "../../api/DemandService";
import parse from "html-react-parser";
import {
  Chart as ChartJS,
  ChartConfiguration,
  LineController,
  BarController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { red } from "@mui/material/colors";
const _DashboardService = new DashboardService();
const _DemandService = new DemandService();
ChartJS.register(
  BarController,
  BarElement,
  PointElement,
  LinearScale,
  Title,
  zoomPlugin,
  CategoryScale,
  Tooltip
);
function DashboardIndex() {
  // const [selected, setSelected] = useState(null);

  const [isfirst, SetIsFirst] = useState(0);
  const { cookies, setCookie } = useContext(AuthContext);
  const [getDemandList, setgetDemandList] = useState([]);
  const [DemandChart, setDemandChart] = useState([]);
  const [stepDate, setstepDate] = useState(null);
  const [Month, setMonth] = useState("");
  const [Year, setYear] = useState(new Date().getFullYear());
  const [data, SetChatData] = useState({
    labels: [],
    datasets: [],
  });
  const [Candidate, SetChatCandidateData] = useState({
    labels: [],
    datasets: [],
  });
  const [DynamicTable, setDynamicTable] = useState([]);
  const [string, setstring] = useState([]);
  const [selectedMont, setSelectedMonth] = useState(0);
  const [roleName, setRoleName] = useState(cookies?.roleName);

  const [activeSpoc, setActiveSpoc] = useState(true);
  const [activeRecruiter, setactiveRecruiter] = useState(false);
  const [activeOthers, setActiveOthers] = useState(false);
  const [activeManager, setActiveManager] = useState(false);
  const [activeEmployee, setActiveEmployee] = useState(false);
  const [activeHR, setactiveHR] = useState(false);
  const [activePMO, setactivePMO] = useState(false);
  const [activeTL, setactiveTL] = useState(false);
  const [activeRoleName, setActiveRoleName] = useState();
  const [companyID, setCompanyID] = useState(cookies?.companyID);
  const [ActiveTabCookies,setActiveTabCookies]=useState(cookies.DashboardActiveTabCookies);


  let role;

  useEffect(()=>{
    if(ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Recruiter'):roleName?.includes('Recruiter')){
      if(ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Super Admin'):roleName.includes('Super Admin')){ 
        role='Super Admin,Recruiter'
        setActiveRoleName(role)
        setactiveRecruiter(true)
      }
      else{
        role='Recruiter'
        setActiveRoleName(role)
        setactiveRecruiter(true)
      }
    }
    else if(ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('TL'):roleName.includes('TL')){
      if(ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Super Admin'):roleName.includes('Super Admin')){
        role='Super Admin,TL'
        setActiveRoleName(role)
        setactiveTL(true)
      }
      else{
        role='TL'
        setActiveRoleName(role)
        setactiveTL(true)
      }
    }
    
    else{
      if(ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Super Admin'):roleName.includes('Super Admin')){
        role="Super Admin,spoc,hr,pmo"
        setActiveRoleName(role)
        setActiveOthers(true)
      }
      else{
        role="spoc,hr,pmo"
        setActiveRoleName(role)
        setActiveOthers(true)
      }
    }
  },[])

  useEffect(() => {
    _DashboardService
      .GetDashboard_DemandList(
        Number(cookies.userID),
        activeRecruiter==true?roleName?.includes('Super Admin')?'Super Admin,Recruiter':'Recruiter':role==undefined?activeRoleName:role,
        Year,
        Month,
        companyID
      )
      .then((res) => {
        setgetDemandList(res.data);

        console.log(data);
      })
      .catch((res) => {});
    $(".submenu").removeClass("active");
    $("#Dashboard").addClass("active");

    $(".collapse").removeClass("show");
    $("#Dashboard").addClass("show");

    $(".submenu li").removeClass("active");

    setCookie("EmployeeTableCookies", 1, { path: "/" });

    _DemandService
      .getStepData("STEP")
      .then((res) => {
        setstepDate(res.data);
        $("#DemandTable").DataTable().destroy();
        console.log(res.data);
        $(".loader-container").hide();
      })
      .catch((res) => {
        $(".loader-container").hide();
      });
    _DashboardService
      .MonthWise_Demand(Month, role==undefined?activeRoleName:role, cookies.userID, Year,companyID)
      .then((res) => {
        const { monthObj, demandObj } = res.data;

        console.log(monthObj);
        console.log(demandObj);
        SetChatData({
          labels: monthObj && monthObj[0].Month.split(","),
          datasets: [
            {
              label: "Demand",
              data: demandObj ? demandObj[0].Demand.split(",") : [],
              backgroundColor: "#1269db78",
              borderColor: "#1269db",
              borderWidth: 1,
              barThickness: 40,
            },
          ],
        });

        console.log(data);
      })
      .catch((res) => {});

    _DashboardService
      .MonthWise_Candidate(Month, cookies.roleName, Year,companyID)
      .then((res) => {
        const { monthObj, candidateObj } = res.data;
        SetChatCandidateData({
          labels: monthObj && monthObj[0].Month.split(","),
          datasets: [
            {
              label: "Candidate",
              data: candidateObj ? candidateObj[0].Candidate.split(",") : [],
              backgroundColor: "#1269db78",
              borderColor: "#1269db",
              borderWidth: 1,
              barThickness: 40,
            },
          ],
        });

        console.log(data);
      })
      .catch((res) => {});
    var currentYear = new Date().getFullYear();

    // Get the select element
    document.getElementById("ReportYear").innerHTML = "";
    var selectElement = document.getElementById("ReportYear");

    // Create options for the past five years
    for (var i = 0; i < 5; i++) {
      var optionElement = document.createElement("option");
      optionElement.value = currentYear - i;
      optionElement.textContent = currentYear - i;
      selectElement.appendChild(optionElement);
    }
    document.getElementById("ReportMonth").innerHTML = "";
    var monthList = [
      { number: 1, name: "January" },
      { number: 2, name: "February" },
      { number: 3, name: "March" },
      { number: 4, name: "April" },
      { number: 5, name: "May" },
      { number: 6, name: "June" },
      { number: 7, name: "July" },
      { number: 8, name: "August" },
      { number: 9, name: "September" },
      { number: 10, name: "October" },
      { number: 11, name: "November" },
      { number: 12, name: "December" },
    ];

    // Get the current date
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth() + 1;

    var selectMonthElement = document.getElementById("ReportMonth");
    selectMonthElement.innerHTML = "";
    for (var i = 0; i < monthList.length; i++) {
      var optionMonthElement = document.createElement("option");
      optionMonthElement.value = monthList[i].number;
      optionMonthElement.textContent = monthList[i].name;

      if (currentYear === +Year) {
        if (monthList[i].number > currentMonth) {
          optionMonthElement.disabled = true;
        } else if (monthList[i].number === currentMonth) {
          optionMonthElement.selected = true;
        }
      }

      selectMonthElement.appendChild(optionMonthElement);
      if (isfirst == 1) {
        document.getElementById("ReportMonth").value = selectedMont;
      }
    }

    _DashboardService
      .GetDashboard_DemandList(
        Number(cookies.userID),
        activeRecruiter==true?'Recruiter':role==undefined?activeRoleName:role,
        Year,
        Month,
        companyID
      )
      .then((res) => {
        setgetDemandList(res.data);

        console.log(data);
      })
      .catch((res) => {});
  }, [Month, Year,role,activeRoleName]);

  const MonthChange = (e) => {
    var Mon = [];
    e.map((i) => {
      const date = new Date(i.split("T")[0]); // Create a new Date object with a specific date
      const month = date.getMonth(); // Get the month (0-11)
      Mon.push(month + 1); // Output: 6 (July is represented by 6)
    });
    console.log(Mon);
    setMonth(Mon);
  };
  function yearchange(e) {
    setYear(e.target.value);
  }
  function Monthchange(e) {
    setMonth(e.target.value);
    setSelectedMonth(e.target.value);
    SetIsFirst(1);
  }
  function handleClick(event, elements) {
    $(".DashboardPopUp").click();
    setstring("Demand");
    if (elements.length) {
      const index = elements[0].index; // Get clicked bar index
      const label = data.labels[index]; // Get corresponding label
      _DashboardService
        .Dashboard_DemandList_MonthWise(
          getMonthNumber(label),
          role==undefined?activeRoleName:role,
          cookies.userID,
          Year,
          companyID
        )
        .then((res) => {
          const { demandObjObj } = res.data;
          setDynamicTable([...demandObjObj]);
        })
        .catch((res) => {});
    }
  }
  function CandidateListChart(event, elements) {
    $(".DashboardPopUp").click();
    setstring("Demand");
    if (elements.length) {
      const index = elements[0].index; // Get clicked bar index
      const label = data.labels[index]; // Get corresponding label
      _DashboardService
        .Dashboard_CandidateList_MonthWise(getMonthNumber(label), Year,role==undefined?activeRoleName:role,companyID)
        .then((res) => {
          const { candidateObj } = res.data;
          setDynamicTable([...candidateObj]);
        })
        .catch((res) => {});
    }
  }
  function getMonthNumber(monthName) {
    const date = new Date(`${monthName} 1, 2000`);
    const monthNumber = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
    return monthNumber;
  }

  const handleChangeActiveTab = (value) => {
    
     if (value == "Recruiter") {
      setactiveRecruiter(true);
      setActiveOthers(false)
      setactiveTL(false)

      if (roleName?.includes("Super Admin")) {
        role = "Super Admin,Recruiter";
        setActiveRoleName(role);
        setCookie("DashboardActiveTabCookies", role, { path: "/" });
      } else {
        role = "Recruiter";
        setActiveRoleName(role);
        setCookie("DashboardActiveTabCookies", role, { path: "/" });
      }
    }
    else if (value == "TL") {
      setactiveRecruiter(false);
      setactiveTL(true)
      setActiveOthers(false)

      if (roleName?.includes("Super Admin")) {
        role = "Super Admin,TL";
        setActiveRoleName(role);
        setCookie("DashboardActiveTabCookies", role, { path: "/" });
      } else {
        role = "TL";
        setActiveRoleName(role);
        setCookie("DashboardActiveTabCookies", role, { path: "/" });
      }
    }
     else {
      setactiveRecruiter(false);
      setactiveTL(false);
      setActiveOthers(true);

      const roleArray=roleName?.split(',')
      const UpdatedRoleArray=roleArray.filter(e=> e!='Recruiter' && e!='TL')
      role=UpdatedRoleArray.join(',')
      if (roleName.includes("Super Admin")) {
        setActiveRoleName(role);
        setCookie("DashboardActiveTabCookies", role, { path: "/" });
      } else {
        role = "spoc,hr,pmo"
        setActiveRoleName(role);
        setCookie("DashboardActiveTabCookies", role, { path: "/" });
      }
    } 
  };

  return (
    <>
      <div class="main-panel">
        <div class="content">
          <div
            class="panel-header bg-primary-gradient"
            style={{ height: "113px" }}
          >
            <div class="page-inners" style={{ padding: "15px" }}>
              <div
                class=""
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <h2
                    class="card-title mr-auto"
                    style={{
                      marginLeft: "20px",
                      fontSize: "24px",
                      color: "#575962",
                    }}
                  >
                    {" "}
                    Dashboard
                  </h2>
                </div>
                <div className="d-flex">
                  <select
                    className="form-control mr-2"
                    value={Year}
                    name="Year"
                    id={"ReportYear"}
                    onChange={(e) => yearchange(e)}
                  >
                    <option value="0">--Select--</option>
                  </select>
                  <select
                    className="form-control mr-2"
                    defaultValue={Month}
                    // defaultValue={"September"}
                    name="Month"
                    id={"ReportMonth"}
                    onChange={(e) => Monthchange(e)}
                  >
                    <option value="0">--Select--</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="page-inner mt--5">
            <div class="row  mt-2">
              <div class="card-body ">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <ul class="nav nav-pills">
                          {(roleName?.includes("Recruiter") ||
                            roleName?.includes("Super Admin")) && (
                            <li class="nav-item">
                              <a
                                class={`nav-link ${
                                  activeRecruiter ? "active" : ""
                                }`}
                                href="#"
                                onClick={() =>
                                  handleChangeActiveTab("Recruiter")
                                }
                              >
                                Recruiter
                              </a>
                            </li>
                          )}
                          {(roleName?.includes("TL") || roleName?.includes("Super Admin"))&&
                              <li class="nav-item">
                              <a
                                class={`nav-link ${activeTL?'active':''}`}
                                aria-current="page"
                                href="#"
                                onClick={()=>handleChangeActiveTab('TL')}
                              >
                                TL
                              </a>
                            </li>
                              }
                          {(roleName?.includes("HR") || roleName?.includes("SPOC") || roleName?.includes("TL") || roleName?.includes("PMO") || roleName?.includes("Manager") ||
                            roleName?.includes("Super Admin")) && (
                            <li class="nav-item">
                              <a
                                class={`nav-link ${activeOthers ? "active" : ""}`}
                                href="#"
                                onClick={() => handleChangeActiveTab("Others")}
                              >
                                Others
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="page-inner mt--5"></div> */}
          {cookies.roleName != null && activeRoleName?.includes("Recruiter") ? (
            <div class="page-inner mt--5">
              {getDemandList.cVal != null && getDemandList.cVal.length
                ? getDemandList.cVal.map((r, i) => {
                    return (
                      <div class="row row-card-no-pd mt-2">
                        <div class="col-sm-6 col-md-3">
                          <div class="card card-stats card-round">
                            <div class="card-body ">
                              <div class="row">
                                <div class="col-6">
                                  <div class="icon-big text-center">
                                    <img src={profilenum} alt="indonesia" />
                                  </div>
                                </div>
                                <div class="col-6 col-stats">
                                  <div class="numbers">
                                    <p class="card-category" style={{ fontSize: "18px" }}>No of Profile </p>
                                    <h4 class="card-title">{r.no_Profile}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-3">
                          <div class="card card-stats card-round">
                            <div class="card-body ">
                              <div class="row">
                                <div class="col-6">
                                  <div class="icon-big text-center">
                                    <img src={openpic} alt="indonesia" />
                                  </div>
                                </div>
                                <div class="col-6 col-stats">
                                  <div class="numbers">
                                    <p class="card-category" style={{ fontSize: "18px" }}>Open</p>
                                    <h4 class="card-title">{r.open}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-3">
                          <div class="card card-stats card-round">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-6">
                                  <div class="icon-big text-center">
                                    <img src={Selected} alt="indonesia" />
                                  </div>
                                </div>
                                <div class="col-6 col-stats">
                                  <div class="numbers">
                                    <p class="card-category" style={{ fontSize: "18px" }}>Selected</p>
                                    <h4 class="card-title">{r.selected}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-3">
                          <div class="card card-stats card-round">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-6">
                                  <div class="icon-big text-center">
                                    <img src={Rejected} alt="indonesia" />
                                  </div>
                                </div>
                                <div class="col-6 col-stats">
                                  <div class="numbers">
                                    <p class="card-category" style={{ fontSize: "18px" }}>Rejected</p>
                                    <h4 class="card-title">{r.rejected}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          ) : (
            <div class="page-inner mt--5">
              {getDemandList.dashboard_data != null &&
              getDemandList.dashboard_data.length
                ? getDemandList.dashboard_data.map((m, i) => {
                    return (
                      <div class="row row-card-no-pd mt-2">
                        <div class="col-sm-6 col-md-3">
                          <div class="card card-stats card-round">
                            <div class="card-body ">
                              <div class="row">
                                <div class="col-6">
                                  <div class="icon-big text-center">
                                    <img src={Demand} alt="indonesia" />
                                  </div>
                                </div>
                                <div class="col-6 col-stats">
                                  <div class="numbers">
                                    <p
                                      class="card-category"
                                      style={{ fontSize: "18px" }}
                                    >
                                      Demand{" "}
                                    </p>
                                    <h4 class="card-title">
                                      {m.demandCreated}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-3">
                          <div class="card card-stats card-round">
                            <div class="card-body ">
                              <div class="row">
                                <div class="col-6">
                                  <div class="icon-big text-center">
                                    <img src={openpic} alt="indonesia" />
                                  </div>
                                </div>
                                <div class="col-6 col-stats">
                                  <div class="numbers">
                                    <p
                                      class="card-category"
                                      style={{ fontSize: "18px" }}
                                    >
                                      Open
                                    </p>
                                    <h4 class="card-title">{m.open}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-3">
                          <div class="card card-stats card-round">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-6">
                                  <div class="icon-big text-center">
                                    <img src={Selected} alt="indonesia" />
                                  </div>
                                </div>
                                <div class="col-6 col-stats">
                                  <div class="numbers">
                                    <p
                                      class="card-category"
                                      style={{ fontSize: "18px" }}
                                    >
                                      Selected
                                    </p>
                                    <h4 class="card-title">{m.selected}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-3">
                          <div class="card card-stats card-round">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-6">
                                  <div class="icon-big text-center">
                                    <img src={Rejected} alt="indonesia" />
                                  </div>
                                </div>
                                <div class="col-6 col-stats">
                                  <div class="numbers">
                                    <p
                                      class="card-category"
                                      style={{ fontSize: "18px" }}
                                    >
                                      Rejected
                                    </p>
                                    <h4 class="card-title">{m.rejected}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          )}

          <div className="page-inner mt--5">
            <div class="row row-card-no-pd">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <div class="card-head-row">
                      <h4 class="card-title">Demand & Candidate Pipeline</h4>
                      {/* <div class="card-tools">
                      <button class="btn btn-icon btn-link btn-primary btn-xs">
                        <span class="fa fa-angle-down"></span>
                      </button>
                      <button class="btn btn-icon btn-link btn-primary btn-xs btn-refresh-card">
                        <span class="fa fa-sync-alt"></span>
                      </button>
                      <button class="btn btn-icon btn-link btn-primary btn-xs">
                        <span class="fa fa-times"></span>
                      </button>
                    </div> */}
                    </div>
                  </div>
                  <div class="card-body">
                    <div
                      class="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div class="col-md-6">
                        <div
                          style={{
                            border: " 2px solid #2570d91f",
                            borderRadius: "10px",
                            minHeight: "350px",
                            maxHeight: "350px",
                            overflowX: "scroll",

                            // marginRight: '40px'
                          }}
                        >
                          <div class="table-responsive table-hover table-sales">
                            {cookies?.roleName != null &&
                            activeRoleName?.includes("Recruiter") ? (
                              <table class="table" id="DemandList">
                                <thead>
                                  <th>Demand Code</th>

                                  <th>Recruiting Start Date</th>
                                  <th>Day(s) Open</th>
                                </thead>
                                <tbody>
                                  {getDemandList.r_DemandGrid != null &&
                                  getDemandList.r_DemandGrid.length
                                    ? getDemandList.r_DemandGrid.map((p, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>{p.demandCode}</td>
                                            <td>{p.recruitingStartDate}</td>
                                            <td>{p.no_DaysOpen}</td>
                                          </tr>
                                        );
                                      })
                                    : ""}
                                </tbody>
                              </table>
                            ) : (
                              <table class="table" id="DemandList">
                                <thead>
                                  <th>Client</th>
                                  <th>Demand Created</th>
                                  <th>Selected</th>
                                  <th>Rejected</th>
                                  <th>Open</th>
                                </thead>

                                <tbody>
                                  {getDemandList.spoc_CandidateGrid != null &&
                                  getDemandList.spoc_CandidateGrid.length
                                    ? getDemandList.spoc_CandidateGrid.map(
                                        (k, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>{k.clientName}</td>
                                              <td>{k.demandCreated}</td>
                                              <td>{k.selected}</td>
                                              <td>{k.rejected}</td>
                                              <td>{k.open}</td>
                                            </tr>
                                          );
                                        }
                                      )
                                    : ""}
                                </tbody>
                              </table>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <a
                              href=""
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "12px",
                                width: "100px",
                                marginBottom: "16px",
                              }}
                              className="btn btn-sm btn-primary"
                            >
                              <Link
                                style={{ color: "white" }}
                                to={"/DemandIndex"}
                              >
                                View More
                              </Link>
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <div></div> */}

                      <div class="col-md-6">
                        <div
                          style={{
                            border: " 2px solid #2570d91f",
                            borderRadius: "10px",
                            minHeight: "350px",
                            maxHeight: "350px",
                            overflowX: "scroll",
                            // marginLeft: '40px'
                          }}
                        >
                          <div class="table-responsive table-hover table-sales">
                            {cookies?.roleName != null &&
                            activeRoleName?.includes("Recruiter") ? (
                              <table class="table" id="CandidateList">
                                <thead>
                                  <th>Demand Code</th>
                                  <th>No of Profiles </th>
                                  <th>Open</th>
                                  <th>Selected</th>
                                  <th>Rejected</th>
                                </thead>
                                <tbody>
                                  {getDemandList.r_CandidateGrid != null &&
                                  getDemandList.r_CandidateGrid.length
                                    ? getDemandList.r_CandidateGrid.map(
                                        (y, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>{y.demandCode}</td>
                                              <td>{y.no_Profile}</td>
                                              <td>{y.open}</td>
                                              <td>{y.selected}</td>
                                              <td>{y.rejected}</td>
                                            </tr>
                                          );
                                        }
                                      )
                                    : ""}
                                </tbody>
                              </table>
                            ) : (
                              // <table class="table" id="CandidateList">
                              //   <thead>
                              //     <th>Demand Code</th>

                              //     {getDemandList.stepLevel != null &&
                              //     getDemandList.stepLevel.length
                              //       ? getDemandList.stepLevel.map((vt, i) => {
                              //           return <th>{vt.stepDescription}</th>;
                              //         })
                              //       : ""}
                              //   </thead>

                              //   <tbody>
                              //     {getDemandList.demand != null &&
                              //     getDemandList.demand.length
                              //       ? getDemandList.demand.map((v, i) => {
                              //           var flag = 0;
                              //           return (
                              //             <tr key={i}>
                              //               <td>{v.demandRequestCode}</td>

                              //               {stepDate != null &&
                              //                 stepDate.length > 0 &&
                              //                 stepDate.map((s) => {
                              //                   var EmployeeName = 0;

                              //                   getDemandList.spoc_DemandGrid !=
                              //                     null &&
                              //                     getDemandList.spoc_DemandGrid
                              //                       .length > 0 &&
                              //                     getDemandList.spoc_DemandGrid.map(
                              //                       (d) => {
                              //                         if (
                              //                           d.demandRequestID ===
                              //                           v.demandRequestID
                              //                         ) {
                              //                           if (
                              //                             s.value ===
                              //                             d.currentStatus
                              //                           ) {
                              //                             flag = 1;
                              //                             EmployeeName =
                              //                               d.count;
                              //                           }
                              //                         }
                              //                       }
                              //                     );
                              //                   if (flag == 0) {
                              //                     return <td>0</td>;
                              //                   } else {
                              //                     return (
                              //                       <td>{EmployeeName}</td>
                              //                     );
                              //                   }
                              //                 })}

                              //               {/* {getDemandList.stepLevel != null &&
                              //             getDemandList.stepLevel > 0 &&
                              //             stepDate.map((s) => {
                              //               var noi = 0;

                              //               var flag = 0;
                              //               getDemandList.spoc_DemandGrid !=
                              //                 null &&
                              //                 getDemandList.spoc_DemandGrid
                              //                   .length > 0 &&
                              //                 getDemandList.spoc_DemandGrid.map(
                              //                   (d) => {
                              //                     if (
                              //                       v.demandRequestID ===
                              //                         d.demandRequestID &&
                              //                       s.stepID === d.stepID
                              //                     ) {
                              //                       flag = 1;
                              //                       noi = d.count;

                              //                       //return <td>{noi}</td>
                              //                     }
                              //                   }
                              //                 );
                              //               if (flag != 0) {
                              //                 return <td>{noi}</td>;
                              //               } else {
                              //                 return <td></td>;
                              //               }
                              //             })} */}
                              //             </tr>
                              //           );
                              //         })
                              //       : ""}
                              //   </tbody>
                              // </table>
                              <table class="table" id="CandidateList">
                                <thead>
                                  <th>Demand Code</th>
                                  <th>No of Profiles </th>
                                  <th>Open</th>
                                  <th>Selected</th>
                                  <th>Rejected</th>
                                </thead>
                                <tbody>
                                  {getDemandList.r_CandidateGrid != null &&
                                  getDemandList.r_CandidateGrid.length
                                    ? getDemandList.r_CandidateGrid.map(
                                        (y, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>{y.demandCode}</td>
                                              <td>{y.no_Profile}</td>
                                              <td>{y.open}</td>
                                              <td>{y.selected}</td>
                                              <td>{y.rejected}</td>
                                            </tr>
                                          );
                                        }
                                      )
                                    : ""}
                                </tbody>
                              </table>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "16px",
                            }}
                          >
                            <a
                              href=""
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "12px",
                                width: "100px",
                                marginBottom: "16px",
                              }}
                              className="btn btn-sm btn-primary"
                            >
                              <Link
                                style={{ color: "white" }}
                                to={"/DemandIndex"}
                              >
                                View More
                              </Link>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="page-inner mt--5">
            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">
                    <div class="card-head-row">
                      <h4 class="card-title">Demand</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <div
                      class="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-sm-12">
                        {
                          <Bar
                            className=""
                            id="DemandReport"
                            data={data}
                            options={{
                              onClick: handleClick,
                              responsive: true,

                              plugins: {
                                tooltip: {
                                  enabled: true,
                                },
                                zoom: {
                                  pan: {
                                    enabled: true,
                                    mode: "x",
                                  },
                                },
                                // tooltip : ,
                                datalabels: {
                                  anchor: "end",
                                  align: "top",
                                  font: {
                                    size: 8,
                                    weight: "bold",
                                  },
                                  // formatter: function (value) {
                                  //   if (value == 0) return "";
                                  //   else return value;
                                  // },
                                },
                              },
                              scales: {
                                x: {
                                  grid: {
                                    display: true,
                                    drawBorder: false,
                                    drawOnChartArea: true,
                                    drawTicks: true,
                                  },
                                },
                                y: {
                                  drawBorder: false,
                                  grace: "5%",
                                  ticks: {
                                    precision: 0,
                                  },
                                  grid: {
                                    display: true,
                                    drawBorder: false,
                                    drawOnChartArea: true,
                                    drawTicks: true,
                                  },
                                },
                              },
                            }}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">
                    <div class="card-head-row">
                      <h4 class="card-title">Candidate</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <div
                      class="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-sm-12">
                        {
                          <Bar
                            className=""
                            id="CandidateReport"
                            data={Candidate}
                            options={{
                              onClick: CandidateListChart,
                              responsive: true,

                              plugins: {
                                tooltip: {
                                  enabled: true,
                                },
                                zoom: {
                                  pan: {
                                    enabled: true,
                                    mode: "x",
                                  },
                                },
                                // tooltip : ,
                                datalabels: {
                                  anchor: "end",
                                  align: "top",
                                  font: {
                                    size: 8,
                                    weight: "bold",
                                  },
                                  // formatter: function (value) {
                                  //   if (value == 0) return "";
                                  //   else return value;
                                  // },
                                },
                              },
                              scales: {
                                x: {
                                  grid: {
                                    display: true,
                                    drawBorder: false,
                                    drawOnChartArea: true,
                                    drawTicks: true,
                                  },
                                },
                                y: {
                                  drawBorder: false,
                                  grace: "5%",
                                  ticks: {
                                    precision: 0,
                                  },
                                  grid: {
                                    display: true,
                                    drawBorder: false,
                                    drawOnChartArea: true,
                                    drawTicks: true,
                                  },
                                },
                              },
                            }}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="DashboardPopUp"
        data-bs-toggle="modal"
        data-bs-target="#DashboardPopUp"
      ></button>
      <div
        className="modal fade"
        id="DashboardPopUp"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title">
                <span className="fw-mediumbold"></span>
                <span className="fw-light">{string}</span>
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                className="table-responsive"
                // style={{ overflowX: "scroll", height: "70vh" }}
              >
                <table
                  className="display table table-striped table-hover"
                  id="ReportTable"
                >
                  <thead>
                    <tr>
                      {/* <th>Employee Code</th>
                        <th style={{ minWidth: "200px" }}>Employee Name</th>
                        <th>Employee Role</th> */}
                      {DynamicTable != null &&
                        DynamicTable.length > 0 &&
                        DynamicTable.map((e, i) => {
                          if (i == 0) {
                            var obj = Object.keys(e);
                            return (
                              obj != null &&
                              obj.length > 0 &&
                              obj.map((e) => {
                                return <th data-sort="string">{e}</th>;
                              })
                            );
                          }
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {DynamicTable != null &&
                      DynamicTable.length > 0 &&
                      DynamicTable.map((e, i) => {
                        var j = Object.values(e);

                        return (
                          <tr>
                            {j != null &&
                              j.length > 0 &&
                              j.map((k, i) => {
                                if (k != null) {
                                  return <td>{parse(k)}</td>;
                                } else {
                                  return <td>-</td>;
                                }
                              })}
                          </tr>
                        );
                      })}
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DashboardIndex;
