import ProfileSearchList from "../Components/ProfileSearch/ProfileSearchList";
import AxiosServices from "./AxiosService";

const Axios=new AxiosServices();

class ProfileSearchService
{
    Profiledatalist=async(companyID,roleName)=>{
         return Axios.get(`/CandidatePipeLine/ProfileSearchList?companyID=${companyID}&&roleName=${roleName}`)
    }
    BulkUpload=async(data)=>{
        return Axios.post('/CandidatePipeLine/BulkUpload',data)
   }
   BulkUploadFromFTP=async(data)=>{
     return await Axios.post('/CandidatePipeLine/BulkUploadFromFTP',data)
}
   filesave=async(data)=>{
        return Axios.post('/CandidatePipeLine/fileSave',data, {responseType: 'blob'})
   }
   Demanddatalist=async(companyID,roleName)=>{
     return Axios.get(`/CandidatePipeLine/Demanddatalist?companyID=${companyID}&&roleName=${roleName}`)
}
SaveDemandProfile=async(data)=>{
     return Axios.post('/CandidatePipeLine/SaveDemandProfile',data)
}
GetProfilelistByDemandID=async(id,CompanyID,RoleName)=>{
     return Axios.get(`/CandidatePipeLine/GetProfilelistByDemandID?id=${id}&&CompanyID=${CompanyID}&&RoleName=${RoleName}`)
}
}


export default ProfileSearchService