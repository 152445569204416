import { useState, useContext, useEffect, useReducer, useRef } from "react";
import $ from "jquery";
import { AuthContext } from "../../Context/AuthContext";

import AssociateService from "../../api/AssociateService";
// import "datatables.net";
// import 'datatables.net'
// import { Modal, Button } from "react-bootstrap";
import AddOrEditEmployee from "./AddOrEditEmployee";
import swal from "sweetalert";
import Swal from "sweetalert2"
import "datatables.net";
import Toast from "../Toastify";
import { ToastContainer, toast } from "react-toastify";
import DemandTemplate from "../../assets/Associate_Master_Data.xlsx";

import "react-toastify/dist/ReactToastify.css";

const AssociateServiceObj = new AssociateService();

const MainGrid = () => {
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);
  const fileInputRef = useRef(null);
  const [EmpID, SetEmpID] = useState(0);
  const [EmployeeDatailes, SetEmployeeDatailes] = useState([]);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [ModelName, setModelName] = useState("");
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);

  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);
  const [CompanyID, setCompanyID] = useState(cookies?.companyID)
  const [roleName, seRoleName] = useState(cookies?.roleName)

  const [AutoGenrateEmployeeCode, setAutoGenrateEmployeeCode] = useState(0);

  function HandleClick(id) {
    
    const currentPageBeforeEdit = currentPage;
    SetEmpID(id);
    if (id == 0) {
      setModelName("Employee Create");
    } else {
      setModelName("Employee Edit");
    }
    PageRender();
    setCurrentPage(currentPageBeforeEdit);
  }

  function DeleteEmployee(id) {
    swal({
      title: "Are You Sure?",
      text: "Do you want to delete this associate?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {

        AssociateServiceObj.DeleteEmployee(id)
          .then((res) => {
            Toast("Associate deleted succesfully", "success");
            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  const [users, setUsers] = useState([]);
  useEffect(() => {
    // $("#AssociateDisplay").dataTable();
    $(".submenu").removeClass("active");
    $("#ConfigurationMenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Configuration").addClass("show");

    $(".submenu li").removeClass("active");
    $("#Employee").addClass("active");
    $('.loader-container').show();
    AssociateServiceObj.getData(CompanyID, roleName)
      .then((res) => {
        $('.loader-container').hide();
        if (res != null && res.data != null && res.data.length > 0) {
          SetEmployeeDatailes([...res.data]);
          $("#AssociateDisplay").DataTable().destroy();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // setTimeout(() => {
    //   $('#AssociateDisplay').DataTable().destroy();
    //   setUsers([]);
    // },2000);
  }, [GridRender, Render]);

  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(newPage) {
    setCurrentPage(newPage);

    // Update the DataTable page immediately
    const table = $("#AssociateDisplay").DataTable();

    table.page(newPage - 1).draw(false);

    // Update your custom cookie here
    setCookie("EmployeeTableCookies", newPage, { path: "/" });
  }

  useEffect(() => {
    // Destroy the existing DataTable instance if it exists
    const existingTable = $("#AssociateDisplay").DataTable();
    let searchValue = existingTable ? existingTable.search() : '';

    if ($.fn.DataTable.isDataTable("#AssociateDisplay")) {
      existingTable.destroy();
    }

    // Initialize the DataTable
    const table = $("#AssociateDisplay").DataTable({
      ordering: false,
      pageLength: 10,
    });

    // Restore the search value
    table.search(searchValue).draw();

    
    // Attach an event listener for page change
    table.on("page.dt", function () {
      const newPage = table.page.info().page + 1;
      handlePageChange(newPage);
    });

    // Initialize the DataTable with the current page
    const currentPageToDisplay =
      cookies.EmployeeTableCookies != null && cookies.ProfileTableCookies!="" && cookies.ProfileTableCookies!=undefined
        ? cookies.EmployeeTableCookies
        : currentPage;
    table.page(currentPageToDisplay - 1).draw(false);

    return () => {
      // Make sure to remove the event listener when the component unmounts
      table.off("page.dt");
    };
  }, [EmployeeDatailes, currentPage, setCookie, cookies.EmployeeTableCookies]);


  function refreshPage() {
    window.location.reload(false);
  }
  function GenrateEmpCode() {
    AssociateServiceObj.GetAutogenrateEmployeeCode().then((res) => {

      setAutoGenrateEmployeeCode(res.data)
    })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleFileUpload = () => {
    
    fileInputRef.current.click();
  };
  const handleFileSelected = (event) => {
    
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      // Handle the selected Excel file here
      console.log("Selected Excel file:", selectedFile);
      let reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = (e) => {
      var filestr = e.target.result.split(",");
      SavaFile(filestr[1]);
    };
    } else {
      swal({
        text: "Invalid file type. Please select an Excel file (.xlsx)",
        icon: "warning",
        button: "Ok",
      });
      // Display an error message or handle the invalid file type
      console.error("Invalid file type. Please select an Excel file (.xlsx)");
    }
  };

  const SavaFile = (str) => {
    debugger
    const obj = {
      str: str,
      EmployeeID: Number(cookies.userID),
    };

    $(".loader-container").show();

    AssociateServiceObj.BulkUpload(obj)
      .then((response) => {
        
        $(".loader-container").hide();

        // Check if response.data.value is available
        if (response.data.responseObject.value.responseObject) {
          const responseData = JSON.parse(response.data.responseObject.value.responseObject);

          const successCount = responseData.TotalCount;
          const errorMessage = responseData.Errors;
          const errorCount = responseData.Errors.length;
          const updateCount = responseData.UpdateCount;
          const totalCount = successCount + errorCount;
          if (totalCount == successCount) {
            const successMessage =
              updateCount > 0
                ? `${responseData.TotalCount} Associate(s) Profile Uploaded successfully`
                : `Associate(s) Profile Updated successfully`;

            Toast(successMessage, "success");
            setTimeout(() => {
              $(".close").trigger("click");
            }, 2000);
            refreshPage();
          } else if (errorCount > 0) {
            Swal.fire({
              icon: 'warning',
              title: 'Associate Profile Upload Details',
              customClass: 'swal-wide',
              html: `
              <style>
                table {
                  border-collapse: collapse;
                  width: 100%;
                  margin-bottom: 10px;
                }
                th, td {
                  border: 1px solid #ddd;
                  padding: 8px;
                  text-align: center;
                }
                .error-container {
                  max-height: 100px;
                  overflow-y: auto;
                  margin-top: 10px;
                  word-wrap: break-word;
                }
                .error-list {
                  list-style-type: none;
                  padding: 0;
                  margin: 0;
                }
                .error-list-item {
                  margin-bottom: 5px;
                  font-size: 13px;
                  text-align:left;
                }
                .error-head{
                  font-size: 13px;
                }
                
              </style>
              <table>
                <thead>
                  <tr>
                    <th>Total Count</th>
                    <th>Success Count</th>
                    <th>Error Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>${totalCount}</td>
                    <td>${successCount}</td>
                    <td>${errorCount}</td>
                  </tr>
                </tbody>
              </table>
                    <div class="error-container">
            <div ><b class="error-head">Error Messages:</b></div>
            <div class="error-list">
              ${errorMessage.map(msg => `<div class="error-list-item">${msg}</div>`).join('')}
            </div>
          </div>
            `,
            }).then((result) => {
              if (result.isConfirmed) {
                refreshPage();
              }
            });
          }



        } else {
          // Handle unexpected response format
          console.error("Unexpected response format:", response);
          $(".loader-container").hide();
          Toast("Something went wrong", "warning");
          setTimeout(() => {
            $(".close").trigger("click");
          }, 2000);
          refreshPage();
        }
      })
      .catch((error) => {
        console.error(error);
        $(".loader-container").hide();
        Toast("An error occurred while uploading Associates", "error");
        refreshPage();
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <h4 class="card-title">Associate </h4>

                      {priviledge.includes("EMPCREATE") && (
                        <a
                          href="#"
                          className="btn btn-primary btn-round ml-auto btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#addRowModal"
                          style={{ color: "#fff" }}
                          onClick={GenrateEmpCode}
                        >
                          <i className="fa fa-plus"></i>
                          <span className="ml-1">Add Associate</span>
                        </a>
                      )}
                      {priviledge.includes("EMPCREATE") && (
                        <button
                          className="btn btn-primary btn-sm btn-round ml-2"
                          onClick={handleFileUpload}
                          id="uploadBtn"
                        >
                          <i className="fa fa-upload"></i>{" "}
                          <span className="ml-1"> Associate Upload</span>
                        </button>
                      )}

                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileSelected}
                        accept=".xlsx"
                      />

                      {priviledge.includes("EMPCREATE") && (
                        <a
                          className="btn btn-primary btn-sm btn-round ml-2"
                          href={DemandTemplate}
                          download
                        >
                          <i className="fa fa-download"></i>{" "}
                          <span className="ml-1"> Associate Template</span>
                        </a>
                      )}
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table
                        className="display table table-striped table-hover"
                        id="AssociateDisplay"
                      >
                        <thead>
                          <tr>
                            <th>Associate Code</th>
                            <th>Associate Name</th>

                            <th>Email</th>
                            <th style={{ width: "15%" }}>Role</th>
                            <th>Reporting Manager</th>
                            <th>Company Name</th>
                            <th>Status</th>
                            {priviledge.includes(
                                        "EMPEDIT"
                                      ) ||priviledge.includes(
                                        "EMPDELETE"
                                      )?(
                            <th style={{ textAlign: "center" }}>Action</th>
                                      ):("")}
                          </tr>
                        </thead>
                        <tbody>
                          {EmployeeDatailes != null &&
                            EmployeeDatailes.length > 0
                            ? EmployeeDatailes.map((v, i) => {
                              return (
                                <tr key={i}>
                                  <td>{v.employee_Code}</td>
                                  <td>{v.employee_Name}</td>
                                  {/* <td>{v.mobile_Number}</td> */}
                                  <td>{v.email}</td>

                                  <td>{v.roleNames}</td>
                                  <td>{v.reportManager}</td>
                                  <td>{v.companyName}</td>
                                  <td className="feed-item feed-item-secondary">
                                    {v.isActive == 0 ? (
                                      <span class="fw-mediumbold text-danger">
                                        InActive
                                      </span>
                                    ) : (
                                      <span class="fw-mediumbold text-success">
                                        Active
                                      </span>
                                    )}
                                  </td>
                                  {priviledge.includes(
                                        "EMPEDIT"
                                      ) ||priviledge.includes(
                                        "EMPDELETE"
                                      )?(
                                        <td>
                                    <div class="form-button-action">
                                      {priviledge.includes(
                                        "EMPEDIT"
                                      ) ? (
                                        <button
                                          type="button"
                                          // data-toggle="tooltip"
                                          title=""
                                          class="btn btn-link btn-primary btn-lg"
                                          data-original-title="Edit"
                                          data-bs-toggle="modal"
                                          data-bs-target="#addRowModal"
                                          onClick={() =>
                                            HandleClick(v.employeeID)
                                          }
                                        >
                                          <i class="fa fa-edit"></i>
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                      {priviledge.includes(
                                        "EMPDELETE"
                                      ) && v.employeeID>1? (
                                        <button
                                          type="button"
                                          data-toggle="tooltip"
                                          title=""
                                          class="btn btn-link btn-danger"
                                          data-original-title="Remove"
                                          onClick={() =>
                                            DeleteEmployee(v.employeeID)
                                          }
                                        >
                                          <i class="fas fa-trash-alt"></i>
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                      ):("")}
                                  
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="addRowModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header no-bd">
                <h5 class="modal-title">
                  {EmpID == 0 ? (
                    <span class="fw-mediumbold">Add Associate</span>
                  ) : (
                    <span class="fw-mediumbold">Edit Associate</span>
                  )}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={refreshPage}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <AddOrEditEmployee employeeID={EmpID} Employeecode={AutoGenrateEmployeeCode} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainGrid;
