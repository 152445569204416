import AxiosServices from "./AxiosService";

const Axios = new AxiosServices();

class DemandService {
  // CreateSave=async(Settings)=>{
  //     return Axios.post('/Settings/DropDownListSave',Settings);
  // }
  getStepData = async (Code) => {
    return Axios.get(`/Settings/DropDownList?Code=${Code}`);
  };
  GetClient = async (Code) => {
    return Axios.get(`/Settings/DropDownList?Code=${Code}`);
  };
  GetDemandType = async (Code) => {
    return Axios.get(`/Settings/DropDownList?Code=${Code}`);
  };
  GetDemandFulfilent = async (Code) => {
    return Axios.get(`/Settings/DropDownList?Code=${Code}`);
  };
  GetSkillSet = async (Code) => {
    return Axios.get(`/Settings/DropDownList?Code=${Code}`);
  };
  GetSkillSetType = async (Code) => {
    return Axios.get(`/Settings/DropDownList?Code=${Code}`);
  };
  SavaDemandRequest = async (data) => {
    
    return Axios.post("/DemandRequest/CreateDemand", data);
  };
  getData = async () => {
    return Axios.get("/Employee/List");
  };
  GetDemandList = async (EmployeeID, RoleName,CompanyID) => {
    return Axios.get(
      `/DemandRequest/GetDemandList?EmployeeID=${EmployeeID}&RoleName=${RoleName}&&CompanyID=${CompanyID}`
    );
  };

  DeleteDemand = async (DemandID) => {
    return Axios.get(`/DemandRequest/DeleteDemand?DemandID=${DemandID}`);
  };
  UpdatedemandData = async (data) => {
    return Axios.post("/DemandRequest/UpdatedemandData", data);
  };
  ChangeDemandStatus = async (DemandStatus, DemandId) => {
    return Axios.get(
      `/DemandRequest/ChangeDemandStatus?DemandStatus=${DemandStatus}&DemandId=${DemandId}`
    );
  };

  AcceptAssessment = async (DemandId, Step) => {
    return Axios.get(
      `/DemandRequest/AcceptAssessment?DemandId=${DemandId}&Step=${Step}`
    );
  };
  SLA_SaveAcceptTime = async (DemandId, Step, EmpID) => {
    return Axios.get(
      `/DemandRequest/SLA_SaveAcceptTime?DemandId=${DemandId}&Step=${Step}&EmpID=${EmpID}`
    );
  };

  ProfileMappers = async (companyID,roleName) => {
    return Axios.get(`/DemandRequest/ProfileMappers?CompanyID=${companyID}&&roleName=${roleName}`);
  };
  ScreeningLevels = async () => {
    return Axios.get("/DemandRequest/ScreeningLevels");
  };
  EmployeeList = async () => {
    return Axios.get("/DemandRequest/EmployeeList");
  };
  TemplateColumn = async (companyID,roleName) => {
    return Axios.get(`/DemandRequest/TemplateList?companyID=${companyID}&roleName=${roleName}`, {
      responseType: "arraybuffer", // Specify binary response type
    });
  };

  ScreenBaseEmployee = async (companyID,roleName) => {
    return Axios.get(`/DemandRequest/ScreenBaseEmployee?CompanyID=${companyID}&&roleName=${roleName}`);
  };
}

export default DemandService;
