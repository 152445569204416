import Axios from "axios";

//dev
//hr_rec
 
//  export const imgURL = "https://localhost:44305/";
//  Axios.defaults.baseURL = "https://localhost:44305/api";
// // hrmsapp
// var urlHRMS = "http://localhost:49367/";
///////////////////////OLD////////////////////////////////
//UAT
//hr_rec
// export const imgURL = "http://cybertronicsrecruitmentapi.vaanamtechdemo.com/";
// Axios.defaults.baseURL ="http://cybertronicsrecruitmentapi.vaanamtechdemo.com/api";
// var urlHRMS = "http://cybertronicshrms.vaanamtechdemo.com/"

//QA
// export const imgURL = "http://ctrecruitmentapi.vaanamtechdemo.com/";
//  Axios.defaults.baseURL = "http://ctrecruitmentapi.vaanamtechdemo.com/api";
//  var urlHRMS = "http://cthrms.vaanamtechdemo.com/";

///////////////////////NEW////////////////////////////////

////New UAT
// export const imgURL = "http://uatrecruitmentapi.mycloudxtreme.com/";
// Axios.defaults.baseURL ="http://uatrecruitmentapi.mycloudxtreme.com/api";
// var urlHRMS = "http://cybertronicshrms.vaanamtechdemo.com/"

////New QA
// export const imgURL = "http://qarecruitmentapi.mycloudxtreme.com/";
//  Axios.defaults.baseURL = "http://qarecruitmentapi.mycloudxtreme.com/api";
//  var urlHRMS = "http://cthrms.vaanamtechdemo.com/";

// //PROD WITH HTTPS
export const imgURL = "https://myworkapi.mycloudxtreme.com/";
 Axios.defaults.baseURL = "https://myworkapi.mycloudxtreme.com/api";
 var urlHRMS = "https://myworkhrms.mycloudxtreme.com/";


// Axios.defaults.baseURL =process.env.HR_API;

Axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

export default class AxiosServices {
  post(url, data, Header = false) {
    return Axios.post(url, data, Header);
  }
  get(url, header = null) {
    return Axios.get(url, header);
  }
  geturl() {
    return urlHRMS;
  }
}
