import React, {
  useEffect,
  useState,
  useContext,
  useReducer,
  useRef,
} from "react";
import $, { data } from "jquery";
import DemandService from "../../api/DemandService";
// import "datatables.net";
import * as XLSX from "xlsx";
import { renderMarkup } from "react-render-markup";
import Tooltip from "@mui/material/Tooltip";
import { AuthContext } from "../../Context/AuthContext";
import { json, useLocation, useNavigate } from "react-router";
import ReactTooltip from "react-tooltip";
import MySelect from "../MultiSelect/Myselect";
import { Display2 } from "bootstrap-4-react/lib/components/Display";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "universal-cookie";
import DataTablee from "datatables.net-dt";
import "datatables.net";
import "datatables.net-fixedcolumns-dt";
import swal from "sweetalert";
import CandidatePipelineService from "../../api/CandidatePipelineService";
import ApplicationConfigService from "../../api/ApplicationConfigService";
import { render } from "@testing-library/react";
import { HtmlTable } from "../SideBar/CommonTable";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";


const _DemandService = new DemandService();
const _CandidatePipeLineService = new CandidatePipelineService();
const DemandIndex = () => {
  const { cookies,setCookie} = useContext(AuthContext);
  const uploadbtn = () => {
    $("#DemandUpload").click();
  };
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  // on change states
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [stepDate, setstepDate] = useState(null);
  const [Client, setClient] = useState(null);
  const [DemandType, setDemandType] = useState(null);
  const [DemandFulfilment, setDemandFulfilment] = useState(null);
  const [SkillSet, setSkillSet] = useState(null);
  const [SkillSetType, setSkillSetType] = useState(null);
  const [Employee, setEmployee] = useState(null);
  const [SelectedValue, SetSelectedValue] = useState(null);
  const [DemandID, SetDemandID] = useState(null);
  const [getDemandList, setgetDemandList] = useState();
  const [DataTable, setDataTable] = useState([]);
  const [Stepsgrp, setStepsgrp] = useState([]);
  const [Stepstring, setStepstring] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [PopName, setPopName] = useState("Add");
  const [ButtonName, setButtonName] = useState("Add");
  const [Screendata, SetScreendata] = useState(null);
  const [ScreenContacter, SetScreenContacter] = useState(null);
  const [screenBasedEmp, setscreenBasedEmp] = useState();
  const [locationList, setLocationList] = useState([]);
  const location = useLocation()
  const [columnList,setColumnList]=useState([]);
  const [tempdemandList,setTempDemandList]=useState([]);

  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);

  const DemandTableref = useRef(null);
  // submit
  const [excelData, setExcelData] = useState(null);
  const AppService = new ApplicationConfigService();


  useEffect(() => {
    GetLocationDetails();
  }, [selectedLocation]);

  const GetLocationDetails = () => {
    AppService.GetLocationDetails()
      .then((res) => {
        if (res.data.length > 0) {
          setLocationList(res.data);
          console.log('location', res.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const [companyID, setCompanyID] = useState(cookies?.companyID);
  const [roleName, setRoleName] = useState(cookies?.roleName);
  const [userID, setuserID] = useState(cookies?.userID);
  const [CompanyLocation, setCompanyLocation] = useState([]);
  const [selectedCompanyID, setSelectedCompanyID] = useState(
    cookies?.companyID
  );

  const [activeSpoc, setActiveSpoc] = useState(false);
  const [activeRecruiter, setactiveRecruiter] = useState(false);
  const [activeOthers, setActiveOthers] = useState(false);
  const [activeManager, setActiveManager] = useState(false);
  const [activeHR, setactiveHR] = useState(false);
  const [activePMO, setactivePMO] = useState(false);
  const [activeTL, setactiveTL] = useState(false);

  // const [DemandActiveTab,setDemandActiveTab]=useState(location?.state?.activeTab);

  const [activeRoleName, setActiveRoleName] = useState();
  const [flag, setFlag] = useState(true)

  const roleNameDumm = roleName?.includes('Super Admin') ? 'Super Admin' : 'Others'
  let role;
  const [ActiveTabCookies,setActiveTabCookies]=useState(cookies.DemandActiveTabCookies);
  


  useEffect(() => {
    debugger
    GetLocationDetails();

    if ((ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined)?ActiveTabCookies.includes('SPOC'):(roleName.includes('SPOC') || roleName.includes('Super Admin'))) {
      if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Super Admin'):roleName.includes('Super Admin')) {
        role = 'Super Admin,SPOC'
        setActiveRoleName(role)
        setActiveSpoc(true)
      }
      else {
        role = 'SPOC'
        setActiveRoleName(role)
        setActiveSpoc(true)
      }
    }
    else if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Recruiter'):roleName?.includes('Recruiter')) {
      if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Super Admin'):roleName.includes('Super Admin')) {
        role = 'Super Admin,Recruiter'
        setActiveRoleName(role)
        setactiveRecruiter(true)
      }
      else {
        role = 'Recruiter'
        setActiveRoleName(role)
        setactiveRecruiter(true)
      }
    }

    else if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('HR'):roleName?.includes('HR')) {
      if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Super Admin'):roleName.includes('Super Admin')) {
        role = 'Super Admin,HR'
        setActiveRoleName(role)
        setactiveHR(true)
      }
      else {
        role = 'HR'
        setActiveRoleName(role)
        setactiveHR(true)
      }
    }

    else if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('PMO'):roleName?.includes('PMO')) {

      if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Super Admin'):roleName.includes('Super Admin')) {
        role = 'Super Admin,PMO'
        setActiveRoleName(role)
        setactivePMO(true)
      }
      else {
        role = 'PMO'
        setActiveRoleName(role)
        setactivePMO(true)
      }
    }

    else if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('TL'):roleName?.includes('TL')) {

      if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Super Admin'):roleName.includes('Super Admin')) {
        role = 'Super Admin,TL'
        setActiveRoleName(role)
        setactiveTL(true)
      }
      else {
        role = 'TL'
        setActiveRoleName(role)
        setactiveTL(true)
      }
    }

    else if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Manager'):roleName?.includes('Manager')) {

      if (ActiveTabCookies!="" && ActiveTabCookies!=null && ActiveTabCookies!=undefined?ActiveTabCookies.includes('Super Admin'):roleName.includes('Super Admin')) {
        role = 'Super Admin,Manager'
        setActiveRoleName(role)
        setActiveManager(true)
      }
      else {
        role = 'Manager'
        setActiveRoleName(role)
        setActiveManager(true)
      }
    }
  }, [])

  useEffect(() => {
    SetSelectedValue(null)
    setEmployee(null);
    setscreenBasedEmp(null);
    GetDemandList()
    $(".loader-container").show();
    _DemandService
      .getStepData("STEPS")
      .then((res) => {
        var Step = res.data.filter((e) => e.stepLevel == "SPOC");
        setstepDate(Step);

        console.log(res.data);
        $(".loader-container").hide();
      })
      .catch((res) => {
        $(".loader-container").hide();
      });

    _DemandService
      .GetClient("CLIENT")
      .then((res) => {
        setClient(res.data);
        console.log(res.data);
      })
      .catch((res) => { });
    _DemandService
      .GetDemandType("DEMANDTYPE")
      .then((res) => {
        setDemandType(res.data);
        console.log(res.data);
      })
      .catch((res) => { });
    _DemandService
      .GetDemandFulfilent("DEMANDFULFILMENT")
      .then((res) => {
        setDemandFulfilment(res.data);
        console.log(res.data);
      })
      .catch((res) => { });
    _DemandService
      .GetSkillSet("SKILLSET")
      .then((res) => {
        setSkillSet(res.data);
        console.log(res.data);
      })
      .catch((res) => { });
    _DemandService
      .GetSkillSetType("SKILLSETTYPE")
      .then((res) => {
        setSkillSetType(res.data);
        console.log(res.data);
      })
      .catch((res) => { });
    // _DemandService
    //   .getData()
    //   .then((res) => {
    //     console.log(res.data);
    //     const Emp = res.data.map((response) => ({
    //       value: response.employeeID,
    //       label: response.employee_Name + "- (" + response.employee_Code + ")",
    //     }));
    //     setEmployee(Emp);
    //   })
    //   .catch((res) => {});
    _DemandService
      .EmployeeList()
      .then((res) => {
        SetScreenContacter(res.data);
        console.log(res.data);
      })
      .catch((res) => { });

    _DemandService
      .ProfileMappers(selectedCompanyID, roleNameDumm)
      .then((res) => {
        const Emp = res.data.map((response) => ({
          value: response.employeeID,
          label: response.employee_Name + "- (" + response.employee_Code + ")",
        }));
        setEmployee(Emp);
      })
      .catch((res) => { });

    _DemandService
      .ScreenBaseEmployee(selectedCompanyID, roleNameDumm)
      .then((res) => {

        const Emp = res.data.map((response) => ({
          value: response.employeeID,
          label: response.employee_Name + "- (" + response.employee_Code + ")",
          key: response?.stepDescription
        }));
        setscreenBasedEmp(Emp);
      })
      .catch((res) => { });

    _DemandService
      .ScreeningLevels()
      .then((res) => {

        SetScreendata(res.data);

        console.log("Screening :", res.data);
      })
      .catch((res) => { });



    _CandidatePipeLineService
      .getSkillData("COMPANYNAME")
      .then((res) => {
        setCompanyLocation(res.data);
        console.log(res.data);
      })
      .catch((res) => {console.log(res) });
    $(".submenu").removeClass("active");
    $("#Demandmenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Demand").addClass("show");

    $(".submenu li").removeClass("active");
    $("#DemandReq").addClass("active");
  }, [selectedCompanyID]);


  
  const CompanyLocationOnChange = (e) => {
    const Value = e.target.value
    setSelectedCompanyID(Value);
  }
  
  const GetDemandList = async () => {


   await _DemandService
      .GetDemandList(Number(userID), role == undefined || role == null ? activeRoleName : role, companyID)
      .then((res) => {

        setgetDemandList(res.data);  

        setStepsgrp(res.data.demandStep);
      })
      .catch((res) => { });
  }

 

  useEffect(()=>{
    const columnListTemp=[
      {
        data:'demandRequestID',
        createdCell:(td, cellData, rowData, row, col) => {
          var flag = 0;
          var dtControl=$(`<td class="dt-control" style="width:20px !important"></td>`).on(
            "click",
            function(ev){
              TableHeader(rowData, flag, ev)
            }
          )
          $(td).html(dtControl);
        }
  
      },
      {
        title:'Demand Code',
        data:'demandCode',
        createdCell:(td, cellData, rowData, row, col) => {
            var dID = rowData?.demandRequestID;
            var Dcode = rowData?.demandCode;
            var client = rowData?.client;
            var skill =rowData?.skillSet;
            var exp = rowData?.experience;
            var Step =(!cookies.roleName?.includes("SPOC") || activeSpoc != true) ? rowData.step : "";;
            var NextStepName = rowData?.nextStepName;
            var CurrentStep = rowData?.currentStep;
            var Stepp = "";
              if (CurrentStep != null && CurrentStep != "") {
                Stepp = Step.includes(CurrentStep)
                  ? CurrentStep
                  : Step;
              } else {
                Stepp = Step;
              }
              var DemandDate = rowData?.date.split(" ");
              DemandDate = DemandDate[0];
              var IsAccept = rowData?.acceptTime;

          var demandFulfilment=rowData?.demandFulfilment
          var skillSetType=rowData?.skillSetType
          var demandType=rowData?.demandType
          var title="Demand Fulfillment : "+demandFulfilment+", Skill Set Type : "+skillSetType+", Demand Type : " +demandType
          var tdValue=$(`<td title="${title}">
          <a
            href="#"
          >
            ${rowData.demandCode}
          </a>
        </td>`).on(
          "click",
          function(){
            CandidatePipeline(
              dID,
              Dcode,
              client,
              skill,
              exp,
              Step,
              NextStepName,
              1,
              IsAccept
            )
          }
        )
        $(td).html(tdValue)
        }
      },
      {
        title:'Client',
        data:'client'
      },
      {
        title:'Skill Set',
        data:'skillSet'
      },
      {
        title:'Job Description',
        data:'jobDescription',
        createdCell:(td, cellData, rowData, row, col) => {
          let Fragment = $(`<s"></s>`);
          var tooltip="";

          tooltip=$(`<div class="Eclispedot">
          <span  title="${cellData}">
         ${cellData}
          </span>
          </div>
          <i class="fas fa-copy" title="Copy Job Description"></i>
         `).on(
          "click",
              function(){
                CopyDes(cellData)
              }
        );
         
          $(td).html(tooltip);
        }
      },{
        title:'Request Received Date',
        data:'date',
        createdCell:(td, cellData, rowData, row, col) => {
          var flag = 0;
          var ReceivedDate=$(`<td  style="display:ruby">${cellData}</td>`)
          $(td).html(ReceivedDate);
        }
      },
      {
        title:'Experience',
        data:'experience'
      },
      {
        title:'Location',
        data:'location'
      },
      {
        title:'Company Location',
        data:'companyName'
      },{
        title:'Recruiters',
        data:'recruiters',
        class:(activeSpoc && cookies?.roleName?.includes("SPOC"))?'show':'hide'
      }
    ]

  
    // if(getDemandList?.dList){
      
      {activeSpoc &&
        cookies.roleName != null &&
        cookies.roleName?.includes("SPOC")
        && stepDate != null &&
        stepDate.length > 0 &&
          stepDate.map((e)=>{
            
            var list={
              title:e.value,
              data:e.value
            }
            columnListTemp?.push(list)
          })
      }

      var nextColumn=[
        {
          title:'Step',
          data:'step',
          class:(activeRecruiter==true || activeManager==true||activeTL==true)?'show':'hide'
        },
        {
          title:'Final Status',
          data:'finalStatus',
          class:(activeSpoc && cookies.roleName != null && cookies.roleName.includes("SPOC") ?'show':'hide'),
          createdCell:(td, cellData, rowData, row, col) => {
            
            var value=rowData.finalStatus
            var dropdown="";
            dropdown=$(`<select id="DemandStatus" class="form-control"  style="width: 100px;border: none;background: transparent;">
            <option value="New">New</option>
            <option value="Open">Open</option>
            <option value="Close">Close</option>
            <option value="Rejected">Reject</option>
          </select>`).val(value).on(
            "change",
            function(e){
                ChangeDemandStatus(
                e.target.value,
                rowData?.demandRequestID
              ) 
                
            }
          )
          $(td).html(dropdown);
          }
        },
        {
          title:'Action',
          data : "demandRequestID",
          createdCell: (td, cellData, rowData, row, col) => {
            
            var ex = rowData?.experience.split("-");
            var JobDes = rowData?.jobDescription;
            var dID = rowData?.demandRequestID;
            var Dcode = rowData?.demandCode;
            var client = rowData?.client;
            var skill =rowData?.skillSet;
            var exp = rowData?.experience;
            var Step =(!cookies.roleName?.includes("SPOC") || activeSpoc != true) ? rowData.step : "";;
            var NextStepName = rowData?.nextStepName;
            var CurrentStep = rowData?.currentStep;
            var CurrentStatus = rowData?.currentStatus;
            var Stepp = "";
              if (CurrentStep != null && CurrentStep != "") {
                Stepp = Step.includes(CurrentStep)
                  ? CurrentStep
                  : Step;
              } else {
                Stepp = Step;
              }
              var JobDescription = rowData?.jobDescription;
              var CilentId = rowData?.clientID;
              var DemandFulfilment = rowData?.demandFulfillmentID;
              var SkillID = rowData?.skillSetID;
              var SkillsetTypeID = rowData?.skillSetTypeID;
              var DemandDate = rowData?.date.split(" ");
              DemandDate = DemandDate[0];
              var DatePicker = rowData?.date;
              var Minex = ex[0];
              var maxex = ex[1];
              var location = rowData?.location;
              var DemandTypeID = rowData?.demandTypeID;
              var RecruiterID = rowData?.recruiterID;
              var finalStatus = rowData?.finalStatus;
              var companyID = rowData?.companyID
              var IsAccept = rowData?.acceptTime;
              var flag = 0;

              let profileBtn="";
              let ProfileViewBtn = "";
              let EditBtn = "";
              let DeleteBtn="";
              let Fragment = $(`<div class="form-button-action"></div`);

              ProfileViewBtn=$(`<a href="#" class="btn btn-link btn-success btn-sm" title="Profiles view" style="font-Size: 18px"><i class="fas fa-chevron-circle-right"></i></a>`).on(
              "click",
              function(){
                CandidatePipeline(
                  dID,
                  Dcode,
                  client,
                  skill,
                  exp,
                  Step,
                  NextStepName,
                  2,
                  IsAccept
                )
              }
             )

              profileBtn = $(`<a href="#" class="btn btn-link btn-success btn-sm" title="Profiles" style="font-size:18px"><i class="fas fa-file-alt"></i></a>`).on(
              "click",
              function(){
                CandidatePipeline(
                  dID,
                  Dcode,
                  client,
                  skill,
                  exp,
                  Step,
                  NextStepName,
                  1,
                  IsAccept
                )
              }
            )
           
            EditBtn=$(`<button type="button" title="Edit" class="btn btn-link btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#addRowModal" style="font-size:18px"><i class="fa fa-edit"></i></button></div>`).on(
              "click",
              function(){
                EditDemand(
                  dID,
                  JobDescription,
                  CilentId,
                  DemandFulfilment,
                  SkillID,
                  SkillsetTypeID,
                  DemandDate,
                  Minex,
                  maxex,
                  location,
                  DemandTypeID,
                  RecruiterID,
                  companyID
                )
              }
            )

             
             DeleteBtn=$(`<button type="button" data-bs-toggle="tooltip" title="Remove" class="btn btn-link btn-danger btn-sm" style="font-size:18px"><i class="fas fa-trash-alt"></i></button>`).on(
              "click",
              ()=>DeleteDemand(dID)
             )

             {cookies.roleName != null &&
              (cookies.roleName?.includes("SPOC") && activeSpoc == true)?Fragment.append(ProfileViewBtn):Fragment.append(profileBtn)}

              {priviledge.includes("DEMANDEDIT")&& Fragment.append(EditBtn)}

             {priviledge.includes("DEMANDDELETE")  &&  Fragment.append(DeleteBtn)}
            $(td).html(Fragment);
          },
        }
      ]
      nextColumn.map((next)=>{
        columnListTemp?.push(next)
      })
      
      setColumnList(columnListTemp)


      var mergedObject = [];
      var DemList = [];
      var stepDetails=[];
      
            if(stepDate!=null && stepDate!=undefined && stepDate?.length>0){
              
              
              getDemandList?.dList?.forEach((list) => {
          
                stepDetails=[]
                mergedObject=[]
                stepDate?.forEach((column)=>{
                  
                    getDemandList?.demandStep?.forEach((step,i) => {

                        if (list?.demandRequestID == step?.demandRequestID) {
                          
                          var stepvalue=step['stepID']
                          var value=step['employeeID']
                          stepDetails={...stepDetails,[stepvalue]:value}
                          if(mergedObject.length==0){
                            mergedObject = { ...list, ...step,...stepDetails};
                          }
                          else{
                            mergedObject={...mergedObject,...stepDetails}
                          }
                          
                        }
                    });
                    if(mergedObject.length==0){
                        if(mergedObject?.length==0){
                          mergedObject = { ...list};
                        }
                    }
                    
                var columnValue=column?.value
                if(!(columnValue in mergedObject)){
                  
                  mergedObject={...mergedObject,[columnValue]:null}
                }
                })
                
                DemList?.push(mergedObject);
              });
            }

      setTempDemandList(DemList)

    // }
  },[getDemandList])


  const extractedValues = screenBasedEmp?.map(emp => {
    // const nameParts = emp?.label?.split('-');
    const extractedName = emp?.label?.trim()?.toLowerCase(); // Convert to lowercase
    return { ...emp, extractedName };
  });

  const extractedEmployeeValues = Employee?.map(emp => {
    // const nameParts = emp?.label?.split('-');
    const extractedName = emp?.label?.trim()?.toLowerCase(); // Convert to lowercase
    return { ...emp, extractedName };
  });

  const constextractedClient = Client?.map(emp => {
    const nameParts = emp?.value?.trim()
    const extractedName = nameParts?.toLowerCase(); // Convert to lowercase
    return { ...emp, extractedName };
  });

  const extractedCompLoc = CompanyLocation?.map(emp => {
    const nameParts = emp?.value?.trim()
    const extractedName = nameParts?.toLowerCase(); // Convert to lowercase
    return { ...emp, extractedName };
  });

  const extractedDemandFulfilment = DemandFulfilment?.map(emp => {
    const nameParts = emp?.value?.trim()
    const extractedName = nameParts?.toLowerCase(); // Convert to lowercase
    return { ...emp, extractedName };
  });

  const extractedDemandType = DemandType?.map(emp => {
    const nameParts = emp?.value?.trim()
    const extractedName = nameParts?.toLowerCase(); // Convert to lowercase
    return { ...emp, extractedName };
  });

  const extractedSkillSet = SkillSet?.map(emp => {
    const nameParts = emp?.value?.trim()
    const extractedName = nameParts?.toLowerCase(); // Convert to lowercase
    return { ...emp, extractedName };
  });

  const extractedSkillSetType = SkillSetType?.map(emp => {
    const nameParts = emp?.value?.trim()
    const extractedName = nameParts?.toLowerCase(); // Convert to lowercase
    return { ...emp, extractedName };
  });

  function FileUpload(e) {
debugger
    var stepValue;
    var dateList;
    var FileDate = [];
    var dynaStep = [];
    var SavedDate = [];
    var updatedFileDate = "";
    var str = "";
    var screen_Based_Name=[];
    console.log(stepDate);

    let selectedFile = e.target.files[0];
    if (selectedFile) {

      // console.log(selectedFile.type);
      if (selectedFile && fileType.includes(selectedFile.type)) {

        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
debugger
          setExcelFileError(null);
          setExcelFile(e.target.result);

          const workbook = XLSX.read(e.target.result, {
            type: "buffer",
            cellText: false,
            cellDates: true,
          });

          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          screen_Based_Name=stepDate?.map(e=>e?.value);
          const Default_Valid_Columns=["S.NO","Client","DemandType","DemandFulfilment","SkillSet","SkillSetType","JobDescription","MinimumExperience","MaximumExperience","Location","CompanyLocation","Recruiters"]
          const Valid_Columns = Default_Valid_Columns?.concat(screen_Based_Name);
          const Invalid_Columns = [];
          const range = XLSX?.utils?.decode_range(worksheet['!ref']);
          for (let col = range?.s?.c; col <= range?.e?.c; col++) {
              const cellAddress = { c: col, r: range?.s?.r };
              const headerCell = worksheet[XLSX?.utils?.encode_cell(cellAddress)];
              if (headerCell && headerCell.t === 's') {
                  const columnName = XLSX?.utils?.format_cell(headerCell);
                  if (!Valid_Columns?.includes(columnName)) {
                      Invalid_Columns?.push(columnName);
                  }
              } else {
                  Invalid_Columns?.push(""); // Handle empty column names
              }
          }
          
          // Join column names with comma separators
          const InvalidcolumnNamesString = Invalid_Columns?.join(", ");
          if(Invalid_Columns.length>0)
          {
            Swal.fire({
              icon: 'warning',
              title: 'Demand Upload Details',
              customClass: 'swal-wide',
              html: `
              <style>
                table {
                  border-collapse: collapse;
                  width: 100%;
                  margin-bottom: 10px;
                }
                th, td {
                  border: 1px solid #ddd;
                  padding: 8px;
                  text-align: center;
                }
                .error-container {
                  max-height: 100px;
                  overflow-y: auto;
                  margin-top: 10px;
                  word-wrap: break-word;
                }
                .error-list {
                  list-style-type: none;
                  padding: 0;
                  margin: 0;
                }
                .error-list-item {
                  margin-bottom: 5px;
                  font-size: 13px;
                  text-align:left;
                }
                .error-head{
                  font-size: 13px;
                }
                
              </style>
              <table>
                <thead>
                  <tr>
                    <th>Invalid Column Names</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>${InvalidcolumnNamesString}</td>
                  </tr>
                </tbody>
              </table>
            `,
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
          }
          else
          {
            const data = XLSX.utils.sheet_to_json(worksheet, {
              header: 0,
              raw: false,
              dateNF: "yyyy-mm-dd",
            });
  
  
            // console.log(data)
  
            // var obj = 0;
  
            data != null &&
              data.map((i, obj) => {
  
                dynaStep = [];
                dateList = {
                  CompanyID: i.CompanyLocation,
                  Client: i.Client,
                  DemandType: i.DemandType,
                  DemandFulfilment: i.DemandFulfilment,
                  SkillSet: i.SkillSet,
                  SkillSetType: i.SkillSetType,
                  JobDescription: i.JobDescription,
                  MinimumExperience: i.MinimumExperience,
                  MaximumExperience: i.MaximumExperience,
                  Location: i.Location,
                  Recruiters: i.Recruiters,
                  stepStr: "",
                };
  
                if (data.hasOwnProperty(obj)) {
  
                  for (var prop in data[obj]) {
  
                    if (data[obj].hasOwnProperty(prop)) {
  
                      stepDate != null &&
                        stepDate.length > 0 &&
                        stepDate.map((e) => {
  
                          if (e.value == prop) {
                            const matchingEmp = extractedValues.find(emp =>
                              emp.key.toLowerCase() == prop.toLowerCase() &&
                              emp.extractedName.toLowerCase() == data[obj][prop]?.trim()?.toLowerCase()
                            );
  
                            if (matchingEmp) {
  
                              stepValue = {
                                key: prop,
                                value: matchingEmp.value
                              };
                              dynaStep.push(stepValue);
                              dateList.stepStr = JSON.stringify(dynaStep);
                            }
                          }
                        });
                    }
                  }
                }
  
  
                if (stepDate.length != dynaStep?.length) {
  
                  stepDate?.forEach((screen) => {
  
                    if (dynaStep.length != 0) {
                      dynaStep?.forEach((e) => {
  
                        if (screen.value != e.key) {
  
                          stepValue = {
                            key: screen.value,
                            value: 0
                          };
                          dynaStep.push(stepValue);
                          dateList.stepStr = JSON.stringify(dynaStep);
                        }
                      })
                    }
                    else {
                      stepValue = {
                        key: screen.value,
                        value: 0
                      };
                      dynaStep.push(stepValue);
                      dateList.stepStr = JSON.stringify(dynaStep);
                    }
                  })
                }
  
  
                FileDate.push(dateList);
                console.log(FileDate);
  
                updatedFileDate = FileDate.map((file, index) => {
  
                  if (index == obj) {
  debugger
                    const matchingClient = constextractedClient?.find(client => client?.extractedName == file['Client']?.trim()?.toLowerCase());
                    const mathingCompanyLocation = extractedCompLoc?.find(ComLoc => ComLoc?.extractedName == file['CompanyID']?.trim()?.toLowerCase());
                    const matchingRecruiters = extractedEmployeeValues?.find(Emp => Emp?.extractedName == file['Recruiters']?.trim()?.toLowerCase());
                    const matchingDemandFulfilment = extractedDemandFulfilment?.find(Emp => Emp?.extractedName == file['DemandFulfilment']?.trim()?.toLowerCase());
                    const matchingDemandType = extractedDemandType?.find(Emp => Emp?.extractedName == file['DemandType']?.trim()?.toLowerCase());
                    const matchingSkillSet = extractedSkillSet?.find(Emp => Emp?.extractedName == file['SkillSet']?.trim()?.toLowerCase());
                    const matchingSkillSetType = extractedSkillSetType?.find(Emp => Emp?.extractedName == file['SkillSetType']?.trim()?.toLowerCase());
                    if (matchingClient) {
                      // Replace the value with the new value
                      file['Client'] = matchingClient?.id; // Replace 'New Value' with the desired value
                    }
                    else if (file['Client'] == undefined || file['Client'] == null) {
                      file['Client'] = 0
                    }
                    if (mathingCompanyLocation) {
                      file['CompanyID'] = mathingCompanyLocation?.id; // Replace 'New Value' with the desired value
                    }
                    else if (file['CompanyID'] == undefined || file['CompanyID'] == null) {
                      file['CompanyID'] = 0
                    }
  
                    if (matchingRecruiters) {
                      file['Recruiters'] = matchingRecruiters?.value; // Replace 'New Value' with the desired value
                    }
                    else if (file['Recruiters'] == undefined || file['Recruiters'] == null) {
                      file['Recruiters'] = 0
                    }
                    if (matchingDemandFulfilment) {
                      file['DemandFulfilment'] = matchingDemandFulfilment?.id; // Replace 'New Value' with the desired value
                    }
                    else if (file['DemandFulfilment'] == undefined || file['DemandFulfilment'] == null) {
                      file['DemandFulfilment'] = 0
                    }
                    if (matchingDemandType) {
                      file['DemandType'] = matchingDemandType?.id; // Replace 'New Value' with the desired value
                    }
                    else if (file['DemandType'] == undefined || file['DemandType'] == null) {
                      file['DemandType'] = 0
                    }
                    if (matchingSkillSet) {
                      file['SkillSet'] = matchingSkillSet?.id; // Replace 'New Value' with the desired value
                    }
                    else if (file['SkillSet'] == undefined || file['SkillSet'] == null) {
                      file['SkillSet'] = 0
                    }
                    if (matchingSkillSetType) {
                      file['SkillSetType'] = matchingSkillSetType?.id; // Replace 'New Value' with the desired value
                    }
                    else if (file['SkillSetType'] == undefined || file['SkillSetType'] == null) {
                      file['SkillSetType'] = 0
                    }
                    if (file['MaximumExperience'] == undefined || file['MaximumExperience'] == null) {
                      file['MaximumExperience'] = 0
                    }
                    if (file['MinimumExperience'] == undefined || file['MinimumExperience'] == null) {
                      file['MinimumExperience'] = 0
                    }
  
                    return file;
                  }
                })
                if ((updatedFileDate.length - 1) > 0) {
  
                  updatedFileDate.forEach((e, i) => {
  
                    if (e != undefined) {
                      
                      SavedDate.push(e)
                    }
                  })
                }
                else {
                  
                  updatedFileDate.forEach((e) => {
                    
                    SavedDate.push(e)
                  })
                }
  
              });
            
            str = JSON.stringify(SavedDate)
            SavaDemand(str)
          };
          console.log(FileDate);
          }
      } else {
        setExcelFileError("Please select only excel file types");
        swal({
          text: "Invalid file type. Please select an Excel file (.xlsx)",
          icon: "warning",
          button: "Ok",
        });
        setExcelFile(null);
      }
    } else {
      console.log("plz select your file");
    }
  }

  const SavaDemand = (str) => {

    const data = {
      DemandReqStr: str,
      EmployeeID: Number(cookies.userID),
    };
    $(".loader-container").show();
    _DemandService
      .SavaDemandRequest(data)
      .then((res) => {
        if(res?.data>0){
          $(".loader-container").hide();
        swal({
          text: "Demand has been uploaded successfully",
          icon: "success",
          button: "Ok",
        });
        GetDemandList()
        }
        else{
          $(".loader-container").hide();
          swal({
            text: "something went wrong",
            icon: "error",
            button: "Ok",
          });
          GetDemandList()
        }
        // console.log(res.data);
      })
      .catch((res) => {
        $(".loader-container").hide();
      });
  };
  // const CopyDes = (text) => {
  //     navigator.clipboard.writeText(text);
  // }

  function CopyDes(element) {
    
    var $temp = $("<input>");

    $("body").append($temp);

    $temp.val(element).select();

    document.execCommand("copy");

    $temp.remove();
  }

  const navigate = useNavigate();
  
  const CandidatePipeline = (
    e,
    Dcode,
    client,
    skill,
    exp,
    Step,
    NextStepName,
    isview,
    IsAccept
  ) => {

    if (IsAccept > 0 || !cookies.roleName?.includes("Recruiter") || activeRecruiter != true) {
      navigate("/CandidatePipeline", {
        state: {
          DemandReqID: e,
          Dcode: Dcode,
          client: client,
          skill: skill,
          exp: exp,
          Step: Step,
          NextStepName: NextStepName,
          isview: isview,
          activeRoleName: activeRoleName,
          activeTab: activeHR == true ? 'HR' : activeManager == true ? 'Manager' : activePMO == true ? 'PMO' : activeRecruiter == true ? 'Recruiter' : activeSpoc == true ? 'SPOC' : 'TL'
        },
      });
    } else {
      _DemandService.AcceptAssessment(Number(e), Step).then((res) => {
        swal({
          title: "SLA",
          text: "On Your Acceptance SLA will end up With " + res.data,
          icon: "info",
          buttons: true,
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            $(".loader-container").show();
            _DemandService
              .SLA_SaveAcceptTime(Number(e), Step, Number(cookies.userID))
              .then((res) => {
                navigate("/CandidatePipeline", {
                  state: {
                    DemandReqID: e,
                    Dcode: Dcode,
                    client: client,
                    skill: skill,
                    exp: exp,
                    Step: Step,
                    NextStepName: NextStepName,
                    isview: isview,
                    activeRoleName: activeRoleName,
                    activeTab: activeHR == true ? 'HR' : activeManager == true ? 'Manager' : activePMO == true ? 'PMO' : activeRecruiter == true ? 'Recruiter' : activeSpoc == true ? 'SPOC' : 'TL'
                  },
                });
                $(".loader-container").hide();
                GetDemandList()
              })
              .catch((res) => {
                $(".loader-container").hide();
              });
          }
        });
      });
    }
  };

  const DeleteDemand = (DemandID) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this Demand?",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        $(".loader-container").show();
        _DemandService
          .DeleteDemand(DemandID)
          .then((res) => {
            $(".loader-container").hide();
            swal({
              text: "Demand has been deleted successfully",
              icon: "success",
              button: "Ok",
            });
            GetDemandList()
          })
          .catch((res) => { });
      }
    });
  };

  const RecruitersChange = (e) => {
    SetSelectedValue(e);
    setDemandErr({ Recruiters: "" });
  };

  const EditDemand = (
    dID,
    JobDescription,
    CilentId,
    DemandFulfilment,
    SkillID,
    SkillsetTypeID,
    formattedDate,
    Minex,
    maxex,
    location,
    DemandTypeID,
    RecruiterID,
    companyID
  ) => {
debugger
    setPopName("Edit");
    setButtonName("Update");
  
    SetDemandID(dID);
    setSelectedLocation(location)
    $("#DemandRequestID").val(dID);
    $("#JobDec").val(JobDescription);
    $("#Client").val(CilentId);
    $("#DemandType").val(DemandTypeID);
    $("#DemandFullfilment").val(DemandFulfilment);
    $("#SkillSet").val(SkillID);
    $("#SkillSetType").val(SkillsetTypeID);
    $("#MinExperience").val(Minex);
    $("#MaxExperience").val(maxex);
    $("#Location").val(location);
    $("#CompanyLocation").val(companyID);

    var SelectedEmp = [];
    var s = RecruiterID?.split(",");
    for (var i = 0; i < s?.length; i++) {
      const GetEmp = Employee?.filter((emp) => {
        return parseInt(s[i]) === emp.value;
      });
      SelectedEmp.push(GetEmp[0]);
    }
    SetSelectedValue(SelectedEmp);
    stepdropdown(dID);
  };

  const [DemandErr, setDemandErr] = useState({
    DemandReqID: "",
    JobDescription: "",
    Client: "",
    DemandType: "",
    DemandFulfilment: "",
    SkillSet: "",
    SkillSetType: "",
    Date: "",
    minExp: "",
    Location: "",
    stepStr: "",
    CompanyLocation: "",
  });

  var dynamicCell = [];
  const SaveDemand = (e) => {
    
    e.preventDefault();
    var flag = true;
    const formdata = new FormData(e.currentTarget);
    var Stepstr = localStorage.getItem("StepStr");
    var val = [];
    var parsedData;
    var rec;
    if (SelectedValue != null) {
      SelectedValue.map((v, i) => val.push(v.value));
      // console.log(val.join(','));
      rec = val.join(",");
    }

    const dateList = {
      DemandReqID: Number(formdata.get("DemandRequestID")),
      JobDescription: formdata.get("JobDescription"),
      Client:
        formdata.get("Client") === null || formdata.get("Client") === undefined
          ? Number(0)
          : Number(formdata.get("Client")),
      DemandType: Number(formdata.get("DemandType")),
      DemandFulfilment: Number(formdata.get("DemandFullfilment")),
      SkillSet: Number(formdata.get("SkillSet")),
      SkillSetType: Number(formdata.get("SkillSetType")),
      minExp: formdata.get("MinExperience"),
     
      maxExp: Number(formdata.get("MaxExperience")),
      Location: selectedLocation,
      Recruiters: rec,
      stepStr: Stepstr != null && Stepstr !== "" ? Stepstr : "",
      EmployeeID: Number(cookies.userID),
      CompanyLocation: selectedCompanyID,
    };
  
    if (dateList?.stepStr != null && dateList?.stepStr != undefined && dateList?.stepStr != "") {

      parsedData = JSON.parse(dateList?.stepStr);
      parsedData.map((e) => {

        dynamicCell.push(e)
      })
    }

    const newDemandErr = {};

    // Validate all fields
    if (dateList.JobDescription == null || dateList.JobDescription == "") {
      newDemandErr.JobDescription = "Please Enter Job Description";
      flag = false;
    }
    if (
      dateList.DemandReqID == null ||
      dateList.DemandReqID == "" ||
      dateList.DemandReqID == 0
    ) {
      if (dateList.Client == null || dateList.Client == "") {
        newDemandErr.Client = "Please Select Client"
        flag = false;
      }
    }

    if (
      dateList.DemandType == null ||
      dateList.DemandType == "" ||
      dateList.DemandType == 0
    ) {
      newDemandErr.DemandType = "Please Select Demand Type"
      flag = false;
    }
    if (
      dateList.DemandFulfilment == null ||
      dateList.DemandFulfilment == "" ||
      dateList.DemandFulfilment == 0
    ) {
      newDemandErr.DemandFulfilment = "Please Select Demand Fulfilment"
      flag = false;
    }
    if (
      dateList.SkillSet == null ||
      dateList.SkillSet == "" ||
      dateList.SkillSet == 0
    ) {
      newDemandErr.SkillSet = "Please Select Skill Set"
      flag = false;
    }
    if (
      dateList.SkillSetType == null ||
      dateList.SkillSetType == "" ||
      dateList.SkillSetType == 0
    ) {
      newDemandErr.SkillSetType = "Please Select Skill Set Type"
      flag = false;
    }
    // if (dateList.Date == null || dateList.Date == "") {
    //   setDemandErr({ Date: "Please Select Date" });
    //   flag = false;
    // }
    if (
      dateList.minExp == null ||
      dateList.minExp == ""
    ) {
      newDemandErr.minExp = "Please Enter Minimum Experience"
      flag = false;
    }
    // if (
    //   dateList.maxExp == null ||
    //   dateList.maxExp == "" ||
    //   dateList.maxExp == 0
    // ) {
    //   setDemandErr({ maxExp: "Please Enter Maximum Experience" });
    //   flag = false;
    // }
    if (dateList.Location == null || dateList.Location == "") {

      newDemandErr.Location = "Please Enter Location"
      flag = false;
    }
    // if (dateList.Recruiters == null || dateList.Recruiters == "") {
    //   newDemandErr.Recruiters= "Please Enter Recruiters"
    //   flag = false;
    // }

    stepDate.forEach((step) => {

      if (step.isMandatory) {
        if (parsedData !== undefined && parsedData !== null) {
          parsedData?.forEach((e) => {
            if (step.value === e.key) {
              if (e.value === null || e.value === undefined || e.value === "") {
                newDemandErr[e.key] = `Please Select ${e.key} Details`;
                flag = false;
              }
            }
          });
        } else {
          newDemandErr[step.value] = `Please Select ${step.value} Details`;
          flag = false;
        }
      } else {

        if (stepDate.length != dynamicCell.length) {
          stepDate.forEach((screen) => {

            if (parsedData != null && parsedData != undefined && parsedData != "") {
              parsedData.forEach((e) => {

                if (e.key != screen.value) {

                  // if(!e.value>0){

                  const DetailsObj = {
                    DemandStepID: 0,
                    value: 0,
                    key: screen.value,
                  };
                  dynamicCell.push(DetailsObj);

                  var stringifyVal = JSON.stringify(dynamicCell);
                  dateList['stepStr'] = stringifyVal;
                  localStorage.setItem("StepStr", stringifyVal);
                  // }
                }
              })
            }
            else {

              if (stepDate.length != dynamicCell.length) {

                stepDate.forEach((screen) => {

                  const DetailsObj = {
                    DemandStepID: 0,
                    value: 0,
                    key: screen.value,
                  };
                  dynamicCell.push(DetailsObj);

                  var stringifyVal = JSON.stringify(dynamicCell);
                  dateList['stepStr'] = stringifyVal;
                  localStorage.setItem("StepStr", stringifyVal);
                })
              }
            }
          })
        }
        else {

          if (parsedData != null && parsedData != undefined && parsedData != "") {

            parsedData.forEach((screen, i) => {

              if (screen['value'] == "") {

                screen['value'] = 0
                dynamicCell[i] = screen;

                var stringifyVal = JSON.stringify(dynamicCell);
                dateList['stepStr'] = stringifyVal;
                localStorage.setItem("StepStr", stringifyVal);

              }
            })
          }
        }
      }
    });


    setDemandErr((prevErr) => ({ ...prevErr, ...newDemandErr }));

    console.log(dateList);
    if (flag) {
      SaveData(dateList);
    }
  };

  const SaveData = (data) => {

    $(".loader-container").show();
    _DemandService
      .UpdatedemandData(data)
      .then((res) => {

        $(".close").click();
        $(".loader-container").hide();
        swal({
          text:
            DemandID > 0
              ? "Demand  has been updated successfully"
              : "Demand  has been saved successfully",
          icon: "success",
          button: "Ok",
        });
        GetDemandList()
        ClosePop();
      })
      .catch((res) => { });
  };

  var dynamicCellEdit = [];
  const stepdropdown = (DemandID) => {
debugger;
    $(".StepDDL").val("");
    return (
      stepDate != null &&
      stepDate.length > 0 &&
      stepDate.map((e) => {
        var screenName = "";
        if (DemandID != null && DemandID != "") {
          var St = Stepsgrp;
          var Emp = "";
          var EmployeeLength = 0;
          if (Stepsgrp != null && Stepsgrp.length > 0) {
            Emp = Stepsgrp.filter((em) => {
              return em.demandRequestID == DemandID && e.value === em.stepID;
            });
            EmployeeLength = Emp.length;
          }
          var DemandStepDetailsId = "";
          if (EmployeeLength > 0) {
            DemandStepDetailsId = Emp[0].demandStepDetailID;
          } else {
            DemandStepDetailsId = 0;
          }
          var Id = "";
          if (EmployeeLength > 0) {
            Id = Emp[0].empID;
          } else {
            Id = 0;
          }
          // $('.StepDDL').val(Id);

          // var Id = Emp[0].empID;
          screenName = e.value;
          var ClassName = ".StepDDL" + screenName;

          $(ClassName).val(Id);

          const DetailsObj = {
            DemandStepID: DemandStepDetailsId,
            value: Id,
            key: screenName,
          };
          dynamicCellEdit.push(DetailsObj);
          localStorage.setItem("StepStr", JSON.stringify(dynamicCellEdit));
        }
      })
    );
  };

  var dynamicCell = []; // Initialize an empty array to store the data

  const StepDet = (EmpId, Screen, DemandStepDetailsId) => {

    const DetailsObj = {
      DemandStepID: DemandStepDetailsId,
      value: EmpId,
      key: Screen,
    };

    if (EmpId != null && EmpId != undefined && EmpId !== "") {
      setDemandErr((prevErr) => ({ ...prevErr, [Screen]: "" }));
    }



    let dynamicCell = JSON.parse(localStorage.getItem("StepStr")) || [];

    // Check if the item already exists in dynamicCell
    const existingItemIndex = dynamicCell.findIndex(
      (Step) =>
        Step.DemandStepID === DetailsObj.DemandStepID &&
        Step.key === DetailsObj.key
    );

    if (existingItemIndex !== -1) {
      // Update the existing item if found
      dynamicCell[existingItemIndex] = DetailsObj;
    } else {
      // Add the new item if not found
      dynamicCell.push(DetailsObj);
    }

    // Save the updated dynamicCell to localStorage
    localStorage.setItem("StepStr", JSON.stringify(dynamicCell));
  };

  const ChangeDemandStatus = (DemandStatus, DemandID) => {
    
    _DemandService
      .ChangeDemandStatus(DemandStatus, DemandID)
      .then((res) => {
        swal({
          text: "Demand status  has been changed successfully",
          icon: "success",
          button: "Ok",
        });
        GetDemandList()
      })
      .catch((res) => { });
  };

  function ClearData() {
    $("#DemandRequestID").val(0);
    $("#JobDec").val("");
    $("#Client").val("");
    $("#DemandType").val("");
    $("#DemandFullfilment").val("");
    $("#SkillSet").val("");
    $("#SkillSetType").val("");
    setStartDate(new Date());
    $("#MinExperience").val("");
    $("#MaxExperience").val("");
    $("#Location").val("");
    $(".ScreenDDL").val("");
    $("#CompanyLocation").val(selectedCompanyID);
    SetSelectedValue(null);
    localStorage.removeItem("StepStr");
    SetDemandID(null);
    setSelectedLocation(null)
  }

  const ClosePop = () => {
    $(".close").click();
    if (PopName == "Add") {
      setPopName("Edit");
    }
    if (PopName == "Edit") {
      setPopName("Add");
    }

    if (ButtonName == "Add") {
      setButtonName("Update");
    }
    if (ButtonName == "Update") {
      setButtonName("Add");
    }
    ClearData();
    // document.getElementById("MaxExperience").disabled = true;
  };

  const ChangePopName = () => {
    // ClosePop();
    setPopName("Add");
    setButtonName("Add");
  };

  function numberOnly(id) {
    var element = document.getElementById(id);
    element.value = element.value.replace(/[^0-9]/g, "");

    if (id === "MinExperience" && parseInt(element.value) >= 0) {
      setDemandErr({ minExp: "" });
      // document.getElementById("MaxExperience").disabled = false;
    }

    if (id === "MaxExperience") {
      var minExpValue = parseInt(
        document.getElementById("MinExperience").value
      );
      var maxExpValue = parseInt(element.value);

      if (
        !isNaN(minExpValue) &&
        !isNaN(maxExpValue) &&
        maxExpValue < minExpValue
      ) {
        setDemandErr({
          maxExp: "Max Experience should be greater than Min Experience",
        });
      } else {
        setDemandErr({ maxExp: "" });
      }
    }
  }

  const ChangeDate = (date) => {
    setStartDate(date);
    setDemandErr({ Date: "" });
  };

  const TableHeader = (e, flag, ev) => {

    var html = "";
    var body = "";

    var DemandDate = e.date.split(" ");
    DemandDate = DemandDate[0];

    html += `<th style="background: white!important;width: 100px;">Request Received Date</th>`;
    if (activeRoleName?.includes("SPOC")) {
      html += `<th style="background: white!important;width: 100px;">Recruiters</th>`;
    }

    if (
      activeRoleName != null &&
      activeRoleName?.includes("SPOC") &&
      stepDate != null &&
      stepDate.length > 0
    ) {
      stepDate.map((e) => {
        html += `<th style="background: white!important;width: 100px;">${e.value}</th>`;
      });
    } else if (!activeRoleName?.includes("HR") && !activeRoleName?.includes("PMO")) {
      html += `<th style="background: white!important;width: 100px;">Step</th>`;
    }
    if (activeRoleName?.includes("SPOC")) {
      html += `<th style="background: white!important;width: 100px;">Profile Count</th>`;
    }
    body += `<td>${DemandDate}</td>`;
    if (activeRoleName?.includes("SPOC")) {
      body += `<td>${e.recruiters==null?"":e.recruiters}</td>`;
    }
    if (
      activeRoleName != null &&
      activeRoleName?.includes("SPOC") &&
      stepDate != null &&
      stepDate.length > 0
    ) {
      stepDate.map((s) => {
        var EmployeeName = "";
        if (
          getDemandList.demandStep != null &&
          getDemandList.demandStep.length > 0
        ) {
          getDemandList.demandStep.map((d) => {
            if (d.demandRequestID === e.demandRequestID) {
              if (s.value === d.stepID) {
                flag = 1;
                EmployeeName = d.employeeID;
                // return <td>{d.employeeID}</td>
              }
            }
          });
        }
        if (flag == 0) {
          body += `<td></td>`;
        } else {
          body += `<td>${EmployeeName}</td>`;
        }
      });
    } else {
      if (!activeRoleName?.includes("HR") && !activeRoleName?.includes("PMO")) {
        body += `<td>${e.step}</td>`;
      }
    }
    if (activeRoleName?.includes("SPOC")) {
      body += `<td>${e.demandCount}</td>`;
    }
    // <></>

    let table = new DataTablee("#DemandRequestTable");
    let tr = ev.target.closest("tr");
    let row = table.row(tr);


    if (row.child.isShown()) {
      // This row is already open - close it
      row.child.hide();
    } else {
      // Open this row
      row
        .child(
          `<table class='subTable'><thead><tr>${html}</tr></thead><tbody><tr>${body}</tr></tbody></table>`
        )
        .show();
    }
    return row;
  };

  const clearAllErrors = () => {
    setDemandErr({
      DemandReqID: "",
      JobDescription: "",
      Client: "",
      DemandType: "",
      DemandFulfilment: "",
      SkillSet: "",
      SkillSetType: "",
      Date: "",
      minExp: "",
      maxExp: "",
      Location: "",
      Recruiters: "",
      stepStr: "",
    });
  };

  function handleDownloadClick() {
    _DemandService
      .TemplateColumn(companyID, roleName)
      .then((response) => {
        // Create a blob URL for the Excel file data
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element and trigger a click to start the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "DemandTemplate.xlsx"; // Specify the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading Excel file", error);
      });
  }


  const handleChangeActiveTab = (value) => {

    if (value == 'SPOC') {
      setActiveSpoc(true);
      setactiveRecruiter(false);
      setactiveHR(false);
      setactivePMO(false);
      setactiveTL(false)
      setActiveManager(false)

      if (roleName.includes('Super Admin')) {
        role = 'Super Admin,SPOC'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()

      }
      else {
        role = 'SPOC'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
    }
    else if (value == 'Recruiter') {
      setActiveSpoc(false);
      setactiveRecruiter(true);
      setactiveHR(false);
      setactivePMO(false);
      setactiveTL(false)
      setActiveManager(false)

      if (roleName.includes('Super Admin')) {
        role = 'Super Admin,Recruiter'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()

      }
      else {
        role = 'Recruiter'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
    }

    else if (value == 'HR') {
      setActiveSpoc(false);
      setactiveRecruiter(false);
      setactiveHR(true);
      setactivePMO(false);
      setactiveTL(false)
      setActiveManager(false)


      if (roleName.includes('Super Admin')) {
        role = 'Super Admin,HR'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
      else {
        role = 'HR'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
    }

    else if (value == 'PMO') {
      setActiveSpoc(false);
      setactiveRecruiter(false);
      setactiveHR(false);
      setactivePMO(true);
      setactiveTL(false)
      setActiveManager(false)

      if (roleName.includes('Super Admin')) {
        role = 'Super Admin,PMO'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
      else {
        role = 'PMO'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
    }

    else if (value == 'TL') {
      setActiveSpoc(false);
      setactiveRecruiter(false);
      setactiveHR(false);
      setactivePMO(false);
      setactiveTL(true)
      setActiveManager(false)

      if (roleName.includes('Super Admin')) {
        role = 'Super Admin,TL'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
      else {
        role = 'TL'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
    }

    else if (value == 'Manager') {
      setActiveSpoc(false);
      setactiveRecruiter(false);
      setactiveHR(false);
      setactivePMO(false);
      setactiveTL(false)
      setActiveManager(true)

      if (roleName.includes('Super Admin')) {
        role = 'Super Admin,Manager'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
      else {
        role = 'Manager'
        setActiveRoleName(role)
        setCookie("DemandActiveTabCookies", role, { path: "/" });
        GetDemandList()
      }
    }

  }

  function alphabetOnly(e) {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z ]+$/;
    if (!regex.test(inputValue)) {
        e.target.value = inputValue.replace(/[^a-zA-Z]/g, '');
    }
  }



  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            {/* <div className="page-header">
                            <h4 className="page-title">Employee </h4>

                        </div>  */}
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4
                        className="card-title mr-auto"
                        style={{ width: "85%" }}
                      >
                        Demand
                      </h4>
                      {priviledge.includes("DEMANDCREATE") && (
                        <button
                          className="btn btn-primary btn-sm btn-round ml-auto"
                          data-bs-toggle="modal"
                          onClick={(e) => ChangePopName()}
                          data-bs-target="#addRowModal"
                        >
                          <i className="fa fa-plus"></i>
                          <span className="ml-1"> Add Demand</span>
                        </button>
                      )}
                      {priviledge.includes("DEMANDUPLOAD") && (
                        <button
                          className="btn btn-primary btn-sm btn-round ml-2"
                          onClick={uploadbtn}
                          id="uploadBtn"
                        >
                          <i className="fa fa-upload"></i>{" "}
                          <span className="ml-1"> Demand Upload</span>
                        </button>
                      )}

                      <input
                        type="file"
                        id="DemandUpload"
                        onChange={FileUpload}
                        style={{ display: "none" }}
                      />

                      {priviledge.includes("DEMANDUPLOAD") && (
                        <button
                          className="btn btn-primary btn-sm btn-round ml-2"
                          onClick={handleDownloadClick}
                        >
                          <i className="fa fa-download"></i>{" "}
                          <span className="ml-1"> Demand Template</span>
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="card-body">
                    {/* Modal */}
                    <div
                      className="modal fade"
                      id="addRowModal"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                      data-bs-backdrop="static"
                    >
                      <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                          <div className="modal-header no-bd">
                            <h5 className="modal-title">
                              <span className="fw-mediumbold">{PopName} </span>
                              <span className="fw-light">Demand</span>
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                ClosePop();
                                clearAllErrors();
                              }}
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form onSubmit={SaveDemand}>
                              <input
                                type="hidden"
                                id="DemandRequestID"
                                name="DemandRequestID"
                              />
                              <div className="row">
                                {DemandID == null || DemandID == "" ? (
                                  <div className="col-sm-6">
                                    <div className="form-group ">
                                      <label>Client</label>{" "}
                                      <span className="text-danger">*</span>
                                      <select
                                        className="form-control "
                                        onChange={(e) =>
                                          setDemandErr({
                                            ...DemandErr,
                                            Client: "",
                                          })
                                        }
                                        id="Client"
                                        name="Client"
                                      >
                                        <option value="">Select Client</option>
                                        {Client != null &&
                                          Client.length > 0 &&
                                          Client.map((e) => {
                                            if (e.value != null) {
                                              return (
                                                <option value={e.id}>
                                                  {e.value}
                                                </option>
                                              );
                                            }
                                          })}
                                      </select>
                                      <span style={{ color: "red" }}>
                                        {DemandErr.Client}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Demand Type</label>{" "}
                                    <span className="text-danger">*</span>
                                    <select
                                      className="form-control "
                                      onChange={(e) =>
                                        setDemandErr({
                                          ...DemandErr,
                                          DemandType: "",
                                        })
                                      }
                                      id="DemandType"
                                      name="DemandType"
                                    >
                                      <option value="">
                                        Select Demand Type
                                      </option>
                                      {DemandType != null &&
                                        DemandType.length > 0 &&
                                        DemandType.map((e) => {
                                          if (e.value != null) {
                                            return (
                                              <option value={e.id}>
                                                {e.value}
                                              </option>
                                            );
                                          }
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {DemandErr.DemandType}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Demand Fullfilment</label>{" "}
                                    <span className="text-danger">*</span>
                                    <select
                                      className="form-control "
                                      onChange={(e) =>
                                        setDemandErr({
                                          ...DemandErr,
                                          DemandFulfilment: "",
                                        })
                                      }
                                      id="DemandFullfilment"
                                      name="DemandFullfilment"
                                    >
                                      <option value="">
                                        Select Demand Fullfilment
                                      </option>
                                      {DemandFulfilment != null &&
                                        DemandFulfilment.length > 0 &&
                                        DemandFulfilment.map((e) => {
                                          if (e.value != null) {
                                            return (
                                              <option value={e.id}>
                                                {e.value}
                                              </option>
                                            );
                                          }
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {DemandErr.DemandFulfilment}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Skill Set</label>{" "}
                                    <span className="text-danger">*</span>
                                    <select
                                      className="form-control "
                                      onChange={(e) =>
                                        setDemandErr({
                                          ...DemandErr,
                                          SkillSet: "",
                                        })
                                      }
                                      id="SkillSet"
                                      name="SkillSet"
                                    >
                                      <option value="">Select Skill Set</option>
                                      {SkillSet != null &&
                                        SkillSet.length > 0 &&
                                        SkillSet.map((e) => {
                                          if (e.value != null) {
                                            return (
                                              <option value={e.id}>
                                                {e.value}
                                              </option>
                                            );
                                          }
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {DemandErr.SkillSet}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Skill Set Type</label>{" "}
                                    <span className="text-danger">*</span>
                                    <select
                                      className="form-control "
                                      onChange={(e) =>
                                        setDemandErr({
                                          ...DemandErr,
                                          SkillSetType: "",
                                        })
                                      }
                                      id="SkillSetType"
                                      name="SkillSetType"
                                    >
                                      <option value="">
                                        Select Skill Set Type
                                      </option>
                                      {SkillSetType != null &&
                                        SkillSetType.length > 0 &&
                                        SkillSetType.map((e) => {
                                          if (e.value != null) {
                                            return (
                                              <option value={e.id}>
                                                {e.value}
                                              </option>
                                            );
                                          }
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {DemandErr.SkillSetType}
                                    </span>
                                  </div>
                                </div>
                                {/* <div className="col-sm-6">
                                  
                                  <div className="form-group ">
                                    <label>Request Received Date</label>{" "}
                                    <span className="text-danger">*</span>
                                    <DatePicker
                                      className="form-control"
                                      selected={startDate}
                                      onChange={(date) => ChangeDate(date)}
                                      dateFormat="dd-MM-yyyy"
                                      maxDate={new Date()}
                                    />
                                    <span style={{ color: "red" }}>
                                      {DemandErr.Date}
                                    </span>
                                  </div>
                                </div> */}
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Min Experience</label>{" "}
                                    <span className="text-danger">*</span>
                                    <input
                                      id="MinExperience"
                                      type="text"
                                      onChange={(e) =>
                                        numberOnly("MinExperience")
                                      }
                                      maxLength="2"
                                      className="form-control"
                                      name="MinExperience"
                                      placeholder="MinExperience"
                                    />
                                    <span style={{ color: "red" }}>
                                      {DemandErr.MinExperience}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Max Experience</label>{" "}

                                    <input
                                      id="MaxExperience"
                                      type="text"
                                      onChange={(e) =>
                                        numberOnly("MaxExperience")
                                      }
                                      maxLength="2"
                                      className="form-control"
                                      name="MaxExperience"
                                      placeholder="MaxExperience"

                                    />

                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Location</label>{" "}
                                    <span className="text-danger">*</span>
                                    <Autocomplete
                                      //options={locationList?.map((location) => location.LocationName)}
                                      options={locationList?.length > 0 ? locationList.map((location) => location.LocationName) : []}
                                      value={selectedLocation}
                                      id="Location"
                                      name="Location"
                                      onKeyUp={(e) => {
                                        setSelectedLocation(e.target.defaultValue);
                                      }}
                                      onChange={(e) => {
                                        setSelectedLocation(e.target.innerText);
                                        setDemandErr({
                                          ...DemandErr,
                                          Location: "",
                                        })
                                      }}
                                      
                                      renderInput={(params) => <TextField {...params} placeholder="Location" />}
                                    />

                                    <span style={{ color: "red" }}>
                                      {DemandErr.Location}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <label>Company Location</label>{" "}
                                    <span className="text-danger">*</span>
                                    <select
                                      className="form-control "
                                      onChange={CompanyLocationOnChange}
                                      id="CompanyLocation"
                                      name="CompanyLocation"
                                      disabled={roleName.includes('Super Admin') ? false : true}
                                      value={selectedCompanyID}
                                    >
                                      <option value="">
                                        Select Company Location
                                      </option>
                                      {CompanyLocation != null &&
                                        CompanyLocation.length > 0 &&
                                        CompanyLocation.map((e) => {
                                          if (e.value != null) {
                                            return (
                                              <option value={e.id}>
                                                {e.value}
                                              </option>
                                            );
                                          }
                                        })}
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {DemandErr.CompanyLocation}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group ">
                                    <label>Recruiters</label>{" "}

                                    <MySelect
                                      isMulti
                                      options={Employee}
                                      value={SelectedValue}
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      name="roleSeletedList"
                                      placeholder="Select Recruiters"
                                      onChange={RecruitersChange}
                                    />

                                  </div>
                                </div>
                                {
                                  // <>
                                  //     {stepdropdown(DemandID)}
                                  // </>
                                  // $('.StepDDL').val('');
                                  stepDate != null &&
                                  stepDate.length > 0 &&
                                  stepDate.map((e) => {

                                    var screenName = "";
                                    if (DemandID != null && DemandID != "") {
                                      var St = Stepsgrp;
                                      var Emp = "";
                                      var EmployeeLength = 0;
                                      if (
                                        Stepsgrp != null &&
                                        Stepsgrp.length > 0
                                      ) {
                                        Emp = Stepsgrp.filter((em) => {
                                          return (
                                            em.demandRequestID == DemandID &&
                                            e.value === em.stepID
                                          );
                                        });
                                        EmployeeLength = Emp.length;
                                      }
                                      var DemandStepDetailsId = "";
                                      if (EmployeeLength > 0) {
                                        DemandStepDetailsId =
                                          Emp[0].demandStepDetailID;
                                      } else {
                                        DemandStepDetailsId = 0;
                                      }
                                      var Id = "";
                                      if (EmployeeLength > 0) {
                                        Id = Emp[0].empID;
                                      } else {
                                        Id = 0;
                                      }
                                      // $('.StepDDL').val(Id);


                                      // var Id = Emp[0].empID;
                                      screenName = e.value;
                                      var Class =
                                        "form-control ScreenDDL StepDDL" +
                                        screenName;
                                      return (
                                        <div className="col-sm-6 DropDownStep">
                                          <div className="form-group">
                                            <label>{screenName}</label>{" "}
                                            {e.isMandatory &&
                                              <span className="text-danger">
                                                *
                                              </span>
                                            }
                                            <select
                                              className={Class}
                                              onChange={(e) =>
                                                StepDet(
                                                  e.target.value,
                                                  screenName,
                                                  DemandStepDetailsId
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select Employee
                                              </option>
                                              {screenBasedEmp != null &&
                                                screenBasedEmp.length > 0 &&
                                                screenBasedEmp.map((e) => {
                                                  if (screenName == e.key) {
                                                    return (
                                                      <option value={e.value}>
                                                        {e.label}
                                                      </option>
                                                    );
                                                  }
                                                })}
                                            </select>
                                            <span style={{ color: "red" }}>
                                              {DemandErr[screenName]}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      screenName = e.value;
                                      var Class =
                                        "form-control ScreenDDL StepDDL" +
                                        screenName;
                                      return (
                                        <div className="col-sm-6 DropDownStep">
                                          <div className="form-group">
                                            <label>{screenName}</label>{" "}
                                            {e.isMandatory &&
                                              <span className="text-danger">
                                                *
                                              </span>
                                            }
                                            <select
                                              className={Class}
                                              onChange={(e) =>
                                                StepDet(
                                                  e.target.value,
                                                  screenName,
                                                  0
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select Employee
                                              </option>
                                              {screenBasedEmp != null &&
                                                screenBasedEmp.length > 0 &&
                                                screenBasedEmp.map((e) => {
                                                  if (screenName == e.key) {
                                                    return (
                                                      <option value={e.value}>
                                                        {e.label}
                                                      </option>
                                                    );
                                                  }
                                                })}
                                            </select>
                                            <span style={{ color: "red" }}>
                                              {DemandErr[screenName]}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })
                                }
                                <div className="col-sm-12">
                                  <div className="form-group ">
                                    <label>Job Description</label>{" "}
                                    <span className="text-danger">*</span>
                                    <textarea
                                      id="JobDec"
                                      rows={5}
                                      className="form-control"
                                      name="JobDescription"
                                      placeholder="Job Description"
                                      onChange={(e) =>
                                        setDemandErr({
                                          ...DemandErr,
                                          JobDescription: "",
                                        })
                                      }
                                    />
                                    <span style={{ color: "red" }}>
                                      {DemandErr.JobDescription}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer no-bd">
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() => {
                                    ClosePop();
                                    clearAllErrors();
                                  }}
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  id="addRowButton"
                                  className="btn btn-primary btn-sm"
                                >
                                  {ButtonName}
                                </button>

                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <ul class="nav nav-pills">

                              {(roleName?.includes("SPOC") || roleName?.includes("Super Admin")) &&
                                <li class="nav-item">
                                  <a class={`nav-link ${activeSpoc ? 'active' : ''}`}
                                    href="#"
                                    onClick={() => handleChangeActiveTab('SPOC')}
                                  >
                                    SPOC
                                  </a>
                                </li>
                              }

                              {(roleName?.includes("Recruiter") || roleName?.includes("Super Admin")) &&
                                <li class="nav-item">
                                  <a class={`nav-link ${activeRecruiter ? 'active' : ''}`}
                                    href="#"
                                    onClick={() => handleChangeActiveTab('Recruiter')}
                                  >
                                    Recruiter
                                  </a>
                                </li>
                              }

                              {(roleName?.includes("TL") || roleName?.includes("Super Admin")) &&
                                <li class="nav-item">
                                  <a
                                    class={`nav-link ${activeTL ? 'active' : ''}`}
                                    aria-current="page"
                                    href="#"
                                    onClick={() => handleChangeActiveTab('TL')}
                                  >
                                    TL
                                  </a>
                                </li>
                              }

                              {(roleName?.includes("PMO") || roleName?.includes("Super Admin")) &&
                                <li class="nav-item">
                                  <a class={`nav-link ${activePMO ? 'active' : ''}`}
                                    href="#"
                                    onClick={() => handleChangeActiveTab('PMO')}
                                  >
                                    PMO
                                  </a>

                                </li>
                              }

                              {(roleName?.includes("Manager") || roleName?.includes("Super Admin")) &&
                                <li class="nav-item">
                                  <a class={`nav-link ${activeManager ? 'active' : ''}`}
                                    href="#"
                                    onClick={() => handleChangeActiveTab('Manager')}
                                  >
                                    Manager
                                  </a>
                                </li>
                              }

                              {(roleName?.includes("HR") || roleName?.includes("Super Admin")) &&
                                <li class="nav-item">
                                  <a class={`nav-link ${activeHR ? 'active' : ''}`}
                                    href="#"
                                    onClick={() => handleChangeActiveTab('HR')}
                                  >
                                    HR
                                  </a>
                                </li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <HtmlTable
                   columnList={columnList}
                   rowList={tempdemandList}
                   id={'DemandRequestTable'}
                   FixedColumns={
                    {
                      left: 2,
                      right: 1,
                    }
                   }
                    />
                   

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default DemandIndex;

