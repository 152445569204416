import React, { useEffect, useState, useRef, useReducer } from "react";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import $ from "jquery";
import swal from "sweetalert";
import AssociateService from "../../api/AssociateService";
import MySelect from "../MultiSelect/Myselect";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

const AppService = new ApplicarionConfigService();
const AssociateServiceobj = new AssociateService();

const Options = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();

function DropdownList() {
  const [DropdowndataList, SetDropdowndataList] = useState([]);
  const [Dropdowntype, SetDropdowntype] = useState([]);

  const [DropdowntypeID, SetDropdowntypeID] = useState("0");
  const [DropdowntypeValue, SetDropdowntypeValue] = useState("");
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const valueRef = useRef(null);
  const SortOrderRef = useRef(null);
  const [RoleList, setRoleList] = useState([]);
  const [rolelistbyselect, setrolelistbyselect] = useState([]);
  const [selectMapping, setSelectmapping] = useState();
  const [isMandatory, setIsMandatory] = useState();
  const onKeyDown = (event) => {
    // if (event.code === 'Space') event.preventDefault()
  };

  useEffect(() => {
    if (DropdowntypeValue !== "") {
      AppService.getData(DropdowntypeValue).then((res) => {
        console.log(res.data);
        if (res != null && res.data != null && res.data.length > 0) {
          SetDropdowndataList([...res.data]);
          console.log([...res.data]);
        } else {
          SetDropdowndataList(null);
        }
      });

      if (DropdowntypeValue == "STEP" || DropdowntypeValue == "Step") {
        $(".SortOrderAll").show();

        $(".SortOrdertableHeader").text("Sort Order");
        $(".StepLeveltableHeader").text("Step Level");
      } else {
        $(".SortOrderAll").hide();
        $(".SortOrdertableHeader").text("");
        $(".StepLeveltableHeader").hide();
      }
      document.getElementById("values").value = "";
      document.getElementById("SortOrder").value = "";
    }

    AssociateServiceobj.Create(0).then((res) => {
      var data = res.data;

      // Assuming data.roleList is an array
      var filteredRoleList = data.roleList.filter((_, index) => index > 1);

      setRoleList(filteredRoleList);

      var stationIDs =
        data.stringRoleID != null ? data.stringRoleID.split(",") : [];
      var stationArray = [];
      RoleList.map((x) => {
        for (var i = 0; i < stationIDs.length; i++) {
          if (Number(stationIDs[i]) == x.value) {
            const values = {
              label: x.label,
              value: x.value,
            };
            stationArray.push(values);
          }
        }
      });
      setrolelistbyselect([...stationArray]);
    });
  }, [DropdowntypeValue, Render]);

  useEffect(() => {
    AppService.DropDownTypeList().then((res) => {
      if (res != null && res.data != null && res.data.length > 0) {
        SetDropdowntype(res.data);
        SetDropdowntypeValue(res.data[0].value);
      }
    });
  }, []);

  function DeleteDropdown(id) {
    $(window).scrollTop(0);
    swal({
      title: "Are You Sure?",

      text: "Do you want to delete this data from the list?",

      buttons: true,

      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const obj = {
          ID: Number(id),
          Term: DropdowntypeValue,
        };
        AppService.Deletedata(obj)
          .then((response) => {
            PageRender();
            swal("Dropdownlist", "Deleted Successfully!", "success");
            document.getElementById("values").value = "";
          })
          .catch((error) => {
            if (error.response.data === "SLA Name Already Exist.") {
              swal("Dropdownlist", "SLA Name Already Exist!", "error");
              // Toast("SLA Name Already Exist.", 'error');
            } else {
              swal("Dropdownlist", "Something went wrong!", "error");
              // Toast("Something went wrong", 'error');
            }
          });
      }
    });
  }
  function checkexitsornot(obj) {
    AppService.Getcheckexitsornot(obj)
      .then((res) => {
        console.log(res);

        GetSortOrdercheckexitsornot(obj);
      })
      .catch((err) => {
        $("#errValue").text("Value is already exists");
      });
  }

  function GetSortOrdercheckexitsornot(obj) {
    AppService.CheckLevelSortOrder(obj)
      .then((res) => {
        Savefunction(obj);
      })
      .catch((err) => {
        $("#errSortOrder").text("Sort Value is already exists");
      });
  }
  function Savefunction(obj) {
    $("#errValue").text("");

    obj.stringRoleID = "";
    obj.roleList = [];

    for (var i = 0; i < rolelistbyselect.length; i++) {
      obj.stringRoleID += rolelistbyselect[i].value + ",";
    }

    obj.rolelistbyselect = {};
    AppService.CreateSave(obj)
      .then((response) => {
        PageRender();
        document.getElementById("values").value = "";
        document.getElementById("SortOrder").value = "";
        $("#StepLevel").val("");
        SetDropdowntypeID(0);
        ClosePop();
        swal("Dropdownlist", "Saved Successfully!", "success");
      })
      .catch((error) => {
        if (error.response.data === "SLA Name Already Exist.") {
          swal("Dropdownlist", "SLA Name Already Exist!", "error");
          // Toast("SLA Name Already Exist.", 'error');
        } else {
          swal("Dropdownlist", "Something went wrong!", "error");
          // Toast("Something went wrong", 'error');
        }
      });
  }
  function SaveDropdown() {
    const obj = {
      ID: Number(DropdowntypeID),
      Term: DropdowntypeValue,
      Value: valueRef.current.value,
      SortOrder: Number(SortOrderRef.current.value),
      StepLevel: $("#StepLevel").val(),
      isMandatory: $("#isMandatory").val() === "true" ? 1 : 0,
    };
    if (obj.Value == null || obj.Value == "") {
      $("#errValue").text("Please Enter Value");
      return false;
    }
    if (DropdowntypeValue == "STEP" || DropdowntypeValue == "Step") {
      if (obj.StepLevel == null || obj.StepLevel == "") {
        $("#errStepLevel").text("Please Select Step Level");
        return false;
      }
      if (rolelistbyselect.values == null || rolelistbyselect == 0) {
        $("#rolelisterr").text("Please Select the Role");

        return false;
      }
      if (obj.SortOrder == null || obj.SortOrder == "") {
        $("#errSortOrder").text("Please Enter Sort Order ");
        return false;
      }
    }

    checkexitsornot(obj);
  }
  function EditDropdown(
    id,
    value,
    sortOrder,
    stepLevel,
    stringRoleID,
    level,
    isMandatory
  ) {
    $(window).scrollTop(0);
    document.getElementById("values").value = value;
    SetDropdowntypeID(id);
    document.getElementById("isMandatory").value = isMandatory;
    setIsMandatory(isMandatory);
    document.getElementById("ID").value = id;
    document.getElementById("SortOrder").value = sortOrder;
    $("#StepLevel").val(level);

    var stationIDs = stringRoleID != null ? stringRoleID.split(",") : [];
    var stationArray = [];
    RoleList.map((x) => {
      for (var i = 0; i < stationIDs.length; i++) {
        if (Number(stationIDs[i]) == x.value) {
          const datats = {
            label: x.label,
            value: x.value,
          };
          stationArray.push(datats);
        }
      }
    });
    setrolelistbyselect([...stationArray]);
  }

  const [ButtonName, setButtonName] = useState("Add");

  const ClosePop = () => {
     $(".close").click();

    if (ButtonName == "Add") {
      setButtonName("Update");
    }
    if (ButtonName == "Update") {
      setButtonName("Add");
    }
    document.getElementById("DDlForm").reset();
    setrolelistbyselect([]);
    $("#errValue").text("");
    $("#errStepLevel").text("");
    $("#rolelisterr").text("");
    $("#errSortOrder").text("");
  };

  function ROleOnchange(e) {
    $("#rolelisterr").text("");
    setrolelistbyselect([...e]);
  }
  function hideError() {
    document.getElementById("errValue").textContent = "";
  }

  function hideError2() {
    document.getElementById("errSortOrder").textContent = "";
  }
  function hideValue() {
    document.getElementById("errStepLevel").textContent = "";
  }

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue == "Profile Mapping") {
      setSelectmapping(selectedValue);
      const trimmedLabel = "Recruiter".trim();
      const FindedRole = RoleList.find(
        (role) => role.label.trim() === trimmedLabel
      );

      setrolelistbyselect([FindedRole]);
    } else {
      setrolelistbyselect([]);
    }

    hideValue();
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="row">
        <div className="col-lg-9">
        </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <select
              className="form-control"
              id=""
              onChange={(e) => SetDropdowntypeValue(e.target.value)}
              value={DropdowntypeValue}
              style={{maxWidth:"100%"}}
            >
              {Dropdowntype &&
                Dropdowntype.map((x, i) => {
                  return (
                    x.term != "STATUS" && (
                      <option key={i} value={x.term}>
                        {x.value}
                      </option>
                    )
                  );
                })}
            </select>
          </div>
          <div className="col-lg-1 col-md-12 col-sm-12">
          <button
          className="btn btn-primary btn-round ml-auto  pull-right btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#DropDownConfig"
          style={{ zIndex: 999, position: "relative" }}
        >
          <i className="fa fa-plus"></i> <span className="ml-1">Add</span>
        </button>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="table-responsive">
          <table
            id="add-row"
            className="display table table-striped table-hover"
          >
            <thead>
              <tr>
                <th style={{ display: "none" }}></th>
                <th>Values</th>

                <th className="StepLeveltableHeader">Step Level</th>
                <th className="SortOrdertableHeader">Sort Order</th>

                <th style={{ width: "10%", textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {DropdowndataList &&
                DropdowndataList.map((x, i) => {
                  return (
                    <tr>
                      <td style={{ display: "none" }}>{x.id}</td>
                      <td>{x.value}</td>
                      <td>{x.stepLevel}</td>
                      {x.sortOrder == 0 ? <td></td> : <td>{x.sortOrder}</td>}

                      <td>
                        {x.value != "Assessment" ? (
                          <div class="form-button-action">
                            <button
                              type="button"
                              title=""
                              class="btn btn-link btn-primary btn-lg"
                              data-original-title="Edit"
                              data-bs-toggle="modal"
                              data-bs-target="#DropDownConfig"
                              onClick={(e) =>
                                EditDropdown(
                                  x.id,
                                  x.value,
                                  x.sortOrder,
                                  x.stepLevel,
                                  x.stringRoleID,
                                  x.level,
                                  x.isMandatory
                                )
                              }
                            >
                              <i class="fa fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              data-bs-toggle="tooltip"
                              title=""
                              class="btn btn-link btn-danger"
                              data-original-title="Remove"
                              onClick={(e) => DeleteDropdown(x.id)}
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="modal fade"
        id="DropDownConfig"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header no-bd">
              <h5 className="modal-title">
                <span className="fw-light">{DropdowntypeID>0?"Update Dropdown value":"Add Dropdown value"}</span>
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={ClosePop}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="DDlForm" style={{ marginTop: -32 }}>
                <div className="row card-body">
                  <div className="col-lg-4 col-md-9 col-sm-12">
                    <div class="form-group">
                      <label>Type</label>
                      <select
                        class="form-control "
                        id=""
                        onChange={(e) => SetDropdowntypeValue(e.target.value)}
                        value={DropdowntypeValue}
                      >
                        {Dropdowntype &&
                          Dropdowntype.map((x, i) => {
                            return (
                              x.term != "STATUS" && (
                                <option key={i} value={x.term}>
                                  {x.value}
                                </option>
                              )
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-9 col-sm-12">
                    <input type="hidden" name="ID" id="ID" />
                    <div class="form-group">
                      <label>Values</label>
                      <input
                        type="text"
                        class="form-control "
                        id="values"
                        ref={valueRef}
                        onKeyDown={(event) => onKeyDown(event)}
                        onChange={() => hideError()}
                      ></input>
                      <span style={{ color: "red" }} id="errValue"></span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-9 col-sm-12 SortOrderAll">
                    <div class="form-group ">
                      <label>
                        Is Mandatory <span className="text-danger"></span>
                      </label>
                      <input
                        type="checkbox"
                        name="isMandatory"
                        id="isMandatory"
                        className="form-control"
                        value={isMandatory}
                        checked={isMandatory}
                        onChange={(e) => setIsMandatory(e.target.checked)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-9 col-sm-12 SortOrderAll ml-3">
                    <div className="row">
                      <div class="form-group col-lg-4 col-md-9 col-sm-12">
                        <label>Step Level</label>
                        <select
                          className="form-control"
                          id="StepLevel"
                          // onchange={() => hideValue()}
                          onChange={handleDropdownChange}
                        >
                          <option value="">Select Step Level</option>
                          <option value="Profile Mapping">
                            Profile Mapping
                          </option>
                          <option value="SPOC">Screening Level's</option>
                          <option value="PMO">Interview Level's</option>
                          <option value="HR">HR Level's</option>
                        </select>
                        <span style={{ color: "red" }} id="errStepLevel"></span>
                      </div>
                      <div class="form-group col-lg-4 col-md-9 col-sm-12">
                        <label>Roles</label>
                        <MySelect
                          options={RoleList}
                          value={rolelistbyselect}
                          name="roleSeletedList"
                          id="roleSeletedList"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          placeholder="Select Role"
                          components={{
                            Options,
                            MultiValue,
                            animatedComponents,
                          }}
                          onChange={ROleOnchange}
                          isMulti
                          allowSelectAll={true}
                        />

                        <span style={{ color: "red" }} id="rolelisterr"></span>
                      </div>
                      <div class="form-group col-lg-4 col-md-9 col-sm-12">
                        <label>Sort Order</label>
                        <input
                          type="number"
                          class="form-control SortOrderAll"
                          id="SortOrder"
                          ref={SortOrderRef}
                          onKeyDown={(event) => onKeyDown(event)}
                          onChange={() => hideError2()}
                        ></input>
                        <span style={{ color: "red" }} id="errSortOrder"></span>
                      </div>
                    </div>
                    <input type="hidden" name="ID" id="ID" />
                  </div>

                  <div
                    className="col-lg-12 mt-6 col-md-9 col-sm-12"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <button
                      type="button"
                      id="addRowButton"
                      class="btn btn-primary btn-sm"
                      style={{ marginTop: "1%" }}
                      onClick={SaveDropdown}
                    >
                      {DropdowntypeID>0?"Update":"Add"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DropdownList;
